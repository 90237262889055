import React, { useEffect, useState } from 'react';

import Cards from 'components/pages/DashboardItems/Cards';
import Navbar from '../../containers/NavBar/NavBar';
import { useNavigate, useLocation } from 'react-router-dom';

const Dashboard = () => {
  const [nombreProyecto, setNombreProyecto] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let token = sessionStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      if (location.state) {
        // @ts-ignore
        setNombreProyecto(location.state.nombre);
      } else {
        navigate('/');
      }
    }
  }, [navigate, location]);

  return (
    <>
      <Navbar></Navbar>
      <h3
        className="mt-3"
        style={{ textAlign: 'center', color: 'rgba(255, 170, 0, 0.968)' }}
      >
        {nombreProyecto}
      </h3>
      {nombreProyecto !== '' ? (
        <div id="window" className="mt-1">
          <Cards />
        </div>
      ) : null}
    </>
  );
};

export default Dashboard;
