import React, { useEffect } from 'react';
import {} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from 'assets/images/newLogo.png';
import Navbar from '../../containers/NavBar/NavBar';

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  return (
    <>
      <Navbar></Navbar>
      <div>
        <div
          className="jumbotron"
          style={{ textAlign: 'center', margin: '20px', height: '87vh' }}
        >
          <img
            src={logo}
            style={{ width: '450px' }}
            className="main-navbar--icon"
            alt=""
          />
          <h1 className="display-4">Sistema de Gestion de Datos</h1>
          <p className="lead">
            Bienvenido al sistema para la gestion de datos de Lar Proyectos.
          </p>
          <hr className="my-4" />
          <p>Seleccione una opcion del menu superior izquierdo para empezar.</p>
        </div>
      </div>
    </>
  );
};

export default Home;
