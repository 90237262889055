// @ts-nocheck
/* eslint-disable no-nested-ternary */
import { Row, Col } from 'reactstrap';
import React from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/es';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  carga: {
    align: 'center',
  },
  date: {
    backgroundColor: 'red !important',
  },
});

export const Filter = ({
  fechaInicio,
  setFechaInicio,
  fechaFin,
  setFechaFin,
  cedula,
  handleChangeCedula,
  lote,
  handleChangeLote,
}) => {
  const classes = useStyles();
  return (
    <>
      <Row className="mb-3">
        <Col xs="3">
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={deLocale}
            className={classes.date}
          >
            <KeyboardDatePicker
              variant="inline"
              InputProps={{ readOnly: true }}
              format="dd-MM-yyyy"
              margin="normal"
              label="Seleccione la fecha inicial"
              value={fechaInicio}
              onChange={(date) => {
                setFechaInicio(date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Col>
        <Col xs="3">
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={deLocale}
            className={classes.date}
          >
            <KeyboardDatePicker
              variant="inline"
              InputProps={{ readOnly: true }}
              format="dd-MM-yyyy"
              margin="normal"
              label="Seleccione la fecha final"
              value={fechaFin}
              onChange={(date) => {
                setFechaFin(date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              minDate={fechaInicio + 1}
              minDateMessage="La fecha final debe ser igual o mayor a la de inicio"
            />
          </MuiPickersUtilsProvider>
        </Col>
        <Col xs="3">
          <label>Cedula</label>
          <input
            placeholder="Ingrese la cédula del cliente"
            className="form-control"
            value={cedula}
            onChange={handleChangeCedula}
          />
        </Col>
        <Col xs="3">
          <label>Plan</label>
          <input
            placeholder="Ingrese el # de lote"
            className="form-control"
            value={lote}
            onChange={handleChangeLote}
          />
        </Col>
      </Row>
    </>
  );
};
