import numeral from 'numeral';

export const setCurrentDate = () => {
  let meses = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  let date = new Date();
  let current =
    date.getDate() + ` de ${meses[date.getMonth()]} de ` + date.getFullYear();
  return current;
};

export const bodyTableExport = (data) => {
  if (data.length > 0) {
    return data.map((row) => {
      return (
        <tr>
          <td colSpan={6}>{row.nombre}</td>
          <td colSpan={2}>{row.proyecto}</td>
          <td colSpan={2}>{row.lote}</td>
          <td colSpan={2}>{numeral(row.valorLote).format('$ 0,0')}</td>
          <td colSpan={2}>{numeral(row.pagoTotal).format('$ 0,0')}</td>
          <td colSpan={2}>{numeral(row.abonoCapital).format('$ 0,0')}</td>
          <td colSpan={2}>{numeral(row.abonoInteres).format('$ 0,0')}</td>
          <td colSpan={2}>{numeral(row.totalProyeccion).format('$ 0,0')}</td>
          <td colSpan={2}>{numeral(row.capitalProyeccion).format('$ 0,0')}</td>
          <td colSpan={2}>{numeral(row.interesProyeccion).format('$ 0,0')}</td>
        </tr>
      );
    });
  }
  return null;
};
