import { Button } from '@mui/material';
import React from 'react'
import './ModalDetail.css';

const ModalDetail = ({ handleClose, show, title, message }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <h4 className='title'>{title}</h4>
        <hr/>
        <h5>{message}</h5>
        <Button type="button" style={{textTransform: 'none', fontSize: 18}} onClick={handleClose}>
          Cerrar
        </Button>
      </section>
    </div>
  )
}

export default ModalDetail
