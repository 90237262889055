import React from 'react';
import Navbar from 'components/containers/NavBar/NavBar';


const App = () => {
  return (
    <>
      <Navbar></Navbar>
    </>
  );
};

export default App;
