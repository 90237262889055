/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { Row, Col, Button, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import client from 'utils/database';
import { USERREPORT, LOTESREPORT } from 'utils/database/query/reports';

import PlanReport from './PlanReport';
import QuoteReport from './QuoteReport';
import PaymentReport from './PaymentReport';
import LoteReport from './LotesReport';
import { Proyeccion } from './Proyeccion';
import ProyeccionVsPagos from './proyeccionVsPagos/ProyeccionVsPagos';

export default class Reportes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proyecto: '',
      tipoReporte: 'Planes',
      reportUser: [],
      reportLots: [],
      nombreReporte: '',
      showPlanes: false,
      showCuotas: false,
      showPagos: false,
      showLotes: false,
      showProyeccion: false,
      showProyeccionVsPagos: false,
    };
  }

  componentDidMount() {
    this.initReportes();
  }

  initReportes = () => {
    const proyecto = JSON.parse(sessionStorage.getItem('configuracion')).nombre;
    let currentDate = new Date();
    // @ts-ignore
    currentDate = `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()}`;
    this.setState({
      proyecto,
      nombreReporte: `Reporte Planes ${currentDate}`,
      // arrCampos: optionPlan
    });
  };

  handleChangeTipoReporte = (e) => {
    // let arrCampos = []
    let currentDate = new Date();
    // @ts-ignore
    currentDate = `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()}`;

    switch (e.target.value) {
      case 'Planes':
        this.setState({
          nombreReporte: `Reporte Planes ${currentDate}`,
        });
        break;
      case 'Pagos':
        this.setState({
          nombreReporte: `Reporte Pagos ${currentDate}`,
        });
        break;
      case 'Cuotas':
        this.setState({
          nombreReporte: `Reporte Cuotas ${currentDate}`,
        });
        break;
      case 'ReporteInmuebles':
        this.setState({
          nombreReporte: `Reporte Inmuebles ${currentDate}`,
        });
        break;
      case 'Proyeccion':
        this.setState({
          nombreReporte: `Reporte Proyeccion ${currentDate}`,
        });
        break;
      case 'ProyeccionVsPagos':
        this.setState({
          nombreReporte: `Reporte Proyeccion vs Pagos ${currentDate}`,
        });
        break;
      default:
        this.setState({
          nombreReporte: ``,
        });
    }

    this.setState({
      tipoReporte: e.target.value,
    });
  };

  handleRealizacionReporte = () => {
    this.setState({
      loading: true,
    });
    const { tipoReporte } = this.state;
    if (tipoReporte === 'Planes') {
      this.reportePlan();
    } else if (tipoReporte === 'Pagos') {
      this.reportePagos();
    } else if (tipoReporte === 'Cuotas') {
      this.reporteCuotas();
    } else if (tipoReporte === 'ReporteInmuebles') {
      this.reporteLotes();
    } else if (tipoReporte === 'Proyeccion') {
      this.reporteProyeccion();
    } else if (tipoReporte === 'ProyeccionVsPagos') {
      this.reporteProyeccionVsPagos();
    }
  };

  reportePlan = () => {
    client.query({ query: USERREPORT }).then((result) => {
      this.setState({
        reportUser: result.data.ReporteUsuario,
        showPlanes: true,
        showCuotas: false,
        showPagos: false,
        showLotes: false,
        showProyeccion: false,
        showProyeccionVsPagos: false,
        loading: false,
      });
    });
  };

  reportePagos = () => {
    this.setState({
      showPlanes: false,
      showCuotas: false,
      showPagos: true,
      showLotes: false,
      showProyeccion: false,
      showProyeccionVsPagos: false,
      loading: false,
    });
  };

  reporteCuotas = () => {
    this.setState({
      showPlanes: false,
      showCuotas: true,
      showPagos: false,
      showLotes: false,
      showProyeccion: false,
      showProyeccionVsPagos: false,
      loading: false,
    });
  };

  makeReporteUsuario = () => {
    const report = [];
    const { reportUser, proyecto } = this.state;
    if (reportUser.length > 0) {
      reportUser.map((plan) => {
        return plan.userdata.map((finalUser) => {
          return finalUser.planes.map((finalPlan) => {
            if (
              finalPlan.idPlan === plan._id &&
              finalPlan.proyecto === proyecto
            ) {
              report.push({
                cedula: finalUser.cedula,
                nombre: finalUser.nombre,
                celular: finalUser.celular,
                direccion: finalUser.direccion,
                descripcion: finalPlan.descripcion,
                estado: finalPlan.estado,
                vendedor: plan.vendedor,
                fechaRealizacion: plan.fechaRealizacionPlan,
                fechaInicial: plan.fechaInicialPlan,
                numCuotas: plan.numeroCuotas,
                valorAFinanciar: plan.valorAFinanciar,
                valorInmueble: plan.valorTotal,
                saldoRestante: plan.saldoRestante,
              });
              return null;
            }
            return null;
          });
        });
      });
    }
    return report;
  };

  makeReporteLotes = () => {
    const report = [];
    const { reportLots } = this.state;
    if (reportLots.length > 0) {
      reportLots.map((lote) => {
        report.push({
          tipoInmueble: lote.tipoInmueble,
          numeroLote: lote.numeroLote,
          estado: lote.estado,
          cedulaCliente: lote.idCliente,
          vendedor: lote.idVendedor,
        });
        return null;
      });
    }
    return report;
  };

  reporteLotes = () => {
    const idProyecto = JSON.parse(sessionStorage.getItem('configuracion'))._id;
    client
      .query({ query: LOTESREPORT, variables: { idProyecto } })
      .then((result) => {
        this.setState({
          reportLots: result.data.ReporteLotes,
          showPlanes: false,
          showCuotas: false,
          showPagos: false,
          showLotes: true,
          showProyeccion: false,
          showProyeccionVsPagos: false,
          loading: false,
        });
      });
  };

  reporteProyeccion = () => {
    this.setState({
      showPlanes: false,
      showCuotas: false,
      showPagos: false,
      showLotes: false,
      showProyeccion: true,
      showProyeccionVsPagos: false,
      loading: false,
    });
  };

  reporteProyeccionVsPagos = () => {
    this.setState({
      showPlanes: false,
      showCuotas: false,
      showPagos: false,
      showLotes: false,
      showProyeccion: false,
      showProyeccionVsPagos: true,
      loading: false,
    });
  };

  getFilename = () => {
    const { nombreReporte } = this.state;
    const timestamp = Math.floor(Date.now() / 1000);
    return `${nombreReporte}_${timestamp}.csv`;
  };

  render() {
    const {
      loading,
      tipoReporte,
      showPlanes,
      showCuotas,
      showLotes,
      showPagos,
      showProyeccion,
      showProyeccionVsPagos
    } = this.state;
    const ReportPlans = this.makeReporteUsuario();
    const ReportLots = this.makeReporteLotes();

    return (
      <Row className="p-3">
        <Col>
          <Row>
            <Col>
              <Breadcrumb>
                <BreadcrumbItem>
                  <a href="dashboard">Dashboard</a>
                </BreadcrumbItem>
                <BreadcrumbItem active>Reportes</BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
          <Row className="form-group row Common">
            <Col xs={12} sm={6} md={4} lg={3} xl={2}>
              <label> Tipo Reporte: </label>
              <select
                className="form-control"
                value={tipoReporte}
                onChange={this.handleChangeTipoReporte}
              >
                <option>Planes</option>
                <option>Pagos</option>
                <option>Cuotas</option>
                <option>ReporteInmuebles</option>
                <option>Proyeccion</option>
                <option>ProyeccionVsPagos</option>
              </select>
            </Col>
            <Col xs={12} sm={6} md={4} lg={3} xl={2} className="mt-2">
              <Button
                type="button"
                color="primary"
                className="mt-4 w-100"
                onClick={this.handleRealizacionReporte}
              >
                {!loading ? (
                  'Ir a reporte'
                ) : (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {` Cargando...`}
                  </>
                )}
              </Button>
            </Col>
          </Row>

          <PlanReport data={ReportPlans} show={showPlanes} filter="" />
          <QuoteReport
            proyecto={this.state.proyecto}
            show={showCuotas}
            filter=""
          />
          <PaymentReport
            proyecto={this.state.proyecto}
            show={showPagos}
            filter=""
          />
          <LoteReport data={ReportLots} show={showLotes} filter="" />
          <Proyeccion
            proyecto={this.state.proyecto}
            show={showProyeccion}
            filter=""
          />
          <ProyeccionVsPagos
            proyecto={this.state.proyecto}
            show={showProyeccionVsPagos}
            filter=""
            nombre={this.state.nombreReporte}
          />
        </Col>
      </Row>
    );
  }
}
