import React from 'react';
import { Link } from 'react-router-dom';

const ToLogin = () => {
  return (
    <>
      <div
          className="jumbotron"
          style={{ textAlign: 'center', margin: '20px' }}
        >
          <h1 className="display-5">Debe estar logeado para ingresar a esta sesion.</h1>
          <Link to={'/login'}>Ir a login</Link>
        </div>
    </>
  )
}

export default ToLogin;
