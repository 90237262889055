// @ts-nocheck
import React, { Component, useEffect, useMemo } from 'react';
import { Row, Col, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useTable } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { USERS, SEARCHUSERBYID } from 'utils/database/query/users';
import { UPDATEUSER, CREATEUSER } from 'utils/database/mutation/users';
import Navbar from '../../../containers/NavBar/NavBar';
import client from 'utils/database';
import axiosInstance from 'utils/database/api-leads';
import ToLogin from 'utils/ToLogin/ToLogin';
import { FormControl, MenuItem, TextField } from '@mui/material';
import Loading from 'components/containers/Loading/Loading';

export default class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      users: [],
      filteredUsers: [],
      cedula: '',
      rol: {
        idRol: 2,
        nombre: 'Empleado',
      },
      estado: '',
      nombre: '',
      cotizacion: {
        basico: false,
        tasaInteres: false,
        cobroInteresExtra: false,
      },
      modificaciones: {
        basico: false,
      },
      reportes: {
        basico: false,
      },
      pagos: {
        basico: false,
        editar: false,
        eliminar: false,
      },
      consultas: {
        basico: false,
        eliminar: false,
        descargar: false,
        validar: false,
      },
      leads: {
        cargarLeads: false,
        crearLead: false,
        asignarEspecialista: false,
        informes: false,
        gestionar: false,
        gestionesProgramadas: false
      },
      gestionesPersuasivas: {
        verSeguimientos: false,
        crearSeguimiento: false,
      },
      proyectos: {
        editar: false,
        eliminar: false,
      },
      loading: false,
      isNew: false,
      show: false,
      modalEdit: false,
      messageModal: '',
      titleModal: <div className="center success" />,
      logged: false,
      userState: '',
      loadingData: false,
    };
  }

  states = ['Activo', 'Inactivo'];

  componentWillMount() {
    let token = sessionStorage.getItem('token');
    if (!token) {
      this.setState({ logged: false });
    } else {
      this.setState({ logged: true });
      this.loadRoles();
      this.loadusers();
    }
  }

  loadRoles = () => {
    axiosInstance.get(`/roles`).then((resp) => {
      const allRoles = resp.data;
      this.setState({
        roles: allRoles.data.filter((rol) => rol.idRol !== 3),
      });
    });
  };

  loadusers = () => {
    this.setState({
      loadingData: true,
    });
    client.mutate({ mutation: USERS }).then(async (result) => {
      const userFilter = await result.data.Users.filter(
        (element) => element.rol.idRol !== 3 && element.estado !== 'Eliminado'
      );
      this.setState({
        users: userFilter,
        filteredUsers: userFilter,
        id: '',
        cedula: '',
        nombre: '',
        celular: '',
        correo: '',
        direccion: '',
        rol: {
          idRol: 2,
          nombre: 'Empleado',
        },
        estado: '',
        password: '',
        cotizacion: {
          basico: false,
          tasaInteres: false,
          cobroInteresExtra: false,
        },
        modificaciones: {
          basico: false,
        },
        reportes: {
          basico: false,
        },
        pagos: {
          basico: false,
          editar: false,
          eliminar: false,
        },
        consultas: {
          basico: false,
          eliminar: false,
          descargar: false,
          validar: false,
        },
        leads: {
          cargarLeads: false,
          crearLead: false,
          asignarEspecialista: false,
          informes: false,
          gestionar: false,
          gestionesProgramadas: false
        },
        gestionesPersuasivas: {
          verSeguimientos: false,
          crearSeguimiento: false,
        },
        proyectos: {
          editar: false,
          eliminar: false,
        },
        loadingData: false,
      });
    });
  };

  handleChangeCedula = (e) => {
    this.setState({
      cedula: e.target.value,
    });
  };

  handleChangeNombre = (e) => {
    this.setState({
      nombre: e.target.value,
    });
  };

  handleChangeDireccion = (e) => {
    this.setState({
      direccion: e.target.value,
    });
  };

  handleChangeCelular = (e) => {
    this.setState({
      celular: e.target.value,
    });
  };

  handleChangeCorreo = (e) => {
    this.setState({
      correo: e.target.value,
    });
  };

  handleChangeTipo = (e) => {
    const idRol = e.target.value;
    const rol = this.state.roles.filter(
      (role) => role.idRol === parseInt(idRol)
    )[0];
    this.setState({
      rol: {
        idRol: rol.idRol,
        nombre: rol.nombre,
      },
    });
  };

  handleChangeEstado = (e) => {
    this.setState({
      estado: e.target.value,
    });
  };

  handleChangePassword = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  handleChangePermissions = (e) => {
    let state = e.target.id;
    let field = state.substring(0, state.indexOf('.'));
    let subfield = state.substring(state.indexOf('.') + 1);
    console.log(this.state[field][subfield], subfield);
    let currentValue = this.state[field][subfield];
    if (currentValue) {
      this.setState({
        [field]: {
          ...this.state[field],
          [subfield]: false,
        },
      });
    } else {
      this.setState({
        [field]: {
          ...this.state[field],
          [subfield]: true,
        },
      });
    }
  };

  handleEdituser = (id) => {
    const UserID = id;
    client
      .query({ query: SEARCHUSERBYID, variables: { id: UserID } })
      .then((result) => {
        this.setState({
          isNew: false,
          modalEdit: true,
          id: UserID,
          cedula: result.data.SearchUserById.cedula,
          nombre: result.data.SearchUserById.nombre,
          celular: result.data.SearchUserById.celular,
          correo: result.data.SearchUserById.correo,
          direccion: result.data.SearchUserById.direccion,
          rol: result.data.SearchUserById.rol,
          estado: result.data.SearchUserById.estado,
          cotizacion: result.data.SearchUserById.permisos.cotizacion,
          consultas: result.data.SearchUserById.permisos.consultas,
          pagos: result.data.SearchUserById.permisos.pagos,
          reportes: result.data.SearchUserById.permisos.reportes,
          modificaciones: result.data.SearchUserById.permisos.modificaciones,
          leads: result.data.SearchUserById.permisos.leads,
          gestionesPersuasivas:
            result.data.SearchUserById.permisos.gestionesPersuasivas,
          proyectos: result.data.SearchUserById.permisos.proyectos,
        });
      });
  };

  handleCreateUser = () => {
    this.setState({
      isNew: true,
      modalEdit: true,
      cedula: '',
      nombre: '',
      celular: '',
      correo: '',
      direccion: '',
    });
  };

  checkFieldsCreate = () => {
    let { cedula, nombre, celular, correo, direccion, password } = this.state;
    let arr = [cedula, nombre, celular, correo, direccion, password];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === '') return false;
    }
    return true;
  };

  handleUploadUser = () => {
    if (this.checkFieldsCreate()) {
      this.UploadCreateUser();
    } else {
      this.setState({
        show: true,
        messageModal: 'Todos los campos deben estar llenos',
        titleModal: <div className="center wrong" />,
      });
    }
  };

  UploadCreateUser = () => {
    this.setState({
      loading: true,
    });

    let permissions = {
      cotizacion: this.state['cotizacion'],
      consultas: this.state['consultas'],
      pagos: this.state['pagos'],
      reportes: this.state['reportes'],
      modificaciones: this.state['modificaciones'],
      leads: this.state['leads'],
      gestionesPersuasivas: this.state['gestionesPersuasivas'],
      proyectos: this.state['proyectos'],
    };

    delete permissions.cotizacion.__typename;
    delete permissions.consultas.__typename;
    delete permissions.pagos.__typename;
    delete permissions.reportes.__typename;
    delete permissions.modificaciones.__typename;
    delete permissions.leads.__typename;
    delete permissions.gestionesPersuasivas.__typename;
    delete permissions.proyectos.__typename;

    let dataUser = {
      nombre: this.state.nombre,
      cedula: this.state.cedula,
      celular: this.state.celular,
      direccion: this.state.direccion,
      rol: this.state.rol,
      correo: this.state.correo,
      password: this.state.password,
      permisos: permissions,
    };

    client
      .mutate({ mutation: CREATEUSER, variables: { data: dataUser } })
      .then((user) => {
        this.setState({
          loading: false,
          show: true,
          messageModal: 'Usuario creado correctamente',
          titleModal: <div className="center success" />,
        });
        this.loadusers();
      })
      .catch(() => {
        this.setState({
          loading: false,
          show: true,
          messageModal:
            'Ocurrio un error al crear el usuario en la base de datos',
          titleModal: <div className="center wrong" />,
        });
      });
  };

  checkFieldsUpdate = () => {
    let { cedula, nombre, celular, correo, direccion, estado } = this.state;
    let arr = [cedula, nombre, celular, correo, direccion, estado];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === '') return false;
    }
    return true;
  };

  handleUpdateUser = () => {
    if (this.checkFieldsUpdate()) {
      this.UpdateUser();
    } else {
      this.setState({
        show: true,
        messageModal: 'Todos los campos deben estar llenos',
        titleModal: <div className="center wrong" />,
      });
    }
  };

  UpdateUser = () => {
    this.setState({
      loading: true,
    });
    let permissions = {
      cotizacion: this.state['cotizacion'],
      consultas: this.state['consultas'],
      pagos: this.state['pagos'],
      reportes: this.state['reportes'],
      modificaciones: this.state['modificaciones'],
      leads: this.state['leads'],
      gestionesPersuasivas: this.state['gestionesPersuasivas'],
      proyectos: this.state['proyectos'],
    };

    delete permissions.cotizacion.__typename;
    delete permissions.consultas.__typename;
    delete permissions.pagos.__typename;
    delete permissions.reportes.__typename;
    delete permissions.modificaciones.__typename;
    delete permissions.leads.__typename;
    delete permissions.gestionesPersuasivas.__typename;
    delete permissions.proyectos.__typename;

    let dataUser = {
      nombre: this.state.nombre,
      cedula: this.state.cedula,
      celular: this.state.celular,
      direccion: this.state.direccion,
      password: this.state.password,
      rol: this.state.rol,
      estado: this.state.estado,
      correo: this.state.correo,
      permisos: permissions,
    };

    delete dataUser.rol.__typename;

    if (dataUser.password === '') {
      delete dataUser.password;
    }

    client
      .mutate({
        mutation: UPDATEUSER,
        variables: { id: this.state.id, data: dataUser },
      })
      .then((user) => {
        this.setState({
          loading: false,
          show: true,
          messageModal: 'Usuario actualizado correctamente',
          titleModal: <div className="center success" />,
          modalEdit: false,
        });
        this.loadusers();
      })
      .catch(() => {
        this.setState({
          loading: false,
          show: true,
          messageModal:
            'Ocurrio un error al actualizar el usuario en la base de datos',
          titleModal: <div className="center wrong" />,
        });
      });
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  toggleEdit = () => {
    this.setState({
      modalEdit: false,
    });
  };

  handleChangeUserState = (e) => {
    const newUserState = e.target.value;
    let users = this.state.users.filter(
      (user) => user.estado === e.target.value
    );
    this.setState({
      userState: newUserState,
      filteredUsers: users,
    });
  };

  render() {
    return !this.state.logged ? (
      <ToLogin />
    ) : (
      <>
        <Navbar></Navbar>
        <Row className="main-menu--user-page pt-5 pl-4">
          <Col>
            <Row>
              <Col>
                <div className="main--page-title text-center mt-3">
                  Lista de usuarios
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  color="primary"
                  title="Añadir Nuevo Usuario"
                  onClick={this.handleCreateUser}
                >
                  Añadir usuario
                </Button>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <FormControl sx={{ m: 1, minWidth: 250, maxWidth: 300 }}>
                  <TextField
                    select
                    value={this.state.userState}
                    onChange={this.handleChangeUserState}
                    label="Estado"
                  >
                    {this.states.map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                {this.state.loadingData ? (
                  <Loading />
                ) : (
                  <Table
                    data={this.state.filteredUsers}
                    handleEdit={this.handleEdituser}
                  />
                )}
              </Col>
            </Row>
            <br />
          </Col>
        </Row>

        <Modal
          centered
          size="lg"
          isOpen={this.state.modalEdit}
          toggle={this.toggleEdit}
        >
          <ModalBody>
            <Row className="mt-2 mb-2">
              <Col>
                <div className="main--modal-title mt-3 mb-3">
                  {this.state.isNew ? 'Crear' : 'Actualizar'} Usuario
                </div>
              </Col>
            </Row>
            <Row className="mt-2 mb-2">
              <Col>
                <label>Cedula *</label>
                <input
                  className="form-control"
                  value={this.state.cedula}
                  type="number"
                  onChange={this.handleChangeCedula}
                />
              </Col>
              <Col>
                <label>Nombre *</label>
                <input
                  className="form-control"
                  value={this.state.nombre}
                  onChange={this.handleChangeNombre}
                />
              </Col>
            </Row>
            <Row className="mt-2 mb-2">
              <Col>
                <label>Correo *</label>
                <input
                  className="form-control"
                  value={this.state.correo}
                  type="email"
                  onChange={this.handleChangeCorreo}
                />
              </Col>
              <Col>
                <label>Celular *</label>
                <input
                  type="number"
                  className="form-control"
                  value={this.state.celular}
                  onChange={this.handleChangeCelular}
                />
              </Col>
            </Row>
            <Row className="mt-2 mb-2">
              <Col>
                <label>Direccion *</label>
                <input
                  className="form-control"
                  value={this.state.direccion}
                  onChange={this.handleChangeDireccion}
                />
              </Col>
              {this.state.isNew ? (
                <Col>
                  <label>Contraseña *</label>
                  <input
                    className="form-control"
                    type="password"
                    value={this.state.password}
                    onChange={this.handleChangePassword}
                  />
                </Col>
              ) : (
                <Col>
                  <label>Contraseña</label>
                  <input
                    className="form-control"
                    placeholder="Nueva contraseña"
                    type="password"
                    value={this.state.password}
                    onChange={this.handleChangePassword}
                  />
                </Col>
              )}
            </Row>
            <hr />
            <Row>
              <Col xs={{ offset: 2 }}>
                <label>Rol *</label>
              </Col>
              <Col xs={{ size: 3 }}>
                <select
                  className="form-control"
                  value={this.state.rol.idRol}
                  onChange={this.handleChangeTipo}
                >
                  {this.state.roles.map((rol, index) => (
                    <option key={index} value={rol.idRol}>
                      {rol.nombre}
                    </option>
                  ))}
                </select>
              </Col>
              <Col xs={{ offset: 1 }}>
                <label>Estado *</label>
              </Col>
              <Col xs={{ size: 3 }}>
                <select
                  className="form-control"
                  value={this.state.estado}
                  onChange={this.handleChangeEstado}
                  disabled={false}
                >
                  <option value="Activo">Activo</option>
                  <option value="Inactivo">Inactivo</option>
                </select>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>Permisos</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <strong>COTIZACION</strong>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <input
                      id="cotizacion.basico"
                      type="checkbox"
                      checked={this.state.cotizacion.basico}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Acceso</label>
                  </Col>
                  <Col xs={3}>
                    <input
                      id="cotizacion.tasaInteres"
                      type="checkbox"
                      checked={this.state.cotizacion.tasaInteres}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Tasa Interes</label>
                  </Col>
                  <Col xs={3}>
                    <input
                      id="cotizacion.cobroInteresExtra"
                      type="checkbox"
                      checked={this.state.cotizacion.cobroInteresExtra}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Interes Cuotas Extra</label>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <strong>CONSULTAS</strong>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <input
                      id="consultas.basico"
                      type="checkbox"
                      checked={this.state.consultas.basico}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Acceso</label>
                  </Col>
                  <Col xs={3}>
                    <input
                      id="consultas.validar"
                      type="checkbox"
                      checked={this.state.consultas.validar}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Validar</label>
                  </Col>
                  <Col xs={3}>
                    <input
                      id="consultas.descargar"
                      type="checkbox"
                      checked={this.state.consultas.descargar}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Descargar</label>
                  </Col>
                  <Col xs={3}>
                    <input
                      id="consultas.eliminar"
                      type="checkbox"
                      checked={this.state.consultas.elminar}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Eliminar</label>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <strong>Pagos</strong>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <input
                      id="pagos.basico"
                      type="checkbox"
                      checked={this.state.pagos.basico}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Acceso</label>
                  </Col>
                  <Col xs={3}>
                    <input
                      id="pagos.editar"
                      type="checkbox"
                      checked={this.state.pagos.editar}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Editar Pagos</label>
                  </Col>
                  <Col xs={3}>
                    <input
                      id="pagos.eliminar"
                      type="checkbox"
                      checked={this.state.pagos.eliminar}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Eliminar Pagos</label>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <strong>Reportes</strong>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <input
                      id="reportes.basico"
                      type="checkbox"
                      checked={this.state.reportes.basico}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Acceso</label>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <strong>Modificaciones</strong>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <input
                      id="modificaciones.basico"
                      type="checkbox"
                      checked={this.state.modificaciones.basico}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Acceso</label>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* LEADS */}
            <Row>
              <Col>
                <Row>
                  <Col>
                    <strong>LEADS</strong>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <input
                      id="leads.cargarLeads"
                      type="checkbox"
                      checked={this.state.leads.cargarLeads}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Cargar</label>
                  </Col>
                  <Col xs={3}>
                    <input
                      id="leads.crearLead"
                      type="checkbox"
                      checked={this.state.leads.crearLead}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Crear/actualizar</label>
                  </Col>
                  <Col xs={3}>
                    <input
                      id="leads.asignarEspecialista"
                      type="checkbox"
                      checked={this.state.leads.asignarEspecialista}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Asignar Esp.</label>
                  </Col>
                  <Col xs={3}>
                    <input
                      id="leads.gestionesProgramadas"
                      type="checkbox"
                      checked={this.state.leads.gestionesProgramadas}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Gestiones Progr.</label>
                  </Col>
                  <Col xs={3}>
                    <input
                      id="leads.gestionar"
                      type="checkbox"
                      checked={this.state.leads.gestionar}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Gestionar</label>
                  </Col>
                  <Col xs={3}>
                    <input
                      id="leads.informes"
                      type="checkbox"
                      checked={this.state.leads.informes}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Informes</label>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* GESTIONES PERSUASIVAS */}
            <Row>
              <Col>
                <Row>
                  <Col>
                    <strong>GESTIONES PERSUASIVAS</strong>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <input
                      id="gestionesPersuasivas.verSeguimientos"
                      type="checkbox"
                      checked={this.state.gestionesPersuasivas.verSeguimientos}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Ver Seguimientos</label>
                  </Col>
                  <Col xs={3}>
                    <input
                      id="gestionesPersuasivas.crearSeguimiento"
                      type="checkbox"
                      checked={this.state.gestionesPersuasivas.crearSeguimiento}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Crear Seguimiento</label>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* PROYECTOS */}
            <Row>
              <Col>
                <Row>
                  <Col>
                    <strong>PROYECTOS</strong>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <input
                      id="proyectos.editar"
                      type="checkbox"
                      checked={this.state.proyectos.editar}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Editar</label>
                  </Col>
                  <Col xs={3}>
                    <input
                      id="proyectos.eliminar"
                      type="checkbox"
                      checked={this.state.proyectos.eliminar}
                      onClick={this.handleChangePermissions}
                    />
                    <label className="ml-2">Eliminar</label>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <input className='invisibleInput' value={this.state.password} onChange={this.handleChangePassword} /> */}
            <Row>
              <Col>
                <Button
                  id="updateButton"
                  className="btn btn-primary btnIndex"
                  onClick={
                    this.state.isNew
                      ? this.handleUploadUser
                      : this.handleUpdateUser
                  }
                  disabled={this.state.loading}
                >
                  {!this.state.loading ? (
                    <>{this.state.isNew ? 'Crear' : 'Guardar'}</>
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                      {` Enviando...`}
                    </>
                  )}
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.show}>
          {this.state.titleModal}
          <ModalBody>{this.state.messageModal}</ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-default"
              onClick={this.handleClose}
            >
              Cerrar
            </button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const Table = ({ data, handleEdit }) => {
  const columns = useMemo(
    () => [
      {
        Header: () => null,
        accessor: '_id',
        Cell: ({ row }) => (
          <Button
            className="main--edit-button"
            onClick={() => handleEdit(row.values._id, row.values.nombre)}
          >
            <FontAwesomeIcon icon={['far', 'edit']} color="grey" />
          </Button>
        ),
      },
      {
        Header: 'Nombre',
        accessor: 'nombre',
      },
      {
        Header: 'Tipo',
        accessor: 'rol.nombre',
      },
      {
        Header: 'Correo',
        accessor: 'correo',
      },
      {
        Header: 'Estado',
        accessor: 'estado',
      },
    ],
    [handleEdit]
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      columns,
      data,
    });

  useEffect(() => {}, [data]);

  return (
    <div className="view-history">
      <table
        {...getTableProps()}
        className="table table-bordered table-striped main-menu--projects-table"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr className="table-light" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
