import gql from 'graphql-tag';

export const UPLOADPLAN = gql`
  mutation UPLOADPLAN($datos: PlansInput) {
    CreatePlan(data: $datos) {
      _id
    }
  }
`;

export const UPDATESTATEPLAN = gql`
  mutation UPDATESTATEPLAN($id: ID, $idPlan: ID, $state: String) {
    UpdatePlanState(id: $id, idPlan: $idPlan, state: $state) {
      _id
    }
  }
`;

export const UPDATEPLAN = gql`
  mutation UPLOADPLAN($id: ID, $data: UpdatePlansInput) {
    UpdatePlan(id: $id, data: $data) {
      _id
    }
  }
`;

export const DELETEPLAN = gql`
  mutation DELETEPLAN($id: ID) {
    DeletePlan(idPlan: $id) {
      _id
    }
  }
`;

export const DELETEUSERPLAN = gql`
  mutation DELETEPLANUSER($idUser: ID, $idPlan: ID, $idPlanDelete: ID) {
    DeletePlanUser(idUser: $idUser, idPlan: $idPlan, idPlanDelete: $idPlanDelete) {
      planes {
        estado
        descripcion
      }
    }
  }
`;
