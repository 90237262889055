import { Button } from '@mui/material';
import React from 'react'
import './ModalAuth.css';
const ModalAuth = ({ handleClose, show }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <h4>TRATAMIENTOS DE DATOS</h4>
        <h5>LAR PROYECTOS S.A.S solicita su autorización para la recopilación,
          almacenamiento y tratamiento de sus datos personales,
          y le informa que estos datos se utilizarán únicamente para las siguientes finalidades:
          VINCULACIÓN, ATENCIÓN AL CLIENTE, SOLICITUD DE PRODUCTOS O SERVICIOS,
          CONTRATOS LABORALES O CIVILES,
          y demás finalidades descritas en la Política de Tratamiento y
          Protección de Datos Personales de la sociedad LAR PROYECTOS S.A.S.
          Sus datos personales son tratados y protegidos de acuerdo a lo que reglamentan dichas políticas,
          con apego a lo dispuesto por la normativa colombiana sobre Tratamiento de Datos Personales,
          Ley 1581 de 2012 y el Decreto 1377 de 2013.
          Consulte nuestras Políticas para el Tratamiento y Protección de Datos en
          <br/>
          <a target="_blank" rel="noreferrer" href='https://larproyectos.com.co/politica-de-privacidad/'>
            https://larproyectos.com.co/politica-de-privacidad/
          </a>
          </h5>
        <Button type="button" onClick={handleClose}>
          Aceptar
        </Button>
      </section>
    </div>
  )
}

export default ModalAuth

