import React from 'react'
import logo from 'assets/images/padlock-g9bd6ddb35_1280.png';

const NoAuth = () => {
  return (
    <>
        <div
          className="jumbotron"
          style={{ textAlign: 'center', margin: '20px', height: '45vh' }}
        >
          <img
            src={logo}
            style={{ width: '130px'}}
            className="main-navbar--icon"
            alt=""
          />
          <br/>
          <br/>
          <h4 style={{color: 'red'}}>Sin permisos suficientes.</h4>
        </div>
      </>
  )
}

export default NoAuth
