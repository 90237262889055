import React from 'react';
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap';
import TableConsultas from './TableConsultas';

const Consultas = () => {
  return (
    <>
      <Row className="p-3">
        <Col>
          <Row>
            <Col>
              <Breadcrumb>
                <BreadcrumbItem>
                  <a href="dashboard">Dashboard</a>
                </BreadcrumbItem>
                <BreadcrumbItem active>Consultas</BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
          <Row>
            <Col>
              <div id="rootConsultas">
                <TableConsultas />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Consultas;
