import { Box, Grid, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

const DetalleLead = ({ lead }) => {
  const [leadInfo, setLeadInfo] = useState({
    nombreCompleto: '',
    cedula: '',
    celular: '',
    celularRespaldo: '',
    correo: '',
    edad: '',
    nivelProfesional: '',
    profesion: '',
    ciudadResidencia: '',
    proyecto: '',
    comoNosConocio: '',
    especialistaAsignado: '',
    campaña: '',
    inmueble: '',
  });

  const handleChange = (event) => {
    setLeadInfo({ ...leadInfo, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    if (lead !== undefined && lead !== null) {
      setLeadInfo({
        nombreCompleto: lead.nombreCompleto,
        cedula: lead.cedula,
        celular: lead.celular,
        celularRespaldo: lead.celularRespaldo,
        correo: lead.correo,
        edad: lead.edad,
        nivelProfesional: lead.nivelProfesional?.nombre,
        profesion: lead.profesion,
        ciudadResidencia: lead.ciudadResidencia,
        proyecto: lead.proyecto?.nombre,
        comoNosConocio: lead.comoNosConocio,
        especialistaAsignado: lead.especialistaAsignado?.nombre,
        campaña: lead.campaña,
        inmueble: lead.inmueble,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead]);

  return (
    <Box sx={{ height: 110, width: '100%' }}>
      <Typography
        gutterBottom
        variant="h6"
        style={{
          textAlign: 'center',
          color: 'rgba(255, 170, 0, 0.968)',
        }}
      >
        Resumen Lead
      </Typography>
      <br />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <TextField
            placeholder="Ingrese el nombre completo"
            label="Nombre Completo"
            variant="outlined"
            fullWidth
            disabled
            name="nombreCompleto"
            value={leadInfo.nombreCompleto || ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid xs={12} sm={3} item>
          <TextField
            placeholder="Ingrese un correo"
            label="Correo"
            type="email"
            variant="outlined"
            fullWidth
            disabled
            name="correo"
            value={leadInfo.correo || ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid xs={12} sm={3} item>
          <TextField
            variant="outlined"
            fullWidth
            label="Campaña"
            name="campaña"
            disabled
            onChange={handleChange}
            value={leadInfo.campaña || ''}
          ></TextField>
        </Grid>
        <Grid xs={12} sm={3} item>
          <TextField
            placeholder="Ingrese el celular"
            label="Celular"
            variant="outlined"
            fullWidth
            disabled
            onChange={handleChange}
            name="celular"
            value={leadInfo.celular || ''}
          />
        </Grid>
        <Grid xs={12} sm={3} item>
          <TextField
            placeholder="Numero de respaldo"
            label="Celular 2"
            variant="outlined"
            fullWidth
            disabled
            onChange={handleChange}
            name="celularRespaldo"
            value={leadInfo.celularRespaldo || ''}
          />
        </Grid>
        <Grid xs={12} sm={3} item>
          <TextField
            placeholder="Ingrese una cedula"
            label="Cedula"
            variant="outlined"
            fullWidth
            disabled
            onChange={handleChange}
            name="cedula"
            value={leadInfo.cedula || ''}
          />
        </Grid>
        <Grid xs={12} sm={3} item>
          <TextField
            placeholder="Ingrese la edad"
            label="Edad"
            type="number"
            variant="outlined"
            fullWidth
            disabled
            onChange={handleChange}
            name="edad"
            value={leadInfo.edad || ''}
          />
        </Grid>
        <Grid xs={12} sm={3} item>
          <TextField
            placeholder="Ingrese ciudad"
            label="Ciudad Redidencia"
            variant="outlined"
            fullWidth
            disabled
            onChange={handleChange}
            name="ciudadResidencia"
            value={leadInfo.ciudadResidencia || ''}
          />
        </Grid>
        <Grid xs={12} sm={3} item>
          <TextField
            variant="outlined"
            fullWidth
            disabled
            onChange={handleChange}
            label="Nivel Profesional"
            name="nivelProfesional"
            value={leadInfo.nivelProfesional || ''}
          ></TextField>
        </Grid>
        <Grid xs={12} sm={3} item>
          <TextField
            placeholder="Ingrese una profesion u oficio"
            label="Profesion u oficio"
            variant="outlined"
            fullWidth
            disabled
            onChange={handleChange}
            name="profesion"
            value={leadInfo.profesion || ''}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            variant="outlined"
            fullWidth
            disabled
            onChange={handleChange}
            label="Proyecto Interesado"
            name="proyecto"
            value={leadInfo.proyecto || ''}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            variant="outlined"
            fullWidth
            label="Inmueble de Interes"
            name="inmueble"
            disabled
            onChange={handleChange}
            value={leadInfo.inmueble || ''}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Especialista Asignado"
            name="especialistaAsignado"
            disabled
            onChange={handleChange}
            value={leadInfo.especialistaAsignado || ''}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            placeholder="Ingrese un mensaje"
            label="Como supo de nosotros"
            multiline
            disabled
            onChange={handleChange}
            rows={1}
            variant="outlined"
            fullWidth
            name="comoNosConocio"
            value={leadInfo.comoNosConocio || ''}
          />
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
    </Box>
  );
};

export default DetalleLead;
