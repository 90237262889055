import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActions } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const CardProject = ({proj}) => {
  const navigate = useNavigate();

  const handleProject = (e) => {
    const { nombre } = JSON.parse(e.target.name);
    sessionStorage.setItem('configuracion', e.target.name);
    navigate('/dashboard', { state: { nombre } });
  };

  return (
    <Box width="350px" className='p-2'>
      <Card
        sx={{ maxWidth: 350 }}
        style={{
          backgroundColor: '#F0F1F5',
          boxShadow: '4px 5px rgba(255, 170, 0, 0.968)',
        }}
      >
        <CardContent>
          <Typography variant="h6" component="div">
            {proj.nombre}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="medium"
            color="primary"
            style={{ textTransform: 'none', fontSize: '20px' }}
            onClick={handleProject}
            name={JSON.stringify(proj)}
          >
            Ver
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default CardProject;
