// @ts-nocheck
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import { toast } from 'react-toastify';
import axiosInstance from 'utils/database/api-leads';
import ModalAuth from '../ModalAuthorization/ModalAuth';

const LeadCreate = () => {
  const [leadInfo, setLeadInfo] = useState({
    nombreCompleto: '',
    cedula: '',
    celular: '',
    telefonoFijo: '',
    correo: '',
    edad: '',
    nivelProfesional: '',
    profesion: '',
    ciudadResidencia: '',
    proyecto: '',
    comoNosConocio: '',
    especialistaAsignado: '',
    campaña: '',
    inmueble: '',
  });
  const [profesionalLevels, setProfesionalLevels] = useState([]);
  const [projects, setProjects] = useState([]);
  const [especialistas, setEspecialistas] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const campañas = ['Linea Telefonica', 'Referido'];
  const inmuebles = ['Lote', 'Casa', 'Lote & Casa'];
  const proyectoDefault = "Default";

  const handleChange = (event) => {
    setLeadInfo({ ...leadInfo, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    axiosInstance
      .post('/leads/create', leadInfo)
      .then((res) => {
        toast.success(res.data.message);
        resetForm();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const resetForm = () => {
    setLeadInfo({
      nombreCompleto: '',
      cedula: '',
      celular: '',
      telefonoFijo: '',
      correo: '',
      edad: '',
      nivelProfesional: '',
      profesion: '',
      ciudadResidencia: '',
      proyecto: '',
      comoNosConocio: '',
      especialistaAsignado: '',
      campaña: '',
      inmueble: '',
    });
  };

  useEffect(() => {
    getLevels();
    getProjects();
    getEspecialistas();
  }, []);

  const getLevels = () => {
    axiosInstance.get(`/leads/professional-levels/get`).then((resp) => {
      const allLevels = resp.data;
      setProfesionalLevels(allLevels.data);
    });
  };

  const getProjects = () => {
    axiosInstance.get(`/projects`).then((resp) => {
      const allProjects = resp.data;
      const proys = allProjects.data.filter(p => p.nombre !== proyectoDefault);
      setProjects(proys);
    });
  };

  const getEspecialistas = () => {
    axiosInstance.get(`/users/roles/especialistas`).then((resp) => {
      const allUsers = resp.data;
      setEspecialistas(allUsers.data);
    });
  };

  const handleHideMessage = () => {
    setShowMessage(false);
  }

  return (
    <>
      <ModalAuth show={showMessage} handleClose={handleHideMessage}/>
      <Container style={{ alignContent: 'center', paddingBlock: '40px' }}>
        <Grid>
          <Card style={{ maxWidth: 550, padding: '0px 5px', margin: '0 auto' }}>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                style={{ textAlign: 'center' }}
              >
                Creacion de Lead
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ textAlign: 'center' }}
                gutterBottom
              >
                Por favor llene la informacion requerida
              </Typography>
              <br />
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      placeholder="Ingrese el nombre completo"
                      label="Nombre Completo"
                      variant="outlined"
                      fullWidth
                      required
                      name="nombreCompleto"
                      value={leadInfo.nombreCompleto}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <TextField
                      placeholder="Ingrese un correo"
                      label="Correo"
                      type="email"
                      variant="outlined"
                      fullWidth
                      required
                      name="correo"
                      value={leadInfo.correo}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <TextField
                      select
                      variant="outlined"
                      fullWidth
                      label="Campaña"
                      name="campaña"
                      value={leadInfo.campaña}
                      onChange={handleChange}
                    >
                      {campañas.map((camp) => (
                        <MenuItem key={camp} value={camp}>
                          {camp}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <TextField
                      placeholder="Ingrese el celular"
                      label="Celular"
                      variant="outlined"
                      fullWidth
                      required
                      name="celular"
                      value={leadInfo.celular}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <TextField
                      placeholder="Ingrese una cedula"
                      label="Cedula"
                      variant="outlined"
                      fullWidth
                      name="cedula"
                      value={leadInfo.cedula}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <TextField
                      placeholder="Ingrese la edad"
                      label="Edad"
                      type="number"
                      variant="outlined"
                      fullWidth
                      name="edad"
                      value={leadInfo.edad}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <TextField
                      placeholder="Ingrese ciudad"
                      label="Ciudad Redidencia"
                      variant="outlined"
                      fullWidth
                      name="ciudadResidencia"
                      value={leadInfo.ciudadResidencia}
                      onChange={handleChange}
                      required
                    />
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <TextField
                      select
                      variant="outlined"
                      fullWidth
                      label="Nivel Profesional"
                      name="nivelProfesional"
                      value={leadInfo.nivelProfesional}
                      onChange={handleChange}
                    >
                      {profesionalLevels.map((level) => (
                        <MenuItem key={level._id} value={level.idNivel}>
                          {level.nombre}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <TextField
                      placeholder="Ingrese una profesion u oficio"
                      label="Profesion u oficio"
                      variant="outlined"
                      fullWidth
                      name="profesion"
                      value={leadInfo.profesion}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      variant="outlined"
                      fullWidth
                      required
                      label="Proyecto Interesado"
                      name="proyecto"
                      value={leadInfo.proyecto}
                      onChange={handleChange}
                    >
                      {projects.map((proj) => (
                        <MenuItem key={proj._id} value={proj._id}>
                          {proj.nombre}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      variant="outlined"
                      fullWidth
                      label="Inmueble de Interes"
                      name="inmueble"
                      value={leadInfo.inmueble}
                      onChange={handleChange}
                    >
                      {inmuebles.map((inmueble) => (
                        <MenuItem key={inmueble} value={inmueble}>
                          {inmueble}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      select
                      variant="outlined"
                      fullWidth
                      label="Especialista Asignado"
                      name="especialistaAsignado"
                      value={leadInfo.especialistaAsignado}
                      onChange={handleChange}
                    >
                      {especialistas.map((esp) => (
                        <MenuItem key={esp._id} value={esp._id}>
                          {esp.nombre}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      placeholder="Ingrese un mensaje"
                      label="Como supo de nosotros"
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth
                      name="comoNosConocio"
                      value={leadInfo.comoNosConocio}
                      onChange={handleChange}
                    />
                  </Grid>
                  <br />
                  <Grid xs={12} sm={6} item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Crear
                    </Button>
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <Button
                      type="button"
                      variant="contained"
                      color="error"
                      fullWidth
                    >
                      <Link style={{ color: 'white' }} to={'/leads'}>
                        Cancelar
                      </Link>
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Container>
    </>
  );
};

export default LeadCreate;
