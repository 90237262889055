import gql from 'graphql-tag';

export const PROJECTS = gql`
  query PROJECTS {
    Projects {
      _id
      nombre
      numLotes
      tasaInteres
      lotesAutomaticos
      tipoInmuebles {
        maximoCuotasInicial
        maxFinanciacion
        tipo
      }
    }
  }
`;

export const SEARCHPROJECTBYNAME = gql`
  query SEARCHPROJECT($nombre: ID) {
    SearchProjectById(id: $nombre) {
      _id
      nombre
      tipoInmuebles {
        tipo
        maximoCuotasInicial
        maxFinanciacion
      }
      tasaInteres
      numLotes
    }
  }
`;
