/* eslint-disable no-nested-ternary */
import React, { Component, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
} from 'react-table';
import { Button, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { USERREPORT } from 'utils/database/query/users';
import { SEARCHPLANBYID } from 'utils/database/query/plans';
import client from 'utils/database';

import UserTable from './UserTable';

export default class TableConsultas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentProject: JSON.parse(sessionStorage.getItem('configuracion'))
        .nombre,
      usersReport: [],
      currentUser: '',
      plan: '',
      loading: false,
      filter: '',
    };
  }

  componentDidMount() {
    this.queryUsersReport();
  }

  hanldeFilter = (e) => {
    this.setState({
      filter: e.target.value,
    });
  };
  /**
   * Se obtienen todos los planes que existen en la base de datos
   * Se podria mejorar la consulta buscando directamente los planes que existen de un proyecto en especifico
   */
  queryUsersReport = () => {
    client // Se inicia la consulta/mutacion de GRAPHQL
      .query({ query: USERREPORT })
      .then((result) => {
        this.setState({
          usersReport: result.data.ReporteUsuario, // Se establece el estado para realizar map() en el render del componente
        });
        document.getElementById('loadingPlans').className = 'invisible'; // Se oculta el spin de loading
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  /**
   * Este metodo obtiene la informacion del elemento HTML donde se hizo click y se renderiza
   * un nuevo componente UserTable con la info espefica del usuario y plan correspondiente
   * @param element Elemento HTML con la informacion de usuario y plan
   */
  handlePlan = (element) => {
    let user = element.key; // Se obtiene el info en JSON donde esta la info del usuario y de un plan especifico
    let { idPlan } = user.plan; // Se obtiene el idPlan por destructuring
    client // Se inicia la consulta/mutation de GRAPHQL
      .mutate({ mutation: SEARCHPLANBYID, variables: { id: idPlan } })
      .then((plan) => {
        this.setState({
          plan: plan.data.SearchPlanById, // Se establece el estado para pasar como props al Componente individual de cada Usuario (UserTable)
          currentUser: user,
        });
        const element = (
          <UserTable
            user={this.state.currentUser}
            plan={this.state.plan}
          />
        );
        ReactDOM.render(element, document.getElementById('rootConsultas')); // Se renderiza el componente UserTable en el elemento html 'rootConsultas'
      });
  };

  /**
   * Arrow Function para dar formato de boton a la columna del DATATABLE
   * @param e Elemento HTML con los elementos texto que lleva la columna de la tabla
   */
  buttonTable = (e) => {
    let id = JSON.stringify(e.key);
    return (
      <button id={id} className="btn btn-primary" onClick={this.handlePlan}>
        ver
      </button>
    );
  };

  /**
   * Arrow function para recargar la DATATABLE que contiene la infor de los usuarios
   * TODO cambiar metodo de consulta
   */
  refresh = () => {
    this.setState({
      loading: true,
    });
    client // Se inicia la consulta de GRAPHQL
      .query({ query: USERREPORT })
      .then((result) => {
        this.setState({
          loading: false,
          usersReport: result.data.ReporteUsuario, // Se establece el estado para realizar map() en el render del componente
        });
      })
      // @ts-ignore
      .catch((Err) => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    let newRowUserReport = [];
    this.state.usersReport.map((client) => {
      return client.userdata.map((user) => {
        return user.planes.map((clientFinal) => {
          if (
            client._id === clientFinal.idPlan &&
            clientFinal.proyecto === this.state.currentProject &&
            clientFinal.estado !== 'Invalido'
          ) {
            /* KeyUser es un objeto JSON para obtener la informacion al momento de Renderizar el componente individual
             por cada usuario-plan */
            let keyUser = {
              user: {
                _id: user._id,
                cedula: user.cedula,
                nombre: user.nombre,
                celular: user.celular,
                direccion: user.direccion,
                correo: user.correo,
              },
              plan: {
                _id: clientFinal._id,
                descripcion: clientFinal.descripcion,
                estado: clientFinal.estado,
                idPlan: clientFinal.idPlan,
              },
            };
            // Se anhade los datos al arreglo que sera los datos de las filas del DATATABLE
            newRowUserReport.push({
              vendedor: client.vendedor,
              date: client.fechaRealizacionPlan,
              cedula: user.cedula,
              nombre: user.nombre,
              celular: user.celular,
              direccion: user.direccion,
              estado: clientFinal.estado,
              descripcion: clientFinal.descripcion,
              plan: {
                descripcion: clientFinal.descripcion,
                key: keyUser,
              },
            });
            return null; // Return para salir del map clientFinal
          }
          return null; // Return para salir del map clientFinal
        });
      });
    });

    return (
      <>
        <Row>
          <Col xs={{ size: 2 }}>
            <Button
              id="refreshButon"
              color="primary"
              className="refresh w-100 mb-3"
              onClick={this.refresh}
              disabled={this.state.loading}
            >
              {!this.state.loading ? (
                <>
                  <FontAwesomeIcon icon="sync-alt" className="mr-2" />
                  {`Recargar`}
                </>
              ) : (
                <>
                  <span className="spinner-border spinner-border-sm" />
                  {` Actualizando...`}
                </>
              )}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table
              data={newRowUserReport}
              handleEdit={this.handlePlan}
            />
          </Col>
        </Row>
        <div className="table-responsive" id="tablaUsuarios">
          <div id="loadingPlans" className="d-flex justify-content-center">
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span className="sr-only">Cargando Planes...</span>
            Cargando Planes...
          </div>
        </div>
      </>
    );
  }
}

const Table = ({ data, handleEdit }) => {

  const coulmnsFilter = ['Cedula', 'Nombre', 'Vendedor', 'Plan', 'Realizacion', 'Estado'];
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter
    }),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        accessor: 'plan',
        Cell: ({ value }) => (
          <Button
            className="main--edit-button p-0"
            onClick={() => handleEdit(value)}
          >
            <FontAwesomeIcon icon={['far', 'edit']} color="grey" />
          </Button>
        ),
      },
      {
        Header: 'Cedula',
        accessor: 'cedula'
      },
      {
        Header: 'Nombre',
        accessor: 'nombre',
      },
      {
        Header: 'Celular',
        accessor: 'celular',
      },
      {
        Header: 'Vendedor',
        accessor: 'vendedor',
      },
      {
        Header: 'Plan',
        accessor: 'descripcion',
      },
      {
        Header: 'Realizacion',
        accessor: 'date',
      },
      {
        Header: 'Estado',
        accessor: 'estado',
      },
    ],
    [handleEdit]
  );

  function DefaultColumnFilter({
    column: { filterValue, setFilter, Header }
  }) {

    return (
      <input
        style={{ borderRadius: '6px', width: '90%',  opacity: '0.5'}}
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={Header}
      />
    );
  }

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // @ts-ignore
    page,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    pageOptions,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    state: { pageIndex },
    // @ts-ignore
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
  }, [data]);

  return (
    <>
      <table {...getTableProps()} className="table table-bordered main--table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon
                          icon="sort-down"
                          color="black"
                          className="ml-2"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon="sort-up"
                          color="black"
                          className="ml-2"
                        />
                      )
                    ) : (
                      <FontAwesomeIcon
                        icon="sort"
                        color="black"
                        className="ml-2"
                      />
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr>
              {headerGroup.headers.map((column) => (
                <th>
                  <div>{column.canFilter && coulmnsFilter.includes(column.Header) ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination table--pag d-flex justify-content-end mr-5">
        <button
          type="button"
          className=" mr-2 main-table--page-index"
          onClick={previousPage}
          disabled={!canPreviousPage}
        >
          <FontAwesomeIcon icon="chevron-left" color="black" />
        </button>

        <span className="table--pag__page">{pageIndex + 1}</span>
        <button
          className=" ml-2 mr-2 main-table--page-index"
          type="button"
          onClick={nextPage}
          disabled={!canNextPage}
        >
          <FontAwesomeIcon icon="chevron-right" color="black" />
        </button>
        <span className="table--pag__length">de {pageOptions.length}</span>
      </div>
    </>
  );
};
