import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axiosInstance from 'utils/database/api-leads';

const CrearSeguimiento = () => {
  const [projects, setProjects] = useState([]);
  const [tiposSeguimiento, setTiposSeguimiento] = useState([]);
  const [resultadosSeguimiento, setResultadosSeguimiento] = useState([]);
  const [lots, setLots] = useState([]);
  const proyectoDefault = 'Default';
  const [seguimientoInfo, setSeguimientoInfo] = useState({
    fechaSeguimiento: '',
    cliente: '',
    proyecto: '',
    lote: '',
    tipoSeguimiento: '',
    observacion: '',
    resultado: '',
  });
  const [nombreCliente, setNombreCliente] = useState('');

  useEffect(() => {
    getProjects();
    getTiposSeguimiento();
    getResultadosSeguimiento();
  }, []);

  const getProjects = () => {
    axiosInstance.get(`/projects`).then((resp) => {
      const allProjects = resp.data;
      const proys = allProjects.data.filter(
        (p) => p.nombre !== proyectoDefault
      );
      setProjects(proys);
    });
  };

  const getTiposSeguimiento = () => {
    axiosInstance.get(`/clients/tracking-types`).then((resp) => {
      const allTypes = resp.data;
      setTiposSeguimiento(allTypes.data);
    });
  };

  const getResultadosSeguimiento = () => {
    axiosInstance.get(`/clients/tracking-results`).then((resp) => {
      const allResults = resp.data;
      setResultadosSeguimiento(allResults.data);
    });
  };

  const getLots = (idProyecto) => {
    axiosInstance.get(`/lots/${idProyecto}`).then((resp) => {
      const allLots = resp.data;
      setLots(allLots.data);
    });
  };

  const getClientData = (idProyecto, idLote) => {
    let project = projects.filter((p) => p._id === idProyecto)[0];
    axiosInstance
      .get(`/clients/project/${project.nombre}/lote/${idLote}`)
      .then((resp) => {
        const clientD = resp.data.data;
        setSeguimientoInfo({
          ...seguimientoInfo,
          lote: idLote,
          cliente: clientD._id,
        });
        setNombreCliente(clientD.nombre);
      });
  };

  const handleChangeProject = (e) => {
    const idProyecto = e.target.value;
    setSeguimientoInfo({
      ...seguimientoInfo,
      [e.target.name]: idProyecto,
      lote: '',
      cliente: '',
    });
    setNombreCliente('');
    getLots(idProyecto);
  };

  const handleChangeLote = (e) => {
    const idLote = e.target.value;
    getClientData(seguimientoInfo.proyecto, idLote);
  };

  const handleChange = (event) => {
    setSeguimientoInfo({
      ...seguimientoInfo,
      [event.target.name]: event.target.value,
    });
  };

  const getMinDate = (date) => {
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return date.toISOString().slice(0, 16);
  };

  const resetForm = () => {
    setSeguimientoInfo({
      fechaSeguimiento: '',
      cliente: '',
      proyecto: '',
      lote: '',
      tipoSeguimiento: '',
      observacion: '',
      resultado: '',
    });
    setNombreCliente('');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axiosInstance
      .post(`/clients/trackings`, seguimientoInfo)
      .then((res) => {
        toast.success(res.data.message);
        resetForm();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
      <Container style={{ alignContent: 'center', paddingBlock: '40px' }}>
        <Grid>
          <Card style={{ maxWidth: 680, padding: '0px 5px', margin: '0 auto' }}>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                style={{ textAlign: 'center' }}
              >
                Creacion de Seguimiento
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ textAlign: 'center' }}
                gutterBottom
              >
                Por favor complete la informacion.
              </Typography>
              <br />
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid xs={12} sm={6} item>
                    <TextField
                      select
                      variant="outlined"
                      fullWidth
                      label="Proyecto"
                      name="proyecto"
                      required
                      value={seguimientoInfo.proyecto}
                      onChange={handleChangeProject}
                    >
                      {projects.map((proyecto) => (
                        <MenuItem key={proyecto._id} value={proyecto._id}>
                          {proyecto.nombre}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <TextField
                      select
                      variant="outlined"
                      fullWidth
                      label="Lote"
                      name="lote"
                      required
                      disabled={seguimientoInfo.proyecto === ''}
                      value={seguimientoInfo.lote}
                      onChange={handleChangeLote}
                    >
                      {lots.map((lote) => (
                        <MenuItem key={lote._id} value={lote._id}>
                          {lote.numeroLote}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Nombre"
                      variant="outlined"
                      fullWidth
                      required
                      disabled
                      name="nombre"
                      value={nombreCliente}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="datetime-local"
                      label="Fecha y Hora"
                      type="datetime-local"
                      fullWidth
                      required
                      /*inputProps={{
                        min: getMinDate(new Date()),
                      }}*/
                      name="fechaSeguimiento"
                      value={seguimientoInfo.fechaSeguimiento}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <TextField
                      select
                      variant="outlined"
                      fullWidth
                      label="Tipo Seguimiento"
                      name="tipoSeguimiento"
                      required
                      value={seguimientoInfo.tipoSeguimiento}
                      onChange={handleChange}
                    >
                      {tiposSeguimiento.map((tipo) => (
                        <MenuItem key={tipo._id} value={tipo._id}>
                          {tipo.nombre}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <TextField
                      select
                      variant="outlined"
                      fullWidth
                      label="Resultado"
                      name="resultado"
                      required
                      value={seguimientoInfo.resultado}
                      onChange={handleChange}
                    >
                      {resultadosSeguimiento.map((resultado) => (
                        <MenuItem key={resultado._id} value={resultado._id}>
                          {resultado.nombre}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      placeholder="Ingrese una observacion"
                      label="Observacion"
                      variant="outlined"
                      fullWidth
                      required
                      multiline
                      rows={1}
                      name="observacion"
                      value={seguimientoInfo.observacion}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Container>
    </>
  );
};

export default CrearSeguimiento;
