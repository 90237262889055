import gql from 'graphql-tag';

export const SEARCHPLANBYID = gql`
  query PLAN($id: ID) {
    SearchPlanById(id: $id) {
      _id
      tabla
      pagos
      numeroCuotas
      periodicidad
      porcentajeAFinanciar
      tasaInteres
      valorTotal
      valorFinal
      valorAFinanciar
      saldoRestante
      observaciones
      vendedor
      fechaInicialPlan
      fechaRealizacionPlan
      fechaInicioCuotaIni
      cuotaPersonal
      valorCuotaPersonal
      numAbonos
      cuotaExtraFija
      cuotaExtraSola
      cobrarInteres
      valorCuotaInicial
      cuotaSeparacion
      pagoTotal
    }
  }
`;

export const SEARCHPLANBYIDPAGOS = gql`
  query PLAN($id: ID) {
    SearchPlanById(id: $id) {
      tabla
      numeroCuotas
      tasaInteres
      saldoRestante
    }
  }
`;

export const SEARCHPLANBYIDMOD = gql`
  query PLAN($id: ID) {
    SearchPlanById(id: $id) {
      tabla
      pagos
      tasaInteres
    }
  }
`;

export const SEARCHPLANBYIDMODPLAN = gql`
  query PLAN($id: ID) {
    SearchPlanById(id: $id) {
      tabla
      numeroCuotas
      periodicidad
      porcentajeAFinanciar
      tasaInteres
      valorTotal
      valorFinal
      valorAFinanciar
      saldoRestante
      observaciones
      vendedor
      fechaInicialPlan
      fechaRealizacionPlan
      fechaInicioCuotaIni
      cuotaPersonal
      valorCuotaPersonal
      numAbonos
      cuotasExtra {
        cuota
        valor
      }
      cuotaExtraFija
      cuotaExtraSola
      cobrarInteres
      valorCuotaInicial
      cuotasInicial {
        cuota
        valor
      }
      cuotaSeparacion
      pagoTotal
    }
  }
`;

export const SEARCHPLANCOTIZACION = gql`
  query SearchPlan($id: ID) {
    SearchPlanById(id: $id) {
      _id
      tabla
    }
  }
`;
