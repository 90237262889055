import gql from 'graphql-tag';

export const DELETEPAYMENT = gql`
  mutation DELETEPAYMENT($idPayment: ID) {
    DeletePayment(id: $idPayment) {
      _id
    }
  }
`;

export const DELETEPAYMENTS = gql`
  mutation DELETEMANYPAYMENTS($ids: [ID]) {
    DeleteManyPayments(ids: $ids) {
      _id
    }
  }
`;

export const UPDATEPAYMENT = gql`
mutation UPDATEPAYMENT($id: ID, $data: PaymentInput) {
  UpdatePayment(id: $id, data: $data) {
    _id
    observaciones
  }
}
`;

export const CREATEPAYMENT = gql`
mutation CREATEPAYMENT($data: PaymentInput) {
  CreatePayment(data: $data) {
    _id
  }
}
`;

export const ADDPAYTOPLAN = gql`
  mutation ADDPAYTOPLAN(
    $idPlan: ID
    $idPay: ID
    $valorTotal: Float
    $abonoCapital: Float
  ) {
    AddPaymentToPlan(
      idPlan: $idPlan
      idPayment: $idPay
      valorTotal: $valorTotal
      abonoCapital: $abonoCapital
    ) {
      tabla
    }
  }
`;


export const DELETEPAYMENTFROMPLAN = gql`
  mutation DELETEPAYFROMPLAN($idPlan: ID, $idPay: ID) {
    DeletePaymentFromPlan(idPlan: $idPlan, idPayment: $idPay) {
      pagos
    }
  }
`;

export const MODIFYPAYMENTPLAN = gql`
  mutation MODIFYPAYMENTPLAN(
    $idPlan: ID
    $valorTotal: Float
    $abonoCapital: Float
  ) {
    ModifyPaymentPlan(
      idPlan: $idPlan
      valorTotal: $valorTotal
      abonoCapital: $abonoCapital
    ) {
      saldoRestante
      pagoTotal
    }
  }
`;
