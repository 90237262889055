import React, { Component } from 'react';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import client from 'utils/database';
import { UPDATEUSERCEDULA } from 'utils/database/mutation/users';

export default class ModifyPlan extends Component {
  constructor(props) {
    super(props);
    const {
      personal: { nombre, cedula, direccion, celular, correo },
    } = this.props;
    this.state = {
      titleModal: '',
      messageModal: '',
      show: false,
      loading: false,
      nombre,
      cedula,
      direccion,
      celular,
      correo,
    };
  }

  componentDidMount() {}

  handleChangeCedula = (e) => {
    this.setState({
      cedula: e.target.value,
    });
  };

  handleChangeNombre = (e) => {
    this.setState({
      nombre: e.target.value,
    });
  };

  handleChangeDireccion = (e) => {
    this.setState({
      direccion: e.target.value,
    });
  };

  handleChangeCelular = (e) => {
    this.setState({
      celular: e.target.value,
    });
  };

  handleChangeCorreo = (e) => {
    this.setState({
      correo: e.target.value,
    });
  };

  actualizarDatosPersonales = () => {
    this.setState({
      loading: true,
    });
    const {
      nombre,
      correo,
      celular,
      newCedula,
      direccion,
      cedula,
    } = this.state;
    const dataUser = {
      nombre,
      correo,
      celular,
      direccion,
      cedula: newCedula,
    };
    client
      .mutate({
        mutation: UPDATEUSERCEDULA,
        variables: { cedula, updatedData: dataUser },
      })
      .then(() => {
        this.setState({
          show: true,
          titleModal: <div className="center success" />,
          messageModal: 'Usuario Actualizado correctamente',
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          show: true,
          titleModal: <div className="center wrong" />,
          messageModal:
            'Ocurrio un error al intentar actualizar el usuario, porfavor intentelo de nuevo mas tarde',
          loading: false,
        });
      });
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  render() {
    const {
      loading,
      cedula,
      nombre,
      correo,
      celular,
      direccion,
      show,
      titleModal,
      messageModal,
    } = this.state;
    return (
      <>
        <Row className="mt-3">
          <Col>
            <Row>
              <Col className="pt-4 ml-5 mr-5">
                <Row className="mb-3">
                  <Col>
                    <label>Cedula</label>
                    <input
                      className="form-control"
                      value={cedula}
                      onChange={this.handleChangeCedula}
                    />
                  </Col>
                  <Col>
                    <label>Nombre</label>
                    <input
                      className="form-control"
                      value={nombre}
                      onChange={this.handleChangeNombre}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <label>Correo</label>
                    <input
                      className="form-control"
                      value={correo}
                      onChange={this.handleChangeCorreo}
                    />
                  </Col>
                  <Col>
                    <label>Celular</label>
                    <input
                      className="form-control"
                      value={celular}
                      onChange={this.handleChangeCelular}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <label>Direccion</label>
                    <input
                      className="form-control"
                      value={direccion}
                      onChange={this.handleChangeDireccion}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="main-modificaciones--buttons">
              <Col>
                <Button
                  type="button"
                  color="primary"
                  className="mb-2"
                  onClick={this.actualizarDatosPersonales}
                >
                  {!loading ? (
                    'Actualizar Informacion'
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                      />
                      {` Actualizado...`}
                    </>
                  )}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Modal isOpen={show} toggle={this.handleClose}>
          <ModalHeader>
            <>{titleModal}</>
          </ModalHeader>
          <ModalBody>{messageModal}</ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-default"
              onClick={this.handleClose}
            >
              Cerrar
            </button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
