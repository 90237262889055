import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { esES, GridToolbarFilterButton } from '@mui/x-data-grid';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import moment from 'moment';
import RateReviewIcon from '@mui/icons-material/RateReview';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React, { useState } from 'react';
import './DetailGestions.css';
import ModalDetail from '../../ModalDetail/ModalDetail';
import axiosInstance from 'utils/database/api-leads';
import { toast } from 'react-toastify';

const DetailGestions = ({ handleClose, show, gestionsData }) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';
  const [showObservationTable, setShowObservationTable] = useState(false);
  const [observacionTable, setObservacionTable] = useState('');
  const [titleObservacion, setTitleObservacion] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [idGestion, setIdGestion] = useState('');
  const [observacion, setObservacion] = useState('');

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );

  const handleOpenDialog = (idGestion) => {
    setOpenDialog(true);
    setIdGestion(idGestion);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setObservacion('');
  };

  const handleShowObservation = (tipoGestion, observacion) => {
    setObservacionTable(observacion);
    setTitleObservacion(tipoGestion);
    setShowObservationTable(true);
  };

  const handleHideObservationTable = () => {
    setObservacionTable('');
    setTitleObservacion('');
    setShowObservationTable(false);
  };

  const handleFollow = (event) => {
    event.preventDefault();
    const data = { idGestion, observacion };
    axiosInstance
      .post(`/leads/management/follow-up/${gestionsData.idLead}`, data)
      .then((res) => {
        toast.success(res.data.message);
        setObservacion('');
        setOpenDialog(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const columns = [
    {
      field: 'Accion',
      headerAlign: 'center',
      align: 'center',
      width: 70,
      renderCell: (cellValues) => {
        return (
          <>
            <Button
              hidden={cellValues.row.estado.idEstado === 2}
              onClick={() => handleOpenDialog(cellValues.id)}
              variant="text"
              size="small"
            >
              <RateReviewIcon titleAccess="Agregar Seguimiento" />
            </Button>
          </>
        );
      },
    },
    {
      field: 'observacion',
      headerName: 'Observacion',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 100,
      renderCell: (cellValues) => {
        return (
          <>
            <Button
              onClick={() =>
                handleShowObservation(
                  cellValues.row.tipoGestion.nombre,
                  cellValues.row.observacion
                )
              }
              variant="text"
              size="small"
            >
              <VisibilityIcon titleAccess="Ver observacion" />
            </Button>
          </>
        );
      },
    },
    {
      field: 'fecha',
      headerAlign: 'center',
      align: 'center',
      headerName: 'Fecha y Hora',
      width: 210,
      valueFormatter: (params) =>
        params.value ? moment(params.value).format('DD/MM/YYYY hh:mm A') : null,
    },
    {
      field: 'tipoGestion',
      headerName: 'Medio de Gestion',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 300,
      valueGetter: ({ value }) => value && value.nombre,
    },
    {
      field: 'consultorAsignado',
      headerName: 'Consultor',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 300,
      valueGetter: ({ value }) => value && value.nombre,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 200,
      valueGetter: ({ value }) => value && value.nombre,
    },
  ];

  return (
    <div className={showHideClassName}>
      <ModalDetail
        show={showObservationTable}
        handleClose={handleHideObservationTable}
        title={titleObservacion}
        message={observacionTable}
      />
      <section className="modal-main">
        <h4 className="title">Gestiones - {gestionsData.leadName}</h4>
        <Box
          sx={{
            height: 410,
            width: '100%',
          }}
        >
          <DataGrid
            rows={gestionsData.gestions}
            // @ts-ignore
            columns={columns}
            pageSize={5}
            disableSelectionOnClick
            rowsPerPageOptions={[5]}
            components={{ Toolbar: CustomToolbar }}
            getRowId={(row) => row._id}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
        <Button
          type="button"
          style={{ textTransform: 'none', fontSize: 18 }}
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </section>
      <Dialog fullWidth open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Registro de Seguimiento</DialogTitle>
        <form onSubmit={handleFollow}>
          <DialogContent>
            <DialogContentText>
              Por favor completar la siguiente informacion.
            </DialogContentText>
            <br />
            <TextField
              autoFocus
              margin="dense"
              label="Observacion"
              variant="outlined"
              placeholder="Escriba alguna información"
              fullWidth
              value={observacion}
              required
              multiline
              onChange={(event) => setObservacion(event.target.value)}
              rows={2}
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit">Agregar</Button>
            <Button onClick={handleCloseDialog}>Cancelar</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default DetailGestions;
