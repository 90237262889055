import React, { Component, Fragment } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import client from 'utils/database';
import {
  UPDATETABLESAMECUOTE,
  UPDATETABLEPAY,
} from 'utils/database/mutation/tables';
import {
  UPDATEPAYMENT,
  DELETEPAYMENT,
  DELETEPAYMENTFROMPLAN,
  MODIFYPAYMENTPLAN,
} from 'utils/database/mutation/payments';
import { SEARCHPLANBYIDMOD } from 'utils/database/query/plans';
import { SEARCHPAYMENTBYID } from 'utils/database/query/payments';
import axiosInstance from 'utils/database/api-leads';

export default class ModifyPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      action: 'Modify',
      titleModal: '',
      messageModal: '',
      show: false,
      planes: this.props.planes,
      currentPlan: '',
      currentPayment: '',
      currentTable: '',
      paymentsId: [],
      payments: [],
      boolInteres: false,
      fecha: '',
      valorPago: 0,
      abonoCapital: 0,
      abonoInteres: 0,
      observaciones: 0,
      cuota: '',
      diasMora: 0,
      interesMora: 0,
      newFecha: '',
      newValorPago: 0,
      newAbonoCapital: 0,
      newAbonoInteres: 0,
      newObservaciones: '',
      newDiasMora: 0,
      newInteresMora: 0,
      diffValorPago: 0,
      diffAbonoCapital: 0,
      diffAbonoInteres: 0,
      diffDiasMora: 0,
      diffInteresMora: 0,
      estado: 'Pagado',
      permisos: {},
    };
  }

  componentDidMount() {
    this.getProjectInfo();
  }

  getProjectInfo = () => {
    let configuracion = sessionStorage.getItem('configuracion');
    let user = sessionStorage.getItem('user');
    configuracion = JSON.parse(configuracion);
    user = JSON.parse(user);
    this.setState({
      vendedor: user.nombre,
      tipoVendedor: user.tipo,
      proyecto: configuracion.nombre,
    });
    this.getPermisos(user._id);
  };

  getPermisos = (idUser) => {
    axiosInstance.get(`/users/permisos/${idUser}`).then((resp) => {
      const info = resp.data;
      this.setState({
        permisos: info.data?.permisos?.pagos,
      });
    });
  };

  handleChangeIdPlan = (e) => {
    // document.getElementById('tablePayments').classList.remove('invisible')
    let idPlan = e.target.value;
    this.setState({
      currentPlan: idPlan,
      payments: [],
      paymentsId: [],
    });
    this.getPayments(idPlan);
  };

  getPayments = (idPlan) => {
    client
      .query({ query: SEARCHPLANBYIDMOD, variables: { id: idPlan } })
      .then((result) => {
        this.setState({
          paymentsId: result.data.SearchPlanById.pagos,
          tasaInteres: result.data.SearchPlanById.tasaInteres,
          currentTable: result.data.SearchPlanById.tabla,
        });
        this.getValuePayments(result.data.SearchPlanById.pagos);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getValuePayments = (arrayPayments) => {
    for (let i = 0; i < arrayPayments.length; i++) {
      client
        .query({
          query: SEARCHPAYMENTBYID,
          variables: { idPayment: arrayPayments[i] },
        })
        .then((pay) => {
          this.setState({
            payments: this.state.payments.concat(pay),
          });
        })
        .catch((err) => console.log(err));
    }
  };

  showModifyPayment = (e) => {
    document.getElementById('modificacionPagos').className = '';
    let idPayment = e.target.id;
    client
      .query({ query: SEARCHPAYMENTBYID, variables: { idPayment } })
      .then((payment) => {
        this.setState({
          currentPayment: idPayment,
          fechaPago: payment.data.SearchPaymentById.fecha,
          numeroCuota: payment.data.SearchPaymentById.cuota,
          valorPago: payment.data.SearchPaymentById.valorPago,
          abonoCapital: payment.data.SearchPaymentById.abonoCapital,
          abonoInteres: payment.data.SearchPaymentById.abonoInteres,
          observaciones: payment.data.SearchPaymentById.observaciones,
          interesMora: payment.data.SearchPaymentById.interesMora,
          diasMora: payment.data.SearchPaymentById.diasMora,
          newFechaPago: '',
          newValorPago: 0,
          newAbonoCapital: 0,
          newAbonoInteres: 0,
          newObservaciones: '',
          newInteresMora: 0,
          newDiasMora: 0,
        });
      })
      .catch((err) => console.log(err));
  };

  handleChangeValorPago = (e) => {
    let diff = parseInt(e.target.value) - parseInt(this.state.valorPago);
    this.setState({
      newValorPago: e.target.value,
      diffValorPago: diff,
    });
  };

  handleChangeAbonoCapital = (e) => {
    let diff = parseInt(e.target.value) - parseInt(this.state.abonoCapital);
    this.setState({
      newAbonoCapital: e.target.value,
      diffAbonoCapital: diff,
    });
  };

  handleChangeAbonoInteres = (e) => {
    let diff = parseInt(e.target.value) - parseInt(this.state.abonoInteres);
    this.setState({
      newAbonoInteres: e.target.value,
      diffAbonoInteres: diff,
    });
  };

  handleChangeFecha = (e) => {
    this.setState({
      newFechaPago: e.target.value,
    });
  };

  handleChangeInteresMora = (e) => {
    let diff = parseInt(e.target.value) - parseInt(this.state.interesMora);
    this.setState({
      newInteresMora: e.target.value,
      diffInteresMora: diff,
    });
  };

  handleChangeDiasMora = (e) => {
    let diff = parseInt(e.target.value) - parseInt(this.state.diasMora);
    this.setState({
      newDiasMora: e.target.value,
      diffDiasMora: diff,
    });
  };

  handleChangeObservaciones = (e) => {
    this.setState({
      newObservaciones: e.target.value,
    });
  };

  handleChangeEstado = (e) => {
    let index = e.target.options.selectedIndex;
    // let proyecto = e.target.childNodes[index].id
    let newState = e.target.childNodes[index].value;
    this.setState({
      estado: newState,
    });
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };
  /**
   * Aqui se empieza la modificacion del pago, afectando las tablas(TABLE)
   * en este caso, solo la misma cuota, neutralizando el valor del pago correspondiente
   */
  modifyTableSameCuote = (idPayment) => {
    document.getElementById('modifyingPayment').className =
      'btn btn-primary btnCentered';
    document.getElementById('modifyBtnPayment').className =
      'btn btn-primary btnCentered invisible';
    let idTabla = this.state.currentTable;
    /**
     * Algunos son valores negativos, esto ya que en el backend se realiza un $inc
     * por lo cual, si se hace un increment negativo, se resta
     */
    let tableData = {
      abonoCapital: parseInt(this.state.diffAbonoCapital),
      abonoInteres: parseInt(this.state.diffAbonoInteres),
      estado: this.state.estado,
      pagoTotal: parseInt(this.state.diffValorPago),
      interesMora: parseInt(this.state.diffInteresMora),
      diasMora: parseInt(this.state.diffDiasMora),
    };
    client
      .mutate({
        mutation: UPDATETABLESAMECUOTE,
        variables: {
          idTable: idTabla,
          cuota: this.state.numeroCuota,
          data: tableData,
        },
      })
      .then((result) => {
        this.modifyTableFullPayment(idTabla, idPayment);
      })
      .catch(() => {
        this.setState({
          titleModal: <div className="center wrong" />,
          show: true,
          messageModal:
            'Ocurrio un error al modificar la tabla de plan de pagos',
        });
        document.getElementById('modifyingPayment').className =
          'btn btn-primary btnCentered invisible';
        document.getElementById('modifyBtnPayment').className =
          'btn btn-primary btnCentered';
      });
  };

  modifyTableFullPayment = (idTabla, idPayment) => {
    /**
     * Algunos son valores negativos, esto ya que en el backend se realiza un $inc
     * por lo cual, si se hace un increment negativo, se resta al valor de
     * saldo restante en las cuotas de abajo
     */
    let dataPayment = {
      cuota: this.state.numeroCuota,
      valor: parseInt(this.state.diffAbonoCapital),
    };
    client
      .mutate({
        mutation: UPDATETABLEPAY,
        variables: { idTable: idTabla, data: dataPayment },
      })
      .then((result) => {
        this.modifyPlan(idPayment);
      })
      .catch(() => {
        this.setState({
          titleModal: <div className="center wrong" />,
          show: true,
          messageModal:
            'Ocurrio un problema al modificar la tabla de cotizacion',
        });
        document.getElementById('modifyingPayment').className =
          'btn btn-primary btnCentered invisible';
        document.getElementById('modifyBtnPayment').className =
          'btn btn-primary btnCentered';
      });
  };

  modifyPlan = (idPayment) => {
    let idPlan = this.state.currentPlan;
    let totalValue = parseFloat(this.state.diffValorPago);
    let abonoCapital = parseFloat(this.state.diffAbonoCapital);

    client
      .mutate({
        mutation: MODIFYPAYMENTPLAN,
        variables: {
          idPlan: idPlan,
          valorTotal: totalValue,
          abonoCapital: abonoCapital,
        },
      })
      .then((result) => {
        let action = this.state.action;
        if (action === 'Delete') this.deletePaymentFromPlan(idPayment);
        else this.modifyPayment();
      })
      .catch(() => {
        this.setState({
          titleModal: <div className="center wrong" />,
          show: true,
          messageModal:
            'Ocurrio un problema al modificar el plan en la base de datos',
        });
        document.getElementById('modifyingPayment').className =
          'btn btn-primary btnCentered invisible';
        document.getElementById('modifyBtnPayment').className =
          'btn btn-primary btnCentered';
      });
  };

  modifyPayment = () => {
    let idPayment = this.state.currentPayment;
    let paymentData = {
      fecha: this.state.newFechaPago,
      abonoCapital: parseInt(this.state.newAbonoCapital),
      valorPago: parseInt(this.state.newValorPago),
      abonoInteres: parseInt(this.state.newAbonoInteres),
      observaciones: this.state.newObservaciones,
      interesMora: parseInt(this.state.newInteresMora),
      diasMora: parseInt(this.state.newDiasMora),
      cuota: parseInt(this.state.numeroCuota),
    };
    client
      .mutate({
        mutation: UPDATEPAYMENT,
        variables: { id: idPayment, data: paymentData },
      })
      .then((result) => {
        this.setState({
          titleModal: <div className="center success" />,
          show: true,
          messageModal: 'Pago Modificado satisfactoriamente',
        });
        document.getElementById('modifyingPayment').className =
          'btn btn-primary btnCentered invisible';
        document.getElementById('modifyBtnPayment').className =
          'btn btn-primary btnCentered';
      })
      .catch(() => {
        this.setState({
          titleModal: <div className="center wrong" />,
          show: true,
          messageModal:
            'Ocurrio un error al modificar el pago en la base de datos',
        });
        document.getElementById('modifyingPayment').className =
          'btn btn-primary btnCentered invisible';
        document.getElementById('modifyBtnPayment').className =
          'btn btn-primary btnCentered';
      });
  };

  /** Eliminacion del plan */

  startDelete = (e) => {
    let idPayment = e.target.id;
    let idButton = `deleteBtn${idPayment}`;
    let idButtonDeleting = `deletingBtn${idPayment}`;

    document.getElementsByName(idButton)[0].className =
      'btn btn-danger invisible';
    document.getElementById(idButtonDeleting).classList.remove('invisible');
    client
      .query({ query: SEARCHPAYMENTBYID, variables: { idPayment } })
      .then((result) => {
        this.setState({
          currentPayment: idPayment,
          diffValorPago: -result.data.SearchPaymentById.valorPago, // Negativo para neutralizar
          diffAbonoCapital: -result.data.SearchPaymentById.abonoCapital, // negativo must be para crecer
          diffAbonoInteres: -result.data.SearchPaymentById.abonoInteres, // negativo para decrecer
          diffDiasMora: -result.data.SearchPaymentById.diasMora, // negativo para ser 0
          diffInteresMora: -result.data.SearchPaymentById.interesMora, // negativo to be zero,
          numeroCuota: result.data.SearchPaymentById.cuota,
          estado: 'Por pagar', // Por pagar,
          action: 'Delete',
        });
        this.modifyTableSameCuote(idPayment);
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          titleModal: <div className="center wrong" />,
          show: true,
          messageModal:
            'Ocurrio un error al cargar el pago de la base de datos',
        });
        document.getElementsByName(idButton)[0].className = 'btn btn-danger';
        document.getElementById(idButtonDeleting).className =
          'btn btn-danger invisible';
      });
  };

  deletePaymentFromPlan = (idPayment) => {
    let idPay = idPayment;
    let idPlan = this.state.currentPlan;

    let idButton = `deleteBtn${idPay}`;
    let idButtonDeleting = `deletingBtn${idPay}`;

    client
      .mutate({
        mutation: DELETEPAYMENTFROMPLAN,
        variables: { idPlan: idPlan, idPay: idPay },
      })
      .then(() => {
        this.deletePayment(idPay);
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          titleModal: <div className="center wrong" />,
          show: true,
          messageModal:
            'Ocurrio un error al eliminar el pago dentro del plan de la base de datos',
        });
        document.getElementsByName(idButton)[0].className = 'btn btn-danger ';
        document.getElementById(idButtonDeleting).className =
          'btn btn-danger invisible';
      });
  };

  deletePayment = (idPayment) => {
    let idPay = idPayment;
    let idPlan = this.state.currentPlan;

    client
      .mutate({ mutation: DELETEPAYMENT, variables: { idPayment: idPay } })
      .then((result) => {
        this.setState({
          titleModal: <div className="center success" />,
          show: true,
          messageModal: 'Pago Eliminado satisfactoriamente',
          action: 'Modify',
          payments: [],
        });
        this.getPayments(idPlan);
        // document.getElementsByName(idButton)[0].className = 'btn btn-danger '
        // document.getElementById(idButtonDeleting).className = 'btn btn-danger invisible'
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          titleModal: <div className="center wrong" />,
          show: true,
          messageModal:
            'Ocurrio un error al eliminar el pago en la base de datos',
        });
        // document.getElementsByName(idButton)[0].className = 'btn btn-danger '
        // document.getElementById(idButtonDeleting).className = 'btn btn-danger invisible'
      });
  };

  /**
   * Arrow Function para dar formato de boton a la columna del DATATABLE
   * @param e Elemento HTML con los elementos texto que lleva la columna de la tabla
   */
  buttonTableDelete = (e) => {
    let key = e.key;
    // Only rol with permission can delete
    if (this.state.permisos.eliminar) {
      // eslint-disable-next-line no-useless-escape
      let id = key.replace(/\"/g, '');
      return (
        <Fragment>
          <button
            name={'deleteBtn' + id}
            type="button"
            id={id}
            className="btn btn-danger"
            onClick={this.startDelete}
          >
            Eliminar
          </button>
          <button
            name={'deletingBtn'}
            type="button"
            id={'deletingBtn' + id}
            className="btn btn-danger invisible"
            disabled
          >
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            {` Eliminando...`}
          </button>
        </Fragment>
      );
    } else {
      return null;
    }
  };

  /**
   * Arrow Function para dar formato de boton a la columna del DATATABLE
   * @param e Elemento HTML con los elementos texto que lleva la columna de la tabla
   */
  buttonTableEdit = (e) => {
    let key = JSON.stringify(e.key);
    // eslint-disable-next-line no-useless-escape
    let id = key && key.replace(/\"/g, '');
    // Only rol with permission can edit
    if (this.state.permisos.editar) {
      return (
        <button
          id={id}
          className="btn btn-primary"
          onClick={this.showModifyPayment}
        >
          Editar
        </button>
      );
    } else {
      return null;
    }
  };

  render() {
    let newRowUserReport = [];
    this.state.payments
      .sort(
        (a, b) =>
          (a &&
            a.data &&
            a.data.SearchPaymentById &&
            a.data.SearchPaymentById.cuota) >
          (b &&
            b.data &&
            b.data.SearchPaymentById &&
            b.data.SearchPaymentById.cuota)
      )
      .map((payment, index) => {
        const validationPayment =
          payment && payment.data && payment.data.SearchPaymentById;
        newRowUserReport.push({
          fecha: validationPayment && payment.data.SearchPaymentById.fecha,
          cuota: validationPayment && payment.data.SearchPaymentById.cuota,
          valor: validationPayment && payment.data.SearchPaymentById.valorPago,
          abCapital:
            validationPayment && payment.data.SearchPaymentById.abonoCapital,
          abInteres:
            validationPayment && payment.data.SearchPaymentById.abonoInteres,
          observaciones:
            validationPayment && payment.data.SearchPaymentById.observaciones,
          eliminar: {
            key: validationPayment && payment.data.SearchPaymentById._id,
            index,
          },
          editar: {
            key: validationPayment && payment.data.SearchPaymentById._id,
            index,
          },
        });
        return null;
      });

    let optionPlan =
      this.state.planes !== []
        ? this.props.planes.map((plan) => {
            if (plan.proyecto === this.state.proyecto) {
              return (
                <option id={plan.descripcion} value={plan.idPlan}>
                  {plan.descripcion}
                </option>
              );
            }
            return null;
          })
        : null;

    return (
      <div>
        <div>
          <span className="warningMessage">
            <i className="fas fa-exclamation-circle" />
            Tenga en cuenta que esta version del sistema no recalcula los
            valores automaticamente para modificar los pagos, por favor realice
            los calculos a mano antes de modificar cualquier pago.Por favor
            llene todos los campos
          </span>
          <span className="warningMessage">
            <i className="fas fa-exclamation-circle" />
            Tambien, como financieramente los intereses se mantienen en el
            tiempo, solo se permitira modificar/eliminar el ultimo pago, si
            desea modificar/eliminar pagos anteriores, debe eliminar desde el
            ultimo pago para no generar errores en el plan de pagos
          </span>
          <h6>Seleccione el plan al que desea modificar sus pagos</h6>
          <select
            className="form-control planoption"
            onChange={this.handleChangeIdPlan}
          >
            <option defaultValue>Seleccione</option>
            {optionPlan}
          </select>
        </div>

        {
          // Si el arreglo esta lleno, se renderiza el componente de DATATABLE, sino, retorna null
          newRowUserReport !== [] ? (
            <BootstrapTable
              data={newRowUserReport}
              id="tablePayments"
              className="DatatablePayments"
              hover
              pagination
              version="4"
            >
              <TableHeaderColumn dataField="fecha" dataSort>
                Fecha
              </TableHeaderColumn>
              <TableHeaderColumn
                isKey
                dataField="cuota"
                dataSort
                filter={{ type: 'TextFilter', delay: 500 }}
              >
                Cuota
              </TableHeaderColumn>
              <TableHeaderColumn dataField="valor" dataSort>
                Valor
              </TableHeaderColumn>
              <TableHeaderColumn dataField="abCapital" dataSort>
                Abono Capital
              </TableHeaderColumn>
              <TableHeaderColumn dataField="abInteres" dataSort>
                Abono Interes
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="observaciones"
                dataSort
                filter={{ type: 'TextFilter', delay: 500 }}
              >
                Observaciones
              </TableHeaderColumn>
              {/* <TableHeaderColumn dataField='estado' dataSort filter={{ type: 'TextFilter', delay: 500 }}>Estado</TableHeaderColumn> */}
              <TableHeaderColumn
                className="viewButton"
                dataField="editar"
                dataFormat={this.buttonTableEdit.bind(this)}
              />
              <TableHeaderColumn
                className="viewButton"
                dataField="eliminar"
                dataFormat={this.buttonTableDelete.bind(this)}
              />
            </BootstrapTable>
          ) : null
        }

        <div id="modificacionPagos" className="invisible">
          <h3>Modificacion Pagos</h3>
          <div className="invisible">
            <div>
              <label htmlFor="exampleFormControlInput1">% Tasa Interes</label>
              <input
                className="form-control CommonWidth"
                value={this.state.tasaInteres}
                disabled
              />
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={this.state.boolInteres}
                id="boolInteres"
                onClick={this.handleChangeBoolIntereses}
              />
              <label className="form-check-label" htmlFor="defaultCheck1">
                Cobrar intereses
              </label>
            </div>
          </div>
          <h5>Datos Anteriores</h5>
          <div>
            <div className="form-group row Common">
              <div className="col-xs-2 Common">
                <label htmlFor="exampleFormControlInput1"> Fecha pago: </label>
                <input
                  type="date"
                  id="fechaCuota"
                  className="form-control CommonWidth"
                  value={this.state.fechaPago}
                  disabled
                  readOnly
                />
              </div>
              <div className="col-xs-2 Common">
                <label htmlFor="exampleFormControlInput1">Cuota #</label>
                <input
                  type="number"
                  id="numeroCuota"
                  className="form-control CommonWidth"
                  value={this.state.numeroCuota}
                  disabled
                  readOnly
                />
              </div>
              <div className="col-xs-2 Common">
                <label htmlFor="exampleFormControlInput1">Valor Pago</label>
                <input
                  type="number"
                  id="valorPago"
                  className="form-control CommonWidth"
                  value={this.state.valorPago}
                  disabled
                  readOnly
                />
              </div>
              <div className="col-xs-2 Common">
                <label htmlFor="exampleFormControlInput1">Abono Capital</label>
                <input
                  type="number"
                  id="abonoCapital"
                  className="form-control CommonWidth"
                  value={this.state.abonoCapital}
                  disabled
                  readOnly
                />
              </div>
              <div className="col-xs-2 Common">
                <label htmlFor="exampleFormControlInput1">Abono Interes</label>
                <input
                  type="number"
                  id="abonoInteres"
                  className="form-control CommonWidth"
                  value={this.state.abonoInteres}
                  disabled
                  readOnly
                />
              </div>
            </div>
            <div className="form-group row Common">
              <div className="col-xs-2 Common">
                <label htmlFor="exampleFormControlInput1">
                  {' '}
                  Interes Mora:{' '}
                </label>
                <input
                  type="number"
                  id="interesMora"
                  className="form-control CommonWidth"
                  value={this.state.interesMora}
                  disabled
                  readOnly
                />
              </div>
              <div className="col-xs-2 Common">
                <label htmlFor="exampleFormControlInput1">Dias Mora</label>
                <input
                  type="number"
                  id="diasMora"
                  className="form-control CommonWidth"
                  value={this.state.diasMora}
                  disabled
                  readOnly
                />
              </div>
              <div className="col-xs-2 Common">
                <label htmlFor="exampleFormControlInput1">Observaciones</label>
                <input
                  type="text"
                  id="observaciones"
                  className="form-control observationWidth"
                  value={this.state.observaciones}
                  disabled
                  readOnly
                />
              </div>
            </div>
          </div>
          <h5>Datos Nuevos</h5>
          <div>
            <div className="form-group row Common">
              <div className="col-xs-2 Common">
                <label htmlFor="exampleFormControlInput1"> Fecha pago: </label>
                <input
                  type="date"
                  id="newFechaPago"
                  className="form-control CommonWidth"
                  value={this.state.newFechaPago}
                  onChange={this.handleChangeFecha}
                />
              </div>
              <div className="col-xs-2 Common">
                <label htmlFor="exampleFormControlInput1">Cuota #</label>
                <input
                  type="number"
                  id="numeroCuota"
                  className="form-control CommonWidth"
                  value={this.state.numeroCuota}
                  onChange={this.handleChangeCuota}
                  disabled
                />
              </div>
              <div className="col-xs-2 Common">
                <label htmlFor="exampleFormControlInput1">Valor Pago</label>
                <input
                  type="number"
                  id="newValorPago"
                  className="form-control CommonWidth"
                  value={this.state.newValorPago}
                  onChange={this.handleChangeValorPago}
                />
              </div>
              <div className="col-xs-2 Common">
                <label htmlFor="exampleFormControlInput1">Abono Capital</label>
                <input
                  type="number"
                  id="newAbonoCapital"
                  className="form-control CommonWidth"
                  value={this.state.newAbonoCapital}
                  onChange={this.handleChangeAbonoCapital}
                />
              </div>
              <div className="col-xs-2 Common">
                <label htmlFor="exampleFormControlInput1">Abono Interes</label>
                <input
                  type="number"
                  id="newAbonoInteres"
                  className="form-control CommonWidth"
                  value={this.state.newAbonoInteres}
                  onChange={this.handleChangeAbonoInteres}
                />
              </div>
            </div>
            <div className="form-group row Common">
              <div className="col-xs-2 Common">
                <label htmlFor="exampleFormControlInput1">
                  {' '}
                  Interes Mora:{' '}
                </label>
                <input
                  type="number"
                  id="newInteresMora"
                  className="form-control CommonWidth"
                  value={this.state.newInteresMora}
                  onChange={this.handleChangeInteresMora}
                />
              </div>
              <div className="col-xs-2 Common">
                <label htmlFor="exampleFormControlInput1">Dias Mora</label>
                <input
                  type="number"
                  id="newDiasMora"
                  className="form-control CommonWidth"
                  value={this.state.newDiasMora}
                  onChange={this.handleChangeDiasMora}
                />
              </div>
              <div className="col-xs-2 Common">
                <label htmlFor="exampleFormControlInput1">Observaciones</label>
                <input
                  type="text"
                  id="newObservaciones"
                  className="form-control observationWidth"
                  value={this.state.newObservaciones}
                  onChange={this.handleChangeObservaciones}
                />
              </div>
            </div>
            <div className="form-group row Common">
              <div className="col-xs-2 Common">
                <label className="form-check-label" htmlFor="defaultCheck1">
                  Nuevo Estado Cuota
                </label>
                <select
                  className="form-control hundWidth"
                  value={this.state.estado}
                  id="boolInteres"
                  onChange={this.handleChangeEstado}
                >
                  <option value="Pagado">Pagado</option>
                  <option value="Por Pagar">Por Pagar</option>
                  <option value="Vencido">Vencido</option>
                  <option value="Pago Parcial">Pago Parcial</option>
                </select>
              </div>
            </div>
          </div>
          <div className="buttonOptions">
            <button
              type="button"
              id="modifyBtnPayment"
              className="btn btn-primary mb-2 btnCentered"
              onClick={this.modifyTableSameCuote}
            >
              Actualizar Informacion
            </button>
            <button
              type="button"
              id="modifyingPayment"
              className="btn btn-primary mb-2 btnCentered invisible"
              disabled
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              {` Actualizado...`}
            </button>
          </div>
        </div>

        <Modal isOpen={this.state.show} toggle={this.handleClose}>
          <ModalHeader>
            <>{this.state.titleModal}</>
          </ModalHeader>
          <ModalBody>{this.state.messageModal}</ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-default"
              onClick={this.handleClose}
            >
              Cerrar
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
