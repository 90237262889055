// @ts-nocheck
import React, { Component } from 'react';
import ReactToExcel from 'react-html-table-to-excel';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import client from 'utils/database';
import { SEARCHUSERBYPARAM } from 'utils/database/query/users';
import {
  LOTS_PROJECT_AVAILABLES,
  VALIDATE_AVAILABLE_LOTE,
} from 'utils/database/query/lots';
import { SEARCHPLANCOTIZACION } from 'utils/database/query/plans';
import { UPDATEPLAN, UPLOADPLAN } from 'utils/database/mutation/plans';
import {
  ADDUSERPLAN,
  UPLOADUSER,
  CHECKUSER,
} from 'utils/database/mutation/users';
import { UPDATETABLE, UPLOADTABLE } from 'utils/database/mutation/tables';

import './Cotizacion.css';
import { InputLabel, MenuItem, Select } from '@mui/material';

export default class Cotizacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proyecto: '', // Podria ser una constante
      maximoCuotasInicial: 0, // Podria ser una constante
      listaNumInmueble: [], // Constante
      tipoVendedor: 2, // Constante (Empleado)
      listaInmueble: [], // Constante
      cedula: '',
      nombre: '',
      email: '',
      celular: '',
      direccion: '',
      vendedor: '',
      observaciones: '',
      inmueble: '', // Tipo Inmueble escogido CASA,LOTE,...
      numInmueble: 1, // Numero Inmueble escogido
      descripcionLote: '', // Inmueble + numInmueble...necesario?()
      boolFechaAutomatica: true, // Boolean fecha de realizacion del plan
      tasaInteres: 0.083, // Valor en %
      precioTotal: '', // Precio neto del inmueble
      valorFinal: 0, // Valor final con intereses
      precioFinanciar: '', // Valor en $
      maxFinanciacion: 70, // Valor en %
      porcentajeFinanciar: '', // Valor en %
      cuotaInicial: '', // Valor en $
      fechaInicioPlan: '', // Valor en DATE
      fechaInicioCuotaIni: '', // Valor en DATE
      fechaRealizacionPlan: '', // Valor en DATE
      numCuotasInicial: '', // Valor en #
      numCuotas: '', // Valor en #
      periodicidad: 'Mensual',
      boolCuotaPersonal: false,
      valorCuotaPersonal: 0, // Valor en $
      boolAbonoExtra: false,
      boolExtrasAuto: false,
      boolIntereses: true,
      boolExtraFija: true,
      boolExtraSola: false,
      numAbonos: 0, // Valor en #, cuotas extras
      TableNumCuotas: [], // Array para renderizar un <select> con opciones dinamicas
      innerCuotasInicial: [], // Array para renderizar un <select> con opciones dinamicas
      arrNumCuotasIni: [], // Array para renderizar un <select> con opciones dinamicas
      arrNumAbonos: [], // Array para renderizar un <select> con opciones dinamicas
      tableUpload: [], // Array para guardar la tabla de amortizacion y exportarla
      currentDate: '', // Fecha actual para la exportacion del documento Excel
      show: false, // Estado del modal
      messageModal: '', // Mensaje del modal
      titleModal: <div className="center success" />, // Estilo barra superior modal
      idPlanOver: '', // Id Plan para sobreescribir (override)
      idTableOver: '', // Id Tabla para sobreescribir (override)
      lotesAutomaticos: false,
      lotesDisponibles: [],
    };
  }

  componentWillMount() {
    let configuracion = sessionStorage.getItem('configuracion'); // usar render props? redux? Deshacerse de sessionStorage
    configuracion = JSON.parse(configuracion);
    let user = sessionStorage.getItem('user');
    user = JSON.parse(user);
    this.getProjectInfo(configuracion, user);
    this.getAvailableLots(configuracion);
    this.getCurrentDate();
  }

  /**
   * Este metodo obtiene la configuracion inicial del proyecto
   * tal como el numero de inmuebles para cada tipo, el maximo % de financiacion,
   * el maximo numero de cuotas iniciales, etc...
   * ademas de la informacion del vendedor actual
   * ademas, los lotes disponibles para el proyecto
   */
  getProjectInfo = (configuracion, user) => {
    console.log(user.permisos.cotizacion);
    console.log(user);
    this.setState({
      vendedor: user.nombre,
      tipoVendedor: user.rol.idRol,
      permisos: user.permisos.cotizacion,
      proyecto: configuracion.nombre,
      tasaInteres: configuracion.tasaInteres,
      listaInmueble: configuracion.tipoInmuebles,
      lotesAutomaticos: configuracion.lotesAutomaticos,
      maximoCuotasInicial: configuracion.tipoInmuebles[0].maximoCuotasInicial,
      maxFinanciacion: configuracion.tipoInmuebles[0].maxFinanciacion,
    });
  };

  /**
   * Este metodo obtiene los lotes en estado disponible del proyecto
   */
  getAvailableLots = (configuracion) => {
    client
      .query({
        query: LOTS_PROJECT_AVAILABLES,
        variables: { idProyecto: configuracion._id },
      })
      .then((result) => {
        const lots = result.data.SearchAvailableLotsByIdProyeto;
        this.setState({
          lotesDisponibles: lots,
        });

        document.getElementById('loadingPlans').className = 'invisible'; // Se oculta el spin de loading
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  /**
   * Este metodo obtiene la fecha en la que se realiza la cotizacion para
   * luego formatearla y exponerla en el documento exportable de Excel
   */
  getCurrentDate = () => {
    let meses = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    let date = new Date();
    let current =
      date.getDate() + ` de ${meses[date.getMonth()]} de ` + date.getFullYear();
    this.setState({
      currentDate: current,
    });
  };

  /**
   * Este metodo cambia el estado de la cedula y a la vez
   * busca en la base de datos si algun usuario coincide y si lo hace,
   * trae la informacion para mayor comodidad del vendedor
   */
  handleChangeCedula = (e) => {
    this.setState({
      cedula: e.target.value.trim(), // Se establece el estado
    });
    document.getElementById('loadingCedula').className =
      'spinner-border spinner-border-sm text-info'; // Spinner loading
    client // Se empieza el Query de GRAPHQL
      .query({
        query: SEARCHUSERBYPARAM,
        variables: { cedula: e.target.value },
      })
      .then((result) => {
        this.setState({
          // Si la cedula encuentra coincidencias, se muestra la informacion del Cliente
          nombre: result.data.SearchUserByParam.nombre,
          celular: result.data.SearchUserByParam.celular,
          email: result.data.SearchUserByParam.correo,
          direccion: result.data.SearchUserByParam.direccion,
        });
        document.getElementById('loadingCedula').className =
          'spinner-border spinner-border-sm text-info invisible';
      })
      .catch(() => {
        // Si no se encuentran coincidencias, simplemente se desaparece el Spinner
        document.getElementById('loadingCedula').className =
          'spinner-border spinner-border-sm text-info invisible';
      });
  };

  handleChangeNombre = (e) => {
    this.setState({
      nombre: e.target.value,
    });
  };

  handleChangeEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  handleChangeCelular = (e) => {
    this.setState({
      celular: e.target.value,
    });
  };

  handleChangeDireccion = (e) => {
    this.setState({
      direccion: e.target.value,
    });
  };

  handleChangeVendedor = (e) => {
    this.setState({
      vendedor: e.target.value,
    });
  };

  handleChangeObservaciones = (e) => {
    this.setState({
      observaciones: e.target.value,
    });
  };

  /**
   * Este metodo, al cambiar el tipo de inmueble en el <select>
   * cambia la configuracion de los valores de maximo cuota inicial y maximo financiacion
   * ya que pueden tener valores diferentes entre tipos de inmueble
   * @param e Elemento HTML select con la informacion y valores de los inmuebles
   */
  handleChangeTipoInmueble = (e) => {
    let index = this.state.listaInmueble
      .map((item) => item.tipo)
      .indexOf(e.target.value);
    let descripcionLote = e.target.value + ' # ' + this.state.numInmueble;

    let { numInmueble, listaNumInmueble } = this.getDataLotes(e.target.value);

    this.setState({
      inmueble: e.target.value,
      descripcionLote: descripcionLote,
      maximoCuotasInicial: this.state.listaInmueble[index].maximoCuotasInicial,
      maxFinanciacion: this.state.listaInmueble[index].maxFinanciacion,
      precioTotal: 0, // Estos valores se vuelven 0 cero para evitar confusiones con los valores
      precioFinanciar: 0,
      porcentajeFinanciar: 0,
      cuotaInicial: 0,
      numInmueble,
      listaNumInmueble,
    });
  };

  getDataLotes = (tipoInmu) => {
    let configuracion = sessionStorage.getItem('configuracion'); // usar render props? redux? Deshacerse de sessionStorage
    configuracion = JSON.parse(configuracion);

    let lotes = this.state.lotesDisponibles.filter((lot) => {
      const tipo = tipoInmu.trim().toUpperCase();
      if (['LOTE', 'CASA'].includes(tipo)) {
        return (
          lot.tipoInmueble === tipoInmu.trim() && lot.estado === "Disponible"
        );
      }
      return lot.tipoInmueble === tipoInmu.trim();
    });

    let numInmueble = null;
    let listaNumInmueble = [];

    if (lotes.length > 0) {
      numInmueble = lotes[0];
      listaNumInmueble = lotes;
    } else {
      if (this.state.lotesAutomaticos) {
        numInmueble = null;
        listaNumInmueble = [];
      } else {
        let arrayNums = [];
        for (let i = 1; i <= configuracion.numLotes; i++) {
          arrayNums.push({ idProyecto: '', numeroLote: i });
        }
        numInmueble = arrayNums[0];
        listaNumInmueble = arrayNums;
      }
    }

    return {
      numInmueble,
      listaNumInmueble,
    };
  };

  /**
   * Este metodo cambia el estado referente al numero del inmueble
   * @param e Elemento HTML con el numero de inmueble
   */
  handleChangeNumInmueble = (e) => {
    const { numeroLote } = JSON.parse(e.target.value);
    let descripcionLote = this.state.inmueble + ' # ' + numeroLote;
    this.setState({
      numInmueble: JSON.parse(e.target.value),
      descripcionLote: descripcionLote,
    });
  };

  /**
   * Este metodo hace de Toggle para que el campo de establecer fecha de realizacion
   * del plan aparezca o desaparezca, a la vez que cambia el estado para luego ser usado
   * @param e Elemento HTML check Button
   */
  handleChangeFechaAuto = (e) => {
    this.setState({
      boolFechaAutomatica: e.target.checked,
    });
    if (e.target.checked === false) {
      document.getElementById('rea').className = 'form-group row cotiCommon';
    } else {
      document.getElementById('rea').className =
        'form-group row cotiCommon invisibleTable';
    }
  };

  handleChangeFechaRealizacion = (e) => {
    this.setState({
      fechaRealizacionPlan: e.target.value,
    });
  };

  handleChangeTasa = (e) => {
    this.setState({
      tasaInteres: e.target.value,
    });
  };

  /**
   * Este metodo, al cambiar el precio total neto del inmueble,
   * halla los valores maximos de valor a financiar y valor de la cuota inicial
   * @param e Elemento HTML donde se obtiene el valor total neto
   */
  handleChangePrecioTotal = (e) => {
    let maxFinanciacion = parseInt(this.state.maxFinanciacion);
    let maxFinanciacionFloat = parseFloat(this.state.maxFinanciacion) / 100;
    this.setState({
      precioTotal: e.target.value,
      precioFinanciar: e.target.value * maxFinanciacionFloat,
      porcentajeFinanciar: maxFinanciacion,
      cuotaInicial: e.target.value - e.target.value * maxFinanciacionFloat,
    });
  };

  /**
   * Este metodo recalcula el valor de la cuota inicial y el % a financiar
   * siempre y cuando el valor a financiar no exceda el % porcentaje maximo a financiar
   * @param e Elemento HTML donde se obtiene el valor A financiar
   */
  handleChangePrecioFinanciar = (e) => {
    let financiar = e.target.value;
    let completo = this.state.precioTotal; // Valor neto del inmueble
    let porcentaje = (financiar * 100) / completo; // Se obtiene el porcentaje real
    let maxFinanciacion = parseInt(this.state.maxFinanciacion);
    let maxFinanciacionFloat = parseFloat(this.state.maxFinanciacion) / 100;
    if (porcentaje <= maxFinanciacion) {
      // Si el porcentaje real es menor o igual al limite establecido se establecen los nuevos valores
      this.setState({
        precioFinanciar: e.target.value,
        porcentajeFinanciar: porcentaje,
        cuotaInicial: completo - e.target.value,
      });
    } else {
      // Si excede el maximo % a financiar, se establecen los valores maximos limites de financiacion
      this.setState({
        precioFinanciar: completo * maxFinanciacionFloat,
        porcentajeFinanciar: maxFinanciacion,
        cuotaInicial: completo - completo * maxFinanciacionFloat,
      });
    }
  };

  /**
   * Este metodo recalcula el valor de la cuota inicial y el valor a financiar
   * siempre y cuando el % a financiar no exceda el % porcentaje maximo a financiar
   * @param e Elemento HTML donde se obtiene el % A financiar
   */
  handleChangePorcentajeFinanciar = (e) => {
    let porcentaje = e.target.value; // Porcentaje real
    let completo = this.state.precioTotal; // Valor neto del inmueble
    let financiar = (completo * porcentaje) / 100;
    let maxFinanciacion = parseInt(this.state.maxFinanciacion);
    let maxFinanciacionFloat = parseFloat(this.state.maxFinanciacion) / 100;
    if (porcentaje <= maxFinanciacion) {
      this.setState({
        // Si el porcentaje real es menor o igual al limite establecido se establecen los nuevos valores
        precioFinanciar: financiar,
        porcentajeFinanciar: porcentaje,
        cuotaInicial: completo - financiar,
      });
    } else {
      // Si excede el maximo % a financiar, se establecen los valores maximos limites de financiacion
      this.setState({
        precioFinanciar: completo * maxFinanciacionFloat,
        porcentajeFinanciar: maxFinanciacion,
        cuotaInicial: completo - completo * maxFinanciacionFloat,
      });
    }
  };

  /**
   * Este metodo recalcula el % a financiar y el valor a financiar
   * siempre y cuando el valor de la cuota inicial no exceda el % porcentaje maximo a financiar
   * @param e Elemento HTML donde se obtiene el valor de la cuota inicial
   */
  handleChangeCuotaInicial = (e) => {
    let maxFinanciacion = parseInt(this.state.maxFinanciacion);
    let maxFinanciacionFloat = parseFloat(this.state.maxFinanciacion) / 100;
    let cuotaInicial = e.target.value;
    let completo = this.state.precioTotal;

    let diff = completo - cuotaInicial;
    let porcentaje = (diff * 100) / completo;

    if (porcentaje <= maxFinanciacion) {
      this.setState({
        porcentajeFinanciar: porcentaje,
        precioFinanciar: diff,
        cuotaInicial: e.target.value,
      });
    } else {
      this.setState({
        precioFinanciar: completo * maxFinanciacionFloat,
        porcentajeFinanciar: maxFinanciacion,
        cuotaInicial: completo - completo * maxFinanciacionFloat,
      });
    }
  };

  /**
   * Este metodo recibe una fecha inicial de cuota inicial, y a partir de esta (si ya hay una fecha de plan inicial)
   * se encuentra el numero de <options> que puede haber dentro del <select> del numero de cuotas inicial
   * @param e Elemento HTML donde se obtiene la fecha de inicial de cuota inicial
   */
  handleChangeFechaCuotaIni = (e) => {
    let mesesDate = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];
    this.setState({
      fechaInicioCuotaIni: e.target.value,
      TableNumCuotas: [],
    });

    var fechaCuotaInicial = e.target.value;
    var anoCuota = parseInt(fechaCuotaInicial.substring(0, 4));
    var mesCuota = parseInt(fechaCuotaInicial.substring(5, 7));
    var dayIni = fechaCuotaInicial.substring(8);

    var fechaIni = this.state.fechaInicioPlan;
    if (fechaIni !== '') {
      var anoIni = parseInt(fechaIni.substring(0, 4));
      var mesIni = parseInt(fechaIni.substring(5, 7));

      let months;
      months = (anoIni - anoCuota) * 12;
      months += mesIni;
      months -= mesCuota;
      var newList = [];
      newList.push(<option selected>Seleccione # </option>);
      if (months <= this.state.maximoCuotasInicial) {
        for (var i = 1; i <= months; i++) {
          newList.push(<option> {i} </option>);
        }
      } else {
        let monthMax = mesCuota + parseInt(this.state.maximoCuotasInicial);
        let dayMax = dayIni;
        let anoMax = anoCuota;

        if (monthMax > 12) {
          anoMax++;
          monthMax -= 12;
        }

        let fechaMax = anoMax + '-' + mesesDate[monthMax - 1] + '-' + dayMax;
        this.setState({
          fechaInicioPlan: fechaMax,
        });
        for (let i = 1; i <= this.state.maximoCuotasInicial; i++) {
          newList.push(<option> {i} </option>);
        }
      }
      this.setState({
        TableNumCuotas: newList,
      });
    }
  };

  /**
   * Este metodo recibe una fecha inicial del plan, y a partir de esta (si ya hay una fecha de cuota inicial)
   * se encuentra el numero de <options> que puede haber dentro del <select> del numero de cuotas inicial
   * @param e Elemento HTML donde se obtiene la fecha de inicio de plan
   */
  handleChangeFechaInicioPlan = (e) => {
    let mesesDate = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];
    this.setState({
      fechaInicioPlan: e.target.value,
      TableNumCuotas: [],
    });
    var fechaIni = e.target.value;
    var anoIni = parseInt(fechaIni.substring(0, 4));
    var mesIni = parseInt(fechaIni.substring(5, 7));

    var fechaCuotaInicial = this.state.fechaInicioCuotaIni;

    if (fechaCuotaInicial !== '') {
      var anoCuota = parseInt(fechaCuotaInicial.substring(0, 4));
      var mesCuota = parseInt(fechaCuotaInicial.substring(5, 7));
      var dayIni = fechaCuotaInicial.substring(8);

      let months;
      months = (anoIni - anoCuota) * 12;
      months += mesIni;
      months -= mesCuota;
      let newList = [];
      newList.push(<option selected>Seleccione # </option>);

      if (months <= this.state.maximoCuotasInicial) {
        for (let i = 1; i <= months; i++) {
          newList.push(<option> {i} </option>);
        }
      } else {
        let monthMax = mesCuota + parseInt(this.state.maximoCuotasInicial);
        let dayMax = dayIni;
        let anoMax = anoCuota;

        if (monthMax > 12) {
          anoMax++;
          monthMax -= 12;
        }

        let fechaMax = anoMax + '-' + mesesDate[monthMax - 1] + '-' + dayMax;
        this.setState({
          fechaInicioPlan: fechaMax,
        });
        for (let i = 1; i <= this.state.maximoCuotasInicial; i++) {
          newList.push(<option> {i} </option>);
        }
      }

      this.setState({
        TableNumCuotas: newList,
      });
    }
  };

  /**
   * Este metodo obtiene el numero de cuotas iniciales y llama a otro metodo auxiliar
   * para construir de manera dinamica un formulario de varias cuotas con su respectivo valor
   * @see fechasInicial()
   * @param e Elemento HTML donde se obtiene el numero de cuotas iniciales
   */
  handleChangeNumCuotasInicial = (e) => {
    this.setState({
      numCuotasInicial: e.target.value,
    });
    this.fechasInicial(e.target.value);
  };

  /**
   * Este metodo recibe un numero entero y define los estados que ocuparan en memoria
   * las diferentes cuotas iniciales, al igual que la cuota de separacion.
   * Ademas crea un arreglo para luego en el render() crear los espacios donde se cambiaran
   * los estados
   * @param num Numero entero que define cuantas cuotas iniciales van a existir
   */
  fechasInicial = (num) => {
    let arrNums = [];
    let valorCuotaInicial = parseInt(this.state.cuotaInicial) / num; // Se divide la cuota inicial en partes iguales
    let key = 'valIni';
    let idStr;
    this.setState({
      valIni0: 0,
    });
    for (let i = 1; i <= num; i++) {
      arrNums.push(i);
      idStr = key + i;
      this.setState({
        [idStr]: valorCuotaInicial, // Cada cuota inicial sera inicialmente de igual valor
      });
    }
    this.setState({
      arrNumCuotasIni: arrNums,
    });
  };

  /**
   * Este metodo cambia el valor de los estados de las cuotas iniciales y abonos extras
   * @param e Elemento HTML donde se obtiene el key(JSON) del estado y el valor nuevo del estado
   */
  handleChangeValuesIni = (e) => {
    let id = e.target.id;
    this.setState({
      [id]: e.target.value,
    });
  };

  /**
   * Este metodo recalcula el valor de las cuotas iniciales, si se cambia un valor, entonces
   * el valor de la cuota inicial se reparte entre el resto de las cuotas iniciales
   * @param e Elemento HTML donde se obtiene el valor cambiado de una cuota inicial
   */
  calculoCuotasIni = (e) => {
    const id = parseInt(e.target.id.replace('valIni', ''));
    const num = parseInt(this.state.numCuotasInicial);
    const valorCuotaInicial = parseInt(this.state.cuotaInicial);
    var newVal = 0;
    var idStr = '';
    var changedVal = 0;
    if (id === parseInt(num)) {
      idStr = '';
      changedVal = 0;
      for (let i = id - 1; i >= 0; i--) {
        idStr = 'valIni' + i;
        changedVal += parseInt(this.state[idStr]);
      }
      newVal = valorCuotaInicial - changedVal;
      idStr = 'valIni' + id;
      this.setState({
        [idStr]: parseInt(newVal),
      });
    } else {
      idStr = '';
      changedVal = 0;
      for (let i = id; i >= 0; i--) {
        idStr = 'valIni' + i + '';
        changedVal += parseInt(this.state[idStr]);
      }
      newVal = (valorCuotaInicial - changedVal) / (num - id);
      for (let i = id + 1; i <= num; i++) {
        idStr = 'valIni' + i;
        this.setState({
          [idStr]: parseInt(newVal),
        });
      }
    }
  };

  handleChangeNumCuotas = (e) => {
    this.setState({
      numCuotas: e.target.value,
    });
  };

  handleChangePeriodicidad = (e) => {
    this.setState({
      periodicidad: e.target.value,
    });
  };

  handleChangeBoolCuotaFija = () => {
    if (this.state.boolCuotaPersonal) {
      this.setState({
        boolCuotaPersonal: false,
      });
      document.getElementById('generarAutoDiv').className =
        'form-check invisibleTable';
      document.getElementById('valorPersonalizada').className =
        'form-group row cotiCommon invisibleTable';
    } else if (!this.state.boolCuotaPersonal) {
      this.setState({
        boolCuotaPersonal: true,
      });
      document.getElementById('generarAutoDiv').className = 'form-check';
      document.getElementById('valorPersonalizada').className =
        'form-group row cotiCommon';
    }
  };

  handleChangeCuotaPersonal = (e) => {
    this.setState({
      valorCuotaPersonal: e.target.value,
    });
  };

  handleChangeBoolAbonoExtra = () => {
    if (this.state.boolAbonoExtra) {
      this.setState({
        boolAbonoExtra: false,
      });
      document.getElementById('extras').className = 'invisibleTable';
    } else if (!this.state.boolAbonoExtra) {
      this.setState({
        boolAbonoExtra: true,
      });
      document.getElementById('extras').className = '';
    }
  };

  handleChangeBoolExtrasAuto = () => {
    if (this.state.boolExtrasAuto) {
      this.setState({
        boolExtrasAuto: false,
      });
    } else if (!this.state.boolExtrasAuto) {
      this.setState({
        boolExtrasAuto: true,
      });
    }
  };

  handleChangeBoolIntereses = () => {
    if (
      this.state.tipoVendedor === 1 ||
      this.state.permisos.cobroInteresExtra
    ) {
      if (this.state.boolIntereses) {
        this.setState({
          boolIntereses: false,
        });
      } else if (!this.state.boolIntereses) {
        this.setState({
          boolIntereses: true,
        });
      }
    }
  };

  handleChangeBoolExtraFija = () => {
    if (this.state.boolExtraFija) {
      this.setState({
        boolExtraFija: false,
        boolExtraSola: true,
      });
    } else if (!this.state.boolExtraFija) {
      this.setState({
        boolExtraFija: true,
        boolExtraSola: false,
      });
    }
  };

  handleChangeBoolExtraSola = () => {
    if (this.state.boolExtraSola) {
      this.setState({
        boolExtraFija: true,
        boolExtraSola: false,
      });
    } else if (!this.state.boolExtraSola) {
      this.setState({
        boolExtraFija: false,
        boolExtraSola: true,
      });
    }
  };

  handleChangeNumAbonos = (e) => {
    this.setState({
      numAbonos: e.target.value,
    });
    this.divsAbonos(e.target.value);
  };

  divsAbonos = (num) => {
    let arrNums = [];
    let key = 'valbonos';
    let idStr;
    for (let i = 0; i < num; i++) {
      arrNums.push(i);
      idStr = key + i;
      this.setState({
        [idStr]: 0,
      });
    }
    this.setState({
      arrNumAbonos: arrNums,
    });
    if (this.state.boolExtrasAuto) {
      this.generarExtrasAutomatico(parseInt(num));
    }
  };

  generarExtrasAutomatico = (numAbonos) => {
    let saldo = parseInt(this.state.precioFinanciar);
    let periodos = parseInt(this.state.numCuotas);
    let interes = parseFloat(this.state.tasaInteres) / 100;
    let cuotaFija = parseInt(this.state.valorCuotaPersonal);
    let key = 'valbonos';

    let abInteres, abCapital;
    for (let i = 0; i < periodos; i++) {
      abInteres = saldo * interes;
      abCapital = cuotaFija - abInteres;
      saldo -= abCapital;
    }

    saldo -= cuotaFija;
    let valorExtraAutomatica = saldo / numAbonos;
    for (let i = 0; i < numAbonos; i++) {
      let idStr = key + i;
      this.setState({
        [idStr]: valorExtraAutomatica,
      });
    }
  };

  handleNewCotizacion = () => {};

  // Meses del 0 al 11.
  mese30Dias = [3, 5, 8, 10]; // Abril , Junio , Septiembre y Noviembre
  //mese31Dias = [0, 2, 4, 6, 7, 9, 11]; // Enero , Marzo , Mayo, Julio , Agosto , Octubre y Diciembre.

  validateDayOfMonth = (day, month, year) => {
    day = parseInt(day);
    month = parseInt(month);

    if (month === 1) {
      if (this.validateAnioBisiesto(year) && day > 29) {
        return '29';
      } else if (!this.validateAnioBisiesto(year) && day > 28) {
        return '28';
      } else {
        return day;
      }
    } else if (this.mese30Dias.includes(month) && day > 30) {
      return '30';
    } else {
      return day;
    }
  };

  validateAnioBisiesto = (year) => {
    return parseInt(year) % 4 === 0;
  };

  handleAmortizacion = () => {
    let TABLEUPLOAD = []; // Arreglo donde se guardaran los objetos con la informacion de la tabla en JSON para subirlo a la base de datos
    let table = ''; // letiable donde se hara la tabla para despues insertarla en la <table> con el id correspondiente
    const mesesDate = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];
    const mensualidadArray = ['Mensual', 'Bimensual', 'Trimestral'];

    let precioFinanciar = parseInt(this.state.precioFinanciar);
    let numCuotas = parseInt(this.state.numCuotas);
    let numAbonos = this.state.numAbonos;
    let periodicidad = this.state.periodicidad;
    let repeticionMeses = mensualidadArray.indexOf(periodicidad) + 1;
    let numCuotaInicial = parseInt(this.state.numCuotasInicial) + 1;
    let precioTotal = parseInt(this.state.precioTotal);
    const precioTotalFIJO = parseInt(this.state.precioTotal);
    let intereses = this.state.tasaInteres;
    intereses = parseFloat(intereses) / 100;

    let interesn, abcapital, cuota, extra, fechaCuotaInicial;
    let matrizCuotas = [];
    let matrizValor = [];

    let boolAutomaticDate = this.state.boolFechaAutomatica; // Boolean donde se checkea si la fecha de realizacion es hoy u otro dia anterior
    let automaticDate,
      automaticMonth,
      automaticYear,
      automaticDay,
      fechaRealizacionPlan;
    if (boolAutomaticDate) {
      automaticDate = new Date();
      automaticMonth = automaticDate.getMonth();
      automaticYear = automaticDate.getFullYear();
      automaticDay = automaticDate.getDate();
      fechaRealizacionPlan =
        automaticYear + '-' + mesesDate[automaticMonth] + '-' + automaticDay;
    } else if (!boolAutomaticDate) {
      fechaRealizacionPlan = this.state.fechaRealizacionPlan;
    }

    let fechaInicioPlan = this.state.fechaInicioPlan;
    let anhoPlan = parseInt(fechaInicioPlan.substring(0, 4));
    let mesPlan = parseInt(fechaInicioPlan.substring(5, 7));
    let diaPlan = fechaInicioPlan.substring(8, 10);

    let fechaInicioCuotaInicial = this.state.fechaInicioCuotaIni;
    let anoInicial = parseInt(fechaInicioCuotaInicial.substring(0, 4));
    let mesInicial = parseInt(fechaInicioCuotaInicial.substring(5, 7)) - 1;
    let diaInicial = fechaInicioCuotaInicial.substring(8, 10);

    let boolCuotaPersonal = this.state.boolCuotaPersonal; // Boolean que define si se establecio el valor de una cuota Fija
    if (!boolCuotaPersonal) {
      cuota =
        precioFinanciar / ((1 - (1 + intereses) ** -numCuotas) / intereses);
    } else if (boolCuotaPersonal) {
      cuota = this.state.valorCuotaPersonal;
    }

    let j = 0;
    let indexMeses = mesPlan - 1;
    let fecha = '';
    let id;
    let totalValue = 0;

    // Cabeceras de la tabla de amortizacion
    table += '<tr>';
    table += '<th>Cuota</th>';
    table += '<th>Tipo</th>';
    table += '<th>Fecha</th>';
    table += '<th>Cuota$$</th>';
    table += '<th>Abono Al Capital</th>';
    table += '<th>Abono Al Interes</th>';
    table += '<th>Saldo Total</th>';
    table += '</tr>';

    // primera fila para trabajar con ella en recalculo() y coger el saldo anterior si de edita la cuota #1
    // var valorCuotaInicial=parseInt(document.getElementById("cuotainicial").value)/numCuotaInicial

    /** TODO revisar este ciclo y mejorar nombre de variables y utilizar los estados de React */
    for (let k = 0; k < numAbonos; k++) {
      var idval = 'valbonos' + k;
      var idcuota = 'cuotaabono' + k;
      var valor = document.getElementById(idval).value;
      var numcuota =
        parseInt(document.getElementById(idcuota).value) +
        parseInt(numCuotaInicial) -
        1;
      matrizCuotas[k] = numcuota;
      matrizValor[k] = document.getElementById(idval).value;
      if (!boolCuotaPersonal) {
        var den = (1 - (1 + intereses) ** -numCuotas) / intereses;
        extra = valor / (1 + intereses) ** numcuota / den;
        cuota -= extra;
      }
    }

    // Ciclo para los estados valIniX: Cuota separacion y cuotas iniciales
    for (let x = 0; x < numCuotaInicial; x++) {
      let tipo;
      var idStr = 'valIni' + x;
      var valorCuotaInicial = document.getElementById(idStr).value;
      totalValue += parseInt(valorCuotaInicial);
      precioTotal -= valorCuotaInicial;
      if (x === 0) {
        fechaCuotaInicial = fechaRealizacionPlan;
      } else {
        fechaCuotaInicial =
          anoInicial +
          '-' +
          mesesDate[mesInicial] +
          '-' +
          this.validateDayOfMonth(diaInicial, mesInicial, anoInicial);
      }

      table += '<tr>';
      table += '<td>' + j + '</td>';
      if (x === 0) {
        table += '<td>CS</td>'; // Tipo de cuota: Cuota de separacion
        tipo = 'CS';
      } else {
        table += '<td>CI</td>'; // Tipo de cuota: Cuota inicial
        tipo = 'CI';
      }
      table += '<td>' + fechaCuotaInicial + '</td>'; // fechas de la cuota inicial
      table += '<td>' + parseInt(valorCuotaInicial) + '</td>';
      table += '<td>' + parseInt(valorCuotaInicial) + '</td>';
      table += '<td>' + 0 + '</td>';
      table += '<td>' + precioTotal + '</td>';
      table += '</tr>';

      if (x === 0) {
      } else {
        if (mesInicial < 12) {
          // fecha=meses[mesInicial]+ano;
          mesInicial++;
        }
        if (mesInicial >= 12) {
          mesInicial = 0;
          anoInicial += 1;
        }
      }

      TABLEUPLOAD.push({
        cuota: parseInt(j),
        fecha: fechaCuotaInicial,
        valorTeoricoCuota: parseInt(valorCuotaInicial),
        abonoCapitalTeorico: parseInt(valorCuotaInicial),
        abonoInteresTeorico: 0,
        saldoRestanteTeorico: precioTotal,
        pagoTotal: 0,
        abonoInteres: 0,
        abonoCapital: 0,
        saldoRestante: precioTotalFIJO,
        estado: 'Por Pagar',
        interesMora: 0,
        tipo: tipo,
      });

      j++;
    }

    // Ciclo para las cuotas Financiadas
    for (let i = 0; i < numCuotas; i++) {
      id = j;
      var written = false;
      // Este if se usa para pasar de mes y de anho si ya se llego hasta diciembre

      if (indexMeses < 12) {
        fecha =
          anhoPlan +
          '-' +
          mesesDate[indexMeses] +
          '-' +
          this.validateDayOfMonth(diaPlan, indexMeses, anhoPlan);
        indexMeses += repeticionMeses;
      }
      if (indexMeses >= 12) {
        // indexMeses = 0
        indexMeses = indexMeses - 12;
        anhoPlan += 1;
      }
      // Aqui se arman las tablas con los valores de #,fecha,cuotas,interes,capital y saldo
      var cuotaExtraOrd;
      for (let k = 0; k < numAbonos; k++) {
        if (parseInt(matrizCuotas[k]) === j) {
          var cuotaSolaCheck =
            document.getElementById('cuotaExtraSola').checked;
          var cuotaNormalCheck =
            document.getElementById('cuotaExtraNormal').checked;
          var interesChek = document.getElementById('cobrarIntereses').checked;

          if (cuotaSolaCheck) {
            cuotaExtraOrd = parseInt(matrizValor[k]);
          } else if (cuotaNormalCheck) {
            cuotaExtraOrd = parseInt(matrizValor[k]) + cuota;
          }

          if (interesChek) {
            interesn = precioFinanciar * intereses;
          } else {
            interesn = 0;
          }

          if (i + 1 === numCuotas && precioFinanciar !== 0) {
            cuotaExtraOrd = precioFinanciar + interesn;
          }

          abcapital = cuotaExtraOrd - interesn;
          precioFinanciar -= abcapital;

          cuotaExtraOrd = parseInt(cuotaExtraOrd);
          precioFinanciar = parseInt(precioFinanciar);
          interesn = parseInt(interesn);
          abcapital = parseInt(abcapital);

          table += '<tr id="' + id + '">';
          table += '<td>' + j + '</td>';
          table += '<td>C</td>';
          table += '<td>' + fecha + '</td>';
          table +=
            '<td onkeyup="recalcular(' +
            id +
            ')" contenteditable="true">' +
            cuotaExtraOrd +
            '</td>';
          table += '<td>' + abcapital + '</td>';
          table += '<td>' + interesn + '</td>';
          table += '<td>' + precioFinanciar + '</td>';
          table += '</tr>';

          totalValue += cuotaExtraOrd;

          TABLEUPLOAD.push({
            cuota: parseInt(j),
            fecha: fecha,
            valorTeoricoCuota: cuotaExtraOrd,
            abonoCapitalTeorico: abcapital,
            abonoInteresTeorico: interesn,
            saldoRestanteTeorico: precioFinanciar,
            pagoTotal: 0,
            abonoInteres: 0,
            abonoCapital: 0,
            saldoRestante: precioTotalFIJO,
            estado: 'Por Pagar',
            interesMora: 0,
            tipo: 'C',
          });
          written = true;
        }
      }

      if (!written) {
        interesn = precioFinanciar * intereses;

        if (i + 1 === numCuotas && precioFinanciar !== 0) {
          cuota = precioFinanciar + interesn;
        }
        abcapital = cuota - interesn;
        precioFinanciar -= abcapital;

        cuota = parseInt(cuota);
        precioFinanciar = parseInt(precioFinanciar);
        interesn = parseInt(interesn);
        abcapital = parseInt(abcapital);

        table += '<tr id="' + id + '">';
        table += '<td>' + parseInt(j) + '</td>';
        table += '<td>C</td>';
        table += '<td>' + fecha + '</td>';
        table +=
          '<td onkeyup="recalcular(' +
          id +
          ')" contenteditable="true">' +
          cuota +
          '</td>';
        table += '<td>' + abcapital + '</td>';
        table += '<td>' + interesn + '</td>';
        table += '<td>' + precioFinanciar + '</td>';
        table += '</tr>';

        totalValue += cuota;

        TABLEUPLOAD.push({
          cuota: parseInt(j),
          fecha: fecha,
          valorTeoricoCuota: cuota,
          abonoCapitalTeorico: abcapital,
          abonoInteresTeorico: interesn,
          saldoRestanteTeorico: precioFinanciar,
          pagoTotal: 0,
          abonoInteres: 0,
          abonoCapital: 0,
          saldoRestante: precioTotalFIJO,
          estado: 'Por Pagar',
          interesMora: 0,
          tipo: 'C',
        });
      }
      j++;
    }

    this.setState({
      fechaRealizacionPlan: fechaRealizacionPlan,
      tableUpload: TABLEUPLOAD,
      valorFinal: totalValue,
    });

    // se copia la tabla en el documento html
    document.getElementById('table').innerHTML =
      '<table id="amort" class="table table-bordered  table-striped">' +
      table +
      '</table>';
    document.getElementById('tbodyTable').innerHTML += table;
    document.getElementById('nombre').value = this.state.nombre;
    document.getElementById('cedula').value = this.state.cedula;
    document.getElementById('celular').value = this.state.celular;
    document.getElementById('email').value = this.state.email;
    document.getElementById('direccion').value = this.state.direccion;
    // document.getElementById('vendedor').value = this.state.vendedor
    document.getElementById('observaciones').value = this.state.observaciones;
    let descripcionLote =
      this.state.inmueble + ' # ' + this.state.numInmueble.numeroLote;
    this.setState({
      descripcionLote: descripcionLote,
    });
    document.getElementById('numLote').value = descripcionLote;
    // <div className='cotiCommon invisible' id='descarga'>
    document.getElementById('descarga').className = 'cotiCommon';
  };

  handleUpload = () => {
    if (this.checkFillAll()) {
      document.getElementById('loadingButton').className =
        'btn btn-primary buttonUpload';
      document.getElementById('upload').className =
        'btn btn-primary buttonUpload invisible';

      console.log(this.state.numInmueble);
      let { _id } = this.state.numInmueble;
      if (_id) {
        client
          .query({
            query: VALIDATE_AVAILABLE_LOTE,
            variables: { id: _id, tipoInm: this.state.inmueble },
          })
          .then((loteFound) => {
            if (loteFound.data.ValidateAvailableLote) {
              this.checkSobreescribir();
              // this.uploadTabla()
            } else {
              this.setState({
                show: true,
                titleModal: <div className="center wrong" />,
                messageModal: 'El inmueble seleccionado no esta disponible',
              });
              document.getElementById('loadingButton').className =
                'btn btn-primary buttonUpload invisible';
              document.getElementById('upload').className =
                'btn btn-primary buttonUpload';
              //this.getAvailableLots(configuracion);
            }
          })
          .catch((err) => {
            console.log('Lote error ', err);
            this.setState({
              show: true,
              titleModal: <div className="center wrong" />,
              messageModal: 'Ha ocurrido un error, por favor intente mas tarde',
            });
            document.getElementById('loadingButton').className =
              'btn btn-primary buttonUpload invisible';
            document.getElementById('upload').className =
              'btn btn-primary buttonUpload';
          });
      } else {
        this.checkSobreescribir();
        // this.uploadTabla()
      }
    } else {
      this.setState({
        show: true,
        titleModal: <div className="center wrong" />,
        messageModal:
          'Debes Llenar todos los campos iniciales antes de subir el Plan',
      });
    }
  };

  checkFillAll() {
    var {
      cedula,
      nombre,
      email,
      celular,
      direccion,
      vendedor,
      numInmueble,
      inmueble,
      precioTotal,
      tasaInteres,
      precioFinanciar,
      porcentajeFinanciar,
      periodicidad,
      numCuotas,
      fechaInicioPlan,
      fechaInicioCuotaIni,
      numCuotasInicial,
      cuotaInicial,
    } = this.state;
    var arreglo = [
      cedula,
      nombre,
      email,
      celular,
      direccion,
      vendedor,
      numInmueble,
      inmueble,
      precioTotal,
      tasaInteres,
      precioFinanciar,
      porcentajeFinanciar,
      periodicidad,
      numCuotas,
      fechaInicioPlan,
      fechaInicioCuotaIni,
      numCuotasInicial,
      cuotaInicial,
    ];

    for (var i = 0; i < arreglo.length; i++) {
      if (arreglo[i] === '') {
        return false;
      }
    }
    return true;
  }

  checkSobreescribir = () => {
    let ifOver = false;
    let cedula = this.state.cedula + '';
    var descripcionLote =
      this.state.inmueble + ' # ' + this.state.numInmueble.numeroLote;
    console.log('descripcionLote: ' + descripcionLote);
    let dataUser = {
      cedula,
    };
    client
      .query({ query: CHECKUSER, variables: { datos: dataUser } })
      .then((userFound) => {
        for (
          let i = 0;
          i < userFound.data.SearchUserByParam.planes.length;
          i++
        ) {
          if (
            userFound.data.SearchUserByParam.planes[i].descripcion ===
            descripcionLote
          ) {
            if (
              userFound.data.SearchUserByParam.planes[i].estado === 'Pendiente'
            ) {
              this.setState({
                showOverWrite: true,
                idPlanOver: userFound.data.SearchUserByParam.planes[i].idPlan,
              });
              ifOver = true;
            }
          }
        }
        if (!ifOver) this.uploadTabla();
      })
      .catch((err) => {
        console.log(err);
        if (!ifOver) this.uploadTabla();
      });
  };

  handleUpdate = () => {
    this.handleCloseOverWrite();
    client
      .query({
        query: SEARCHPLANCOTIZACION,
        variables: { id: this.state.idPlanOver },
      })
      .then((plan) => {
        this.setState({
          idTableOver: plan.data.SearchPlanById.tabla,
        });
        this.updateTable(plan.data.SearchPlanById.tabla);
      });
  };

  updateTable = (idTablaOver) => {
    var tablaObject = {
      content: this.state.tableUpload,
    };
    client
      .mutate({
        mutation: UPDATETABLE,
        variables: { id: idTablaOver, data: tablaObject },
      })
      .then((result) => {
        this.updatePlan(idTablaOver);
      })
      .catch((err) => {
        this.setState({
          show: true,
          titleModal: <div className="center wrong" />,
          messageModal: 'Error al sobreescribir la tabla en la base de datos',
        });
        console.log(err);
        document.getElementById('loadingButton').className =
          'btn btn-primary buttonUpload invisible';
        document.getElementById('upload').className =
          'btn btn-primary buttonUpload';
      });
  };

  updatePlan = (idTablaOver) => {
    var arrCuotasExtra = [];
    var arrCuotasInicial = [];
    for (let i = 0; i < this.state.numAbonos; i++) {
      let key = 'valbonos' + i;
      let keyNum = 'cuotaabono' + i;
      arrCuotasExtra.push({
        cuota: parseInt(this.state[keyNum]),
        valor: parseFloat(this.state[key]),
      });
    }
    for (let i = 1; i <= this.state.numCuotasInicial; i++) {
      let key = 'valIni' + i;
      arrCuotasInicial.push({
        cuota: i,
        valor: parseFloat(this.state[key]),
      });
    }

    var list = {
      tabla: idTablaOver,
      numeroCuotas: parseInt(this.state.numCuotas),
      periodicidad: this.state.periodicidad,
      porcentajeAFinanciar: parseFloat(this.state.porcentajeFinanciar),
      tasaInteres: parseFloat(this.state.tasaInteres),
      valorTotal: parseFloat(this.state.precioTotal),
      valorFinal: parseFloat(this.state.valorFinal),
      valorAFinanciar: parseFloat(this.state.precioFinanciar),
      saldoRestante: parseFloat(this.state.precioTotal),
      // 'saldoRestante': parseFloat(this.state.valorFinal),
      cuotaSeparacion: parseFloat(this.state['valIni0']),
      observaciones: this.state.observaciones,
      vendedor: this.state.vendedor,
      fechaInicialPlan: this.state.fechaInicioPlan,
      fechaInicioCuotaIni: this.state.fechaInicioCuotaIni,
      fechaRealizacionPlan: this.state.fechaRealizacionPlan,
      cuotaPersonal: this.state.boolCuotaPersonal,
      valorCuotaPersonal: parseFloat(this.state.valorCuotaPersonal),
      numAbonos: parseInt(this.state.numAbonos),
      cuotasExtra: arrCuotasExtra,
      cuotaExtraSola: this.state.boolExtraSola,
      cuotaExtraFija: this.state.boolExtraFija,
      cobrarInteres: this.state.boolIntereses,
      valorCuotaInicial: parseFloat(this.state.cuotaInicial),
      cuotasInicial: arrCuotasInicial,
      pagoTotal: 0,
    };
    client
      .mutate({
        mutation: UPDATEPLAN,
        variables: { id: this.state.idPlanOver, data: list },
      })
      .then((result) => {
        this.setState({
          show: true,
          titleModal: <div className="center success" />,
          messageModal: 'Plan Sobreescrito exitosamente',
        });
        //this.getAvailableLots(configuracion);
        document.getElementById('loadingButton').className =
          'btn btn-primary buttonUpload invisible';
        document.getElementById('upload').className =
          'btn btn-primary buttonUpload';
      })
      .catch((err) => {
        this.setState({
          show: true,
          titleModal: <div className="center wrong" />,
          messageModal: 'Error al sobreescribir el Plan En la base de Datos',
        });
        console.log(err);
        document.getElementById('loadingButton').className =
          'btn btn-primary buttonUpload invisible';
        document.getElementById('upload').className =
          'btn btn-primary buttonUpload';
      });
  };

  uploadTabla = () => {
    this.handleCloseOverWrite();
    var tablaObject = {
      content: this.state.tableUpload,
    };
    client
      .mutate({ mutation: UPLOADTABLE, variables: { datos: tablaObject } })
      .then((result) => {
        this.uploadPlan(result.data.CreateTable._id);
      })
      .catch((err) => {
        this.setState({
          show: true,
          titleModal: <div className="center wrong" />,
          messageModal: 'Error al crear la tabla en la base de datos',
        });
        console.log(err);
        document.getElementById('loadingButton').className =
          'btn btn-primary buttonUpload invisible';
        document.getElementById('upload').className =
          'btn btn-primary buttonUpload';
      });
  };

  uploadPlan = (idTable) => {
    var arrCuotasExtra = [];
    var arrCuotasInicial = [];
    for (let i = 0; i < this.state.numAbonos; i++) {
      let key = 'valbonos' + i;
      let keyNum = 'cuotaabono' + i;
      arrCuotasExtra.push({
        cuota: parseInt(this.state[keyNum]),
        valor: parseFloat(this.state[key]),
      });
    }
    for (let i = 1; i <= this.state.numCuotasInicial; i++) {
      let key = 'valIni' + i;
      arrCuotasInicial.push({
        cuota: i,
        valor: parseFloat(this.state[key]),
      });
    }

    var list = {
      tabla: idTable,
      numeroCuotas: parseInt(this.state.numCuotas),
      periodicidad: this.state.periodicidad,
      porcentajeAFinanciar: parseFloat(this.state.porcentajeFinanciar),
      tasaInteres: parseFloat(this.state.tasaInteres),
      valorTotal: parseFloat(this.state.precioTotal),
      valorFinal: parseFloat(this.state.valorFinal),
      valorAFinanciar: parseFloat(this.state.precioFinanciar),
      saldoRestante: parseFloat(this.state.precioTotal),
      // 'saldoRestante': parseFloat(this.state.valorFinal),
      cuotaSeparacion: parseFloat(this.state['valIni0']),
      observaciones: this.state.observaciones,
      vendedor: this.state.vendedor,
      fechaInicialPlan: this.state.fechaInicioPlan,
      fechaInicioCuotaIni: this.state.fechaInicioCuotaIni,
      fechaRealizacionPlan: this.state.fechaRealizacionPlan,
      cuotaPersonal: this.state.boolCuotaPersonal,
      valorCuotaPersonal: parseFloat(this.state.valorCuotaPersonal),
      numAbonos: parseInt(this.state.numAbonos),
      cuotasExtra: arrCuotasExtra,
      cuotaExtraSola: this.state.boolExtraSola,
      cuotaExtraFija: this.state.boolExtraFija,
      cobrarInteres: this.state.boolIntereses,
      valorCuotaInicial: parseFloat(this.state.cuotaInicial),
      cuotasInicial: arrCuotasInicial,
      pagoTotal: 0,
    };
    client
      .mutate({ mutation: UPLOADPLAN, variables: { datos: list } })
      .then((result) => {
        this.uploadUser(result.data.CreatePlan._id);
      })
      .catch((err) => {
        this.setState({
          show: true,
          titleModal: <div className="center wrong" />,
          messageModal: 'Error al crear el Plan En la base de Datos',
        });
        console.log(err);
        document.getElementById('loadingButton').className =
          'btn btn-primary buttonUpload invisible';
        document.getElementById('upload').className =
          'btn btn-primary buttonUpload';
      });
  };

  uploadUser = (idPlan) => {
    let configuracion = sessionStorage.getItem('configuracion'); // usar render props? redux? Deshacerse de sessionStorage
    configuracion = JSON.parse(configuracion);
    let user = sessionStorage.getItem('user');
    user = JSON.parse(user);

    let cedula = this.state.cedula + '';
    console.log('numInmueble ', this.state.numInmueble);
    const { numeroLote, _id } = this.state.numInmueble;
    console.log('id', _id);
    var descripcionLote = this.state.inmueble + ' # ' + numeroLote;
    let dataUser = {
      cedula,
    };

    let dataPlan = {
      idPlan: idPlan,
      estado: 'Pendiente',
      descripcion: descripcionLote,
      proyecto: this.state.proyecto,
      lote: {
        id: _id,
        idProyecto: configuracion._id,
        idPlan,
        numeroLote,
        estado: 'Pendiente',
        idCliente: cedula,
        idVendedor: user.correo,
      },
    };

    let newUserData = {
      nombre: this.state.nombre,
      cedula: cedula,
      direccion: this.state.direccion,
      celular: this.state.celular,
      correo: this.state.email,
      planes: dataPlan,
      rol: {
        idRol: 3,
        nombre: 'Cliente',
      },
    };

    this.setState({
      descripcionLote: descripcionLote,
    });

    client
      .query({ query: CHECKUSER, variables: { datos: dataUser } })
      .then((result) => {
        if (result.data.SearchUserByParam != null) {
          client
            .mutate({
              mutation: ADDUSERPLAN,
              variables: { cedula: cedula, datos: dataPlan },
            })
            .then((result) => {
              this.setState({
                show: true,
                titleModal: <div className="center success" />,
                messageModal: 'Plan Añadido exitosamente',
              });
              //this.getAvailableLots(configuracion);
              document.getElementById('loadingButton').className =
                'btn btn-primary buttonUpload invisible';
              document.getElementById('upload').className =
                'btn btn-primary buttonUpload';
            })
            .catch((err) => {
              this.setState({
                show: true,
                titleModal: <div className="center wrong" />,
                messageModal: 'Ocurrio un error al intentar añadir el plan',
              });
              console.log(err);
              document.getElementById('loadingButton').className =
                'btn btn-primary buttonUpload invisible';
              document.getElementById('upload').className =
                'btn btn-primary buttonUpload';
            });
        } else if (result.data.SearchUserByParam === null) {
          client
            .mutate({ mutation: UPLOADUSER, variables: { datos: newUserData } })
            .then((result) => {
              this.setState({
                show: true,
                titleModal: <div className="center success" />,
                messageModal: 'Plan cargado Exitosamente',
              });
              //this.getAvailableLots(configuracion);
              document.getElementById('loadingButton').className =
                'btn btn-primary buttonUpload invisible';
              document.getElementById('upload').className =
                'btn btn-primary buttonUpload';
            })
            .catch((err) => {
              this.setState({
                show: true,
                titleModal: <div className="center wrong" />,
                messageModal: 'Ocurrio un error al intentar crear el plan',
              });
              console.log(err);
              document.getElementById('loadingButton').className =
                'btn btn-primary buttonUpload invisible';
              document.getElementById('upload').className =
                'btn btn-primary buttonUpload';
            });
        }
      });
  };

  handleClose = () => {
    this.setState({
      show: false,
      loading: false,
    });
  };

  handleCloseOverWrite = () => {
    this.setState({
      showOverWrite: false,
    });
  };

  handleShowTasaInteres = () => {
    if (this.state.permisos.tasaInteres || this.state.tipoVendedor === 1) {
      return (
        <input
          type="number"
          id="interes"
          className="form-control hundredWidth"
          width="35px"
          value={this.state.tasaInteres}
          onChange={this.handleChangeTasa}
        />
      );
    } else {
      return (
        <input
          type="number"
          id="interes"
          className="form-control hundredWidth"
          width="35px"
          value={this.state.tasaInteres}
          onChange={this.handleChangeTasa}
          disabled
        />
      );
    }
  };

  // handleShowCobroInteresExtra = () => {
  //   if (this.state.permisos.cobroInteresExtra || this.state.tipoVendedor === 'Admin') {
  //     return (
  //       <input type='number' id='interes' className='form-control hundredWidth' width='35px' value={this.state.tasaInteres} onChange={this.handleChangeTasa} />
  //     )
  //   } else {
  //     return (
  //       <input type='number' id='interes' className='form-control hundredWidth' width='35px' value={this.state.tasaInteres} onChange={this.handleChangeTasa} disabled />
  //     )
  //   }
  // }

  render() {
    let tasaInteres = this.handleShowTasaInteres();
    // let cobroInteresExtra = this.handleShowCobroInteresExtra()
    // Las Variables [separacion cuotasRender i y Join] hacen parte de el valor de las cuotas iniciales dividas
    let separacion = (
      <div>
        <div className="col-xs-2 cotiCommon">
          <label htmlFor="exampleFormControlInput1">Separacion</label>
        </div>
        <div className="col-xs-2 cotiCommon">
          <input
            type="number"
            id={`valIni0`}
            className="form-control hundredTwentyWidth"
            placeholder="Cuota separacion"
            value={this.state['valIni0']}
            onChange={this.handleChangeValuesIni}
            onBlur={this.calculoCuotasIni}
          />
        </div>
      </div>
    );
    let i = 0;
    let cuotasRender = this.state.arrNumCuotasIni.map((vals) => {
      i++;
      let key = 'valIni' + i;
      return (
        <div>
          <div className="col-xs-2 cotiCommon">
            <label htmlFor="exampleFormControlInput1">{`Cuota ${i}`}</label>
          </div>
          <div className="col-xs-2 cotiCommon">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id={`date-cuotaini${i}`}>
                  {i}
                </span>
              </div>
              {i === parseInt(this.state.numCuotasInicial) ? (
                <input
                  disabled
                  type="number"
                  id={`valIni${i}`}
                  className="form-control"
                  value={this.state[key]}
                  onChange={this.handleChangeValuesIni}
                  onBlur={this.calculoCuotasIni}
                />
              ) : (
                <input
                  type="number"
                  id={`valIni${i}`}
                  className="form-control"
                  value={this.state[key]}
                  onChange={this.handleChangeValuesIni}
                  onBlur={this.calculoCuotasIni}
                />
              )}
            </div>
          </div>
        </div>
      );
    });
    let join = [separacion, cuotasRender];
    // Las Variables separacion cuotasRender y Join hacen parte de el valor de las cuotas iniciales dividas
    // La varianle numAbonosObject hace referencia a los objectos que se renderizaran de los abonos extras programados
    let numAbonosObject = this.state.arrNumAbonos.map((bonos) => {
      let keyValAbonos = `valbonos${bonos}`;
      let keyNumAbonos = `cuotaabono${bonos}`;
      return (
        <div className="col-md cotiCommon abonosExtraWith">
          <div className="input-group mb-3">
            <input
              placeholder="Cuota #"
              type="text"
              id={`cuotaabono${bonos}`}
              className="form-control widthInputAbono"
              value={this.state[keyNumAbonos]}
              onChange={this.handleChangeValuesIni}
            />
            <div className="input-group-append input-group-prepend">
              <span className="input-group-text" id={bonos}>
                {bonos + 1}
              </span>
            </div>
            <input
              placeholder="Valor Abono"
              type="text"
              id={`valbonos${bonos}`}
              className="form-control widthInputAbono"
              value={this.state[keyValAbonos]}
              onChange={this.handleChangeValuesIni}
            />
          </div>
        </div>
      );
    });

    let optionTypeInmueble = this.state.listaInmueble.map((inmueble) => {
      return (
        <MenuItem key={inmueble.tipo} value={inmueble.tipo}>
          {inmueble.tipo}
        </MenuItem>
      );
    });

    let optionNumInmueble = this.state.listaNumInmueble.map((nums) => {
      return (
        <option value={JSON.stringify(nums)} key={nums.numeroLote}>
          {nums.numeroLote}
        </option>
      );
    });

    let bodyTableExport =
      this.state.tableUpload !== []
        ? this.state.tableUpload.map((row) => {
            return (
              <tr>
                <td>{row.cuota}</td>
                <td>{row.tipo}</td>
                <td>{row.fecha}</td>
                <td>{row.valorTeoricoCuota}</td>
                <td>{row.abonoCapitalTeorico}</td>
                <td>{row.abonoInteresTeorico}</td>
                <td>{row.saldoRestanteTeorico}</td>
              </tr>
            );
          })
        : null;

    return (
      <div className=" ModifyBack">
        <div id="content-wrapper" className="Cotizacion">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="dashboard">Dashboard</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Cotizacion
              </li>
            </ol>
          </div>
          <div>
            <div id="personal" className="persoTable">
              <h3>Datos Personales</h3>
              <table
                className="table table-bordered main--table table--cotizacion"
                id="infoPersonal"
              >
                <thead>
                  <tr>
                    <th style={{ width: 10 + '%' }}>
                      {`Cedula `}
                      <div
                        id="loadingCedula"
                        className="spinner-border spinner-border-sm text-info invisible"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </th>
                    <td id="cedula" className="invisibleTD">
                      <input
                        className="invisibleInput"
                        value={this.state.cedula}
                        onChange={this.handleChangeCedula}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Nombre</th>
                    <td id="nombre" className="invisibleTD">
                      <input
                        className="invisibleInput"
                        value={this.state.nombre}
                        onChange={this.handleChangeNombre}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td id="email" className="invisibleTD">
                      <input
                        type="email"
                        className="invisibleInput"
                        value={this.state.email}
                        onChange={this.handleChangeEmail}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Celular</th>
                    <td id="celular" className="invisibleTD">
                      <input
                        className="invisibleInput"
                        value={this.state.celular}
                        onChange={this.handleChangeCelular}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Direccion</th>
                    <td id="direccion" className="invisibleTD">
                      <input
                        className="invisibleInput"
                        value={this.state.direccion}
                        onChange={this.handleChangeDireccion}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Vendedor</th>
                    <td id="vendedor" className="invisibleTD">
                      <input
                        className="invisibleInput"
                        value={this.state.vendedor}
                        onChange={this.handleChangeVendedor}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Observaciones</th>
                    <td id="observaciones" className="invisibleTD">
                      <input
                        className="invisibleInput"
                        value={this.state.Observaciones}
                        onChange={this.handleChangeObservaciones}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th># Inmbueble</th>
                    <td id="numLote">
                      <div className="form-group row cotiCommon">
                        <div className="tipoInput">
                          <div className="input-group">
                            <InputLabel id="select-label">Tipo </InputLabel>
                            <Select
                              labelId="select-label"
                              id="tipoLote"
                              className="form-control hundredWidth"
                              value={this.state.inmueble}
                              label={'Tipo inmueble'}
                              onChange={this.handleChangeTipoInmueble}
                            >
                              {optionTypeInmueble}
                            </Select>
                            <div className="input-group-append input-group-prepend">
                              <span className="input-group-text" id="id">
                                #
                              </span>
                            </div>
                            <select
                              id="tipoLote"
                              className="form-control fiftyWidth"
                              value={JSON.stringify(this.state.numInmueble)}
                              onChange={this.handleChangeNumInmueble}
                            >
                              {optionNumInmueble}
                            </select>
                            {this.state.inmueble === '' && (
                              <h6 className="lotes">Seleccione un tipo</h6>
                            )}
                            {this.state.inmueble !== '' &&
                              this.state.listaNumInmueble.length === 0 && (
                                <h6 className="lotes">
                                  No hay inmuebles disponibles
                                </h6>
                              )}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Proyecto</th>
                    <td className="tdProyect">{this.state.proyecto}</td>
                  </tr>
                </thead>
                <tbody className="invisibleTable" id="tbodyTable" />
              </table>
            </div>
          </div>
          {/* <!--Recoleccion de datos para realizar la tabla de amortizacion--> */}
          <h3>Datos financiacion</h3>
          <div className="cotiCommon" id="fechaReal">
            <div className="form-check cotiCommon">
              <input
                type="checkbox"
                checked={this.state.boolFechaAutomatica}
                id="fechaAutomatica"
                className="form-check-input"
                onClick={this.handleChangeFechaAuto}
                readOnly
              />
              <label htmlFor="exampleFormControlInput1">
                Fecha Realizacion plan Automatica
              </label>
              <i className="fas fa-info-circle tooltip right">
                <span className="tiptext">
                  Esta fecha indica el dia en el que el plan de cotizacion fue
                  realizado
                </span>
              </i>
            </div>
            <div className="form-group row cotiCommon invisibleTable" id="rea">
              <div className="col-xs-2 cotiCommon">
                <label htmlFor="exampleFormControlInput1">
                  {' '}
                  Fecha realizacion plan:{' '}
                </label>
                <input
                  type="date"
                  id="fecharealizacion"
                  className="form-control"
                  value={this.state.fechaRealizacionPlan}
                  onChange={this.handleChangeFechaRealizacion}
                />
              </div>
            </div>
          </div>
          <div className="form-group row cotiCommon">
            <div className="col-xs-2 cotiCommon">
              <label htmlFor="exampleFormControlInput1">
                {' '}
                Tasa interes %:{' '}
              </label>
              {tasaInteres}
              {/* {
                this.state.tipoVendedor === 'Admin' ? <input type='number' id='interes' className='form-control hundredWidth' width='35px' value={this.state.tasaInteres} onChange={this.handleChangeTasa} />
                  : <input type='number' id='interes' className='form-control hundredWidth' width='35px' value={this.state.tasaInteres} onChange={this.handleChangeTasa} disabled />
              } */}
              {/* <input type='number' id='interes' className='form-control hundredWidth' width='35px' value={this.state.tasaInteres} onChange={this.handleChangeTasa} /> */}
            </div>
            <div className="col-xs-2 cotiCommon">
              <label htmlFor="exampleFormControlInput1">
                {' '}
                Precio total inmueble:{' '}
              </label>
              <input
                type="number"
                id="precioFull"
                className="form-control"
                width="35px"
                value={this.state.precioTotal}
                onChange={this.handleChangePrecioTotal}
              />
            </div>
            <div className="col-xs-2 cotiCommon">
              <label htmlFor="exampleFormControlInput1">
                {' '}
                Precio a financiar:{' '}
              </label>
              <input
                type="number"
                id="financiar"
                className="form-control"
                width="35px"
                value={this.state.precioFinanciar}
                onChange={this.handleChangePrecioFinanciar}
              />
            </div>
            <div className=" col-xs-2 cotiCommon">
              <label htmlFor="exampleFormControlInput1"> % a financiar: </label>
              <input
                type="number"
                id="porfinanciar"
                className="form-control ninetyWidth"
                width="35px"
                value={this.state.porcentajeFinanciar}
                onChange={this.handleChangePorcentajeFinanciar}
              />
            </div>
            <div className="col-xs-2 cotiCommon">
              <label htmlFor="exampleFormControlInput1"> Cuota inicial: </label>
              <input
                type="number"
                id="cuotainicial"
                className="form-control"
                value={this.state.cuotaInicial}
                onChange={this.handleChangeCuotaInicial}
              />
            </div>
          </div>
          <div className="form-group row cotiCommon" id="ini">
            <div className="col-xs-2 cotiCommon">
              <div className="col-xs-2 ">
                <label htmlFor="exampleFormControlInput1">
                  Fecha Cuota inicial
                </label>
                <i className="fas fa-info-circle tooltip right">
                  <span className="tiptext">
                    Esta fecha indica el día en el que la cuota inicial será
                    empezada a pagar
                  </span>
                </i>
                <input
                  type="date"
                  id="fechaCuotaInicial"
                  className="form-control oneseventreeW"
                  value={this.state.fechaInicioCuotaIni}
                  onChange={this.handleChangeFechaCuotaIni}
                />
              </div>
            </div>
            <div className="thirtyPadding">
              <label htmlFor="exampleFormControlInput1">
                {' '}
                Fecha Plan financiacion{' '}
              </label>
              <i className="fas fa-info-circle tooltip right">
                <span className="tiptext">
                  Esta fecha indica el día en el que el valor financiado será
                  empezado a pagar
                </span>
              </i>
              <input
                type="date"
                id="fechainicial"
                className="form-control oneseventreeW"
                value={this.state.fechaInicioPlan}
                onChange={this.handleChangeFechaInicioPlan}
              />
            </div>
            <div className="col-xs-2 cotiCommon" id="divCuotasIni">
              <label htmlFor="exampleFormControlSelect1">Cuotas Inicial</label>
              <select
                className="form-control"
                id="numCuotasInicial"
                value={this.state.numCuotasInicial}
                onChange={this.handleChangeNumCuotasInicial}
              >
                {this.state.TableNumCuotas}
              </select>
            </div>
            <div className="col-xs-2 cotiCommon" id="fechasCuotasIni">
              <div className="cotiCommon">
                <div
                  id="innerCuotasInicial"
                  className="form-group row cotiCommon"
                >
                  {this.state.arrNumCuotasIni !== [] ? join : null}
                  {this.state.innerCuotasInicial}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="form-group row cotiCommon">
              <div className="col-xs-2 cotiCommon">
                <label htmlFor="exampleFormControlInput1"> Cuotas: </label>
                <input
                  type="number"
                  id="cuotas"
                  className="form-control hundredWidth"
                  width="35px"
                  value={this.state.numCuotas}
                  onChange={this.handleChangeNumCuotas}
                />
              </div>
              <div className="col-xs-2 cotiCommon">
                <label htmlFor="exampleFormControlSelect1">Periodicidad</label>
                {/* Estate periodicidad */}
                <select
                  className="form-control"
                  id="periodicidad"
                  value={this.state.periodicidad}
                  onChange={this.handleChangePeriodicidad}
                >
                  <option>Mensual</option>
                  <option>Bimensual</option>
                  <option>Trimestral</option>
                </select>
              </div>
            </div>
          </div>
          {/* <!--Aqui se realiza las cuotas personalizadas, esto para establecer cuotas fijas y abonos extras 100% al capital--> */}
          <div className="cotiCommon" id="personalizada">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="checkCuota"
                value={this.state.boolCuotaPersonal}
                onClick={this.handleChangeBoolCuotaFija}
              />
              <label className="form-check-label" htmlFor="defaultCheck1">
                Establecer cuota Fija
              </label>
              <i className="fas fa-info-circle tooltip right">
                <span className="tiptext">
                  Se establece el valor de las cuotas en el plan de
                  financiación, la ultima cuota será igual al saldo restante a
                  la fecha
                </span>
              </i>
            </div>
            <div
              className="form-group row cotiCommon invisibleTable"
              id="valorPersonalizada"
            >
              <div className="col-xs-2 cotiCommon">
                <label htmlFor="exampleFormControlInput1">
                  {' '}
                  Valor Cuota Fija:{' '}
                </label>
                <input
                  type="number"
                  id="valcuotapers"
                  className="form-control"
                  width="35px"
                  value={this.state.valorCuotaPersonal}
                  onChange={this.handleChangeCuotaPersonal}
                />
              </div>
            </div>
          </div>

          {/* <!--Se chequea si el plan va a tener abonos extras--> */}
          <div className="cotiCommon">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="check1"
                checked={this.state.boolAbonoExtra}
                onClick={this.handleChangeBoolAbonoExtra}
                readOnly
              />
              <label className="form-check-label" htmlFor="defaultCheck1">
                Abonos extra programados
              </label>
              <i className="fas fa-info-circle tooltip right">
                <span className="tiptext">
                  Se establece el numero y valor de los abonos extra
                </span>
              </i>
            </div>
          </div>
          {/* <!--Aqui se establecen las cuotas extras que el cliente desee realizar--> */}
          <div id="extras" className="invisibleTable">
            <div>
              <div className="twentyPadding">
                <div className="form-check" id="generarAutoDiv">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="generarAutomatico"
                    checked={this.state.boolExtrasAuto}
                    onClick={this.handleChangeBoolExtrasAuto}
                    readOnly
                  />
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Generar Extras Automaticamente
                  </label>
                  <i className="fas fa-info-circle tooltip right">
                    <span className="tiptext">
                      Al establecer la cuota fija, el sistema puede calcular un
                      valor aproximado de las cuotas extra
                    </span>
                  </i>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="cobrarIntereses"
                    checked={this.state.boolIntereses}
                    onClick={this.handleChangeBoolIntereses}
                    readOnly
                  />
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Cobrar Intereses
                  </label>
                  <i className="fas fa-info-circle tooltip right">
                    <span className="tiptext">
                      Se establece si se le cobrará intereses en las cuotas de
                      abono extra
                    </span>
                  </i>
                </div>
              </div>
              <div className="twentyPadding">
                <div className="form-check twentyPadding">
                  <div>
                    <input
                      className="form-check-input fifthPadding"
                      type="checkbox"
                      id="cuotaExtraNormal"
                      checked={this.state.boolExtraFija}
                      onClick={this.handleChangeBoolExtraFija}
                      readOnly
                    />
                    <label
                      className="form-check-label fifthPadding"
                      htmlFor="defaultCheck1"
                    >
                      Cuota extra+Cuota Fija
                    </label>
                    <i className="fas fa-info-circle tooltip right">
                      <span className="tiptext">
                        Se establece que el valor de la cuota(en la que se
                        programe el abono) sea igual al valor del abono + el
                        valor de la cuota normal(fija)
                      </span>
                    </i>
                  </div>
                  <div>
                    <input
                      className="form-check-input fifthPadding"
                      type="checkbox"
                      id="cuotaExtraSola"
                      checked={this.state.boolExtraSola}
                      onClick={this.handleChangeBoolExtraSola}
                      readOnly
                    />
                    <label
                      className="form-check-label fifthPadding"
                      htmlFor="defaultCheck1"
                    >
                      Cuota Extra sola
                    </label>
                    <i className="fas fa-info-circle tooltip right">
                      <span className="tiptext">
                        Se establece que el valor de la cuota(en la que se
                        programe el abono) sea igual al valor del abono
                      </span>
                    </i>
                  </div>
                </div>
              </div>
              <div className="form-group row cotiCommon">
                <div className="col-xs-2 cotiCommon">
                  <label htmlFor="exampleFormControlInput1">
                    {' '}
                    Numero abonos extra:{' '}
                  </label>
                  <i className="fas fa-info-circle tooltip right">
                    <span className="tiptext">
                      Se debe tener en cuenta que en la mayoria de los casos la
                      ultima cuota será de un valor diferente al valor de la
                      cuota normal y de los abonos
                    </span>
                  </i>
                  <input
                    type="number"
                    id="numbonos"
                    className="form-control hundredWidth"
                    width="35px"
                    value={this.state.numAbonos}
                    onChange={this.handleChangeNumAbonos}
                  />
                </div>
              </div>
            </div>
            <div id="dates">
              <div className="form-group cotiCommon">{numAbonosObject}</div>
            </div>
          </div>

          <div className="cotiCommon buttonCreate" id="confirm">
            <button
              id="btnConfirm"
              type="submit"
              className="btn btn-primary mb-2"
              onClick={this.handleAmortizacion}
            >
              Generar Plan
            </button>
          </div>

          <div id="divReload" className="reload invisibleTable">
            <button
              className="btn btn-primary mb-2 reloadButton"
              id="reloadPage"
              onClick={this.handleNewCotizacion}
            >
              Nueva cotizacion
            </button>
          </div>
          {/* <!--Esta tabla es el espacio para la tabla de amortizacion que se crea con los datos de financiacion--> */}
          <div id="table" className="paddingTable" />
          {/* <!--Parte de descarga a documento de excel y subida del plan a la base de datos para validacion--> */}

          <div className="cotiCommon invisible" id="descarga">
            <ReactToExcel
              className="btn btn-primary mb-2"
              table="estadoCuenta"
              filename={`${this.state.nombre}-${this.state.cedula}`}
              sheet="sheet 1"
              buttonText="Exportar Tabla"
            />

            <button
              id="loadingButton"
              className="btn btn-primary buttonUpload invisible"
              type="button"
              style={{ float: 'right' }}
              disabled
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              {` Subiendo...`}
            </button>
            <button
              className="btn btn-primary mb-2 buttonUpload"
              id="upload"
              style={{ float: 'right' }}
              onClick={this.handleUpload}
            >
              Subir Plan
            </button>
          </div>
          <div id="divReload" className="reload invisibleTable">
            <button
              className="btn btn-primary mb-2 reloadButton"
              id="reloadPage"
              onClick={this.handleNewCotizacion}
            >
              Nueva cotizacion
            </button>
          </div>
          {/* <!--Tabla para la creacion del documento de excel--> */}
          <div id="divExportar" className="invisible">
            <table id="estadoCuenta" className="table-bordered">
              <tbody>
                <tr>
                  <th
                    colSpan={7}
                    className="title"
                    style={{ textAlign: 'center' }}
                  >
                    LAR PROYECTOS SAS
                  </th>
                </tr>
                <tr>
                  <th
                    colSpan={7}
                    className="title"
                    style={{ textAlign: 'center' }}
                  >
                    COTIZACIÓN
                  </th>
                </tr>
                <tr>
                  <th
                    colSpan={7}
                    className="title"
                    style={{ textAlign: 'center' }}
                  >
                    {JSON.parse(sessionStorage.getItem('configuracion')).nombre}
                  </th>
                </tr>
                <tr>
                  <th
                    colSpan={7}
                    className="title"
                    style={{ textAlign: 'center' }}
                  >
                    {this.state.currentDate}
                  </th>
                </tr>
                <tr>
                  <td style={{ width: '0.8cm' }} />
                  <td style={{ width: '0.8cm' }} />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td style={{ width: '2cm' }} />
                </tr>
                <tr className="border_bottom">
                  <th colSpan={3} style={{ borderBottom: '1px solid black' }}>
                    Cliente
                  </th>
                  <th style={{ borderBottom: '1px solid black' }}>Cedula</th>
                  <th style={{ borderBottom: '1px solid black' }}>Celular</th>
                  <th style={{ borderBottom: '1px solid black' }}>Direccion</th>
                  <td style={{ borderBottom: '1px solid black' }} />
                </tr>
                <tr>
                  <td colSpan={3} style={{ borderBottom: '1px solid black' }}>
                    {this.state.nombre}
                  </td>
                  <td style={{ borderBottom: '1px solid black' }}>
                    {this.state.cedula}
                  </td>
                  <td style={{ borderBottom: '1px solid black' }}>
                    {this.state.celular}
                  </td>
                  <td colSpan={2} style={{ borderBottom: '1px solid black' }}>
                    {this.state.direccion}
                  </td>
                </tr>
                <tr>
                  <td colSpan={7} style={{ borderBottom: '1px solid black' }} />
                </tr>

                <tr>
                  <th colSpan={3} style={{ borderBottom: '1px solid black' }}>
                    Fecha De Negocio
                  </th>
                  <th colSpan={1} style={{ borderBottom: '1px solid black' }}>
                    Inmueble
                  </th>
                  <th colSpan={2} style={{ borderBottom: '1px solid black' }}>
                    Valor Venta
                  </th>
                  <th style={{ borderBottom: '1px solid black' }} />
                </tr>
                <tr>
                  <td colSpan={3}>{this.state.fechaRealizacionPlan}</td>
                  <td colSpan={1}>{this.state.descripcionLote}</td>
                  <td colSpan={2}>{this.state.precioTotal}</td>
                </tr>

                <tr>
                  <td colSpan={3} />
                  <th colSpan={1}>Con intereses</th>
                  <td colSpan={2}>{this.state.valorFinal}</td>
                </tr>

                <tr>
                  <td colSpan={7} style={{ borderBottom: '1px solid black' }} />
                </tr>
                <tr>
                  <th
                    colSpan={7}
                    style={{
                      borderBottom: '1px solid black',
                      textAlign: 'center',
                    }}
                  >
                    Acuerdo Pagos
                  </th>
                </tr>
                <tr>
                  <th style={{ borderBottom: '1px solid black' }}>#</th>
                  <th style={{ borderBottom: '1px solid black' }}>Tipo</th>
                  <th style={{ borderBottom: '1px solid black' }}>
                    Fecha Pactada
                  </th>
                  <th style={{ borderBottom: '1px solid black' }}>Valor</th>
                  <th style={{ borderBottom: '1px solid black' }}>
                    Abono Capital
                  </th>
                  <th style={{ borderBottom: '1px solid black' }}>
                    Abono Intereses
                  </th>
                  <th style={{ borderBottom: '1px solid black' }}>Saldo</th>
                </tr>
                {bodyTableExport}
                <tr>
                  <th
                    colSpan={7}
                    style={{
                      borderBottom: '1px solid black',
                      textAlign: 'center',
                    }}
                  />
                </tr>
                <tr>
                  <th colSpan={7} />
                </tr>
                <tr>
                  <th colSpan={7}>SUJETO A VERIFICACIÓN DE ÁREA CONTABLE</th>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="lastSpaceCoti" />
        </div>
        <Modal isOpen={this.state.show} toggle={this.handleClose}>
          <ModalHeader>
            <>{this.state.titleModal}</>
          </ModalHeader>
          <ModalBody>{this.state.messageModal}</ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-default"
              onClick={this.handleClose}
            >
              Cerrar
            </button>
          </ModalFooter>
        </Modal>
        {/* Modal para la sobreescritura de planes */}
        <Modal
          isOpen={this.state.showOverWrite}
          toggle={this.handleCloseOverWrite}
        >
          <ModalHeader>
            <e>{` Este plan ya existe`}</e>
          </ModalHeader>
          <ModalBody>{`El cliente posee un plan para: ${this.state.inmueble} # ${this.state.numInmueble}. \n Desea sobreescribirlo o crear uno nuevo? Si existen varios planes con esta misma descripción (${this.state.inmueble} # ${this.state.numInmueble}) el programa sobreescribirá el último plan que este en estado PENDIENTE.`}</ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleUpdate}
            >
              Sobreescribir
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.uploadTabla}
            >
              Nuevo
            </button>
            <button
              type="button"
              className="btn btn-default"
              onClick={this.handleCloseOverWrite}
            >
              Cerrar
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
