// @ts-nocheck
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from '@mui/material';
import {
  DataGrid,
  GridToolbarContainer,
  esES,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import moment from 'moment';
import React, { useState } from 'react';
import FollowUpManagement from './FollowUpManagement';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import RateReviewIcon from '@mui/icons-material/RateReview';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModalDetail from '../ModalDetail/ModalDetail';
import axiosInstance from 'utils/database/api-leads';
import { toast } from 'react-toastify';

const HistoryManagement = ({
  managements,
  addFollowUp,
  finalizeManagement,
}) => {
  const [followings, setFollowings] = useState([]);
  const [medioGestion, setMedioGestion] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogGestionDone, setOpenDialogGestionDone] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [observacion, setObservacion] = useState('');
  const [idGestion, setIdGestion] = useState('');
  const [idTypo, setIdTypo] = useState('');
  const [showObservationTable, setShowObservationTable] = useState(false);
  const [observacionTable, setObservacionTable] = useState('');
  const [titleObservacion, setTitleObservacion] = useState('');
  const [responsesGestion, setResponsesGestion] = useState([]);
  const [requiereResponse, setRequiereResponse] = useState(false);
  const [response, setResponse] = useState('');

  const handleFinalizarGestion = (e) => {
    e.preventDefault();
    finalizeManagement(idGestion, idTypo, response);
    setOpenDialogGestionDone(false);
    setRequiereResponse(false);
    setResponse('');
    setResponsesGestion([]);
  };

  const handleFollow = (event) => {
    event.preventDefault();
    addFollowUp(idGestion, observacion);
    setObservacion('');
    setOpenDialog(false);
    setSelectionModel([]);
    setFollowings([]);
  };

  const handleSelection = (gestions) => {
    let manage = managements.filter((mag) => mag._id === gestions[0]);
    if (manage.length > 0) {
      setFollowings(manage[0].seguimientos);
      setMedioGestion(manage[0].tipoGestion.nombre);
    } else {
      setFollowings([]);
    }
  };

  const handleOpenDialog = (idGestion) => {
    setOpenDialog(true);
    setIdGestion(idGestion);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setObservacion('');
  };

  const getManagementResponses = (id) => {
    axiosInstance
      .get(`/leads/management/responses/get-active?gestionId=${id}`)
      .then((res) => {
        setResponsesGestion(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleOpenDialogGestionDone = (idGestion, idTypo) => {
    // Validar si la gestion requiere respuesta
    axiosInstance
      .get(`/leads/management/types/get/${idTypo}`)
      .then((res) => {
        let requiereResponse = res.data.data.requiereRespuesta;
        setRequiereResponse(requiereResponse);
        // Consultar respuestas por tipo de gestion
        if (requiereResponse) {
          getManagementResponses(idTypo);
        }
        setOpenDialogGestionDone(true);
        setIdGestion(idGestion);
        setIdTypo(idTypo);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleCloseDialogGestionDone = () => {
    setOpenDialogGestionDone(false);
    setRequiereResponse(false);
    setResponse('');
    setResponsesGestion([]);
  };

  const handleShowObservation = (tipoGestion, observacion) => {
    setObservacionTable(observacion);
    setTitleObservacion(tipoGestion);
    setShowObservationTable(true);
  };

  const handleHideObservationTable = () => {
    setObservacionTable('');
    setTitleObservacion('');
    setShowObservationTable(false);
  };

  const columns = [
    {
      field: '_id',
      disableExport: true,
      filterable: false,
      headerName: 'ID',
      headerAlign: 'center',
      align: 'center',
      width: 70,
      hide: true,
    },
    {
      field: 'Accion',
      disableExport: true,
      filterable: false,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      width: 120,
      renderCell: (cellValues) => {
        return (
          <>
            <Button
              hidden={cellValues.row.estado.idEstado === 2}
              onClick={() =>
                handleOpenDialogGestionDone(
                  cellValues.id,
                  cellValues.row.tipoGestion.idTypo,
                  cellValues.row
                )
              }
              variant="text"
              size="small"
            >
              <HowToRegIcon titleAccess="Finalizar Gestion" />
            </Button>
            <Button
              hidden={cellValues.row.estado.idEstado === 2}
              onClick={() => handleOpenDialog(cellValues.id)}
              variant="text"
              size="small"
            >
              <RateReviewIcon titleAccess="Agregar Seguimiento" />
            </Button>
          </>
        );
      },
    },
    {
      field: 'observacion',
      headerName: 'Observacion',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 120,
      renderCell: (cellValues) => {
        return (
          <>
            <Button
              onClick={() =>
                handleShowObservation(
                  cellValues.row.tipoGestion.nombre,
                  cellValues.row.observacion
                )
              }
              variant="text"
              size="small"
            >
              <VisibilityIcon titleAccess="Ver observacion" />
            </Button>
          </>
        );
      },
    },
    {
      field: 'fecha',
      headerAlign: 'center',
      align: 'center',
      headerName: 'Fecha',
      width: 160,
      valueFormatter: (params) =>
        params.value ? moment(params.value).format('DD/MM/YYYY hh:mm A') : null,
    },
    {
      field: 'programada',
      headerName: 'Programada',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 220,
      valueGetter: ({ value }) => value && value? "Si": "No",
    },
    {
      field: 'fechaProgramada',
      headerAlign: 'center',
      align: 'center',
      headerName: 'Fecha Programada',
      width: 160,
      valueFormatter: (params) =>
        params.value ? moment(params.value).format('DD/MM/YYYY hh:mm A') : null,
    },
    {
      field: 'tipoGestion',
      headerName: 'Medio de Gestion',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 220,
      valueGetter: ({ value }) => value && value.nombre,
    },
    {
      field: 'subtipo',
      headerName: 'Sub Tipo',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 220,
    },
    {
      field: 'respuesta',
      headerName: 'Respuesta',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 250,
      valueGetter: ({ value }) => value && value.nombre,
    },
    {
      field: 'usuarioGestion',
      headerName: 'Creada Por',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 250,
      valueGetter: ({ value }) => value && value.nombre,
    },
    {
      field: 'consultorAsignado',
      headerName: 'Consultor',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 250,
      valueGetter: ({ value }) => value && value.nombre,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 100,
      valueGetter: ({ value }) => value && value.nombre,
    },
  ];

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );

  return (
    <>
      <ModalDetail
        show={showObservationTable}
        handleClose={handleHideObservationTable}
        title={titleObservacion}
        message={observacionTable}
      />
      <h5 style={{ color: 'rgba(255, 170, 0, 0.968)' }}>
        Histórico de Gestion
      </h5>
      <p>Seleccione una gestion para ver sus seguimientos.</p>
      <div style={{ height: 430 }}>
        <DataGrid
          rows={managements}
          // @ts-ignore
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          getRowId={(row) => row._id}
          checkboxSelection={true}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          components={{ Toolbar: CustomToolbar }}
          selectionModel={selectionModel}
          disableSelectionOnClick
          onSelectionModelChange={(selection) => {
            if (selection.length > 1) {
              const selectionSet = new Set(selectionModel);
              const result = selection.filter((s) => !selectionSet.has(s));
              setSelectionModel(result);
              handleSelection(result);
            } else {
              setSelectionModel(selection);
              handleSelection(selection);
            }
          }}
        />
      </div>
      <Dialog fullWidth open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Registro de Seguimiento</DialogTitle>
        <form onSubmit={handleFollow}>
          <DialogContent>
            <DialogContentText>
              Por favor completar la siguiente informacion.
            </DialogContentText>
            <br />
            <TextField
              autoFocus
              margin="dense"
              label="Observacion"
              variant="outlined"
              placeholder="Escriba alguna información"
              fullWidth
              value={observacion}
              required
              multiline
              onChange={(event) => setObservacion(event.target.value)}
              rows={2}
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit">Agregar</Button>
            <Button onClick={handleCloseDialog}>Cancelar</Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        fullWidth
        open={openDialogGestionDone}
        onClose={handleCloseDialogGestionDone}
      >
        {/* TODO: Agregar campo de respuesta */}
        <DialogTitle>Finalizar Seguimientos</DialogTitle>
        <form onSubmit={handleFinalizarGestion}>
          <DialogContent>
            <DialogContentText>Desea finalizar esta gestion?</DialogContentText>
            <br />
            <TextField
              select
              variant="outlined"
              fullWidth
              label="Respuesta"
              value={response}
              onChange={(event) => setResponse(event.target.value)}
              required={requiereResponse}
              disabled={!requiereResponse}
            >
              {responsesGestion.map((resp) => (
                <MenuItem key={resp._id} value={resp.idResponse}>
                  {resp.nombre}
                </MenuItem>
              ))}
            </TextField>
            {!requiereResponse && (
              <DialogContentText color={'primary'}>
                No requiere respuesta.
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button type="submit">Aceptar</Button>
            <Button onClick={handleCloseDialogGestionDone}>Cancelar</Button>
          </DialogActions>
        </form>
      </Dialog>
      <br />
      <FollowUpManagement follows={followings} medioGestion={medioGestion} />
    </>
  );
};

export default HistoryManagement;
