import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation LOGIN($correo: String!, $password: String!) {
    Login(correo: $correo, password: $password) {
      token,
      message,
      user {
        _id
        nombre
        correo
        rol{
          idRol
          nombre
        }
        permisos {
          cotizacion {
            basico
            tasaInteres
            cobroInteresExtra
          }
          modificaciones {
            basico
          }
          reportes {
            basico
          }
          pagos {
            basico
          }
          consultas {
            basico
            eliminar
            descargar
            validar
          }
          leads {
            cargarLeads
            crearLead
            asignarEspecialista,
            informes,
            gestionar,
            gestionesProgramadas
          }
        }
      }
    }
  }
`;

export const LOGIN2 = gql`
  mutation LOGIN2($correo: String!, $password: String!) {
    Login(correo: $correo, password: $password) {
      token
      user {
        nombre
        correo
        rol{
          idRol
          nombre
        }
        permisos {
          cotizacion {
            basico
            tasaInteres
            cobroInteresExtra
          }
          modificaciones {
            basico
          }
          reportes {
            basico
          }
          pagos {
            basico
          }
          consultas {
            basico
            eliminar
            descargar
            validar
          }
          leads {
            cargarLeads
            crearLead
            asignarEspecialista,
            informes,
            gestionar,
            gestionesProgramadas
          }
        }
      }
    }
  }
`;
