// @ts-nocheck
import React, { Component } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownItem,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import client from 'utils/database';
import { CREATEPAYMENT, ADDPAYTOPLAN } from 'utils/database/mutation/payments';
import {
  UPDATETABLESAMECUOTE,
  UPDATETABLEPAY,
} from 'utils/database/mutation/tables';
import { SEARCHUSERBYPARAM } from 'utils/database/query/users';
import { SEARCHPLANBYIDPAGOS } from 'utils/database/query/plans';
import { SEARCHTABLEBYID } from 'utils/database/query/tables';

import './Pagos.css';

export default class Pagos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proyectoActual: '',
      cedula: '',
      nombre: '',
      planes: [],
      idPlanActual: '',
      planUsuario: '',
      tasaInteres: 0,
      fechaPago: '',
      arrCuotas: [],
      indexFecha: 0,
      cuota: 0,
      idTable: '',
      valorTeoricoCuota: 0,
      abCapitalTeorico: 0,
      abInteresTeorico: 0,
      saldoRestanteTeorico: 0,
      saldoRestanteReal: 0,
      valorPago: 0,
      abCapital: 0,
      abInteres: 0,
      boolInteres: true,
      observaciones: '',
      valorMora: 0,
      diasMora: 0,
      interesMora: 0,
      estado: 'Pagado',
      messageModal: '',
      show: false,
      titleModal: '',
      estadoCuota: '',
      // NEW
      valorPagado: 0,
      oldAbCapital: 0,
      oldAbInteres: 0,
      pendCapital: 0,
      pendInteres: 0,
      valorPendiente: 0,
      showCuotes: false,
    };
  }

  componentDidMount() {
    let proyectoActual = JSON.parse(
      sessionStorage.getItem('configuracion')
    ).nombre;
    this.setState({
      proyectoActual: proyectoActual,
    });
  }

  handleChangeCedula = (e) => {
    this.setState({
      cedula: e.target.value,
    });
  };

  buscarUsuario = (e) => {
    e.preventDefault();
    document.getElementById('loadingButton').className =
      'btn btn-primary buttonUpload';
    document.getElementById('buscarBtn').className =
      'btn btn-primary buttonUpload invisible';
    let planesValidos = [];
    client
      .query({
        query: SEARCHUSERBYPARAM,
        variables: { cedula: this.state.cedula },
      })
      .then((user) => {
        console.log(user.data.SearchUserByParam.planes);
        user.data.SearchUserByParam.planes.map((plan) => {
          console.log(plan.estado, plan.idPlan);
          if (
            plan.estado === 'Valido' &&
            plan.proyecto === this.state.proyectoActual
          ) {
            planesValidos.push(plan);
            return null;
          }
          return null;
        });
        this.setState({
          nombre: user.data.SearchUserByParam.nombre,
          planes: planesValidos,
          proyecto: planesValidos[0].proyecto,
          idPlanActual: planesValidos[0].idPlan,
        });
        document.getElementById('loadingButton').className =
          'btn btn-primary buttonUpload invisible';
        document.getElementById('buscarBtn').className =
          'btn btn-primary buttonUpload';
        document.getElementById('formPago').className = '';
        this.getCuotas(planesValidos[0].idPlan);
      })
      .catch(() => {
        this.setState({
          titleModal: <div className="center wrong" />,
          show: true,
          messageModal:
            'Es posible que el usuario no exista o tenga planes Pendientes. Por favor, asegurese de validar los planes antes de realizar un pago',
        });
        document.getElementById('loadingButton').className =
          'btn btn-primary buttonUpload invisible';
        document.getElementById('buscarBtn').className =
          'btn btn-primary buttonUpload';
      });
  };

  getCuotas = (idPlan) => {
    client
      .query({ query: SEARCHPLANBYIDPAGOS, variables: { id: idPlan } })
      .then((plan) => {
        console.log(plan);
        this.setState({
          idTable: plan.data.SearchPlanById.tabla,
          saldoRestanteTeorico: plan.data.SearchPlanById.saldoRestante,
          saldoRestanteReal: plan.data.SearchPlanById.saldoRestante,
          tasaInteres: plan.data.SearchPlanById.tasaInteres,
        });
        this.getInitialValue(plan.data.SearchPlanById.tabla, 0);
      });
  };

  getInitialValue = (idTabla, cuota) => {
    client
      .query({ query: SEARCHTABLEBYID, variables: { id: idTabla } })
      .then((tabla) => {
        let arr = [];
        for (let i = 0; i < tabla.data.SearchTableById.content.length; i++) {
          arr.push(tabla.data.SearchTableById.content[i].fecha);
        }
        let response = tabla.data.SearchTableById.content;
        let boolInteres = response[cuota].abonoInteresTeorico !== 0;
        this.setState({
          arrCuotas: arr,
          cuota: arr[0],
          indexFecha: 0,
          valorTeoricoCuota: response[cuota].valorTeoricoCuota,
          abCapitalTeorico: response[cuota].abonoCapitalTeorico,
          abInteresTeorico: response[cuota].abonoInteresTeorico,
          estadoCuota: response[cuota].estado,
          boolInteres: boolInteres,
          valorPagado: response[cuota].pagoTotal,
          oldAbCapital: response[cuota].abonoCapital,
          oldAbInteres: response[cuota].abonoInteres,
          pendCapital:
            response[cuota].abonoCapitalTeorico - response[cuota].abonoCapital,
          pendInteres:
            response[cuota].abonoInteresTeorico - response[cuota].abonoInteres,
          valorPendiente:
            response[cuota].valorTeoricoCuota - response[cuota].pagoTotal,
        });
      });
  };

  getValues = (idTabla, cuota) => {
    client
      .query({ query: SEARCHTABLEBYID, variables: { id: idTabla } })
      .then((tabla) => {
        this.setState({
          valorTeoricoCuota:
            tabla.data.SearchTableById.content[cuota].valorTeoricoCuota,
        });
      });
  };

  handleChangePlan = (e) => {
    let index = e.target.options.selectedIndex;
    let proyecto = e.target.childNodes[index].id;
    let idPlan = e.target.childNodes[index].value;
    this.setState({
      planUsuario: e.target.value,
      proyecto: proyecto,
      idPlanActual: idPlan,
    });
    this.getCuotas(idPlan);
  };

  handleChangeFechaPago = (e) => {
    this.setState({
      fechaPago: e.target.value,
    });
  };

  handleChangeCuota = (e) => {
    let cuota = e.target.name;
    let index = parseInt(e.target.value);
    console.log(e.target);
    console.log(cuota, index);
    let idTable = this.state.idTable;
    client
      .query({ query: SEARCHTABLEBYID, variables: { id: idTable } })
      .then((tabla) => {
        let response = tabla.data.SearchTableById.content;
        let boolInteres = response[index].abonoInteresTeorico !== 0;
        this.setState({
          cuota,
          valorTeoricoCuota: response[index].valorTeoricoCuota,
          abCapitalTeorico: response[index].abonoCapitalTeorico,
          abInteresTeorico: response[index].abonoInteresTeorico,
          estadoCuota: response[index].estado,
          indexFecha: index,
          boolInteres: boolInteres,
          valorPagado: response[index].pagoTotal,
          oldAbCapital: response[index].abonoCapital,
          oldAbInteres: response[index].abonoInteres,
          pendCapital:
            response[index].abonoCapitalTeorico - response[index].abonoCapital,
          pendInteres:
            response[index].abonoInteresTeorico - response[index].abonoInteres,
          valorPendiente:
            response[index].valorTeoricoCuota - response[index].pagoTotal,
        });
      });
  };

  handleChangeValorPago = (e) => {
    let valor = e.target.value;
    let abInteres = 0;
    if (this.state.boolInteres) {
      // abInteres = e.target.value * (this.state.tasaInteres / 100)
      abInteres =
        this.state.saldoRestanteTeorico * (this.state.tasaInteres / 100);
    }

    //console.log("ab interes: ", abInteres);

    let abCapital = e.target.value - abInteres;
    this.setState({
      valorPago: e.target.value,
      abCapital: abCapital,
      abInteres: abInteres,
      saldoRestanteReal: this.state.saldoRestanteTeorico - valor,
    });
  };

  handleChangeBoolInteresRecalculo = (bool) => {
    let valor = this.state.valorPago;
    let abInteres = 0;
    if (bool) {
      abInteres =
        this.state.saldoRestanteTeorico * (this.state.tasaInteres / 100);
    }
    let abCapital = this.state.valorPago - abInteres;
    this.setState({
      abCapital: abCapital,
      abInteres: abInteres,
      saldoRestanteReal: this.state.saldoRestanteTeorico - valor,
    });
  };

  handleChangeBoolIntereses = (e) => {
    let bool;
    if (this.state.boolInteres) {
      bool = false;
      this.setState({
        boolInteres: false,
      });
    } else if (!this.state.boolInteres) {
      bool = true;
      this.setState({
        boolInteres: true,
      });
    }
    this.handleChangeBoolInteresRecalculo(bool);
  };

  handleChangeObservaciones = (e) => {
    this.setState({
      observaciones: e.target.value,
    });
  };

  handleChangeBoolMora = () => {
    // id='moraFields' className='form-group row Common invisible'
    if (this.state.boolInteresMora) {
      this.setState({
        boolInteresMora: false,
      });
      document.getElementById('moraFields').className =
        'form-group row Common invisible';
    } else if (!this.state.boolInteresMora) {
      this.setState({
        boolInteresMora: true,
      });
      document.getElementById('moraFields').className = 'form-group row Common';
    }
    this.recalculoMora();
  };

  recalculoMora = (interesMora, diasMora) => {
    let valor = this.state.valorPago;
    let bool = this.state.boolInteres;
    // let interesMora = this.state.interesMora
    // let diasMora = this.state.diasMora
    let valorInteresMora = interesMora * diasMora * valor;
    let abInteres = 0;

    if (bool) {
      abInteres = this.state.valorPago * (this.state.tasaInteres / 100);
    }

    let abCapital = this.state.valorPago - abInteres - valorInteresMora;
    this.setState({
      valorMora: valorInteresMora,
      abCapital: abCapital,
      abInteres: abInteres,
      saldoRestanteReal:
        this.state.saldoRestanteTeorico - (valor - valorInteresMora),
    });
  };

  handleValorInteresMora = (e) => {
    this.setState({
      interesMora: e.target.value,
    });
    this.recalculoMora(e.target.value, this.state.diasMora);
  };

  handleChangeDiasMora = (e) => {
    this.setState({
      diasMora: e.target.value,
    });
    this.recalculoMora(this.state.interesMora, e.target.value);
  };

  handleChangeValorMora = (e) => {
    this.setState({
      valorMora: e.target.value,
    });
  };

  checkBeforePayment = () => {
    let { cedula, nombre, fechaPago, valorPago } = this.state;
    var arreglo = [cedula, nombre, fechaPago, valorPago];

    for (var i = 0; i < arreglo.length; i++) {
      if (arreglo[i] === '') {
        return false;
      }
    }
    
    return true;
  };

  handleUpload = () => {
    if (this.checkBeforePayment()) {
      document.getElementById('loadingButton').className =
        'btn btn-primary buttonUpload';
      document.getElementById('pagosBtn').className =
        'btn btn-primary buttonUpload invisible';
      this.realizarPago();
    } else {
      this.setState({
        titleModal: <div className="center wrong" />,
        show: true,
        messageModal:
          'Por favor complete la información antes de realizar el pago',
      });
    }
  };

  realizarPago = () => {
    let dataPayment = {
      fecha: this.state.fechaPago, // No debe ser vacia
      abonoCapital: Math.round(this.state.abCapital), // Aproximación al dígito inmediato superior o inferior, dependiendo si el valor de los decimales se superior o inferior a 5
      valorPago: parseInt(this.state.valorPago),
      abonoInteres: Math.round(this.state.abInteres), // Aproximación al dígito inmediato superior o inferior, dependiendo si el valor de los decimales se superior o inferior a 5
      observaciones: this.state.observaciones,
      interesMora: Math.round(this.state.valorMora), // Aproximación al dígito inmediato superior o inferior, dependiendo si el valor de los decimales se superior o inferior a 5
      diasMora: parseInt(this.state.diasMora),
      cuota: this.state.indexFecha,
    };

    if (
      dataPayment.fecha === '' ||
      dataPayment.fecha === null ||
      dataPayment.fecha === undefined
    ) {
      this.setState({
        titleModal: <div className="center wrong" />,
        show: true,
        messageModal: 'Por favor seleccione la fecha de pago',
      });
    } else {
      client
        .mutate({ mutation: CREATEPAYMENT, variables: { data: dataPayment } })
        .then((result) => {
          this.addPaymentToPlan(result.data.CreatePayment._id);
        })
        .catch(() => {
          this.setState({
            titleModal: <div className="center wrong" />,
            show: true,
            messageModal: 'Error al realizar el pago',
          });
        });
    }
  };

  addPaymentToPlan = (idPayment) => {
    let idPlan = this.state.idPlanActual;
    let valorTotal = parseFloat(this.state.valorPago);
    let abCapital = Math.round(this.state.abCapital); // Aproximación al dígito inmediato superior o inferior, dependiendo si el valor de los decimales se superior o inferior a 5
    client
      .mutate({
        mutation: ADDPAYTOPLAN,
        variables: {
          idPlan: idPlan,
          idPay: idPayment,
          valorTotal: valorTotal,
          abonoCapital: abCapital,
        },
      })
      .then((result) => {
        this.modifyTableSameCuote(result.data.AddPaymentToPlan.tabla);
      });
  };

  modifyTableSameCuote = (idTabla) => {
    let tableData = {
      abonoCapital: Math.round(this.state.abCapital), // Aproximación al dígito inmediato superior o inferior, dependiendo si el valor de los decimales se superior o inferior a 5
      abonoInteres: Math.round(this.state.abInteres), // Aproximación al dígito inmediato superior o inferior, dependiendo si el valor de los decimales se superior o inferior a 5
      estado: this.state.estado,
      pagoTotal: parseInt(this.state.valorPago),
      interesMora: Math.round(this.state.valorMora), // Aproximación al dígito inmediato superior o inferior, dependiendo si el valor de los decimales se superior o inferior a 5
      diasMora: parseInt(this.state.diasMora),
    };
    client
      .mutate({
        mutation: UPDATETABLESAMECUOTE,
        variables: {
          idTable: idTabla,
          cuota: this.state.indexFecha,
          data: tableData,
        },
      })
      .then((result) => {
        this.modifyTableFullPayment(idTabla);
      })
      .catch(() => {
        this.setState({
          titleModal: <div className="center wrong" />,
          show: true,
          messageModal:
            'Ocurrio un error al actualizar la tabla de plan de pagos',
        });
      });
  };

  modifyTableFullPayment = (idTabla) => {
    let dataPayment = {
      cuota: this.state.indexFecha,
      valor: Math.round(this.state.abCapital), // Aproximación al dígito inmediato superior o inferior, dependiendo si el valor de los decimales se superior o inferior a 5
    };
    client
      .mutate({
        mutation: UPDATETABLEPAY,
        variables: { idTable: idTabla, data: dataPayment },
      })
      .then((result) => {
        this.setState({
          titleModal: <div className="center success" />,
          show: true,
          messageModal: 'Pago realizado satisfactoriamente',
          valorPago: 0,
          observaciones: '',
          fechaPago: '',
          abCapital: 0,
          abInteres: 0,
        });
        document.getElementById('loadingButton').className =
          'btn btn-primary buttonUpload invisible';
        document.getElementById('pagosBtn').className =
          'btn btn-primary buttonUpload';
        this.getCuotas(this.state.idPlanActual);
      })
      .catch(() => {
        this.setState({
          titleModal: <div className="center wrong" />,
          show: true,
          messageModal:
            'Ocurrio un problema al actualizar la tabla de cotizacion',
        });
      });
  };

  newPayment = () => {
    //
  };

  handleChangeEstado = (e) => {
    let index = e.target.options.selectedIndex;
    let newState = e.target.childNodes[index].value;
    this.setState({
      estado: newState,
    });
  };

  showPartialPayment = () => {
    this.setState({
      titleModal: <div className="center wrong" />,
      show: true,
      messageModal:
        'Recuerde que para pagos parciales, se deben primero pagar los intereses, si ya lo has pagado, asegurese que el boton "Cobrar intereses" este apagado.',
    });
  };

  toggleCuotes = () => {
    this.setState({
      showCuotes: !this.state.showCuotes,
    });
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  render() {
    let userPlans = this.state.planes.map((plan) => {
      let proyectoActual = JSON.parse(
        sessionStorage.getItem('configuracion')
      ).nombre;
      return plan.proyecto === proyectoActual && plan.estado === 'Valido' ? (
        <option key={plan.idPlan} value={plan.idPlan} id={plan.proyecto}>
          {plan.descripcion}
        </option>
      ) : null;
    });

    let cuotas = this.state.arrCuotas.map((num, index) => (
      <DropdownItem
        name={num}
        value={index}
        key={index}
        onClick={this.handleChangeCuota}
      >
        {num}
      </DropdownItem>
    ));

    return (
      <div id="DashboardBody" className="ModifyBack">
        <div id="content-wrapper" className="PagosBody">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="dashboard">Dashboard</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Pagos
              </li>
            </ol>
          </div>
          <div>
            <form onSubmit={this.buscarUsuario}>
              <div className="form-group row Common">
                <div className="col-xs-2 Common">
                  <input
                    type="number"
                    id="cedula"
                    className="form-control"
                    width="35px"
                    placeholder="Cedula"
                    value={this.state.cedula}
                    onChange={this.handleChangeCedula}
                  />
                </div>
                <div className="col-xs-2 Common">
                  <button
                    type="submit"
                    className="btn btn-primary mb-2"
                    id="buscarBtn"
                  >
                    Buscar
                  </button>
                  <button
                    id="loadingButton"
                    className="btn btn-primary buttonUpload invisible"
                    type="button"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {` Buscando...`}
                  </button>
                </div>
              </div>
            </form>

            <div>
              <div className="form-group row Common">
                <div className="col-xs-2 Common">
                  <input
                    type="text"
                    id="nombre"
                    className="form-control nameWidth"
                    value={this.state.nombre}
                    disabled
                  />
                </div>
              </div>
              <div className="form-group row Common">
                <div className="input-group Common ProyectGroup">
                  <select
                    id="tipoLote"
                    placeholder="Descripcion"
                    className="form-control hundredWidth"
                    value={this.state.planUsuario}
                    onChange={this.handleChangePlan}
                  >
                    {userPlans}
                  </select>
                  <div className="input-group-append input-group-prepend">
                    <span className="input-group-text" id="id">
                      De
                    </span>
                  </div>
                  <input
                    id="tipoLote"
                    placeholder="Proyecto"
                    className="form-control hundredWidth "
                    value={this.state.proyecto}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div id="formPago" className="invisible">
              {/* <div id='divReload' className='reload'>
                <button className='btn btn-primary mb-2 reloadExtended' id='reloadPage' onclick=''>Nuevo pago</button>
              </div> */}

              <div className="form-group row Common">
                <div className="col-xs-2 Common">
                  <label htmlFor="exampleFormControlInput1">Fecha pago: </label>
                  <input
                    type="date"
                    id="fechaCuota"
                    className="form-control CommonWidth"
                    value={this.state.fechaPago}
                    onChange={this.handleChangeFechaPago}
                  />
                </div>

                <div className="col-xs-2 Common">
                  <label htmlFor="exampleFormControlInput1">Cuota #</label>
                  <div className="input-group mb-3">
                    <ButtonDropdown
                      isOpen={this.state.showCuotes}
                      toggle={this.toggleCuotes}
                      className="main--dropdown-cuotes"
                    >
                      <DropdownToggle>
                        {this.state.indexFecha} | {this.state.cuota}
                      </DropdownToggle>
                      <DropdownMenu>{cuotas}</DropdownMenu>
                    </ButtonDropdown>
                  </div>
                </div>

                <div className="col-xs-2 Common">
                  <label htmlFor="exampleFormControlInput1">
                    {' '}
                    Estado Cuota:{' '}
                  </label>
                  <input
                    type="text"
                    id="fechaCuota"
                    className="form-control CommonWidth"
                    value={this.state.estadoCuota}
                    disabled
                  />
                </div>
              </div>
              <div className="form-group row Common">
                <div className="col-xs-2 Common">
                  <label htmlFor="exampleFormControlInput1">
                    Valor Pactado:
                  </label>
                  <input
                    type="number"
                    id="valorTeorico"
                    value={this.state.valorTeoricoCuota}
                    className="form-control CommonWidth"
                    disabled
                  />
                </div>
                <div className="col-xs-2 Common">
                  <label htmlFor="exampleFormControlInput1">
                    Abono Capital Pactado:
                  </label>
                  <input
                    type="number"
                    id="valorTeorico"
                    value={this.state.abCapitalTeorico}
                    className="form-control CommonWidth"
                    disabled
                  />
                </div>
                <div className="col-xs-2 Common">
                  <label htmlFor="exampleFormControlInput1">
                    Abono Interes Pactado:
                  </label>
                  <input
                    type="number"
                    id="valorTeorico"
                    value={this.state.abInteresTeorico}
                    className="form-control CommonWidth"
                    disabled
                  />
                </div>
                <div className="col-xs-2 Common">
                  <label htmlFor="exampleFormControlInput1">
                    Tasa Interes %:
                  </label>
                  <input
                    type="number"
                    id="valorTeorico"
                    value={this.state.tasaInteres}
                    className="form-control CommonWidth"
                    disabled
                  />
                </div>
              </div>

              <div className="form-group row Common">
                <div className="col-xs-2 Common">
                  <label htmlFor="exampleFormControlInput1">
                    {' '}
                    Valor Pagado:
                  </label>
                  <input
                    type="number"
                    id="abCapital"
                    className="form-control CommonWidth"
                    value={this.state.valorPagado}
                    disabled
                  />
                </div>
                <div className="col-xs-2 Common">
                  <label htmlFor="exampleFormControlInput1">
                    Abonado Capital:
                  </label>
                  <input
                    type="number"
                    id="abInteres"
                    className="form-control CommonWidth"
                    value={this.state.oldAbCapital}
                    disabled
                  />
                </div>
                <div className="col-xs-2 Common">
                  <label htmlFor="exampleFormControlInput1">
                    Abonado Interes:
                  </label>
                  <input
                    type="number"
                    id="abInteres"
                    className="form-control CommonWidth"
                    value={this.state.oldAbInteres}
                    disabled
                  />
                </div>
              </div>
              <div className="form-group row Common">
                <div className="col-xs-2 Common">
                  <label htmlFor="exampleFormControlInput1">
                    {' '}
                    Valor Pendiente:{' '}
                  </label>
                  <input
                    type="number"
                    id="valorPagado"
                    className="form-control CommonWidth"
                    value={this.state.valorPendiente}
                    onChange={this.handleChangeValorPago}
                    disabled
                  />
                </div>
                <div className="col-xs-2 Common">
                  <label htmlFor="exampleFormControlInput1">
                    Pendiente Capital:
                  </label>
                  <input
                    type="number"
                    id="abInteres"
                    className="form-control CommonWidth"
                    value={this.state.pendCapital}
                    disabled
                  />
                </div>
                <div className="col-xs-2 Common">
                  <label htmlFor="exampleFormControlInput1">
                    Pendiente Interes:
                  </label>
                  <input
                    type="number"
                    id="abInteres"
                    className="form-control CommonWidth"
                    value={this.state.pendInteres}
                    disabled
                  />
                </div>
              </div>

              <div className="form-group row Common">
                <div className="col-xs-2 Common">
                  <label htmlFor="exampleFormControlInput1">
                    Nuevo Valor Pago:{' '}
                  </label>
                  <input
                    type="number"
                    id="valorPago"
                    className="form-control CommonWidth"
                    value={this.state.valorPago}
                    onChange={this.handleChangeValorPago}
                  />
                </div>
                <div className="col-xs-2 Common">
                  <label htmlFor="exampleFormControlInput1">
                    Nuevo Abono Capital:
                  </label>
                  <input
                    type="number"
                    id="abCapital"
                    className="form-control CommonWidth"
                    value={this.state.abCapital}
                    disabled
                  />
                </div>
                <div className="col-xs-2 Common">
                  <label htmlFor="exampleFormControlInput1">
                    Nuevo Abono Interes:
                  </label>
                  <input
                    type="number"
                    id="abInteres"
                    className="form-control CommonWidth"
                    value={this.state.abInteres}
                    disabled
                  />
                </div>

                <div className="col-xs-2 Common">
                  <label htmlFor="exampleFormControlInput1">
                    Saldo Restante:
                  </label>
                  <input
                    type="number"
                    id="saldoRestante"
                    className="form-control CommonWidth"
                    value={this.state.saldoRestanteReal}
                    disabled
                  />
                </div>
              </div>
              <div className="form-group row Common">
                <div className="col-xs-2 Common">
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Nuevo Estado Cuota
                  </label>
                  <select
                    className="form-control hundWidth"
                    value={this.state.estado}
                    id="boolInteres"
                    onChange={this.handleChangeEstado}
                  >
                    <option value="Pagado">Pagado</option>
                    <option value="Por Pagar">Por Pagar</option>
                    <option value="Vencido">Vencido</option>
                    <option value="Pago Parcial">Pago Parcial</option>
                  </select>
                </div>
              </div>
              <div className="Common">
                <div className="form-check">
                  {/* <input className='form-check-input' type='checkbox' value={this.state.boolInteres} id='boolInteres' onClick={this.handleChangeBoolIntereses} /> */}
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.boolInteres}
                    value={this.state.boolInteres}
                    id="boolInteres"
                    onClick={this.handleChangeBoolIntereses}
                  />
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Cobrar intereses
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={this.state.boolInteresMora}
                    id="boolInteres"
                    onClick={this.handleChangeBoolMora}
                  />
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Cobrar intereses Mora
                  </label>
                </div>
                <div
                  id="moraFields"
                  className="form-group row Common invisible"
                >
                  <div className="col-xs-2 Common">
                    <label htmlFor="exampleFormControlInput1">
                      {' '}
                      Tasa Interes Mora:{' '}
                    </label>
                    <input
                      type="number"
                      id="valorPago"
                      className="form-control CommonWidth"
                      value={this.state.interesMora}
                      onChange={this.handleValorInteresMora}
                    />
                  </div>
                  <div className="col-xs-2 Common">
                    <label htmlFor="exampleFormControlInput1">
                      {' '}
                      Dias Mora:{' '}
                    </label>
                    <input
                      type="number"
                      id="valorPago"
                      className="form-control CommonWidth"
                      value={this.state.diasMora}
                      onChange={this.handleChangeDiasMora}
                    />
                  </div>
                  <div className="col-xs-2 Common">
                    <label htmlFor="exampleFormControlInput1">
                      {' '}
                      Valor Interes Mora:{' '}
                    </label>
                    <input
                      type="number"
                      id="valorPago"
                      className="form-control CommonWidth"
                      value={this.state.valorMora}
                      onChange={this.handleChangeValorMora}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="form-group observ">
                <label htmlFor="comment">Observaciones:</label>
                <textarea
                  className="form-control"
                  rows="3"
                  cols="3"
                  id="comment"
                  value={this.state.observaciones}
                  onChange={this.handleChangeObservaciones}
                  placeholder="# de comprobante, banco, notas relevantes, etc"
                />
              </div>

              <div id="divReload" className="reload">
                <button
                  className="btn btn-primary mb-2"
                  id="pagosBtn"
                  onClick={this.handleUpload}
                >
                  Realizar pago
                </button>
                <button
                  id="loadingButton"
                  className="btn btn-primary buttonUpload invisible"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {` Subiendo...`}
                </button>
              </div>

              {/* <div id='newPayment' className='reload'>
                <button className='btn btn-primary mb-2 reloadExtended' id='reloadPage' onClick={this.newPayment} >Nuevo pago</button>
              </div> */}
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.show} toggle={this.handleClose}>
          <ModalHeader>
            <>{this.state.titleModal}</>
          </ModalHeader>
          <ModalBody>{this.state.messageModal}</ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-default"
              onClick={this.handleClose}
            >
              Cerrar
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
