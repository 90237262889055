// @ts-nocheck
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from 'react-table';

export const TableReport = ({ data, filter }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'nombre',
      },
      {
        Header: 'Proyecto',
        accessor: 'proyecto',
      },
      {
        Header: 'Lote',
        accessor: 'lote',
      },
      {
        Header: 'Valor lote',
        accessor: 'valorLote',
      },
      {
        Header: 'Total Abono',
        accessor: 'pagoTotal',
      },
      {
        Header: 'Abono Capital',
        accessor: 'abonoCapital',
      },
      {
        Header: 'Abono Interes',
        accessor: 'abonoInteres',
      },
      {
        Header: 'Total Proyeccion',
        accessor: 'totalProyeccion',
      },
      {
        Header: 'Proyeccion Capital',
        accessor: 'capitalProyeccion',
      },
      {
        Header: 'Proyeccion Interes',
        accessor: 'interesProyeccion',
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(filter);
  }, [data, setGlobalFilter, filter]);

  return (
    <>
      <table {...getTableProps()} className="table table-bordered main--table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon
                          icon="sort-down"
                          color="black"
                          className="ml-2"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon="sort-up"
                          color="black"
                          className="ml-2"
                        />
                      )
                    ) : (
                      <FontAwesomeIcon
                        icon="sort"
                        color="black"
                        className="ml-2"
                      />
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination table--pag d-flex justify-content-end mr-5">
        <button
          type="button"
          className=" mr-2 main-table--page-index"
          onClick={previousPage}
          disabled={!canPreviousPage}
        >
          <FontAwesomeIcon icon="chevron-left" color="black" />
        </button>

        <span className="table--pag__page">{pageIndex + 1}</span>
        <button
          className=" ml-2 mr-2 main-table--page-index"
          type="button"
          onClick={nextPage}
          disabled={!canNextPage}
        >
          <FontAwesomeIcon icon="chevron-right" color="black" />
        </button>
        <span className="table--pag__length">de {pageOptions.length}</span>
      </div>
    </>
  );
};
