import { getGridStringOperators } from '@mui/x-data-grid';
import moment from 'moment';

const filterOperators = getGridStringOperators().filter(({ value }) =>
  ['equals', 'contains'].includes(value)
);

let columnsD = [
  {
    field: 'estado',
    headerName: 'Estado',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 180,
    valueGetter: ({ value }) => value && value.nombre,
  },
  {
    field: 'especialistaAsignado',
    headerName: 'Especialista Asig.',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 230,
    valueGetter: ({ value }) => value && value.nombre,
  },
  {
    field: 'proyecto',
    headerName: 'Proyecto Interesado',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 230,
    valueGetter: ({ value }) => value && value.nombre,
  },
  {
    field: 'celular',
    headerName: 'Celular 1',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 110,
  },
  {
    field: 'correo',
    headerName: 'Correo',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 180,
  },
  {
    field: 'medioRegistro',
    headerName: 'Medio Regis.',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 110,
    valueGetter: ({ value }) => value && value.nombre,
  },
  {
    field: 'fechaRegistro',
    headerName: 'Fecha Registro',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 160,
    valueFormatter: (params) =>
      params.value ? moment(params.value).format('DD/MM/YYYY hh:mm A') : null,
  },
  {
    field: 'inmueble',
    headerName: 'Tipo Inmueble',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 105,
  },
  {
    field: 'cedula',
    headerName: 'Cedula',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 100,
  },
  {
    field: 'ciudadResidencia',
    headerName: 'Ciudad',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 110,
  },
  {
    field: 'celularRespaldo',
    headerName: 'Celular 2',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 110,
  },
  {
    field: 'edad',
    headerName: 'Edad',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 50,
  },
  {
    field: 'nivelProfesional',
    headerName: 'Nivel Prof.',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 130,
    valueGetter: ({ value }) => value && value.nombre,
  },
  {
    field: 'profesion',
    headerName: 'Profesion u Oficio',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 200,
  },
  {
    field: 'comoNosConocio',
    headerName: 'Como Nos Conoció',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 200,
  },
  {
    field: 'campaña',
    headerName: 'Campaña',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 125,
  },
  {
    field: 'fechaAsignacionEspecialista',
    headerName: 'Fecha Asig. Especia.',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 160,
    valueFormatter: (params) =>
      params.value ? moment(params.value).format('DD/MM/YYYY hh:mm A') : null,
  },
  {
    field: 'consultor',
    headerName: 'Consultor Asig.',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 230,
    valueGetter: ({ value }) => value && value.nombre,
  },
  {
    field: 'consultorDate',
    headerName: 'Fecha Asig. Consultor',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 180,
    valueGetter: ({ row }) => {
      if (row.consultor) {
        return moment(row.consultor.fechaAsignacion).format(
          'DD/MM/YYYY hh:mm A'
        );
      }
    },
  },
  {
    field: 'perfilamiento',
    headerName: 'Perfil - Sector Inversion',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 300,
    valueGetter: ({ value }) => value && value.sectorInversion,
  },
  {
    field: 'perfilamiento2',
    headerName: 'Perfil - Area lote',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 130,
    valueGetter: ({ row }) => {
      if (row.perfilamiento) {
        return row.perfilamiento.areaLote + ' m2';
      }
    },
  },
  {
    field: 'perfilamiento3',
    headerName: 'Perfil - Clima Preferido',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 170,
    valueGetter: ({ row }) => {
      return row.perfilamiento?.clima;
    },
  },
  {
    field: 'perfilamiento4',
    headerName: 'Perfil - Etapa Proyecto',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 170,
    valueGetter: ({ row }) => {
      return row.perfilamiento?.etapa;
    },
  },
  {
    field: 'perfilamiento5',
    headerName: 'Perfil - Presupuesto',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 160,
    valueGetter: ({ row }) => {
      return row.perfilamiento?.presupuesto;
    },
  },
  {
    field: 'perfilamiento6',
    headerName: 'Perfil - Disfrute inversion',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 190,
    valueGetter: ({ row }) => {
      if (row.perfilamiento) {
        return row.perfilamiento.mesesInversion + ' meses';
      }
    },
  },
  {
    field: 'perfilamiento7',
    headerName: 'Perfil - Motivo inversion',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 190,
    valueGetter: ({ row }) => {
      return row.perfilamiento?.motivoInversion;
    },
  },
];

columnsD.map((col) => (col.filterOperators = filterOperators));

export { columnsD };
