import React, { Component } from 'react';
import ReactToExcel from 'react-html-table-to-excel';
import ReactDOM from 'react-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import client from 'utils/database';
import {
  UPDATESTATEPLAN,
  DELETEPLAN,
  DELETEUSERPLAN,
} from 'utils/database/mutation/plans';
import { DELETETABLE } from 'utils/database/mutation/tables';
import { DELETEPAYMENTS } from 'utils/database/mutation/payments';
import { SEARCHTABLEBYID } from 'utils/database/query/tables';
import { SEARCHPAYMENTBYIDARRAY } from 'utils/database/query/payments';
import TableConsultas from './TableConsultas';
import numeral from 'numeral';

export default class SserTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      tableId: this.props.plan.tabla,
      paymentId: this.props.plan.pagos,
      planId: this.props.plan._id,
      contentTable: [],
      contentPayment: [],
      show: false,
      showDelete: false,
      currentDate: '',
      showMessage: false,
      messageModal: '',
      titleModal: <div className="center success" />,
      totalAbonoCapitalPagos: 0
    };
  }

  /**
   * Se cargan los metodos iniciales para obtener la informacion especifica de cada usuario/tipo
   */
  componentWillMount() {
    this.loadTableById();
    this.loadPaymentById();
    this.setCurrentDate();
    let user = JSON.parse(sessionStorage.getItem('user')); // Tipo de usuario: 1 - Administrador, 2 - Empleado, 3 - Cliente
    this.setState({
      userType: user.rol.nombre,
      permisos: user.permisos.consultas,
    });
  }

  /**
   * Este metodo obtiene el plan de amortizacion/tabla donde se muestran
   * todas las cuotas y pagos teoricos y reales del cliente
   */
  loadTableById = () => {
    client
      .query({ query: SEARCHTABLEBYID, variables: { id: this.state.tableId } })
      .then((result) => {
        this.setState({
          contentTable: result.data.SearchTableById.content, // Se establece el estado para luego hacer render() con map()
        });
      });
  };

  /**
   * Este metodo carga todos los pagos para renderizarlos en una
   * tabla aparte con todos sus detalles (Fecha, cuota, observaciones...)
   */
  loadPaymentById = () => {
    client
      .query({
        query: SEARCHPAYMENTBYIDARRAY,
        variables: { idPayment: this.state.paymentId },
      })
      .then((result) => {
        this.setState({
          contentPayment: result.data.SearchPaymentArray, // Se establece el estado para luego hacer render() con map()
        });
        this.calculateTotalAbonoCapital(result.data.SearchPaymentArray);
      });
  };

  /**
   * Este metodo calcula el total abonado al capital por todos los pagos
   */
  calculateTotalAbonoCapital = (pagos) => {
    let totalAbonoCapital = Number(
      pagos.reduce(function (total, pagoActual) {
        return total + pagoActual.abonoCapital;
      }, 0)
    );
    this.setState({
      totalAbonoCapitalPagos: totalAbonoCapital,
    });
  };

  /**
   * Este metodo se utiliza para obtener la fecha y formatearla para
   * escribirla en la tabla que se exporta del estado de cuenta de cada usuario-plan.
   */
  setCurrentDate = () => {
    let meses = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    let date = new Date();
    let current =
      date.getDate() + ` de ${meses[date.getMonth()]} de ` + date.getFullYear();
    this.setState({
      currentDate: current,
    });
  };

  /**
   * Este metodo muestra el modal que permite establecer un estado especifico al plan
   */
  changeEstadoPlan = () => {
    this.setState({
      show: true,
    });
  };

  /**
   * Este metodo cambia el estado de plan a VALIDO, INVALIDO, PENDIENTE
   * segun el boton clickeado en el modal
   */
  handleChangeEstadoPlan = (e) => {
    let newState = e.target.id; // Se obtiene el nuevo estado de plan VALIDO, INVALIDO, PENDIENTE
    client
      .mutate({
        mutation: UPDATESTATEPLAN,
        variables: {
          id: this.props.user.user._id,
          idPlan: this.props.user.plan.idPlan,
          state: newState,
        },
      })
      .then((result) => {
        this.props.user.plan.estado = newState;
        this.handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Se cierra el modal que muestra los estados a los cuales
   * se puede actualizar el plan
   */
  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  /**
   * Se cierra el modal que muestra los mensajes de error o de exito
   */
  handleCloseMessage = () => {
    this.setState({
      showMessage: false,
    });
  };

  /**
   * Se cierra el modal que muestra los Warnings sobre
   * eliminar un plan de la base de datos
   */
  handleCloseDelete = () => {
    this.setState({
      showDelete: false,
    });
  };

  /**
   * Metodo para devolverse o re-renderizar el componente donde muestra
   * la tabla de todos los usuarios y sus planes
   */
  goBack = () => {
    const element = <TableConsultas />;
    ReactDOM.render(element, document.getElementById('rootConsultas')); // Se renderiza el componente BodyConsultas en el elemento HTML 'rootConsultas'
  };

  /**
   * Metodo toggle para ocultar tabla de cotizacion y
   * mostrar la tabla de pagos
   */
  handleSeePayments = () => {
    document.getElementById('dataTable2').className =
      'table table-striped table-bordered invisible';
    document.getElementById('pagosTableFull').className =
      'table table-striped table-bordered';
    document.getElementById('btnSeePayment').className =
      'btn btn-primary mb-2 spaced invisible';
    document.getElementById('btnSeeTable').className =
      'btn btn-primary mb-2 spaced';
  };

  /**
   * Metodo toggle para ocultar tabla de pagos y
   * mostrar la tabla de cotizacion
   */
  handleSeeTable = () => {
    document.getElementById('dataTable2').className =
      'table table-striped table-bordered';
    document.getElementById('pagosTableFull').className =
      'table table-striped table-bordered invisible';
    document.getElementById('btnSeeTable').className =
      'btn btn-primary mb-2 spaced invisible';
    document.getElementById('btnSeePayment').className =
      'btn btn-primary mb-2 spaced';
  };

  /**
   * Metodo para mostar los warnings en un modal sobre
   * borrar un plan en la base de datos
   */
  handleDelete = () => {
    this.setState({
      showDelete: true,
    });
  };

  /**
   * Metodo para eliminar la tabla correspondiente al plan actual
   * en la base de datos
   */
  deleteTable = () => {
    this.handleCloseDelete(); // Se cierra el modal de los warnings de delete
    document.getElementById('loadingButton').className = 'btn btn-danger';
    document.getElementById('delete').className = 'btn btn-danger invisible';
    client // Se empieza la mutacion de GRAPHQL
      .mutate({
        mutation: DELETETABLE,
        variables: { idTable: this.state.tableId },
      })
      .then((table) => {
        this.deletePayments(); // Despues de borrar la tabla exitosamente se continua con la siguiente operacion
      })
      .catch((err) => {
        // Si ocurre un error se despliega un modal mostrando la causa de por que se ha detenido la operacion
        this.setState({
          showMessage: true,
          titleModal: <div className="center wrong" />,
          messageModal: 'Error al eliminar la tabla de la base de datos',
        });
        console.log(err);
        document.getElementById('loadingButton').className =
          'btn btn-danger invisible';
        document.getElementById('delete').className = 'btn btn-danger';
      });
  };

  /**
   * Metodo para eliminar los pagos correspondiente al plan actual
   * en la base de datos
   */
  deletePayments = () => {
    client
      .mutate({
        mutation: DELETEPAYMENTS,
        variables: { ids: this.state.paymentId },
      })
      .then((payments) => {
        this.deletePlanUser();
      })
      .catch((err) => {
        this.setState({
          showMessage: true,
          titleModal: <div className="center wrong" />,
          messageModal: 'Error al eliminar los pagos de la base de datos',
        });
        console.log(err);
        document.getElementById('loadingButton').className =
          'btn btn-danger invisible';
        document.getElementById('upload').className = 'btn btn-danger';
      });
  };

  /**
   * Metodo para eliminar el plan actual de la base de datos
   */
  deletePlan = () => {
    client
      .mutate({ mutation: DELETEPLAN, variables: { id: this.state.planId } })
      .then((plan) => {
        this.setState({
          showMessage: true,
          titleModal: <div className="center success" />,
          messageModal: 'Plan Eliminado exitosamente',
        });
        document.getElementById('loadingButton').className =
          'btn btn-danger invisible';
        document.getElementById('delete').className = 'btn btn-danger ';
        this.goBack();
      })
      .catch((err) => {
        this.setState({
          showMessage: true,
          titleModal: <div className="center wrong" />,
          messageModal: 'Error al eliminar el plan de la base de datos',
        });
        console.log(err);
        document.getElementById('loadingButton').className =
          'btn btn-danger  invisible';
        document.getElementById('upload').className = 'btn btn-danger ';
      });
  };

  /**
   * Metodo para eliminar el plan del array del usuario de la base de datos
   */
  deletePlanUser = () => {
    let idUser = this.props.user.user._id;
    let idPlan = this.props.user.plan._id;
    let idPlanDelete = this.props.user.plan.idPlan;
    console.log(this.props.user);
    client
      .mutate({
        mutation: DELETEUSERPLAN,
        variables: { idUser: idUser, idPlan: idPlan, idPlanDelete },
      })
      .then((plan) => {
        this.deletePlan();
      })
      .catch((err) => {
        this.setState({
          showMessage: true,
          titleModal: <div className="center wrong" />,
          messageModal: 'Error al eliminar el plan del usuario',
        });
        console.log(err);
        document.getElementById('loadingButton').className =
          'btn btn-danger invisible';
        document.getElementById('upload').className = 'btn btn-danger ';
      });
  };

  handleShowDownload = () => {
    if (this.state.userType === 'Admin' || this.state.permisos.descargar) {
      return (
        <ReactToExcel
          className="btn btn-primary mb-2 spaced"
          table="reporte"
          filename={`${this.props.user.user.nombre}-${this.props.user.user.cedula}`}
          sheet="sheet 1"
          buttonText="Exportar Estado De Cuenta"
        />
      );
    }
  };

  handleShowDelete = () => {
    if (this.state.userType === 'Admin' || this.state.permisos.eliminar) {
      return (
        <td>
          <button
            id="delete"
            className="btn btn-danger mb-2"
            data-target="#myModal"
            onClick={this.handleDelete}
          >
            Eliminar
          </button>
          <button
            id="loadingButton"
            className="btn btn-danger invisible"
            type="button"
            disabled
          >
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            {` Eliminando...`}
          </button>
        </td>
      );
    }
  };

  handleShowValidate = () => {
    if (this.state.userType === 'Admin' || this.state.permisos.validar) {
      return (
        <td>
          <button
            id="btnEstado"
            className="btn btn-primary mb-2"
            data-target="#myModal"
            onClick={this.changeEstadoPlan}
          >
            {this.props.user.plan.estado}
          </button>
        </td>
      );
    } else {
      return <td>{this.props.user.plan.estado}</td>;
    }
  };

  render() {
    let showDownload = this.handleShowDownload();
    let showDelete = this.handleShowDelete();
    let showValidate = this.handleShowValidate();
    let bodyTablePlan =
      this.state.contentTable.length > 0
        ? this.state.contentTable.map((row) => {
            return (
              <tr>
                <td>{row.cuota}</td>
                <td>{row.tipo}</td>
                <td className="text-capitalize">{row.fecha}</td>
                {/* {numeral(product.price).format('$0,0.00')} */}
                <td>{numeral(row.valorTeoricoCuota).format('$ 0,0')}</td>
                <td>{numeral(row.abonoCapitalTeorico).format('$ 0,0')}</td>
                <td>{numeral(row.abonoInteresTeorico).format('$ 0,0')}</td>
                <td>{numeral(row.saldoRestanteTeorico).format('$ 0,0')}</td>
                <td>{numeral(row.pagoTotal).format('$ 0,0')}</td>
                <td>{numeral(row.abonoInteres).format('$ 0,0')}</td>
                <td>{numeral(row.abonoCapital).format('$ 0,0')}</td>
                <td>{numeral(row.saldoRestante).format('$ 0,0')}</td>
                <td>{row.estado}</td>
                <td>{numeral(row.interesMora).format('$ 0,0')}</td>
                <td>{numeral(row.diasMora).format('$ 0,0')}</td>
              </tr>
            );
          })
        : null;

    let bodyPaymentPlan =
      this.state.contentPayment.length > 0
        ? this.state.contentPayment.map((pay) => {
            return (
              <tr>
                <td>{pay.cuota}</td>
                <td className="text-capitalize">{pay.fecha}</td>
                <td>{numeral(pay.valorPago).format('$ 0,0')}</td>
                <td>{numeral(pay.abonoCapital).format('$ 0,0')}</td>
                <td>{numeral(pay.abonoInteres).format('$ 0,0')}</td>
                <td>{numeral(pay.interesMora).format('$ 0,0')}</td>
                <td>{pay.diasMora}</td>
                <td>{pay.observaciones}</td>
              </tr>
            );
          })
        : null;

    const getFecha = (fecha) => {
      const date = fecha.split('-');
      let stringDate = `${date[2]}/${date[1]}/${date[0]}`;
      return stringDate;
    };

    let bodyTableExport =
      this.state.contentTable.length > 0
        ? this.state.contentTable.map((row) => {
            return (
              <tr>
                <td>{row.cuota}</td>
                <td>{row.tipo}</td>
                <td>{getFecha(row.fecha).toString()}</td>
                <td>{row.valorTeoricoCuota}</td>
                <td>{row.pagoTotal}</td>
                <td>{row.abonoCapital}</td>
                <td>{row.abonoInteres}</td>
                {/* <td>{row.valorTeoricoCuota - row.pagoTotal}</td> */}
                {/* <td>{row.saldoRestanteTeorico}</td> */}
                <td>{row.saldoRestante}</td>
                <td>{row.interesMora}</td>
                <td>{row.diasMora}</td>
              </tr>
            );
          })
        : null;
    let j = 0;
    let bodyPaymentExport =
      this.state.contentPayment.length > 0
        ? this.state.contentPayment.map((pay) => {
            j++;
            return (
              <tr>
                <td>{j}</td>
                <td colSpan={2}>{pay.fecha}</td>
                <td>{pay.cuota}</td>
                <td>{pay.valorPago}</td>
                <td>{pay.abonoCapital}</td>
                <td>{pay.abonoInteres}</td>
                <td>{pay.interesMora}</td>
                <td>{pay.diasMora}</td>
              </tr>
            );
          })
        : null;

    return (
      <div id="tablaPersonal" className="table-responsive">
        <button
          id="btnEstado"
          className="btn btn-primary mb-2 spaced"
          onClick={this.goBack}
        >
          <FontAwesomeIcon icon="chevron-left" />
        </button>
        <div id="datosPlan">
          <table className="table table-sm">
            <tbody>
              <tr>
                <th>Cedula:</th>
                <td id="cedula">{this.props.user.user.cedula}</td>
                <th>Valor total inmueble:</th>
                <td>{numeral(this.props.plan.valorTotal).format('$ 0,0')}</td>
                <th>Fecha realizacion Plan:</th>
                <td>{this.props.plan.fechaRealizacionPlan}</td>
              </tr>
              <tr>
                <th>Nombre:</th>
                <td>{this.props.user.user.nombre}</td>
                <th>Tasa Interes %:</th>
                <td>{this.props.plan.tasaInteres}</td>
                <th>Fecha de inicio:</th>
                <td>{this.props.plan.fechaInicialPlan}</td>
              </tr>
              <tr>
                <th>Celular:</th>
                <td>{this.props.user.user.celular}</td>
                <th>Valor Financiado:</th>
                <td>
                  {numeral(this.props.plan.valorAFinanciar).format('$ 0,0')}
                </td>
                <th>#Cuotas:</th>
                <td>{this.props.plan.numeroCuotas}</td>
              </tr>
              <tr>
                <th>Correo:</th>
                <td>{this.props.user.user.correo}</td>
                <th>% Financiado:</th>
                <td>{this.props.plan.porcentajeAFinanciar}</td>
                <th>Pago Total:</th>
                <td>{numeral(this.props.plan.pagoTotal).format('$ 0,0')}</td>
              </tr>
              <tr>
                <th>Direccion:</th>
                <td>{this.props.user.user.direccion}</td>
                <th>Periodicidad:</th>
                <td>{this.props.plan.periodicidad}</td>
                <th>Saldo Restante:</th>
                <td>
                  {numeral(this.props.plan.valorTotal - this.state.totalAbonoCapitalPagos).format('$ 0,0')}
                </td>
              </tr>
              <tr>
                <th>Lote #</th>
                <td>{this.props.user.plan.descripcion}</td>
                <th>Valor Con Intereses</th>
                <td>{numeral(this.props.plan.valorFinal).format('$ 0,0')}</td>
                <th>$ Cuota Inicial:</th>
                <td>
                  {numeral(this.props.plan.valorCuotaInicial).format('$ 0,0')}
                </td>
              </tr>
              <tr>
                <th>Vendedor:</th>
                <td>{this.props.plan.vendedor}</td>
                <th>Observaciones</th>
                <td colSpan={3}>{this.props.plan.observaciones}</td>
              </tr>
              <tr>
                <th>Estado</th>
                {/* <td></td> */}
                {showValidate}
                {/* { this.state.userType === 'Admin' ? <td><button id='btnEstado' className='btn btn-primary mb-2' data-target='#myModal' onClick={this.changeEstadoPlan}>{this.props.user.plan.estado}</button></td>
                  : <td>{this.props.user.plan.estado}</td>
                } */}
                <th />
                {/* <td></td> */}
                <td />
                <th />
                {/* <td></td> */}
                {showDelete}
                {/* { this.state.userType === 'Admin'
                  ? <React.Fragment>
                    <td>
                      <button id='delete' className='btn btn-danger mb-2' data-target='#myModal' onClick={this.handleDelete}>Eliminar</button>
                      <button id='loadingButton' className='btn btn-danger invisible' type='button' disabled>
                        <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' />
                        {` Eliminando...`}
                      </button>
                    </td>
                  </React.Fragment>
                  : null
                } */}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="centeredButtons">
          <button
            id="btnSeeTable"
            className="btn btn-primary mb-2 spaced invisible"
            onClick={this.handleSeeTable}
          >
            Ver Plan Cotizacion
          </button>
          <button
            id="btnSeePayment"
            className="btn btn-primary mb-2 spaced"
            onClick={this.handleSeePayments}
          >
            Ver todos los Pagos
          </button>
          {showDownload}
        </div>

        <div className="table-responsive" id="tablaPersonal">
          <table className="table table-striped table-bordered" id="dataTable2">
            <thead>
              <tr>
                {/* <th className='tenWidth' id='' /> */}
                <th>Cuota</th>
                <th>Tipo</th>
                <th>Fecha Pactada</th>
                <th>Valor Pactado</th>
                <th>Ab Capital Teorico</th>
                <th>Ab Interes Teorico</th>
                <th>Saldo Capital Teorico</th>
                <th>Pago Total</th>
                <th>Ab Interes</th>
                <th>Ab Capital</th>
                <th>Saldo Capital</th>
                <th>Estado</th>
                <th>Interes Mora</th>
                <th>Dias Mora</th>
              </tr>
            </thead>
            <tbody id="bodytable2">{bodyTablePlan}</tbody>
          </table>

          <table
            className="table table-striped table-bordered invisible"
            id="pagosTableFull"
          >
            <thead id="headPagos">
              <tr>
                <th>Cuota</th>
                <th>Fecha Pago</th>
                <th>Valor Pago</th>
                <th>Abono Capital</th>
                <th>Abono Interes</th>
                <th>Interes Mora</th>
                <th>Dias Mora</th>
                <th>Observaciones</th>
              </tr>
            </thead>
            <tbody id="bodyPagos">{bodyPaymentPlan}</tbody>
          </table>
        </div>

        <div id="divExportar" className="invisible">
          <table id="reporte" className="table-bordered">
            <tbody>
              <tr>
                <td colSpan={3} rowSpan={3} />
                <td />
                <th colSpan={3} className="title">
                  LAR PROYECTOS SAS
                </th>
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td />
                <th colSpan={3} className="title">
                  ESTADO DE CUENTA
                </th>
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td />
                <th colSpan={3} className="title">
                  {JSON.parse(sessionStorage.getItem('configuracion')).nombre}
                </th>
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td />
                <td />
                <td />
                <td />
                <th colSpan={3} className="title">
                  {this.state.currentDate}
                </th>
                <td />
                <td />
                <td />
              </tr>

              <tr>
                <td style={{ width: '0.8cm' }} />
                <td style={{ width: '0.8cm' }} />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td style={{ width: '0.8cm' }} />
              </tr>
              <tr className="border_bottom">
                <th colSpan={3} style={{ borderBottom: '1px solid black' }}>
                  Cliente
                </th>
                <th style={{ borderBottom: '1px solid black' }}>Cedula</th>
                <th style={{ borderBottom: '1px solid black' }}>Celular</th>
                <td style={{ borderBottom: '1px solid black' }} />
                <th style={{ borderBottom: '1px solid black' }}>Direccion</th>
                <td style={{ borderBottom: '1px solid black' }} />
                <td style={{ borderBottom: '1px solid black' }} />
                <td style={{ borderBottom: '1px solid black' }} />
              </tr>
              <tr>
                <td colSpan={3} style={{ borderBottom: '1px solid black' }}>
                  {this.props.user.user.nombre}
                </td>
                <td style={{ borderBottom: '1px solid black' }}>
                  {this.props.user.user.cedula}
                </td>
                <td style={{ borderBottom: '1px solid black' }}>
                  {this.props.user.user.celular}
                </td>
                <td style={{ borderBottom: '1px solid black' }} />
                <td colSpan={4} style={{ borderBottom: '1px solid black' }}>
                  {this.props.user.user.direccion}
                </td>
              </tr>
              <tr>
                <td colSpan={10} style={{ borderBottom: '1px solid black' }} />
              </tr>

              <tr>
                <th colSpan={3} style={{ borderBottom: '1px solid black' }}>
                  Fecha De Negocio
                </th>
                <th colSpan={1} style={{ borderBottom: '1px solid black' }}>
                  Inmueble
                </th>
                <th colSpan={2} style={{ borderBottom: '1px solid black' }}>
                  Valor Venta
                </th>
                <th colSpan={2} style={{ borderBottom: '1px solid black' }}>
                  Pagado
                </th>
                <th colSpan={2} style={{ borderBottom: '1px solid black' }}>
                  Saldo
                </th>
              </tr>
              <tr>
                <td colSpan={3}>{this.props.plan.fechaRealizacionPlan}</td>
                <td colSpan={1}>{this.props.user.plan.descripcion}</td>
                <td colSpan={2}>
                  {numeral(this.props.plan.valorTotal).format('$ 0,0')}
                </td>
                <td colSpan={2}>
                  {numeral(this.props.plan.pagoTotal).format('$ 0,0')}
                </td>
                <td colSpan={2}>
                  {numeral(this.props.plan.valorTotal - this.state.totalAbonoCapitalPagos).format('$ 0,0')}
                </td>
              </tr>

              <tr>
                <td colSpan={3} />
                <td colSpan={3} />
                {/* <th colSpan={1} >Con intereses</th>
                <td colSpan={2} >{this.props.plan.valorFinal}</td> */}
                <td colSpan={2} />
                <td colSpan={2} />
              </tr>

              <tr>
                <td colSpan={10} style={{ borderBottom: '1px solid black' }} />
              </tr>
              <tr>
                <th
                  colSpan={10}
                  style={{
                    borderBottom: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  Acuerdo Pagos
                </th>
              </tr>
              <tr>
                <th style={{ borderBottom: '1px solid black' }}>#</th>
                <th style={{ borderBottom: '1px solid black' }}>Tipo</th>
                <th style={{ borderBottom: '1px solid black' }}>
                  Fecha Pactada
                </th>
                <th style={{ borderBottom: '1px solid black' }}>Valor</th>
                <th style={{ borderBottom: '1px solid black' }}>Pagado</th>
                <th style={{ borderBottom: '1px solid black' }}>
                  Abono Capital
                </th>
                <th style={{ borderBottom: '1px solid black' }}>
                  Abono Intereses
                </th>
                <th style={{ borderBottom: '1px solid black' }}>Saldo</th>
                <th style={{ borderBottom: '1px solid black' }}>
                  Interes Mora
                </th>
                <th style={{ borderBottom: '1px solid black' }}>Dias</th>
              </tr>
              {bodyTableExport}
              <tr>
                <th style={{ borderBottom: '1px solid black' }} />
                <th style={{ borderBottom: '1px solid black' }} />
                <th style={{ borderBottom: '1px solid black' }} />
                <th style={{ borderBottom: '1px solid black' }} />
                <th style={{ borderBottom: '1px solid black' }} />
                <th style={{ borderBottom: '1px solid black' }} />
                <th style={{ borderBottom: '1px solid black' }} />
                <th style={{ borderBottom: '1px solid black' }} />
                <th style={{ borderBottom: '1px solid black' }} />
                <th style={{ borderBottom: '1px solid black' }} />
              </tr>
              <tr>
                <th
                  colSpan={10}
                  style={{
                    borderBottom: '1px solid black',
                    textAlign: 'center',
                  }}
                />
              </tr>
              <tr>
                <th
                  colSpan={10}
                  style={{
                    borderBottom: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  Pagos Realizados
                </th>
              </tr>
              <tr>
                <th style={{ borderBottom: '1px solid black' }}>#</th>
                <th colSpan={2} style={{ borderBottom: '1px solid black' }}>
                  Fecha
                </th>
                <th style={{ borderBottom: '1px solid black' }}>Cuota</th>
                <th style={{ borderBottom: '1px solid black' }}>Valor Pago</th>
                <th style={{ borderBottom: '1px solid black' }}>
                  Abono Capital
                </th>
                <th style={{ borderBottom: '1px solid black' }}>
                  Abono Intereses
                </th>
                <th style={{ borderBottom: '1px solid black' }}>
                  Interes Mora
                </th>
                <th style={{ borderBottom: '1px solid black' }}>Dias</th>
                <th style={{ borderBottom: '1px solid black' }} />
              </tr>
              {bodyPaymentExport}
              <tr>
                <th
                  colSpan={10}
                  style={{
                    borderBottom: '1px solid black',
                    textAlign: 'center',
                  }}
                />
              </tr>
              <tr>
                <th colSpan={10} />
              </tr>
              <tr>
                <th colSpan={10}>SUJETO A VERIFICACIÓN DE ÁREA CONTABLE</th>
              </tr>
            </tbody>
          </table>
        </div>

        <Modal isOpen={this.state.show} toggle={this.handleClose}>
          <ModalHeader closeButton>Cambiar estado</ModalHeader>
          <ModalBody>{`Estado Actual del Plan: ${this.props.user.plan.estado}`}</ModalBody>
          <ModalFooter>
            <button
              type="button"
              id="Invalido"
              className="btn btn-secondary"
              onClick={this.handleChangeEstadoPlan}
            >
              Invalidar
            </button>
            <button
              type="button"
              id="Pendiente"
              className="btn btn-warning"
              onClick={this.handleChangeEstadoPlan}
            >
              Pendiente
            </button>
            <button
              type="button"
              id="Valido"
              className="btn btn-success"
              onClick={this.handleChangeEstadoPlan}
            >
              Validar
            </button>
            <button
              type="button"
              className="btn btn-default"
              onClick={this.handleClose}
            >
              Cancelar
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.showDelete} toggle={this.handleClose}>
          <ModalHeader closeButton>
            <>{` Eliminar Plan`}</>
          </ModalHeader>
          <ModalBody>{`Esta seguro de eliminar este plan? Si lo elimina, no podra recuperarse, se eliminaran todos sus datos y pagos realizados anteriormente.`}</ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-danger"
              onClick={this.deleteTable}
            >
              Eliminar
            </button>
            <button
              type="button"
              className="btn btn-default"
              onClick={this.handleCloseDelete}
            >
              Cancelar
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.showMessage} toggle={this.handleCloseMessage}>
          <ModalHeader>
            <>{this.state.titleModal}</>
          </ModalHeader>
          <ModalBody>{this.state.messageModal}</ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-default"
              onClick={this.handleCloseMessage}
            >
              Cerrar
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
