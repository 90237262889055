// @ts-nocheck
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import Navbar from '../../../containers/NavBar/NavBar';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddIcon from '@material-ui/icons/Add';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PeopleIcon from '@material-ui/icons/People';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Col, Container, Row } from 'reactstrap';
import './index.css';
import axiosInstance from 'utils/database/api-leads';

const Leads = () => {
  const navigate = useNavigate();
  const [permisos, setPermisos] = useState({});
  const [idRol, setIdRol] = useState();
  const rolesReasignar = [7, 4]; // Lider y Coordinador

  useEffect(() => {
    let token = sessionStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      let userLocal = JSON.parse(sessionStorage.getItem('user'));
      getPermisos(userLocal._id);
      setIdRol(userLocal.rol.idRol);
    }
  }, [navigate]);

  const getPermisos = (idUser) => {
    axiosInstance.get(`/users/permisos/${idUser}`).then((resp) => {
      const info = resp.data;
      setPermisos(info.data?.permisos?.leads);
    });
  };

  return (
    <>
      <Navbar></Navbar>
      <Container fluid>
        <Row className="justify-content-center">
          <Col style={{ textAlign: 'center', fontFamily: 'arial' }}>
            <h1>Modulo de Leads</h1>
            <h6>Bienvenido al modulo para la adminstracion de Leads</h6>
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: 'center', padding: '20px' }}>
            <Button
              hidden={!permisos.cargarLeads}
              className="option"
              variant="outlined"
              startIcon={<CloudUploadIcon color="primary" />}
            >
              <Link to={'load'}>Cargar Leads</Link>
            </Button>
            <Button
              hidden={!permisos.asignarEspecialista &&
                !permisos.cargarLeads &&
                !permisos.crearLead &&
                !permisos.gestionar &&
                !permisos.informes} // Si no tiene ningun permiso en leads entonces no se muestra el boton
              className="option"
              variant="outlined"
              startIcon={<PeopleIcon color="primary" />}
            >
              <Link to={''}>Ver Leads</Link>
            </Button>
            <Button
              hidden={!permisos.crearLead}
              className="option"
              variant="outlined"
              startIcon={<AddIcon color="primary" />}
            >
              <Link to={'create'}>Crear Lead</Link>
            </Button>
            <Button
              hidden={!permisos.asignarEspecialista}
              className="option"
              variant="outlined"
              startIcon={<AssignmentIcon color="primary" />}
            >
              <Link to={'assign-specialist'}>Asignar Especialista</Link>
            </Button>
            <Button
              hidden={!rolesReasignar.includes(idRol)}
              className="option"
              variant="outlined"
              startIcon={<ChangeCircleIcon color="primary" />}
            >
              <Link to={'change-specialist'}>ReAsignar Especialista</Link>
            </Button>
            <Button
              hidden={!permisos.gestionesProgramadas}
              className="option"
              variant="outlined"
              startIcon={<CalendarMonthIcon color="primary" />}
            >
              <Link to={'scheduled-managements'}>Gestiones Programadas</Link>
            </Button>
          </Col>
        </Row>
        <hr />
        <Outlet />
      </Container>
    </>
  );
};

export default Leads;
