import XLSX from 'xlsx';

const handleDownload = (data, columns, fileName) => {
  const ws = XLSX.utils.json_to_sheet(data, {
    header: columns.map((col) => col.accessor),
  });
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

const handleDownloadDataDetail = (data, columns, fileName) => {
  console.log(columns);
  console.log(data);

  let header = [];

  let d = pivot(data);

  const ws = XLSX.utils.json_to_sheet(d, {
    header,
  });
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

function pivot(arr) {
  function setValue(a, path, val) {
    if (Object(val) !== val) {
      let column = path.toString().toUpperCase();
      a[column] = val;
    } else {
      for (var key in val) {
        setValue(a, key === '0' ? path : path.concat(key), val[key]);
      }
    }
    return a;
  }

  var result = arr.map((obj) => setValue({}, [], obj));

  return result;
}

export { handleDownload, handleDownloadDataDetail };
