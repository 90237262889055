// @ts-nocheck

import { Button } from '@mui/material';
import Navbar from 'components/containers/NavBar/NavBar';
import React, { useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import TableRowsIcon from '@mui/icons-material/TableRows';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Col, Container, Row } from 'reactstrap';
import { useEffect } from 'react';
import client from 'utils/database';
import { SEARCHUSERBYID } from 'utils/database/query/users';
import NoAuth from 'components/containers/NoAuth/NoAuth';
import Loading from 'components/containers/Loading/Loading';

const GestionesPersuasivas = () => {
  const navigate = useNavigate();
  const [permisos, setPermisos] = useState({
    verSeguimientos: false,
    crearSeguimiento: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let token = sessionStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      let userLocal = JSON.parse(sessionStorage.getItem('user'));
      getUserById(userLocal._id);
    }
  }, [navigate]);

  const getUserById = (id) => {
    setLoading(true);
    client
      .query({ query: SEARCHUSERBYID, variables: { id } })
      .then((result) => {
        setPermisos(result.data.SearchUserById.permisos.gestionesPersuasivas);
        setLoading(false);
      });
  };

  return (
    <>
      <Navbar></Navbar>
      <Container fluid>
        <Row className="justify-content-center">
          <Col style={{ textAlign: 'center', fontFamily: 'arial' }}>
            <h1>Gestiones Persuasivas</h1>
            <h6>
              En este Modulo se puede Crear y Ver Seguimientos de los Clientes.
            </h6>
            <Col style={{ textAlign: 'center', padding: '20px' }}>
              <Button
                style={{
                  display:
                    permisos.verSeguimientos || permisos.crearSeguimiento
                      ? ''
                      : 'none',
                }}
                className="option"
                variant="outlined"
                startIcon={<TableRowsIcon color="primary" />}
              >
                <Link to={''}>Ver Seguimientos</Link>
              </Button>
              <Button
                style={{ display: !permisos.crearSeguimiento ? 'none' : '' }}
                className="option"
                variant="outlined"
                startIcon={<AddCircleIcon color="primary" />}
              >
                <Link to={'crear'}>Crear Seguimiento</Link>
              </Button>
            </Col>
          </Col>
        </Row>
        <hr />
        {loading ? (
          <Loading />
        ) : (
          <>
            {!permisos.verSeguimientos && !permisos.crearSeguimiento ? (
              <NoAuth />
            ) : (
              <Outlet />
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default GestionesPersuasivas;
