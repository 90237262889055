import gql from 'graphql-tag';

export const SEARCHPAYMENTBYID = gql`
  query SEARCHPAYMENT($idPayment: ID) {
    SearchPaymentById(id: $idPayment) {
      _id
      valorPago
      abonoCapital
      observaciones
      abonoInteres
      fecha
      diasMora
      interesMora
      cuota
    }
  }
`;

export const SEARCHPAYMENTBYIDARRAY = gql`
  query SEARCHPAYMENT($idPayment: [ID]) {
    SearchPaymentArray(id: $idPayment) {
      _id
      valorPago
      abonoCapital
      observaciones
      abonoInteres
      fecha
      diasMora
      interesMora
      cuota
    }
  }
`;
