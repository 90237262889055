// @ts-nocheck
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/es';
import { makeStyles } from '@material-ui/core/styles';

import { handleDownloadDataDetail } from './xlsx-download';
import client from 'utils/database';
import { PROYECCIONREPORT } from 'utils/database/query/reports';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#59B6F3',
  },
  cell: {
    fontWeight: 'bold',
    textAlign: 'center',
    border: '1px solid',
  },
  cellBody: {
    border: '1px solid',
  },
  carga: {
    align: 'center',
  },
  date: {
    backgroundColor: 'red !important'
  }
});

export const Proyeccion = ({ proyecto, show, filter }) => {
  const [fechaFinal, setFechaFinal] = useState(null);
  const [data, setData] = useState([]);
  const [header, setHeader] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCapital, setShowCapital] = useState(true);
  const [showInteres, setShowInteres] = useState(true);

  const consultarProyeccion = () => {
    var dateF = fechaFinal.toISOString();
    dateF = dateF.substring(0, 10);
    setLoading(true);
    client
      .query({
        query: PROYECCIONREPORT,
        variables: { proyecto, fechaFinal: dateF, showCapital, showInteres },
      })
      .then((result) => {
        setLoading(false);
        let reporte = result.data.ReporteProyeccion;

        let columns = [];

        reporte.header.map((col, i) => {
          if (i > 1) {
            columns.push({
              Header: `${col}`,
              style: { textAlign: 'center'},
              columns: setColumns(col)
            });
          } else {
            columns.push({
              Header: col.toLocaleUpperCase(),
              accessor: col.toLocaleLowerCase(),
            });
          }
          return 0;
        });
        setHeader(columns);
        setData(reporte.data);
      });
  };

  const setColumns = (col) => {
    if (showCapital && !showInteres) {
      return [
        {
          Header: 'CAPITAL',
          accessor: `${col.toLowerCase()}.capital`,
        }
      ]
    } else if (!showCapital && showInteres) {
      return [
        {
          Header: 'INTERES',
          accessor: `${col.toLowerCase()}.interes`,
        },
      ]
    } else {
      return [
        {
          Header: 'CAPITAL',
          accessor: `${col.toLowerCase()}.capital`,
        },
        {
          Header: 'INTERES',
          accessor: `${col.toLowerCase()}.interes`,
        },
      ]
    }
  }

  return (
    <>
      {show ? (
        <TableData
          data={data}
          columns={header}
          consultar={consultarProyeccion}
          fechaFinal={fechaFinal}
          setFechaFinal={setFechaFinal}
          filter={filter}
          loading={loading}
          showCapital={showCapital}
          setShowCapital={setShowCapital}
          showInteres={showInteres}
          setShowInteres={setShowInteres}
        />
      ) : null}
    </>
  );
};

const TableData = ({ data, columns, consultar, fechaFinal, setFechaFinal, filter, loading, showCapital, setShowCapital, showInteres, setShowInteres }) => {
  const classes = useStyles();

  const handleChangeShowCapital = () => {
    setShowCapital(!showCapital);
  };

  const handleChangeShowInteres = () => {
    setShowInteres(!showInteres);
  };

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
    setGlobalFilter,
  } = useTable(
    {
      data,
      columns,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const startDownload = () => {
    handleDownloadDataDetail(data, columns, 'Proyeccion');
  };

  useEffect(() => {
    setGlobalFilter(filter);
  }, [data, setGlobalFilter, filter]);

  return (
    <>
      <hr />
      {loading ? (
        <CircularProgress className={classes.carga} />
      ) : (
        <>
          <Row>
            <Col xs="3">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale} className={classes.date}>
                <KeyboardDatePicker
                  variant="inline"
                  InputProps={{ readOnly: true }}
                  format="dd-MM-yyyy"
                  margin="normal"
                  label="Seleccione la fecha final"
                  value={fechaFinal}
                  onChange={(date) => {
                    setFechaFinal(date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  disablePast
                />
              </MuiPickersUtilsProvider>
            </Col>
            <Col xs="2" size='5'>
              <div>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      control={<Checkbox checked={showCapital} onChange={handleChangeShowCapital} />}
                      label="Capital"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={showInteres} onChange={handleChangeShowInteres} />}
                      label="Interes"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </Col>
            <Col>
              <Button
                color="primary"
                className="w-90"
                onClick={consultar}
                disabled={fechaFinal === null || (!showInteres && !showCapital)}
              >
                <FontAwesomeIcon
                  icon={['fas', 'sync-alt']}
                  color="white"
                  className="mr-1"
                />
                Generar tabla
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={{ size: 2 }}>
              <Button
                color="success"
                className="w-90"
                onClick={startDownload}
                disabled={columns.length === 0 || data.length === 0}
              >
                <FontAwesomeIcon
                  icon={['far', 'file-excel']}
                  color="white"
                  className="mr-1"
                />
                Descargar
              </Button>
            </Col>
          </Row>
          <br />
          <table
            {...getTableProps()}
            className="table table-bordered main--table"
          >
            <thead className={classes.container}>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} className={classes.cell}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={classes.cellBody}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {columns.length > 0 || data.length > 0 ? (
            <Pagination
              previousPage={previousPage}
              canPreviousPage={canPreviousPage}
              pageIndex={pageIndex}
              nextPage={nextPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
            />
          ) : null}
        </>
      )}
    </>
  );
};

const Pagination = ({
  previousPage,
  canPreviousPage,
  pageIndex,
  nextPage,
  canNextPage,
  pageOptions,
}) => {
  return (
    <div className="pagination table--pag d-flex justify-content-end mr-5">
      <button
        type="button"
        className=" mr-2 main-table--page-index"
        onClick={previousPage}
        disabled={!canPreviousPage}
      >
        <FontAwesomeIcon icon="chevron-left" color="black" />
      </button>

      <span className="table--pag__page">{pageIndex + 1}</span>
      <button
        className=" ml-2 mr-2 main-table--page-index"
        type="button"
        onClick={nextPage}
        disabled={!canNextPage}
      >
        <FontAwesomeIcon icon="chevron-right" color="black" />
      </button>
      <span className="table--pag__length">de {pageOptions.length}</span>
    </div>
  );
};
