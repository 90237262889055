// @ts-nocheck
import 'assets/styles/styles.scss';
import 'numeral/locales';

import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHome,
  faTh,
  faCalculator,
  faTable,
  faMoneyCheckAlt,
  faDatabase,
  faCogs,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faSortUp,
  faSortDown,
  faSort,
  faSyncAlt,
  faSearch,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faEdit, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import App from 'app';
import numeral from 'numeral';
import Home from 'components/pages/Home/Home';
import Login from 'components/pages/Login';
import Leads from 'components/pages/Menu/Leads';
import LoadLeads from 'components/pages/Menu/Leads/LoadFile/LoadLeads';
import Projects from 'components/pages/Menu/Projects';
import ManageProject from 'components/pages/Menu/Projects/manage-projects';
import NewProject from 'components/pages/Menu/Projects/new-project';
import Dashboard from 'components/pages/DashboardItems';
import ManageUsers from 'components/pages/Menu/Users';
import { ResetEmailForm } from 'components/pages/ResetPassword/ResetEmailForm';
import Page404 from 'components/pages/404/NotFound';
import LeadsTable from 'components/pages/Menu/Leads/Table/Table';
import LeadCreate from 'components/pages/Menu/Leads/Create/LeadCreate';
import LeadDetail from 'components/pages/Menu/Leads/Detail/LeadDetail';
import LeadManage from 'components/pages/Menu/Leads/Manage/LeadManage';
import AssignSpecialist from 'components/pages/Menu/Leads/AssignSpecialist/LeadsSpecialist';
import ChangeSpecialist from 'components/pages/Menu/Leads/AssignSpecialist/ChangeSpecialist';
import GestionesPersuasivas from 'components/pages/Menu/GestionesPersuasivas/GestionesPersuasivas';
import TablaSeguimientos from 'components/pages/Menu/GestionesPersuasivas/Table/TablaSeguimientos';
import CrearSeguimiento from 'components/pages/Menu/GestionesPersuasivas/Crear/CrearSeguimiento';
import ScheduledManagements from 'components/pages/Menu/Leads/ScheduledManagements/ScheduledManagements';

numeral.locale('es');
library.add(
  faFileExcel,
  faEdit,
  faHome,
  faTh,
  faCalculator,
  faTable,
  faMoneyCheckAlt,
  faDatabase,
  faCogs,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faSortUp,
  faSortDown,
  faSort,
  faSyncAlt,
  faSearch,
  faSignOutAlt
);
// import * as serviceWorker from './serviceWorker'

//ReactDOM.render(<App />, document.getElementById('root'));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    {/*<Navbar></Navbar>*/}
    <ToastContainer position='bottom-right' />
    <Routes>
      <Route path="/" element={<App />} />
      <Route index element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/leads" element={<Leads />}>
        <Route index element={<LeadsTable />} />
        <Route path="load" element={<LoadLeads />} />
        <Route path="create" element={<LeadCreate />} />
        <Route path="detail/:id" element={<LeadDetail />} />
        <Route path="manage/:id" element={<LeadManage />} />
        <Route path="assign-specialist" element={<AssignSpecialist />} />
        <Route path="change-specialist" element={<ChangeSpecialist />} />
        <Route path="scheduled-managements" element={<ScheduledManagements />} />
      </Route>
      <Route path="/gestiones-persuasivas" element={<GestionesPersuasivas />}>
        <Route index element={<TablaSeguimientos />} />
        <Route path="crear" element={<CrearSeguimiento />} />
      </Route>
      <Route path="/projects" element={<Projects />} />
      <Route path="/projects-managment" element={<ManageProject />} />
      <Route path="/new-project" element={<NewProject />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/users-managment" element={<ManageUsers />} />
      <Route path="/reset-password" element={<ResetEmailForm />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  </BrowserRouter>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
