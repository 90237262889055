// @ts-nocheck
import {
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import { toast } from 'react-toastify';
import {
  DataGrid,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import axiosInstance from 'utils/database/api-leads';
import { Link } from 'react-router-dom';
import Loading from 'components/containers/Loading/Loading';
import moment from 'moment';

const ChangeSpecialist = () => {
  const [leads, setLeads] = useState([]);
  const [specialists, setSpecialists] = useState([]);
  const [specialist, setSpecialist] = useState('');
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: '_id',
      disableExport: true,
      filterable: false,
      headerName: 'ID',
      headerAlign: 'center',
      align: 'center',
      width: 70,
      hide: true,
    },
    {
      field: 'nombreCompleto',
      headerAlign: 'center',
      align: 'center',
      headerName: 'Nombre',
      width: 200,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      headerAlign: 'center',
      align: 'center',
      width: 200,
      valueGetter: ({ value }) => value && value.nombre,
    },
    {
      field: 'especialistaAsignado',
      headerName: 'Especialista Actual',
      headerAlign: 'center',
      align: 'center',
      width: 350,
      valueGetter: ({ value }) => value && value.nombre,
    },
    {
      field: 'fechaAsignacionEspecialista',
      headerName: 'Fecha Asig. Especialista',
      headerAlign: 'center',
      align: 'center',
      width: 300,
      valueFormatter: (params) =>
      params.value ? moment(params.value).format('DD/MM/YYYY hh:mm A') : null,
    },
  ];

  useEffect(() => {
    getSpecialists();
    getLeads();
  }, []);

  const getSpecialists = () => {
    axiosInstance
      .get(`/users/roles/especialistas`)
      .then((resp) => {
        const users = resp.data;
        setSpecialists(users.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const getLeads = () => {
    setLoading(true);
    axiosInstance
      .get(`/leads/specialist/yes`)
      .then((resp) => {
        const leadsDb = resp.data;
        setLeads(leadsDb.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };

  const handleChangeSpecialist = (event) => {
    setSpecialist(event.target.value);
  };

  const handleSelection = (rows) => {
    setSelectedLeads(rows);
  };

  const handleSubmit = () => {
    let data = {
      idEspecialista: specialist,
      listaIdLeads: selectedLeads,
    };
    if (specialist !== '' && selectedLeads.length > 0) {
      axiosInstance
        .post(`/leads/re-assign-specialist`, data)
        .then((res) => {
          setSpecialist('');
          setSelectedLeads([]);
          toast.success(res.data.message);
          getLeads();
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    } else {
      toast.info('Debe selecionar un especialista y al menos 1 lead');
    }
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Container style={{ textAlign: 'center', paddingBottom: 30 }}>
          <Grid>
            <h3>Cambio de especialista</h3>
            <br />
            <FormControl sx={{ m: 1, minWidth: 250, maxWidth: 300 }}>
              <TextField
                select
                value={specialist}
                onChange={handleChangeSpecialist}
                label="Especialista"
              >
                {specialists.map((user) => (
                  <MenuItem key={user._id} value={user._id}>
                    {user.nombre}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <br />
            <br />
          </Grid>
          <div style={{ height: 680 }}>
            <DataGrid
              rows={leads}
              // @ts-ignore
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              getRowId={(row) => row._id}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: CustomToolbar }}
              onSelectionModelChange={(newSelection) =>
                handleSelection(newSelection)
              }
            />
          </div>
          <br />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Reasignar
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="error"
            style={{ marginLeft: 10 }}
          >
            <Link style={{ color: 'white' }} to={'/leads'}>
              Cancelar
            </Link>
          </Button>
        </Container>
      )}
    </>
  );

}

export default ChangeSpecialist

