import gql from 'graphql-tag';

export const UPDATEUSER = gql`
  mutation UPDATEUSER($id: ID, $data: UpdateUserInput) {
    UpdateUserById(id: $id, data: $data) {
      _id
    }
  }
`;

export const UPDATEUSERCEDULA = gql`
  mutation UPDATEUSER($cedula: String, $updatedData: UpdateUserInput) {
    UpdateUser(cedula: $cedula, data: $updatedData) {
      _id
    }
  }
`;

export const CREATEUSER = gql`
  mutation CREATEUSER($data: CreateUserInput) {
    CreateUser(data: $data) {
      _id
    }
  }
`;

export const ADDUSERPLAN = gql`
  mutation AddUSerPlan($cedula: String, $datos: AddPlanInput) {
    AddPlan(cedula: $cedula, data: $datos) {
      _id
    }
  }
`;

export const UPLOADUSER = gql`
  mutation UPLOADUSER($datos: CreateUserInput) {
    CreateClient(data: $datos) {
      _id
    }
  }
`;

export const CHECKUSER = gql`
  query CheckUser($datos: SearchUserInput) {
    SearchUserByParam(data: $datos) {
      _id
      nombre
      cedula
      planes {
        idPlan
        estado
        descripcion
      }
    }
  }
`;
