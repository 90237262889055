// @ts-nocheck
import React, { Component, useMemo, useEffect } from 'react';
import Navbar from '../../../containers/NavBar/NavBar';
import { useTable } from 'react-table';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import client from 'utils/database';
import { PROJECTS, SEARCHPROJECTBYNAME } from 'utils/database/query/projects';
import { DELETEPROJECT, UPDATEPROJECT } from 'utils/database/mutation/projects';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ToLogin from 'utils/ToLogin/ToLogin';
import { SEARCHUSERBYID } from 'utils/database/query/users';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { toast } from 'react-toastify';

export default class ManageProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      id: '',
      nombre: '',
      numLotes: 0,
      tipoInmuebles: [],
      tasaInteres: 0,
      show: false,
      modalEdit: false,
      loading: false,
      messageModal: '',
      titleModal: <div className="center success" />,
      logged: false,
      permisos: {},
      openDialogEliminarProyecto: false,
      newInmueble: {
        tipo: '',
        maximoCuotasInicial: '',
        maxFinanciacion: '',
        __typename: 'typeInmueble',
      },
      existeInmueble: false,
    };
  }

  componentDidMount() {
    let token = sessionStorage.getItem('token');
    if (!token) {
      this.setState({ logged: false });
    } else {
      this.setState({ logged: true });
      let userLocal = JSON.parse(sessionStorage.getItem('user'));
      this.getUserById(userLocal._id);
    }
  }

  getUserById = (id) => {
    client
      .query({ query: SEARCHUSERBYID, variables: { id } })
      .then((result) => {
        this.setState({
          permisos: result.data.SearchUserById.permisos.proyectos,
        });
        this.loadProjects();
      });
  };

  loadProjects = () => {
    client.query({ query: PROJECTS }).then((result) => {
      this.setState({
        projects: result.data.Projects,
      });
    });
  };

  handleEditProject = (id, nombre) => {
    let UserID = id;
    let keyTipo = 'tipo';
    let keyFinan = 'finan';
    let keyIni = 'ini';
    let finalTipo;
    let finalFinan;
    let finalIni;
    let i = 0;
    client
      .query({ query: SEARCHPROJECTBYNAME, variables: { nombre: UserID } })
      .then((result) => {
        result.data.SearchProjectById.tipoInmuebles.map((inmu) => {
          i++;
          finalTipo = keyTipo + i;
          finalFinan = keyFinan + i;
          finalIni = keyIni + i;
          this.setState({
            [finalTipo]: inmu.tipo,
            [finalFinan]: inmu.maxFinanciacion,
            [finalIni]: inmu.maximoCuotasInicial,
          });
          return null;
        });
        this.setState({
          modalEdit: true,
          id: result.data.SearchProjectById._id,
          nombre: result.data.SearchProjectById.nombre,
          numLotes: result.data.SearchProjectById.numLotes,
          tipoInmuebles: result.data.SearchProjectById.tipoInmuebles,
          tasaInteres: result.data.SearchProjectById.tasaInteres,
        });
      });
  };

  handleDeleteProject = (id) => {
    this.setState({
      openDialogEliminarProyecto: true,
      id,
    });
  };

  checkUpdate = () => {
    let { numLotes, maxFinanciacion, maximoCuotasInicial, tasaInteres } =
      this.state;
    let arr = [numLotes, maxFinanciacion, maximoCuotasInicial, tasaInteres];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === 0 || arr[i] === '0') return false;
    }
    return true;
  };

  handleUpdateProject = () => {
    if (this.checkUpdate()) {
      this.updateProject();
    } else {
      this.setState({
        messageModal: 'Todos los campos deben estar llenos',
        show: true,
        titleModal: <div className="center wrong" />,
      });
    }
  };

  updateProject = () => {
    this.setState({
      loading: true,
    });
    let listArr = [];
    let keyTipo = 'tipo';
    let keyFinan = 'finan';
    let keyIni = 'ini';
    let finalTipo;
    let finalFinan;
    let finalIni;
    let finalKey;
    for (let i = 1; i <= this.state.tipoInmuebles.length; i++) {
      finalTipo = keyTipo + i;
      finalFinan = keyFinan + i;
      finalIni = keyIni + i;
      finalKey = {
        tipo: this.state[finalTipo],
        maxFinanciacion: parseFloat(this.state[finalFinan]),
        maximoCuotasInicial: parseInt(this.state[finalIni]),
      };
      listArr.push(finalKey);
    }
    let updateData = {
      nombre: this.state.nombre,
      tasaInteres: parseFloat(this.state.tasaInteres),
      numLotes: parseInt(this.state.numLotes),
      tipoInmuebles: listArr,
    };
    client
      .mutate({
        mutation: UPDATEPROJECT,
        variables: { id: this.state.id, data: updateData },
      })
      .then((newProject) => {
        this.setState({
          messageModal: 'Proyecto actualizado correctamente',
          show: true,
          loading: false,
          titleModal: <div className="center success" />,
        });
        this.loadProjects();
      })
      .catch(() => {
        this.setState({
          messageModal: 'El proyecto no pudo ser actualizado',
          show: true,
          loading: false,
          titleModal: <div className="center wrong" />,
        });
      });
  };

  handleChangeTasaInteres = (e) => {
    this.setState({
      tasaInteres: e.target.value,
    });
  };

  handleChangeNombre = (e) => {
    this.setState({
      nombre: e.target.value,
    });
  };

  handleChangeMaxFinanciacion = (e) => {
    this.setState({
      maxFinanciacion: e.target.value,
    });
  };

  handleChangeNumLotes = (e) => {
    this.setState({
      numLotes: e.target.value,
    });
  };

  handleChangeMaxIniciales = (e) => {
    this.setState({
      maximoCuotasInicial: e.target.value,
    });
  };

  handleChangePropInmuebles = (e) => {
    let state = e.target.id;
    this.setState({
      [state]: e.target.value,
    });
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  toggleEdit = () => {
    this.setState({
      modalEdit: false,
    });
  };

  handleCloseDialogEliminarProyecto = () => {
    this.setState({
      openDialogEliminarProyecto: false,
      id: '',
    });
  };

  handleEliminarProyecto = (event) => {
    event.preventDefault();
    client
      .query({ query: DELETEPROJECT, variables: { id: this.state.id } })
      .then((result) => {
        toast.success('Proyecto eliminado exitosamente');
        this.loadProjects();
        this.setState({
          openDialogEliminarProyecto: false,
          id: '',
        });
      })
      .catch((err) => {
        toast.error('Error eliminado exitosamente');
        this.setState({
          openDialogEliminarProyecto: false,
          id: '',
        });
      });
  };

  handleAddInmueble = (event) => {
    event.preventDefault();
    let nuevoInmu = this.state.newInmueble;
    nuevoInmu.maximoCuotasInicial = parseInt(nuevoInmu.maximoCuotasInicial);
    nuevoInmu.maxFinanciacion = parseInt(nuevoInmu.maxFinanciacion);

    // Valida si ya existe el tipo de inmueble
    const tipos = this.state.tipoInmuebles.filter(
      (inm) => inm.tipo.toLowerCase() === nuevoInmu.tipo.toLowerCase()
    );

    // Si ya existe se muestra el mensaje de error
    if (tipos.length > 0) {
      this.setState({
        existeInmueble: true,
      });
      return;
    }

    const lastId = this.state.tipoInmuebles.length + 1;

    let finalTipo = 'tipo' + lastId;
    let finalFinan = 'finan' + lastId;
    let finalIni = 'ini' + lastId;

    // Se agrega el nuevo tipo al array de inmuebles y limpia el formulario
    this.setState((prevState) => ({
      existeInmueble: false,
      [finalTipo]: nuevoInmu.tipo,
      [finalFinan]: nuevoInmu.maxFinanciacion,
      [finalIni]: nuevoInmu.maximoCuotasInicial,
      tipoInmuebles: [...prevState.tipoInmuebles, nuevoInmu],
      newInmueble: {
        tipo: '',
        maximoCuotasInicial: '',
        maxFinanciacion: '',
        __typename: 'typeInmueble',
      },
    }));
  };

  handleChangePropNewInmueble = (e) => {
    let id = e.target.id;
    this.setState((prevState) => ({
      newInmueble: {
        ...prevState.newInmueble,
        [id]: e.target.value,
      },
    }));
  };

  render() {
    let keyTipo = 'tipo';
    let keyFinan = 'finan';
    let keyIni = 'ini';
    let finalTipo;
    let finalFinan;
    let finalIni;
    let i = 0;

    let editInmuebles = this.state.tipoInmuebles.map((inmu) => {
      i++;
      finalTipo = keyTipo + i;
      finalFinan = keyFinan + i;
      finalIni = keyIni + i;

      return (
        <>
          <Row>
            <Col xs={1}>
              <strong>{` ${i}. `}</strong>
            </Col>
            <Col id="maxIni">
              <input
                id={finalTipo}
                className="form-control"
                value={this.state[finalTipo]}
                disabled
                onChange={this.handleChangePropInmuebles}
              />
            </Col>
            <Col># Cuotas Iniciales</Col>
            <Col id="maxIni">
              <input
                id={finalIni}
                className="form-control"
                type='number'
                value={this.state[finalIni]}
                onChange={this.handleChangePropInmuebles}
              />
            </Col>
            <Col>% Maximo A Financiar</Col>
            <Col id="maxIni">
              <input
                id={finalFinan}
                className="form-control"
                type='number'
                value={this.state[finalFinan]}
                onChange={this.handleChangePropInmuebles}
              />
            </Col>
          </Row>
        </>
      );
    });

    let agregarTiposInmueble = (
      <form onSubmit={this.handleAddInmueble}>
        <Row>
          <Col xs={1}>
            <Button color="success" type="submit" disabled={false}>
              <AddCircleIcon />
            </Button>
          </Col>
          <Col id="maxIni">
            <input
              id="tipo"
              placeholder="Tipo"
              className="form-control"
              value={this.state.newInmueble.tipo}
              required
              onChange={this.handleChangePropNewInmueble}
            />
          </Col>
          <Col># Cuotas Iniciales</Col>
          <Col id="maxIni">
            <input
              id="maximoCuotasInicial"
              placeholder="0"
              className="form-control"
              value={this.state.newInmueble.maximoCuotasInicial}
              required
              type='number'
              onChange={this.handleChangePropNewInmueble}
            />
          </Col>
          <Col>% Maximo A Financiar</Col>
          <Col id="maxIni">
            <input
              id="maxFinanciacion"
              placeholder="0"
              className="form-control"
              value={this.state.newInmueble.maxFinanciacion}
              required
              type='number'
              onChange={this.handleChangePropNewInmueble}
            />
          </Col>
        </Row>
        {this.state.existeInmueble && (
          <Row className="mt-3 mb-3">
            <Col className="text-center">
              <label style={{ color: 'red' }}>
                El tipo de inmueble ya existe
              </label>
            </Col>
          </Row>
        )}
        <hr />
      </form>
    );

    return !this.state.logged ? (
      <ToLogin />
    ) : (
      <>
        <Navbar></Navbar>
        <Row className="main-menu--project-page pt-5">
          <Col>
            <Row>
              <Col className="text-center mt-4 mb-3">
                <div className="main-menu--project-page-title">
                  Lista de Proyectos
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  data={this.state.projects}
                  handleEdit={this.handleEditProject}
                  handleDelete={this.handleDeleteProject}
                  permisos={this.state.permisos}
                />
              </Col>
            </Row>

            <Modal
              isOpen={this.state.modalEdit}
              toggle={this.toggleEdit}
              size="lg"
            >
              <ModalHeader toggle={this.toggleEdit} />
              <ModalBody>
                <Row>
                  <Col>
                    <div className="main--modal-title mb-3">
                      Actualizar Proyecto
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Nombre</label>
                  </Col>
                  <Col>
                    <input
                      className="form-control"
                      value={this.state.nombre}
                      onChange={this.handleChangeNombre}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <label>Tasa de interes</label>
                  </Col>
                  <Col>
                    <input
                      className="form-control"
                      value={this.state.tasaInteres}
                      onChange={this.handleChangeTasaInteres}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <label>Numero De Inmuebles</label>
                  </Col>
                  <Col>
                    <input
                      disabled
                      className="form-control"
                      value={this.state.numLotes}
                      onChange={this.handleChangeNumLotes}
                    />
                  </Col>
                </Row>
                <Row className="mt-3 mb-3">
                  <Col className="text-center">
                    <label>Inmuebles</label>
                  </Col>
                </Row>
                <Row>
                  <Col>{editInmuebles}</Col>
                </Row>
                <hr />
                <Row className="mt-3 mb-3" style={{display: this.state.permisos.editar? 'block' : 'none'}}>
                  <Col className="text-center">
                    <label>
                      <strong>Agregar Inmueble</strong>
                    </label>
                  </Col>
                </Row>
                <Row style={{display: this.state.permisos.editar? 'block' : 'none'}}>
                  <Col>{agregarTiposInmueble}</Col>
                </Row>
                <Row>
                  <Col className="text-center mt-3">
                    <Button
                      color="primary"
                      id="buttonUpdate"
                      onClick={this.handleUpdateProject}
                      disabled={this.state.loading}
                    >
                      {!this.state.loading ? (
                        'Guardar'
                      ) : (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                          {` Actualizando...`}
                        </>
                      )}
                    </Button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
            <Modal isOpen={this.state.show} toggle={this.handleClose} size="lg">
              {this.state.titleModal}
              <ModalBody>{this.state.messageModal}</ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  className="btn btn-default"
                  onClick={this.handleClose}
                >
                  Cerrar
                </Button>
              </ModalFooter>
            </Modal>
            <Dialog
              fullWidth
              open={this.state.openDialogEliminarProyecto}
              onClose={this.handleCloseDialogEliminarProyecto}
            >
              <DialogTitle>Eliminando Proyecto</DialogTitle>
              <form onSubmit={this.handleEliminarProyecto}>
                <DialogContent>
                  <DialogContentText>
                    Esta seguro que desea eliminar el proyecto? Ya no podra
                    verlo en el sistema.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button type="submit">Aceptar</Button>
                  <Button onClick={this.handleCloseDialogEliminarProyecto}>
                    Cancelar
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
          </Col>
        </Row>
      </>
    );
  }
}

const Table = ({ data, handleEdit, handleDelete, permisos }) => {
  const columns = useMemo(
    () => [
      {
        Header: () => null,
        accessor: '_id',
        Cell: ({ row }) => (
          <div style={{ display: 'flex' }}>
            <Button
              className="main--edit-button"
              disabled={!permisos.editar}
              onClick={() => handleEdit(row.values._id, row.values.nombre)}
            >
              <EditIcon
                sx={{ color: permisos.editar ? 'blue' : 'black' }}
                titleAccess={
                  permisos.editar
                    ? 'Editar Proyecto'
                    : 'Sin permisos para editar'
                }
              />
            </Button>
            <Button
              disabled={!permisos.eliminar}
              className="main--edit-button"
              onClick={() => handleDelete(row.values._id)}
            >
              <DeleteIcon
                sx={{ color: permisos.eliminar ? 'red' : 'black' }}
                titleAccess={
                  permisos.eliminar
                    ? 'Eliminar Proyecto'
                    : 'Sin permisos para eliminar'
                }
              />
            </Button>
          </div>
        ),
      },
      {
        Header: 'Nombre',
        accessor: 'nombre',
      },
      {
        Header: '# Lotes',
        accessor: 'numLotes',
      },
      {
        Header: 'Tasa Interes',
        accessor: 'tasaInteres',
      },
      {
        Header: 'Tipos Inmuebles',
        accessor: 'tipoInmuebles',
        Cell: (rowInfo) => {
          return (
            <span>
              {rowInfo.value.map((book) => (
                <span>
                  {book.tipo}
                  <small>{' & '}</small>
                </span>
              ))}
            </span>
          );
        },
        // columns: [
        //   {
        //     Header: 'Tipo',
        //     accessor: 'tipoInmuebles.0.tipo',
        //   },
        //   {
        //     Header: 'Max Financiacion',
        //     accessor: 'tipoInmuebles.0.maxFinanciacion',
        //   },
        //   {
        //     Header: 'Max Cuotas Inicial',
        //     accessor: 'tipoInmuebles.0.maximoCuotasInicial',
        //   },
        // ],
      },
    ],
    [handleEdit, handleDelete, permisos]
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      columns,
      data,
    });

  useEffect(() => {}, [data]);

  return (
    <div className="view-history">
      <table
        {...getTableProps()}
        className="table table-bordered table-striped main--table main-menu--projects-table"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr className="table-light" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
