import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import Navbar from '../../containers/NavBar/NavBar';
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
} from 'reactstrap';

import logo from 'assets/images/lar-azul.svg';

export const ResetEmailForm = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [messageModal, setMessageModal] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let token = sessionStorage.getItem('token');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  const toggle = () => {
    setShow(!show);
  };

  const onSubmit = (values) => {
    const { correo } = values;
    console.log(correo);
    setShow(true);
    setSuccess(true);
    setLoading(false);
    setMessageModal(
      'Se ha enviado un mensaje a su correo, siga las instrucciones'
    );
  };

  return (
    <>
      <Navbar></Navbar>
      <Row className="main-login--background h-100">
        <Col
          xs={{ offset: 1, size: 10 }}
          sm={{ offset: 1, size: 10 }}
          md={{ offset: 2, size: 8 }}
          lg={{ offset: 4, size: 4 }}
          className="d-flex justify-content-center align-items-center pb-5"
        >
          <Card className="main-login--card">
            <CardBody className="pt-4 pb-1">
              <div className="main-login--logo d-flex justify-content-center mb-3">
                <img src={logo} alt="Logo" />
              </div>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <div className="form-label-group">
                    <input
                      type="email"
                      className="form-control"
                      name="correo"
                      placeholder="Email"
                      required
                      ref={register}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <Button
                    className="btn-block m-0"
                    color="primary"
                    type="submit"
                  >
                    {!loading ? (
                      'Enviar'
                    ) : (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                        {` Verificando...`}
                      </>
                    )}
                  </Button>
                </FormGroup>
              </Form>
              <p className="forgot-password text-right">
                <Link to={'/login'}>Iniciar sesion</Link>
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal centered isOpen={show} toggle={toggle}>
        <ModalHeader>
          {success ? (
            <div className={classnames('center', { success: true })} />
          ) : (
            <div className={classnames('center', { wrong: true })} />
          )}
        </ModalHeader>
        <ModalBody>{messageModal}</ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-default" onClick={toggle}>
            Cerrar
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};
