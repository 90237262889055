import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormGroup,
} from 'reactstrap';
import classnames from 'classnames';
import client from 'utils/database';
import { SEARCHUSERBYPARAM } from 'utils/database/query/users';

import ModifyPlan from './ModifyPlan';
import ModifyPersonal from './ModifyPersonal';
import ModifyPayment from './ModifyPayment';

export default class Modificaciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: '',
      cedula: '',
      direccion: '',
      celular: '',
      correo: '',
      planes: [],
      currentIdPlan: '',
      InfoPersonal: false,
      InfoPagos: false,
      InfoPlan: false,
      data: null,
      loading: false,
      showButtons: false,
    };
  }

  handleChangeCedula = (e) => {
    this.setState({
      cedula: e.target.value,
    });
  };

  handleChangeIdPlan = (e) => {
    this.setState({
      currentIdPlan: e.target.value,
    });
  };

  buscarUsuario = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
      showButtons: false,
    });
    const { cedula } = this.state;
    client
      .query({
        query: SEARCHUSERBYPARAM,
        variables: { cedula },
      })
      .then((user) => {
        this.setState({
          loading: false,
          showButtons: true,
          nombre: user.data.SearchUserByParam.nombre,
          direccion: user.data.SearchUserByParam.direccion,
          celular: user.data.SearchUserByParam.celular,
          correo: user.data.SearchUserByParam.correo,
          planes: user.data.SearchUserByParam.planes,
          currentIdPlan: user.data.SearchUserByParam.planes[0].idPlan,
          // proyecto: user.data.SearchUserByParam.planes[0].proyecto,
        });
      })
      .catch(() => {
        this.setState({
          show: true,
          titleModal: <div className="center wrong" />,
          messageModal:
            'El sistema no ha encontrado ningun usuario con la cedula digitada. Si esta seguro que la cedula es correcta, verifique su conexion a internet',
        });
      });
  };

  mostrarInfoPersonal = () => {
    const { cedula, nombre, direccion, celular, correo } = this.state;
    const personal = {
      cedula,
      nombre,
      direccion,
      celular,
      correo,
    };
    this.setState({
      data: personal,
      InfoPersonal: true,
      InfoPagos: false,
      InfoPlan: false,
    });
  };

  mostrarPlanes = () => {
    this.setState({
      InfoPersonal: false,
      InfoPagos: false,
      InfoPlan: true,
    });
  };

  mostrarPagos = () => {
    this.setState({
      InfoPersonal: false,
      InfoPagos: true,
      InfoPlan: false,
    });
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  render() {
    const {
      loading,
      showButtons,
      nombre,
      cedula,
      InfoPagos,
      InfoPersonal,
      InfoPlan,
      data,
      currentIdPlan,
      planes,
      show,
      titleModal,
      messageModal,
    } = this.state;
    return (
      <>
        <Row className="p-3">
          <Col>
            <Row>
              <Col>
                <Breadcrumb>
                  <BreadcrumbItem>
                    <a href="dashboard">Dashboard</a>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Modificaciones</BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
            <Row className="p-3">
              <Form onSubmit={this.buscarUsuario} className="w-100">
                <FormGroup>
                  <Row>
                    <Col xs={4}>
                      <input
                        type="text"
                        id="cedula"
                        className="form-control"
                        placeholder="Cedula"
                        value={cedula}
                        onChange={this.handleChangeCedula}
                      />
                    </Col>
                    <Col xs={2}>
                      <Button
                        color="primary"
                        type="submit"
                        className="w-100"
                        id="buscarBtn"
                        disabled={loading}
                      >
                        {!loading ? (
                          'Buscar'
                        ) : (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            />
                            {` Buscando...`}
                          </>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </Form>
            </Row>

            <Row>
              <Col xs={{ offset: 2, size: 8 }}>
                <div className="main-modificaciones--cliente">{nombre}</div>
              </Col>
            </Row>
            <div
              className={classnames('main-modificaciones--buttons', 'mt-4', {
                'd-none': !showButtons,
              })}
            >
              <Button
                outline
                color="primary"
                type="button"
                className="btn btn-outline-primary btnCentered "
                onClick={this.mostrarPlanes}
              >
                Modificar Planes
              </Button>
              <Button
                outline
                color="primary"
                type="button"
                className="btn btn-outline-primary btnCentered"
                onClick={this.mostrarInfoPersonal}
              >
                Modificar Datos Personales
              </Button>
              <Button
                outline
                color="primary"
                type="button"
                className="btn btn-outline-primary btnCentered"
                onClick={this.mostrarPagos}
              >
                Modificar Pagos
              </Button>
            </div>

            {InfoPlan && data ? (
              <ModifyPlan planes={planes} />
            ) : null}

            {InfoPagos ? (
              <ModifyPayment
                plan={currentIdPlan}
                planes={planes}
              />
            ) : null}

            {InfoPersonal ? (
              <ModifyPersonal personal={data} />
            ) : null}
          </Col>
        </Row>

        <Modal isOpen={show} toggle={this.handleClose}>
          <ModalHeader>
            <>{titleModal}</>
          </ModalHeader>
          <ModalBody>{messageModal}</ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-default"
              onClick={this.handleClose}
            >
              Cerrar
            </button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
