import gql from 'graphql-tag';

export const SEARCHTABLEBYID = gql`
  query SEARCHTABLEBYID($id: ID) {
    SearchTableById(id: $id) {
      content {
        cuota
        fecha
        valorTeoricoCuota
        abonoCapitalTeorico
        abonoInteresTeorico
        saldoRestanteTeorico
        pagoTotal
        abonoInteres
        abonoCapital
        saldoRestante
        estado
        interesMora
        diasMora
        tipo
      }
    }
  }
`;


// const SEARCHTABLEBYID = gql`
//   query TABLE($id: ID) {
//     SearchTableById(id: $id) {
//       content {
//         valorTeoricoCuota
//         saldoRestanteTeorico
//         fecha
//         abonoCapitalTeorico
//         abonoInteresTeorico
//         estado
//       }
//     }
//   }
// `;
