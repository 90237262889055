import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_LEADS_HOST
});

axiosInstance.interceptors.request.use(
  (request) => {
    let token = sessionStorage.getItem('token');
    request.headers["Authorization"] = `Bearer ${token}`
    return request;
  },
  (err) => err
);

export default axiosInstance;

