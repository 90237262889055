import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import client from 'utils/database';
import { LOGIN } from 'utils/database/mutation/login';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
} from 'reactstrap';

import logo from 'assets/images/lar-azul.svg';

const LoginHook = () => {
  const { register, handleSubmit } = useForm();

  const [show, setShow] = useState(false);
  const [messageModal, setMessageModal] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showResetPassword] = useState(false);
  const navigate = useNavigate();

  const toggle = () => {
    setShow(!show);
  };

  const onSubmit = (values) => {
    setLoading(true);
    client
      .mutate({
        mutation: LOGIN,
        variables: values,
      })
      .then((result) => {
        const {
          data: { Login },
        } = result;

        if (!Login || !Login.token) {
          setShow(true);
          setSuccess(false);
          setLoading(false);
          setMessageModal(Login.message);
        } else {
          sessionStorage.setItem('token', Login.token);
          sessionStorage.setItem('user', JSON.stringify(Login.user));

          setLoading(false);
          navigate('/');
        }
      })
      .catch((error) => {
        setShow(true);
        setSuccess(false);
        setLoading(false);
        setMessageModal('Ocurrio Un error');
      });
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (token) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <>
      <Row className="main-login--background h-100">
        <Col
          xs={{ offset: 1, size: 10 }}
          sm={{ offset: 1, size: 10 }}
          md={{ offset: 2, size: 8 }}
          lg={{ offset: 4, size: 4 }}
          className="d-flex justify-content-center align-items-center pb-5"
        >
          <Card className="main-login--card">
            <CardBody className="pt-4 pb-1">
              <div className="main-login--logo d-flex justify-content-center mb-3">
                <img src={logo} alt="Logo" />
              </div>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <div className="form-label-group">
                    <input
                      type="email"
                      className="form-control"
                      name="correo"
                      placeholder="Email"
                      required
                      ref={register}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="form-label-group">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="Contraseña"
                      required
                      ref={register}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <Button
                    className="btn-block m-0"
                    color="primary"
                    type="submit"
                  >
                    {!loading ? (
                      'Iniciar sesión'
                    ) : (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                        {` Verificando...`}
                      </>
                    )}
                  </Button>
                </FormGroup>
              </Form>
              {showResetPassword && <p className="forgot-password text-right">
                <Link to={'/reset-password'}>Olvidé mi contraseña?</Link>
              </p>}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal centered isOpen={show} toggle={toggle}>
        <ModalHeader>
          <div className={classnames('center', { wrong: !success })} />
        </ModalHeader>
        <ModalBody>{messageModal}</ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-default" onClick={toggle}>
            Cerrar
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default LoginHook;
