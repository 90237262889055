import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { PROJECTS } from 'utils/database/query/projects';
import client from 'utils/database';
import Navbar from '../../../containers/NavBar/NavBar';
import CardProject from './CardProject';
import { Grid } from '@mui/material';
import Loading from 'components/containers/Loading/Loading';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [type, setType] = useState(2);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const proyectoDefault = "Default";

  useEffect(() => {
    let token = sessionStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      setLoading(true);
      client.query({ query: PROJECTS }).then((result) => {
        const proys = result.data.Projects.filter(p => p.nombre !== proyectoDefault);
        setProjects(proys);
        setLoading(false);
      });

      let type = JSON.parse(sessionStorage.getItem('user')).rol.idRol;
      setType(type);
    }
  }, [navigate]);

  const handleCreateNewProject = () => {
    navigate('/new-project');
  };

  const handleManageProject = () => {
    navigate('/projects-managment');
  };

  return (
    <>
      <Navbar></Navbar>
      <Row className="main-menu--project-page h-100 pt-5 m-0">
        <Col>
          <Row>
            <Col>
              <h1 className="main-menu--project-welcome">Bienvenido!</h1>
              <h3 className="main-menu--project-select">
                Escoja un Proyecto para empezar
              </h3>
            </Col>
          </Row>
          <Row className="mt-5 d-flex justify-content-center">
            {[1,7].includes(type) ? (
              <>
                <Col
                  className="d-flex justify-content-center"
                  lg={{ size: 2 }}
                  md={2}
                  sm={2}
                >
                  <Button
                    title="Añadir Nuevo Proyecto"
                    onClick={handleCreateNewProject}
                  >
                    Añadir Proyecto
                  </Button>
                </Col>
                <Col
                  className="d-flex justify-content-center"
                  lg={{ size: 2 }}
                  md={2}
                  sm={2}
                >
                  <Button
                    title="Modificar Proyecto"
                    onClick={handleManageProject}
                  >
                    Gestionar Proyectos
                  </Button>
                </Col>
              </>
            ) : null}
          </Row>
          <hr />
          <br />
          {loading ? (
            <Loading />
          ) : (
            <Grid className="p-3" container>
              {projects && projects.length > 0
                ? projects.map((proj) => {
                    return <CardProject key={proj._id} proj={proj} />;
                  })
                : null}
            </Grid>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Projects;
