// @ts-nocheck
import axiosInstance from 'utils/database/api-leads';
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import {
  DataGrid,
  esES,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import LockIcon from '@mui/icons-material/Lock';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { pink } from '@mui/material/colors';
import Loading from 'components/containers/Loading/Loading';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { columnsD } from './data';
import moment from 'moment';
import DetailGestions from './ModalGestions/DetailGestions';
import NoAuth from 'components/containers/NoAuth/NoAuth';
const { useState, useEffect } = require('react');

const LeadsTable = () => {
  const [leads, setLeads] = useState([]);
  const [estados, setEstados] = useState([]);
  const [tipoGestiones, setTipoGestiones] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [permisos, setPermisos] = useState({});
  const [userRole, setUserRole] = useState('');
  const rolesInactiveLead = [1, 4, 7];
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogEliminarLead, setOpenDialogEliminarLead] = useState(false);
  const [openDialogInactivarLead, setOpenDialogInactivarLead] = useState(false);
  const [showButtonRestartLeads, setShowButtonRestartLeads] = useState(false);
  const [showDetailGestions, setShowDetailGestions] = useState(false);
  const [idLead, setIdLead] = useState('');
  const [observacion, setObservacion] = useState('');
  const [gestionsData, setGestionsData] = useState({
    idLead: '',
    leadName: '',
    gestions: [],
  });
  const [filters, setFilters] = useState({
    phone: '',
    idEstado: '',
    idProyecto: '',
    nombre: '',
    idUltimaGestion: '',
  });
  const [lastPage, setLastPage] = useState(0);
  const [contadores, setContadores] = useState({
    nuevos: 1,
    enCita: 1,
    reagendar: 1
  });

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const filtrosLead = JSON.parse(sessionStorage.getItem('filtrosLead'));
    const lastPageTablaLeads = JSON.parse(
      sessionStorage.getItem('lastPageTablaLeads')
    );
    if (lastPageTablaLeads !== null) {
      setLastPage(lastPageTablaLeads);
    }
    if(filtrosLead !== null){
      setFilters(filtrosLead);
    }
    setUserRole(user.rol.idRol);
    getFlag();
    getPermisos(user._id);
    getEstados();
    getProyectos();
    getGestiones();
    getLeads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPermisos = (idUser) => {
    axiosInstance.get(`/users/permisos/${idUser}`).then((resp) => {
      const info = resp.data;
      setPermisos(info.data?.permisos?.leads);
    });
  };

  const getEstados = () => {
    axiosInstance
      .get(`/leads/status/get`)
      .then((resp) => {
        const estados = resp.data.data;
        setEstados(estados);
      });
  }

  const getProyectos = () => {
    axiosInstance.get(`/projects/leads`).then((resp) => {
      const allProjects = resp.data;
      setProyectos(allProjects.data);
    });
  }

  const getGestiones = () => {
    axiosInstance.get(`/leads/management/types/leads`).then((resp) => {
      const allGestiones = resp.data;
      setTipoGestiones(allGestiones.data);
    });
  }

  const getLeads = () => {
    setLoading(true);
    let userLocal = JSON.parse(sessionStorage.getItem('user'));
    const filtrosLead = JSON.parse(sessionStorage.getItem('filtrosLead'));
    axiosInstance
      .get(`/leads?`, {
        params: {
          userId: userLocal._id,
          phone: filtrosLead?.phone,
          idEstado: filtrosLead?.idEstado,
          idProyecto: filtrosLead?.idProyecto,
          nombre: filtrosLead?.nombre,
          idUltimaGestion: filtrosLead?.idUltimaGestion,
        },
      })
      .then((resp) => {
        const allLeads = resp.data;
        setLeads(allLeads.data.leads);
        setContadores(allLeads.data.contadores)
        setLoading(false);
        if (userLocal.rol.idRol === 5 && allLeads.data.length === 0) {
          toast.info('Aun no tienes leads asignados.');
        }
      });
  };

  const getFlag = () => {
    axiosInstance.get(`/leads/flags/get/restartLeads`).then((resp) => {
      const { data } = resp.data;
      if (data) {
        setShowButtonRestartLeads(data.activa);
      }
    });
  };

  const handleInactiveLead = (event) => {
    event.preventDefault();
    axiosInstance
      .patch(`/leads/update-status/${idLead}`, {
        estado: { idEstado: 8, nombre: 'Inactivo' },
        observacion,
      })
      .then((res) => {
        setOpenDialogInactivarLead(false);
        setIdLead('');
        setObservacion('');
        toast.success(res.data.message);
        getLeads();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleActiveLead = (idLead) => {
    axiosInstance
      .patch(`/leads/active-lead/${idLead}`)
      .then((res) => {
        toast.success(res.data.message);
        getLeads();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialogEliminarLead = (idLead) => {
    setOpenDialogEliminarLead(true);
    setIdLead(idLead);
  };

  const handleCloseDialogEliminarLead = () => {
    setOpenDialogEliminarLead(false);
    setIdLead('');
  };

  const handleEliminarLead = () => {
    axiosInstance
      .patch(`/leads/update-status/${idLead}`, {
        estado: { idEstado: 7, nombre: 'Descartado/Eliminado' },
      })
      .then((res) => {
        toast.success(res.data.message);
        getLeads();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
    handleCloseDialogEliminarLead();
  };

  const handleCloseDialogInactivarLead = () => {
    setOpenDialogInactivarLead(false);
    setIdLead('');
  };

  const handleOpenDialogInactivarLead = (idLead) => {
    setOpenDialogInactivarLead(true);
    setIdLead(idLead);
  };

  const handleRestartLeads = () => {
    axiosInstance
      .post(`/leads/restart`)
      .then((res) => {
        toast.success(res.data.message);
        getLeads();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
    handleCloseDialog();
  };

  const handleVerGestiones = (data) => {
    setGestionsData({
      idLead: data.row._id,
      leadName: data.row.nombreCompleto,
      gestions: data.row.gestiones,
    });
    setShowDetailGestions(true);
  };

  const getColorByGestionNumber = (number) => {
    if(number > 4) {
      return {
        background: 'green',
        color: 'white',
      };
    }
    switch (number) {
      case 1:
        return {
          background: 'red',
          color: 'white',
        };
      case 2:
        return {
          background: 'orange',
          color: 'white',
        };
      case 3:
        return {
          background: 'yellow',
          color: 'black',
        };
      case 4:
        return {
          background: 'green',
          color: 'white',
        };
      default:
        return {
          background: 'none',
          color: 'none',
        };
    }
  };

  let columns = [
    {
      field: '_id',
      headerName: 'ID',
      width: 70,
      hide: true,
      disableExport: true,
      filterable: false,
    },
    {
      field: 'Accion',
      disableExport: true,
      filterable: false,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      width: 200,
      renderCell: (cellValues) => {
        return (
          <>
            {cellValues.row.estado.idEstado === 7 ? null : (
              <>
                <Link
                  hidden={
                    cellValues.row.estado.idEstado === 8 || !permisos.crearLead
                  } // Si el estado es Inactivo o el usuario no puede crear/actualizar entonces no se muestra el boton de editar
                  to={`detail/${cellValues.id}`}
                >
                  <EditIcon titleAccess="Editar Lead" />
                </Link>
                <Button
                  hidden={
                    cellValues.row.estado.idEstado === 8 ||
                    !rolesInactiveLead.includes(userRole) // Si el estado es Inactivo o el rol no tiene permisos no se muestra el boton de Inactivar
                  }
                  onClick={() => handleOpenDialogInactivarLead(cellValues.id)}
                  variant="text"
                  size="small"
                >
                  <LockIcon color="secondary" titleAccess="Inactivar Lead" />
                </Button>
                <Button
                  hidden={
                    cellValues.row.estado.idEstado !== 8 ||
                    !rolesInactiveLead.includes(userRole) // Si el estado es diferente a Inactivo o el rol no tiene permisos no se muestra el boton de Activar
                  }
                  onClick={() => handleActiveLead(cellValues.id)}
                  variant="text"
                  size="small"
                >
                  <LockOpenIcon color="action" titleAccess="Activar Lead" />
                </Button>
                <Button
                  hidden={
                    cellValues.row.estado.idEstado === 8 ||
                    !rolesInactiveLead.includes(userRole) // Si el rol es Inactivo o el rol no tiene permisos no se muestra el boton de Eliminar
                  }
                  onClick={() => handleOpenDialogEliminarLead(cellValues.id)}
                  variant="text"
                  size="small"
                >
                  <DeleteIcon
                    sx={{ color: pink[500] }}
                    titleAccess="Descartar/Eliminar Lead"
                  />
                </Button>
                <Link
                  hidden={
                    !permisos.gestionar || cellValues.row.estado.idEstado === 8 // Si el usuario no puede gestionar o el estado es Inactivo
                  }
                  to={`manage/${cellValues.id}`}
                >
                  <ManageAccountsIcon
                    color="success"
                    titleAccess="Gestionar Lead"
                  />
                </Link>
              </>
            )}
          </>
        );
      },
    },
    {
      field: 'gestiones',
      headerName: 'Gestiones',
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      filterable: false,
      valueGetter: ({ value }) => {
        return value
          .map(function (item) {
            return item.tipoGestion.nombre.concat(
              '__' +
                moment(item.fecha)
                  .format('DD/MM/YYYY hh:mm A')
                  .concat('__' + item.estado.nombre)
            );
          })
          .join(', ');
      },
      renderCell: (cellValues) => {
        return (
          <>
            <Button
              onClick={() => handleVerGestiones(cellValues)}
              variant="text"
              size="small"
            >
              <VisibilityIcon titleAccess="Ver gestiones" />
            </Button>
          </>
        );
      },
    },
    {
      field: 'gestionesCantidad',
      headerName: 'Total Gestiones',
      headerAlign: 'center',
      color: 'red',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 140,
      valueGetter: ({ row }) => {
        return row.gestiones.length;
      },
      renderCell: (cellValues) => {
        const numeroGestiones = cellValues.formattedValue;
        const colors = getColorByGestionNumber(numeroGestiones);
        return (
          <p
            style={{
              backgroundColor: colors.background,
              padding: '7px',
              marginTop: '15px',
              color: colors.color,
              borderRadius: '50%'
            }}
          >
            <strong>{numeroGestiones}</strong>
          </p>
        );
      },
    },
    {
      field: 'nombreCompleto',
      headerName: 'Nombre',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 200,
    },
    {
      field: 'ultimaGestion',
      headerName: 'Ultima Gestion',
      headerAlign: 'center',
      color: 'red',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 250,
      valueGetter: ({ row }) => {
        let ultimaGestion = row.gestiones.slice(-1);
        return ultimaGestion.length > 0? ultimaGestion[0].tipoGestion.nombre : "";
      },
    },
  ];

  columns = columns.concat(columnsD);

  const CustomToolbar = () => (
    <GridToolbarContainer>
      {/*<GridToolbarFilterButton />*/}
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );

  const CustomToolbarNoExport = () => (
    <GridToolbarContainer>
      {/*<GridToolbarFilterButton />*/}
    </GridToolbarContainer>
  );

  const onChangePage = (page) => {
    setLastPage(page);
    sessionStorage.setItem('lastPageTablaLeads', page);
  };

  const onChangeFilters = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  const handleFlter = (event) => {
    event.preventDefault();
    sessionStorage.setItem('filtrosLead', JSON.stringify(filters));
    getLeads();
  }

  const noAuth = () => {
    return (
      !permisos.asignarEspecialista &&
      !permisos.cargarLeads &&
      !permisos.crearLead &&
      !permisos.gestionar &&
      !permisos.informes
    );
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {noAuth() ? (
            <NoAuth />
          ) : (
            <>
              <Stack
                spacing={5}
                direction="row"
                alignItems="center"
                justifyContent="center"
                padding="12px"
              >
                {/* Se muestra notificacion de nuevos a todos los roles menos consultor*/}
                {userRole !== 6 && (
                  <Badge
                    badgeContent={contadores.nuevos}
                    max={50000000}
                    color="success"
                  >
                    <span style={{ paddingRight: '5px', fontWeight: 'bold' }}>
                      Nuevos
                    </span>
                    <NotificationsActiveIcon color="info" />
                  </Badge>
                )}
                <Badge
                  badgeContent={contadores.enCita}
                  max={50000000}
                  color="primary"
                >
                  <span style={{ paddingRight: '5px', fontWeight: 'bold' }}>
                    En Cita
                  </span>
                  <ManageAccountsIcon color="error" />
                </Badge>
                <Badge
                  badgeContent={contadores.reagendar}
                  max={50000000}
                  color="secondary"
                >
                  <span style={{ paddingRight: '5px', fontWeight: 'bold' }}>
                    Pendientes Reagendar
                  </span>
                  <RestartAltIcon color="error" />
                </Badge>
              </Stack>
              <DetailGestions
                handleClose={() => setShowDetailGestions(false)}
                show={showDetailGestions}
                gestionsData={gestionsData}
              />
              <Box
                sx={{
                  height: 690,
                  width: '100%',
                  '& .super-app-theme--header': {
                    backgroundColor: 'rgba(255, 170, 0, 0.500)',
                  },
                }}
              >
                <form onSubmit={handleFlter}>
                  <Grid container spacing={1} marginTop={'10px'}>
                    <Grid xs={12} sm={1.1} item >
                      <TextField
                        label="Celular"
                        variant="outlined"
                        fullWidth
                        name="phone"
                        value={filters.phone}
                        onChange={onChangeFilters}
                      />
                    </Grid>
                    <Grid xs={12} sm={2} item >
                      <TextField
                        label="Nombre"
                        variant="outlined"
                        fullWidth
                        name="nombre"
                        value={filters.nombre}
                        onChange={onChangeFilters}
                      />
                    </Grid>
                    <Grid xs={12} sm={2} item>
                      <TextField
                        select
                        variant="outlined"
                        fullWidth
                        label="Proyecto"
                        name="idProyecto"
                        value={filters.idProyecto}
                        onChange={onChangeFilters}
                      >
                        <MenuItem value={""}>Quitar seleccion</MenuItem>
                        {proyectos.map((proyecto) => (
                          <MenuItem key={proyecto._id} value={proyecto._id}>
                            {proyecto.nombre}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid xs={12} sm={2} item>
                      <TextField
                        select
                        variant="outlined"
                        fullWidth
                        label="Estado"
                        name="idEstado"
                        value={filters.idEstado}
                        onChange={onChangeFilters}
                      >
                        <MenuItem value={""}>Quitar seleccion</MenuItem>
                        {estados.map((estado) => (
                          <MenuItem key={estado._id} value={estado.idEstado}>
                            {estado.nombre}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid xs={12} sm={2} item>
                      <TextField
                        select
                        variant="outlined"
                        fullWidth
                        label="Ultima Gestion"
                        name="idUltimaGestion"
                        value={filters.idUltimaGestion}
                        onChange={onChangeFilters}
                      >
                        <MenuItem value={""}>Quitar seleccion</MenuItem>
                        {tipoGestiones.map((tipo) => (
                          <MenuItem key={tipo._id} value={tipo.idTypo}>
                            {tipo.nombre}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid marginTop={'10px'}>
                      <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                      >
                        Buscar
                      </Button>
                    </Grid>
                </form>
                <Button
                  hidden={!showButtonRestartLeads || leads.length < 1}
                  onClick={handleOpenDialog}
                  style={{ marginBottom: '5px', marginTop: '5px' }}
                  color="warning"
                  variant="text"
                >
                  Reiniciar Leads
                </Button>
                <DataGrid
                  rows={leads}
                  // @ts-ignore
                  columns={columns}
                  pageSize={50}
                  page={lastPage}
                  onPageChange={onChangePage}
                  disableSelectionOnClick
                  rowsPerPageOptions={[50]}
                  getRowId={(row) => row._id}
                  localeText={
                    esES.components.MuiDataGrid.defaultProps.localeText
                  }
                  components={{
                    Toolbar: permisos.informes
                      ? CustomToolbar
                      : CustomToolbarNoExport,
                  }}
                />
                <Dialog fullWidth open={openDialog} onClose={handleCloseDialog}>
                  <DialogTitle>Eliminando Leads</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Esta seguro que desea eliminar los leads para realizar
                      pruebas desde 0?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleRestartLeads}>Aceptar</Button>
                    <Button onClick={handleCloseDialog}>Cancelar</Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  fullWidth
                  open={openDialogEliminarLead}
                  onClose={handleCloseDialogEliminarLead}
                >
                  <DialogTitle>Eliminando Lead</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Esta seguro que desea descartar el lead? una vez realizada
                      esta acción ya no será visible.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleEliminarLead}>Aceptar</Button>
                    <Button onClick={handleCloseDialogEliminarLead}>
                      Cancelar
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  fullWidth
                  open={openDialogInactivarLead}
                  onClose={handleCloseDialogInactivarLead}
                >
                  <DialogTitle>Inactivando Lead</DialogTitle>
                  <form onSubmit={handleInactiveLead}>
                    <DialogContent>
                      <DialogContentText>
                        Esta seguro que desea inactivar el lead?
                      </DialogContentText>
                      <br />
                      <TextField
                        autoFocus
                        margin="dense"
                        label="Observacion"
                        variant="outlined"
                        placeholder="Escriba alguna información"
                        fullWidth
                        value={observacion}
                        required
                        multiline
                        onChange={(event) => setObservacion(event.target.value)}
                        rows={2}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button type="submit">Aceptar</Button>
                      <Button onClick={handleCloseDialogInactivarLead}>
                        Cancelar
                      </Button>
                    </DialogActions>
                  </form>
                </Dialog>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default LeadsTable;
