import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { SEARCHPLANBYIDMODPLAN } from 'utils/database/query/plans';
import { UPDATEPLAN } from 'utils/database/mutation/plans';
import { UPDATETABLE } from 'utils/database/mutation/tables';
import client from 'utils/database'

export default class ModifyPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleModal: '',
      messageModal: '',
      show: false,
      idPlan: '',
      proyecto: '',
      cedula: '',
      nombre: '',
      email: '',
      celular: '',
      direccion: '',
      vendedor: '',
      Observaciones: '',
      listaInmueble: ['Casa', 'Lote'],
      inmueble: '',
      listaNumInmueble: [],
      numInmueble: 1,
      boolFechaAutomatica: true,
      fechaRealizacion: '',
      tasaInteres: 0.083,
      precioTotal: '',
      precioFinanciar: '',
      maxFinanciacion: 70,
      porcentajeFinanciar: '',
      cuotaInicial: '',
      fechaInicioPlan: '',
      fechaInicioCuotaIni: '',
      fechaRealizacionPlan: '',
      numCuotasInicial: '',
      numCuotas: '',
      periodicidad: 'Mensual',
      boolCuotaPersonal: false,
      valorCuotaPersonal: 0,
      boolAbonoExtra: false,
      boolExtrasAuto: false,
      boolIntereses: false,
      boolExtraFija: true,
      boolExtraSola: false,
      numAbonos: 0,
      TableNumCuotas: [],
      innerCuotasInicial: [],
      arrNumCuotasIni: [],
      arrNumAbonos: [],
      tableUpload: [],
    };
  }

  componentDidMount() {
    this.getProjectInfo();
  }

  handleChangeIdPlan = (e) => {
    let idPlan = e.target.value;

    let index = e.target.options.selectedIndex;
    let descripcion = e.target.childNodes[index].id;
    let numLote = descripcion.substring(descripcion.indexOf('#') + 2);
    let descLote = descripcion.substring(0, descripcion.indexOf('#') - 1);

    let TableNumCuotas = [];
    let arrNumAbonos = [];
    let boolAbonos;

    client
      .query({ query: SEARCHPLANBYIDMODPLAN, variables: { id: idPlan } })
      .then((result) => {
        for (
          let m = 0;
          m < result.data.SearchPlanById.cuotasInicial.length;
          m++
        ) {
          TableNumCuotas.push(<option> {m + 1} </option>);
          let key = 'valIni' + (m + 1);
          this.setState({
            [key]: result.data.SearchPlanById.cuotasInicial[m].valor,
          });
        }
        for (
          let m = 0;
          m < result.data.SearchPlanById.cuotasExtra.length;
          m++
        ) {
          arrNumAbonos.push(m);
          let keyExtras = 'valbonos' + m;
          let keyCuota = 'cuotaabono' + m;
          this.setState({
            [keyExtras]: result.data.SearchPlanById.cuotasExtra[m].valor,
            [keyCuota]: result.data.SearchPlanById.cuotasExtra[m].cuota,
          });
        }
        boolAbonos = result.data.SearchPlanById.numAbonos > 0;
        if (result.data.SearchPlanById !== null) {
          this.setState({
            idTabla: result.data.SearchPlanById.tabla,
            idPlan: idPlan,
            inmueble: descLote,
            numInmueble: numLote,
            tasaInteres: result.data.SearchPlanById.tasaInteres,
            precioTotal: result.data.SearchPlanById.valorTotal,
            precioFinanciar: result.data.SearchPlanById.valorAFinanciar,
            porcentajeFinanciar:
              result.data.SearchPlanById.porcentajeAFinanciar,
            cuotaInicial: result.data.SearchPlanById.valorCuotaInicial,
            fechaInicioPlan: result.data.SearchPlanById.fechaInicialPlan,
            fechaInicioCuotaIni: result.data.SearchPlanById.fechaInicioCuotaIni,
            fechaRealizacion: result.data.SearchPlanById.fechaRealizacionPlan,
            numCuotasInicial: result.data.SearchPlanById.cuotasInicial.length,
            numCuotas: result.data.SearchPlanById.numeroCuotas,
            periodicidad: result.data.SearchPlanById.periodicidad,
            valIni0: result.data.SearchPlanById.cuotaSeparacion,
            arrNumCuotasIni: result.data.SearchPlanById.cuotasInicial,
            vendedor: result.data.SearchPlanById.vendedor,
            Observaciones: result.data.SearchPlanById.observaciones,
            TableNumCuotas: TableNumCuotas,
            boolCuotaPersonal: result.data.SearchPlanById.cuotaPersonal,
            valorCuotaPersonal: result.data.SearchPlanById.valorCuotaPersonal,
            boolAbonoExtra: boolAbonos,
            boolExtraFija: result.data.SearchPlanById.cuotaExtraFija,
            boolExtraSola: result.data.SearchPlanById.cuotaExtraSola,
            boolIntereses: result.data.SearchPlanById.cobrarInteres,
            numAbonos: result.data.SearchPlanById.numAbonos,
            arrNumAbonos: arrNumAbonos,
          });
        }
      });
  };

  getProjectInfo = () => {
    let configuracion = sessionStorage.getItem('configuracion');
    let user = sessionStorage.getItem('user');
    configuracion = JSON.parse(configuracion);
    user = JSON.parse(user);
    let arrayNums = [];
    for (let i = 1; i <= configuracion.numLotes; i++) {
      arrayNums.push(i);
    }
    this.setState({
      vendedor: user.nombre,
      tipoVendedor: user.tipo,
      proyecto: configuracion.nombre,
      inmueble: configuracion.tipoInmuebles[0].tipo,
      listaInmueble: configuracion.tipoInmuebles,
      numInmueble: arrayNums[0],
      listaNumInmueble: arrayNums,
      maximoCuotasInicial: configuracion.tipoInmuebles[0].maximoCuotasInicial,
      maxFinanciacion: configuracion.tipoInmuebles[0].maxFinanciacion,
    });
  };

  handleChangeVendedor = (e) => {
    this.setState({
      vendedor: e.target.value,
    });
  };

  handleChangeObservaciones = (e) => {
    this.setState({
      Observaciones: e.target.value,
    });
  };

  handleChangeTipoInmueble = (e) => {
    let index = e.target.options.selectedIndex;
    let descripcionLote = e.target.value + ' # ' + this.state.numInmueble;
    this.setState({
      inmueble: e.target.value,
      descripcionLote: descripcionLote,
      maximoCuotasInicial: this.state.listaInmueble[index].maximoCuotasInicial,
      maxFinanciacion: this.state.listaInmueble[index].maxFinanciacion,
      // precioTotal: 0,
      // precioFinanciar: 0,
      // porcentajeFinanciar: 0,
      // cuotaInicial: 0
    });
  };

  handleChangeNumInmueble = (e) => {
    let descripcionLote = this.state.inmueble + ' # ' + e.target.value;
    this.setState({
      numInmueble: e.target.value,
      descripcionLote: descripcionLote,
    });
  };

  handleChangeFechaRealizacion = (e) => {
    this.setState({
      fechaRealizacion: e.target.value,
    });
  };

  handleChangeTasa = (e) => {
    this.setState({
      tasaInteres: e.target.value,
    });
  };

  handleChangePrecioTotal = (e) => {
    let maxFinanciacion = parseInt(this.state.maxFinanciacion);
    let maxFinanciacionFloat = parseFloat(this.state.maxFinanciacion) / 100;
    this.setState({
      precioTotal: e.target.value,
      precioFinanciar: e.target.value * maxFinanciacionFloat,
      porcentajeFinanciar: maxFinanciacion,
      cuotaInicial: e.target.value - e.target.value * maxFinanciacionFloat,
    });
  };

  handleChangePrecioFinanciar = (e) => {
    let financiar = e.target.value;
    let completo = this.state.precioTotal;
    let porcentaje = (financiar * 100) / completo;
    let maxFinanciacion = parseInt(this.state.maxFinanciacion);
    let maxFinanciacionFloat = parseFloat(this.state.maxFinanciacion) / 100;
    if (porcentaje <= maxFinanciacion) {
      this.setState({
        precioFinanciar: e.target.value,
        porcentajeFinanciar: porcentaje,
        cuotaInicial: completo - e.target.value,
      });
    } else {
      this.setState({
        precioFinanciar: completo * maxFinanciacionFloat,
        porcentajeFinanciar: maxFinanciacion,
        cuotaInicial: completo - completo * maxFinanciacionFloat,
      });
    }
  };

  handleChangePorcentajeFinanciar = (e) => {
    let porcentaje = e.target.value;
    let completo = this.state.precioTotal;
    let financiar = (completo * porcentaje) / 100;
    let maxFinanciacion = parseInt(this.state.maxFinanciacion);
    let maxFinanciacionFloat = parseFloat(this.state.maxFinanciacion) / 100;
    if (porcentaje <= maxFinanciacion) {
      this.setState({
        precioFinanciar: financiar,
        porcentajeFinanciar: porcentaje,
        cuotaInicial: completo - financiar,
      });
    } else {
      this.setState({
        precioFinanciar: completo * maxFinanciacionFloat,
        porcentajeFinanciar: maxFinanciacion,
        cuotaInicial: completo - completo * maxFinanciacionFloat,
      });
    }
  };

  handleChangeCuotaInicial = (e) => {
    let maxFinanciacion = parseInt(this.state.maxFinanciacion);
    let maxFinanciacionFloat = parseFloat(this.state.maxFinanciacion) / 100;
    let cuotaInicial = e.target.value;
    let completo = this.state.precioTotal;

    let diff = completo - cuotaInicial;
    let porcentaje = (diff * 100) / completo;

    if (porcentaje <= maxFinanciacion) {
      this.setState({
        porcentajeFinanciar: porcentaje,
        precioFinanciar: diff,
        cuotaInicial: e.target.value,
      });
    } else {
      this.setState({
        precioFinanciar: completo * maxFinanciacionFloat,
        porcentajeFinanciar: maxFinanciacion,
        cuotaInicial: completo - completo * maxFinanciacionFloat,
      });
    }
  };

  handleChangeFechaInicioPlan = (e) => {
    let mesesDate = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];
    this.setState({
      fechaInicioPlan: e.target.value,
      TableNumCuotas: [],
    });
    var fechaIni = e.target.value;
    var anoIni = parseInt(fechaIni.substring(0, 4));
    var mesIni = parseInt(fechaIni.substring(5, 7));

    var fechaCuotaInicial = this.state.fechaInicioCuotaIni;

    if (fechaCuotaInicial !== '') {
      var anoCuota = parseInt(fechaCuotaInicial.substring(0, 4));
      var mesCuota = parseInt(fechaCuotaInicial.substring(5, 7));
      var dayIni = fechaCuotaInicial.substring(8);

      let months;
      months = (anoIni - anoCuota) * 12;
      months += mesIni;
      months -= mesCuota;
      let newList = [];
      newList.push(<option selected>Seleccione # </option>);

      if (months <= this.state.maximoCuotasInicial) {
        for (let i = 1; i <= months; i++) {
          newList.push(<option> {i} </option>);
        }
      } else {
        let monthMax = mesCuota + parseInt(this.state.maximoCuotasInicial);
        let dayMax = dayIni;
        let anoMax = anoCuota;

        if (monthMax > 12) {
          anoMax++;
          monthMax -= 12;
        }

        let fechaMax = anoMax + '-' + mesesDate[monthMax - 1] + '-' + dayMax;
        this.setState({
          fechaInicioPlan: fechaMax,
        });
        for (let i = 1; i <= this.state.maximoCuotasInicial; i++) {
          newList.push(<option> {i} </option>);
        }
      }

      this.setState({
        TableNumCuotas: newList,
      });
    }
  };

  handleChangeFechaCuotaIni = (e) => {
    let mesesDate = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];
    this.setState({
      fechaInicioCuotaIni: e.target.value,
      TableNumCuotas: [],
    });

    var fechaCuotaInicial = e.target.value;
    var anoCuota = parseInt(fechaCuotaInicial.substring(0, 4));
    var mesCuota = parseInt(fechaCuotaInicial.substring(5, 7));
    var dayIni = fechaCuotaInicial.substring(8);

    var fechaIni = this.state.fechaInicioPlan;
    if (fechaIni !== '') {
      var anoIni = parseInt(fechaIni.substring(0, 4));
      var mesIni = parseInt(fechaIni.substring(5, 7));

      let months;
      months = (anoIni - anoCuota) * 12;
      months += mesIni;
      months -= mesCuota;
      var newList = [];
      newList.push(<option selected>Seleccione # </option>);
      if (months <= this.state.maximoCuotasInicial) {
        for (var i = 1; i <= months; i++) {
          newList.push(<option> {i} </option>);
        }
      } else {
        let monthMax = mesCuota + parseInt(this.state.maximoCuotasInicial);
        let dayMax = dayIni;
        let anoMax = anoCuota;

        if (monthMax > 12) {
          anoMax++;
          monthMax -= 12;
        }

        let fechaMax = anoMax + '-' + mesesDate[monthMax - 1] + '-' + dayMax;
        this.setState({
          fechaInicioPlan: fechaMax,
        });
        for (let i = 1; i <= this.state.maximoCuotasInicial; i++) {
          newList.push(<option> {i} </option>);
        }
      }
      this.setState({
        TableNumCuotas: newList,
      });
    }
  };

  handleChangeNumCuotasInicial = (e) => {
    this.setState({
      numCuotasInicial: e.target.value,
    });
    this.fechasInicial(e.target.value);
  };

  handleChangeValuesIni = (e) => {
    let id = e.target.id;
    this.setState({
      [id]: e.target.value,
    });
  };

  fechasInicial = (num) => {
    let arrNums = [];
    let valorCuotaInicial = parseInt(this.state.cuotaInicial) / num;
    let key = 'valIni';
    let idStr;
    this.setState({
      valIni0: 0,
    });
    for (let i = 1; i <= num; i++) {
      arrNums.push(i);
      idStr = key + i;
      this.setState({
        [idStr]: valorCuotaInicial,
      });
    }
    this.setState({
      arrNumCuotasIni: arrNums,
    });
  };

  calculoCuotasIni = (e) => {
    const id = parseInt(e.target.id.replace('valIni', ''));
    const num = parseInt(this.state.numCuotasInicial);
    const valorCuotaInicial = parseInt(this.state.cuotaInicial);
    var newVal = 0;
    var idStr = '';
    var changedVal = 0;
    if (id === parseInt(num)) {
      idStr = '';
      changedVal = 0;
      for (let i = id - 1; i >= 0; i--) {
        idStr = 'valIni' + i;
        changedVal += parseInt(this.state[idStr]);
      }
      newVal = valorCuotaInicial - changedVal;
      idStr = 'valIni' + id;
      this.setState({
        [idStr]: parseInt(newVal),
      });
    } else {
      idStr = '';
      changedVal = 0;
      for (let i = id; i >= 0; i--) {
        idStr = 'valIni' + i + '';
        changedVal += parseInt(this.state[idStr]);
      }
      newVal = (valorCuotaInicial - changedVal) / (num - id);
      for (let i = id + 1; i <= num; i++) {
        idStr = 'valIni' + i;
        this.setState({
          [idStr]: parseInt(newVal),
        });
      }
    }
  };

  handleChangeNumCuotas = (e) => {
    this.setState({
      numCuotas: e.target.value,
    });
  };

  handleChangePeriodicidad = (e) => {
    this.setState({
      periodicidad: e.target.value,
    });
  };

  handleChangeBoolCuotaFija = () => {
    if (this.state.boolCuotaPersonal) {
      this.setState({
        boolCuotaPersonal: false,
      });
      document.getElementById('generarAutoDiv').className =
        'form-check invisibleTable';
      document.getElementById('valorPersonalizada').className =
        'form-group row cotiCommon invisibleTable';
    } else if (!this.state.boolCuotaPersonal) {
      this.setState({
        boolCuotaPersonal: true,
      });
      document.getElementById('generarAutoDiv').className = 'form-check';
      document.getElementById('valorPersonalizada').className =
        'form-group row cotiCommon';
    }
  };

  handleChangeCuotaPersonal = (e) => {
    this.setState({
      valorCuotaPersonal: e.target.value,
    });
  };

  handleChangeBoolAbonoExtra = () => {
    if (this.state.boolAbonoExtra) {
      this.setState({
        boolAbonoExtra: false,
      });
      document.getElementById('extras').className = 'invisibleTable';
    } else if (!this.state.boolAbonoExtra) {
      this.setState({
        boolAbonoExtra: true,
      });
      document.getElementById('extras').className = '';
    }
  };

  handleChangeBoolExtrasAuto = () => {
    if (this.state.boolExtrasAuto) {
      this.setState({
        boolExtrasAuto: false,
      });
    } else if (!this.state.boolExtrasAuto) {
      this.setState({
        boolExtrasAuto: true,
      });
    }
  };

  handleChangeBoolIntereses = () => {
    let userType = JSON.parse(sessionStorage.getItem('user')).tipo;
    if (userType === 'Admin') {
      if (this.state.boolIntereses) {
        this.setState({
          boolIntereses: false,
        });
      } else if (!this.state.boolIntereses) {
        this.setState({
          boolIntereses: true,
        });
      }
    }
  };

  handleChangeBoolExtraFija = () => {
    if (this.state.boolExtraFija) {
      this.setState({
        boolExtraFija: false,
        boolExtraSola: true,
      });
    } else if (!this.state.boolExtraFija) {
      this.setState({
        boolExtraFija: true,
        boolExtraSola: false,
      });
    }
  };

  handleChangeBoolExtraSola = () => {
    if (this.state.boolExtraSola) {
      this.setState({
        boolExtraFija: true,
        boolExtraSola: false,
      });
    } else if (!this.state.boolExtraSola) {
      this.setState({
        boolExtraFija: false,
        boolExtraSola: true,
      });
    }
  };

  handleChangeNumAbonos = (e) => {
    this.setState({
      numAbonos: e.target.value,
    });
    this.divsAbonos(e.target.value);
  };

  divsAbonos = (num) => {
    let arrNums = [];
    let key = 'valbonos';
    let idStr;
    for (let i = 0; i < num; i++) {
      arrNums.push(i);
      idStr = key + i;
      this.setState({
        [idStr]: 0,
      });
    }
    this.setState({
      arrNumAbonos: arrNums,
    });
    if (this.state.boolExtrasAuto) {
      this.generarExtrasAutomatico(parseInt(num));
    }
  };

  generarExtrasAutomatico = (numAbonos) => {
    let saldo = parseInt(this.state.precioFinanciar);
    let periodos = parseInt(this.state.numCuotas);
    let interes = parseFloat(this.state.tasaInteres) / 100;
    let cuotaFija = parseInt(this.state.valorCuotaPersonal);
    let key = 'valbonos';

    let abInteres, abCapital;
    for (let i = 0; i < periodos; i++) {
      abInteres = saldo * interes;
      abCapital = cuotaFija - abInteres;
      saldo -= abCapital;
    }

    saldo -= cuotaFija;
    let valorExtraAutomatica = saldo / numAbonos;
    for (let i = 0; i < numAbonos; i++) {
      let idStr = key + i;
      this.setState({
        [idStr]: valorExtraAutomatica,
      });
    }
  };

  handleNewCotizacion = () => {};

  handleAmortizacion = () => {
    let TABLEUPLOAD = []; // Arreglo donde se guardaran los objetos con la informacion de la tabla en JSON para subirlo a la base de datos
    let table = ''; // letiable donde se hara la tabla para despues insertarla en la <table> con el id correspondiente
    const mesesDate = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];
    const mensualidadArray = ['Mensual', 'Bimensual', 'Trimestral'];

    let precioFinanciar = parseInt(this.state.precioFinanciar);
    let numCuotas = parseInt(this.state.numCuotas);
    let numAbonos = this.state.numAbonos;
    let periodicidad = this.state.periodicidad;
    let repeticionMeses = mensualidadArray.indexOf(periodicidad) + 1;
    let numCuotaInicial = parseInt(this.state.numCuotasInicial) + 1;
    let precioTotal = parseInt(this.state.precioTotal);
    const precioTotalFIJO = parseInt(this.state.precioTotal);
    let intereses = this.state.tasaInteres;
    intereses = parseFloat(intereses) / 100;

    let interesn, abcapital, cuota, extra, fechaCuotaInicial;
    let matrizCuotas = [];
    let matrizValor = [];

    let boolAutomaticDate = this.state.boolFechaAutomatica; // Boolean donde se checkea si la fecha de realizacion es hoy u otro dia anterior
    let automaticDate,
      automaticMonth,
      automaticYear,
      automaticDay,
      fechaRealizacionPlan;
    if (boolAutomaticDate) {
      automaticDate = new Date();
      automaticMonth = automaticDate.getMonth();
      automaticYear = automaticDate.getFullYear();
      automaticDay = automaticDate.getDate();
      // if ( automaticMonth )
      fechaRealizacionPlan =
        automaticYear + '-' + mesesDate[automaticMonth] + '-' + automaticDay;
    } else if (!boolAutomaticDate) {
      fechaRealizacionPlan = document.getElementById('fecharealizacion').value;
    }

    let fechaInicioPlan = this.state.fechaInicioPlan;
    let anhoPlan = parseInt(fechaInicioPlan.substring(0, 4));
    let mesPlan = parseInt(fechaInicioPlan.substring(5, 7));
    let diaPlan = fechaInicioPlan.substring(8, 10);

    let fechaInicioCuotaInicial = this.state.fechaInicioCuotaIni;
    let anoInicial = parseInt(fechaInicioCuotaInicial.substring(0, 4));
    let mesInicial = parseInt(fechaInicioCuotaInicial.substring(5, 7)) - 1;
    let diaInicial = fechaInicioCuotaInicial.substring(8, 10);

    let boolCuotaPersonal = this.state.boolCuotaPersonal; // Boolean que define si se establecio el valor de una cuota Fija
    if (!boolCuotaPersonal) {
      cuota =
        precioFinanciar / ((1 - (1 + intereses) ** -numCuotas) / intereses);
    } else if (boolCuotaPersonal) {
      cuota = this.state.valorCuotaPersonal;
    }

    let j = 0;
    let indexMeses = mesPlan - 1;
    let fecha = '';
    let id;
    let totalValue = 0;

    // Cabeceras de la tabla de amortizacion
    table += '<tr>';
    table += '<th>Cuota</th>';
    table += '<th>Tipo</th>';
    table += '<th>Fecha</th>';
    table += '<th>Cuota$$</th>';
    table += '<th>Abono Al Capital</th>';
    table += '<th>Abono Al Interes</th>';
    table += '<th>Saldo Total</th>';
    table += '</tr>';

    // primera fila para trabajar con ella en recalculo() y coger el saldo anterior si de edita la cuota #1
    // var valorCuotaInicial=parseInt(document.getElementById("cuotainicial").value)/numCuotaInicial

    /** TODO revisar este ciclo y mejorar nombre de variables y utilizar los estados de React */
    for (let k = 0; k < numAbonos; k++) {
      var idval = 'valbonos' + k;
      var idcuota = 'cuotaabono' + k;
      var valor = document.getElementById(idval).value;
      var numcuota =
        parseInt(document.getElementById(idcuota).value) +
        parseInt(numCuotaInicial) -
        1;
      matrizCuotas[k] = numcuota;
      matrizValor[k] = document.getElementById(idval).value;
      if (!boolCuotaPersonal) {
        var den = (1 - (1 + intereses) ** -numCuotas) / intereses;
        extra = valor / (1 + intereses) ** numcuota / den;
        cuota -= extra;
      }
    }

    // Ciclo para los estados valIniX: Cuota separacion y cuotas iniciales
    for (let x = 0; x < numCuotaInicial; x++) {
      let tipo;
      var idStr = 'valIni' + x;
      var valorCuotaInicial = document.getElementById(idStr).value;
      totalValue += parseInt(valorCuotaInicial);
      precioTotal -= valorCuotaInicial;
      if (x === 0) {
        fechaCuotaInicial = fechaRealizacionPlan;
      } else {
        fechaCuotaInicial =
          anoInicial + '-' + mesesDate[mesInicial] + '-' + diaInicial;
      }

      table += '<tr>';
      table += '<td>' + j + '</td>';
      if (x === 0) {
        table += '<td>CS</td>'; // Tipo de cuota: Cuota de separacion
        tipo = 'CS';
      } else {
        table += '<td>CI</td>'; // Tipo de cuota: Cuota inicial
        tipo = 'CI';
      }
      table += '<td>' + fechaCuotaInicial + '</td>'; // fechas de la cuota inicial
      table += '<td>' + parseInt(valorCuotaInicial) + '</td>';
      table += '<td>' + parseInt(valorCuotaInicial) + '</td>';
      table += '<td>' + 0 + '</td>';
      table += '<td>' + precioTotal + '</td>';
      table += '</tr>';

      if (x === 0) {
      } else {
        if (mesInicial < 12) {
          // fecha=meses[mesInicial]+ano;
          mesInicial++;
        }
        if (mesInicial >= 12) {
          mesInicial = 0;
          anoInicial += 1;
        }
      }

      TABLEUPLOAD.push({
        cuota: parseInt(j),
        fecha: fechaCuotaInicial,
        valorTeoricoCuota: parseInt(valorCuotaInicial),
        abonoCapitalTeorico: parseInt(valorCuotaInicial),
        abonoInteresTeorico: 0,
        saldoRestanteTeorico: precioTotal,
        pagoTotal: 0,
        abonoInteres: 0,
        abonoCapital: 0,
        saldoRestante: precioTotalFIJO,
        estado: 'Por Pagar',
        interesMora: 0,
        tipo: tipo,
      });

      j++;
    }

    // Ciclo para las cuotas Financiadas
    for (let i = 0; i < numCuotas; i++) {
      id = j;
      var written = false;
      // Este if se usa para pasar de mes y de anho si ya se llego hasta diciembre

      if (indexMeses < 12) {
        fecha = anhoPlan + '-' + mesesDate[indexMeses] + '-' + diaPlan;
        indexMeses += repeticionMeses;
      }
      if (indexMeses >= 12) {
        // indexMeses = 0
        indexMeses = indexMeses - 12;
        anhoPlan += 1;
      }
      // Aqui se arman las tablas con los valores de #,fecha,cuotas,interes,capital y saldo
      var cuotaExtraOrd;
      for (let k = 0; k < numAbonos; k++) {
        if (parseInt(matrizCuotas[k]) === j) {
          var cuotaSolaCheck = document.getElementById('cuotaExtraSola')
            .checked;
          var cuotaNormalCheck = document.getElementById('cuotaExtraNormal')
            .checked;
          var interesChek = document.getElementById('cobrarIntereses').checked;

          if (cuotaSolaCheck) {
            cuotaExtraOrd = parseInt(matrizValor[k]);
          } else if (cuotaNormalCheck) {
            cuotaExtraOrd = parseInt(matrizValor[k]) + cuota;
          }

          if (interesChek) {
            interesn = precioFinanciar * intereses;
          } else {
            interesn = 0;
          }

          if (i + 1 === numCuotas && precioFinanciar !== 0) {
            cuotaExtraOrd = precioFinanciar + interesn;
          }

          abcapital = cuotaExtraOrd - interesn;
          precioFinanciar -= abcapital;

          cuotaExtraOrd = parseInt(cuotaExtraOrd);
          precioFinanciar = parseInt(precioFinanciar);
          interesn = parseInt(interesn);
          abcapital = parseInt(abcapital);

          table += '<tr id="' + id + '">';
          table += '<td>' + j + '</td>';
          table += '<td>C</td>';
          table += '<td>' + fecha + '</td>';
          table +=
            '<td onkeyup="recalcular(' +
            id +
            ')" contenteditable="true">' +
            cuotaExtraOrd +
            '</td>';
          table += '<td>' + abcapital + '</td>';
          table += '<td>' + interesn + '</td>';
          table += '<td>' + precioFinanciar + '</td>';
          table += '</tr>';

          totalValue += cuotaExtraOrd;

          TABLEUPLOAD.push({
            cuota: parseInt(j),
            fecha: fecha,
            valorTeoricoCuota: cuotaExtraOrd,
            abonoCapitalTeorico: abcapital,
            abonoInteresTeorico: interesn,
            saldoRestanteTeorico: precioFinanciar,
            pagoTotal: 0,
            abonoInteres: 0,
            abonoCapital: 0,
            saldoRestante: precioTotalFIJO,
            estado: 'Por Pagar',
            interesMora: 0,
            tipo: 'C',
          });
          written = true;
        }
      }

      if (!written) {
        interesn = precioFinanciar * intereses;

        if (i + 1 === numCuotas && precioFinanciar !== 0) {
          cuota = precioFinanciar + interesn;
        }
        abcapital = cuota - interesn;
        precioFinanciar -= abcapital;

        cuota = parseInt(cuota);
        precioFinanciar = parseInt(precioFinanciar);
        interesn = parseInt(interesn);
        abcapital = parseInt(abcapital);

        table += '<tr id="' + id + '">';
        table += '<td>' + parseInt(j) + '</td>';
        table += '<td>C</td>';
        table += '<td>' + fecha + '</td>';
        table +=
          '<td onkeyup="recalcular(' +
          id +
          ')" contenteditable="true">' +
          cuota +
          '</td>';
        table += '<td>' + abcapital + '</td>';
        table += '<td>' + interesn + '</td>';
        table += '<td>' + precioFinanciar + '</td>';
        table += '</tr>';

        totalValue += cuota;

        TABLEUPLOAD.push({
          cuota: parseInt(j),
          fecha: fecha,
          valorTeoricoCuota: cuota,
          abonoCapitalTeorico: abcapital,
          abonoInteresTeorico: interesn,
          saldoRestanteTeorico: precioFinanciar,
          pagoTotal: 0,
          abonoInteres: 0,
          abonoCapital: 0,
          saldoRestante: precioTotalFIJO,
          estado: 'Por Pagar',
          interesMora: 0,
          tipo: 'C',
        });
      }
      j++;
    }

    this.setState({
      fechaRealizacionPlan: fechaRealizacionPlan,
      tableUpload: TABLEUPLOAD,
      valorFinal: totalValue,
    });

    // se copia la tabla en el documento html
    document.getElementById('table').innerHTML =
      '<table id="amort" class="table table-bordered  table-striped">' +
      table +
      '</table>';
    // document.getElementById('tbodyTable').innerHTML += table
    // document.getElementById('nombre').value = this.state.nombre
    // document.getElementById('cedula').value = this.state.cedula
    // document.getElementById('celular').value = this.state.celular
    // document.getElementById('email').value = this.state.email
    // document.getElementById('direccion').value = this.state.direccion
    // document.getElementById('vendedor').value = this.state.vendedor
    // document.getElementById('observaciones').value = this.state.observaciones
    // document.getElementById('numLote').value = this.state.inmueble + this.state.numInmueble
    // <div className='cotiCommon invisible' id='descarga'>
    // document.getElementById('descarga').className = 'cotiCommon'
  };

  handleUpload = () => {
    if (this.checkFillAll()) {
      document.getElementById('updatingPlan').className =
        'btn btn-primary buttonUpload';
      document.getElementById('updateBtnPlan').className =
        'btn btn-primary buttonUpload invisible';
      this.handleUpdate();
    } else {
      this.setState({
        show: true,
        titleModal: <div className="center wrong" />,
        messageModal:
          'Debes Llenar todos los campos iniciales antes de subir el Plan',
      });
    }
  };

  checkFillAll() {
    var {
      numInmueble,
      inmueble,
      precioTotal,
      tasaInteres,
      precioFinanciar,
      porcentajeFinanciar,
      periodicidad,
      numCuotas,
      fechaInicioPlan,
      fechaInicioCuotaIni,
      numCuotasInicial,
      cuotaInicial,
    } = this.state;
    var arreglo = [
      numInmueble,
      inmueble,
      precioTotal,
      tasaInteres,
      precioFinanciar,
      porcentajeFinanciar,
      periodicidad,
      numCuotas,
      fechaInicioPlan,
      fechaInicioCuotaIni,
      numCuotasInicial,
      cuotaInicial,
    ];

    for (var i = 0; i < arreglo.length; i++) {
      if (arreglo[i] === '') {
        return false;
      }
    }
    return true;
  }

  handleUpdate = () => {
    this.handleClose();
    this.updateTable(this.state.idTabla);
  };

  updateTable = (idTablaOver) => {
    var tablaObject = {
      content: this.state.tableUpload,
    };
    client
      .mutate({
        mutation: UPDATETABLE,
        variables: { id: idTablaOver, data: tablaObject },
      })
      .then((result) => {
        this.updatePlan(idTablaOver);
      })
      .catch((err) => {
        this.setState({
          show: true,
          titleModal: <div className="center wrong" />,
          messageModal: 'Error al actualizar la tabla en la base de datos',
        });
        console.log(err);
        document.getElementById('updatingPlan').className =
          'btn btn-primary buttonUpload invisible';
        document.getElementById('updateBtnPlan').className =
          'btn btn-primary buttonUpload';
      });
  };

  updatePlan = (idTablaOver) => {
    var arrCuotasExtra = [];
    var arrCuotasInicial = [];
    for (let i = 0; i < this.state.numAbonos; i++) {
      let key = 'valbonos' + i;
      let keyNum = 'cuotaabono' + i;
      arrCuotasExtra.push({
        cuota: parseInt(this.state[keyNum]),
        valor: parseFloat(this.state[key]),
      });
    }
    for (let i = 1; i <= this.state.numCuotasInicial; i++) {
      let key = 'valIni' + i;
      arrCuotasInicial.push({
        cuota: i,
        valor: parseFloat(this.state[key]),
      });
    }

    var list = {
      tabla: idTablaOver,
      numeroCuotas: parseInt(this.state.numCuotas),
      periodicidad: this.state.periodicidad,
      porcentajeAFinanciar: parseFloat(this.state.porcentajeFinanciar),
      tasaInteres: parseFloat(this.state.tasaInteres),
      valorTotal: parseFloat(this.state.precioTotal),
      valorFinal: parseFloat(this.state.valorFinal),
      valorAFinanciar: parseFloat(this.state.precioFinanciar),
      saldoRestante: parseFloat(this.state.precioTotal),
      cuotaSeparacion: parseFloat(this.state['valIni0']),
      observaciones: this.state.Observaciones,
      vendedor: this.state.vendedor,
      fechaInicialPlan: this.state.fechaInicioPlan,
      fechaInicioCuotaIni: this.state.fechaInicioCuotaIni,
      fechaRealizacionPlan: this.state.fechaRealizacionPlan,
      cuotaPersonal: this.state.boolCuotaPersonal,
      valorCuotaPersonal: parseFloat(this.state.valorCuotaPersonal),
      numAbonos: parseInt(this.state.numAbonos),
      cuotasExtra: arrCuotasExtra,
      cuotaExtraSola: this.state.boolExtraSola,
      cuotaExtraFija: this.state.boolExtraFija,
      cobrarInteres: this.state.boolIntereses,
      valorCuotaInicial: parseFloat(this.state.cuotaInicial),
      cuotasInicial: arrCuotasInicial,
      pagoTotal: 0,
    };
    client
      .mutate({
        mutation: UPDATEPLAN,
        variables: { id: this.state.idPlan, data: list },
      })
      .then((result) => {
        this.setState({
          show: true,
          titleModal: <div className="center success" />,
          messageModal: 'Plan actualizado correctamente',
        });
        document.getElementById('updatingPlan').className =
          'btn btn-primary buttonUpload invisible';
        document.getElementById('updateBtnPlan').className =
          'btn btn-primary buttonUpload';
      })
      .catch((err) => {
        this.setState({
          show: true,
          titleModal: <div className="center wrong" />,
          messageModal: 'Error al actualizar el Plan En la base de Datos',
        });
        console.log(err);
        document.getElementById('updatingPlan').className =
          'btn btn-primary buttonUpload invisible';
        document.getElementById('updateBtnPlan').className =
          'btn btn-primary buttonUpload';
      });
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  render() {
    let separacion = (
      <div>
        <div className="col-xs-2 cotiCommon">
          <label htmlFor="exampleFormControlInput1">Separacion</label>
        </div>
        <div className="col-xs-2 cotiCommon">
          <input
            type="number"
            id={`valIni0`}
            className="form-control hundredTwentyWidth"
            placeholder="Cuota separacion"
            value={this.state['valIni0']}
            onChange={this.handleChangeValuesIni}
            onBlur={this.calculoCuotasIni}
          />
        </div>
      </div>
    );
    let i = 0;
    let cuotasRender = this.state.arrNumCuotasIni.map((vals) => {
      i++;
      let key = 'valIni' + i;
      return (
        <div>
          <div className="col-xs-2 cotiCommon">
            <label htmlFor="exampleFormControlInput1">{`Cuota ${i}`}</label>
          </div>
          <div className="col-xs-2 cotiCommon">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id={`date-cuotaini${i}`}>
                  {i}
                </span>
              </div>
              {i === parseInt(this.state.numCuotasInicial) ? (
                <input
                  disabled
                  type="number"
                  id={`valIni${i}`}
                  className="form-control"
                  value={this.state[key]}
                  onChange={this.handleChangeValuesIni}
                  onBlur={this.calculoCuotasIni}
                />
              ) : (
                <input
                  type="number"
                  id={`valIni${i}`}
                  className="form-control"
                  value={this.state[key]}
                  onChange={this.handleChangeValuesIni}
                  onBlur={this.calculoCuotasIni}
                />
              )}
            </div>
          </div>
        </div>
      );
    });
    let join = [separacion, cuotasRender];
    // Las Variables separacion cuotasRender y Join hacen parte de el valor de las cuotas iniciales dividas
    // La varianle numAbonosObject hace referencia a los objectos que se renderizaran de los abonos extras programados
    let numAbonosObject = this.state.arrNumAbonos.map((bonos) => {
      let keyValAbonos = `valbonos${bonos}`;
      let keyNumAbonos = `cuotaabono${bonos}`;
      return (
        <div className="col-md cotiCommon abonosExtraWith">
          <div className="input-group mb-3">
            <input
              placeholder="Cuota #"
              type="text"
              id={`cuotaabono${bonos}`}
              className="form-control widthInputAbono"
              value={this.state[keyNumAbonos]}
              onChange={this.handleChangeValuesIni}
            />
            <div className="input-group-append input-group-prepend">
              <span className="input-group-text" id={bonos}>
                {bonos + 1}
              </span>
            </div>
            <input
              placeholder="Valor Abono"
              type="text"
              id={`valbonos${bonos}`}
              className="form-control widthInputAbono"
              value={this.state[keyValAbonos]}
              onChange={this.handleChangeValuesIni}
            />
          </div>
        </div>
      );
    });

    let optionTypeInmueble = this.state.listaInmueble.map((inmbueble) => {
      return <option key={inmbueble.tipo}>{inmbueble.tipo}</option>;
    });

    let optionNumInmueble = this.state.listaNumInmueble.map((nums) => {
      return <option>{nums}</option>;
    });

    let optionPlan = this.props.planes.map((plan) => {
      if (plan.proyecto === this.state.proyecto) {
        return (
          <option id={plan.descripcion} value={plan.idPlan}>
            {plan.descripcion}
          </option>
        );
      }
      return null;
    });

    return (
      <div>
        <div>
          <h6>Seleccione el plan que desea Modificar</h6>
          <select
            className="form-control planoption"
            onChange={this.handleChangeIdPlan}
          >
            <option defaultValue>Seleccione</option>
            {optionPlan}
          </select>
        </div>
        <div>
          <h3>Datos Plan</h3>
          <table
            className="table table-striped table-bordered"
            id="infoPersonal"
          >
            <tbody id="bodyInfoPerso">
              <tr>
                <th>Vendedor</th>
                <td id="vendedor" className="invisibleTD">
                  <input
                    className="invisibleInput"
                    value={this.state.vendedor}
                    onChange={this.handleChangeVendedor}
                  />
                </td>
              </tr>
              <tr>
                <th>Observaciones</th>
                <td id="observaciones" className="invisibleTD">
                  <input
                    className="invisibleInput"
                    value={this.state.Observaciones}
                    onChange={this.handleChangeObservaciones}
                  />
                </td>
              </tr>
              <tr>
                <th># Inmbueble</th>
                <td id="numLote">
                  <div className="form-group row cotiCommon">
                    <div className="tipoInput">
                      <div className="input-group">
                        <select
                          id="tipoLote"
                          className="form-control hundredWidth"
                          value={this.state.inmueble}
                          onChange={this.handleChangeTipoInmueble}
                          disabled
                        >
                          {optionTypeInmueble}
                        </select>
                        <div className="input-group-append input-group-prepend">
                          <span className="input-group-text" id="id">
                            #
                          </span>
                        </div>
                        <select
                          id="tipoLote"
                          className="form-control fiftyWidth"
                          value={this.state.numInmueble}
                          onChange={this.handleChangeNumInmueble}
                          disabled
                        >
                          {optionNumInmueble}
                        </select>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3>Datos financiacion</h3>
        <div className="cotiCommon" id="fechaReal">
          <div className="form-group row cotiCommon" id="rea">
            <div className="col-xs-2 cotiCommon">
              <label htmlFor="exampleFormControlInput1">
                {' '}
                Fecha realizacion plan:{' '}
              </label>
              <input
                type="date"
                id="fecharealizacion"
                className="form-control"
                value={this.state.fechaRealizacion}
                onChange={this.handleChangeFechaRealizacion}
              />
            </div>
          </div>
        </div>
        <div className="form-group row cotiCommon">
          <div className="col-xs-2 cotiCommon">
            <label htmlFor="exampleFormControlInput1"> Tasa interes %: </label>
            {this.state.tipoVendedor === 'Admin' ? (
              <input
                type="number"
                id="interes"
                className="form-control hundredWidth"
                width="35px"
                value={this.state.tasaInteres}
                onChange={this.handleChangeTasa}
              />
            ) : (
              <input
                type="number"
                id="interes"
                className="form-control hundredWidth"
                width="35px"
                value={this.state.tasaInteres}
                onChange={this.handleChangeTasa}
                disabled
              />
            )}
          </div>
          <div className="col-xs-2 cotiCommon">
            <label htmlFor="exampleFormControlInput1">
              {' '}
              Precio total inmueble:{' '}
            </label>
            <input
              type="number"
              id="precioFull"
              className="form-control"
              width="35px"
              value={this.state.precioTotal}
              onChange={this.handleChangePrecioTotal}
            />
          </div>
          <div className="col-xs-2 cotiCommon">
            <label htmlFor="exampleFormControlInput1">
              {' '}
              Precio a financiar:{' '}
            </label>
            <input
              type="number"
              id="financiar"
              className="form-control"
              width="35px"
              value={this.state.precioFinanciar}
              onChange={this.handleChangePrecioFinanciar}
            />
          </div>
          <div className=" col-xs-2 cotiCommon">
            <label htmlFor="exampleFormControlInput1"> % a financiar: </label>
            <input
              type="number"
              id="porfinanciar"
              className="form-control ninetyWidth"
              width="35px"
              value={this.state.porcentajeFinanciar}
              onChange={this.handleChangePorcentajeFinanciar}
            />
          </div>
          <div className="col-xs-2 cotiCommon">
            <label htmlFor="exampleFormControlInput1"> Cuota inicial: </label>
            <input
              type="number"
              id="cuotainicial"
              className="form-control"
              value={this.state.cuotaInicial}
              onChange={this.handleChangeCuotaInicial}
            />
          </div>
        </div>
        <div className="form-group row cotiCommon" id="ini">
          <div className="col-xs-2 cotiCommon">
            <div className="col-xs-2 ">
              <label htmlFor="exampleFormControlInput1">
                Fecha Cuota inicial
              </label>
              <i className="fas fa-info-circle tooltip right">
                <span className="tiptext">
                  Esta fecha indica el día en el que la cuota inicial será
                  empezada a pagar
                </span>
              </i>
              <input
                type="date"
                id="fechaCuotaInicial"
                className="form-control oneseventreeW"
                value={this.state.fechaInicioCuotaIni}
                onChange={this.handleChangeFechaCuotaIni}
              />
            </div>
          </div>
          <div className="thirtyPadding">
            <label htmlFor="exampleFormControlInput1">
              {' '}
              Fecha Plan financiacion{' '}
            </label>
            <i className="fas fa-info-circle tooltip right">
              <span className="tiptext">
                Esta fecha indica el día en el que el valor financiado será
                empezado a pagar
              </span>
            </i>
            <input
              type="date"
              id="fechainicial"
              className="form-control oneseventreeW"
              value={this.state.fechaInicioPlan}
              onChange={this.handleChangeFechaInicioPlan}
            />
          </div>
          <div className="col-xs-2 cotiCommon" id="divCuotasIni">
            <label htmlFor="exampleFormControlSelect1">Cuotas Inicial</label>
            <select
              className="form-control"
              id="numCuotasInicial"
              value={this.state.numCuotasInicial}
              onChange={this.handleChangeNumCuotasInicial}
            >
              {this.state.TableNumCuotas}
            </select>
          </div>
          <div className="col-xs-2 cotiCommon" id="fechasCuotasIni">
            <div className="cotiCommon">
              <div
                id="innerCuotasInicial"
                className="form-group row cotiCommon"
              >
                {this.state.arrNumCuotasIni !== [] ? join : null}
                {this.state.innerCuotasInicial}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="form-group row cotiCommon">
            <div className="col-xs-2 cotiCommon">
              <label htmlFor="exampleFormControlInput1"> Cuotas: </label>
              <input
                type="number"
                id="cuotas"
                className="form-control hundredWidth"
                width="35px"
                value={this.state.numCuotas}
                onChange={this.handleChangeNumCuotas}
              />
            </div>
            <div className="col-xs-2 cotiCommon">
              <label htmlFor="exampleFormControlSelect1">Periodicidad</label>
              {/* Estate periodicidad */}
              <select
                className="form-control"
                id="periodicidad"
                value={this.state.periodicidad}
                onChange={this.handleChangePeriodicidad}
              >
                <option>Mensual</option>
                <option>Bimensual</option>
                <option>Trimestral</option>
              </select>
            </div>
          </div>
        </div>
        {/* <!--Aqui se realiza las cuotas personalizadas, esto para establecer cuotas fijas y abonos extras 100% al capital--> */}
        <div className="cotiCommon" id="personalizada">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="checkCuota"
              checked={this.state.boolCuotaPersonal}
              onClick={this.handleChangeBoolCuotaFija}
            />
            <label className="form-check-label" htmlFor="defaultCheck1">
              Establecer cuota Fija
            </label>
            <i className="fas fa-info-circle tooltip right">
              <span className="tiptext">
                Se establece el valor de las cuotas en el plan de financiación,
                la ultima cuota será igual al saldo restante a la fecha
              </span>
            </i>
          </div>
          <div
            className={
              this.state.boolCuotaPersonal
                ? 'form-group row cotiCommon'
                : 'form-group row cotiCommon invisibleTable'
            }
            id="valorPersonalizada"
          >
            <div className="col-xs-2 cotiCommon">
              <label htmlFor="exampleFormControlInput1">
                {' '}
                Valor Cuota Fija:{' '}
              </label>
              <input
                type="number"
                id="valcuotapers"
                className="form-control"
                width="35px"
                value={this.state.valorCuotaPersonal}
                onChange={this.handleChangeCuotaPersonal}
              />
            </div>
          </div>
        </div>

        {/* <!--Se chequea si el plan va a tener abonos extras--> */}
        <div className="cotiCommon">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="check1"
              checked={this.state.boolAbonoExtra}
              onClick={this.handleChangeBoolAbonoExtra}
              readOnly
            />
            <label className="form-check-label" htmlFor="defaultCheck1">
              Abonos extra programados
            </label>
            <i className="fas fa-info-circle tooltip right">
              <span className="tiptext">
                Se establece el numero y valor de los abonos extra
              </span>
            </i>
          </div>
        </div>
        {/* <!--Aqui se establecen las cuotas extras que el cliente desee realizar--> */}
        <div
          id="extras"
          className={this.state.boolAbonoExtra ? '' : 'invisibleTable'}
        >
          <div>
            <div className="twentyPadding">
              <div className="form-check" id="generarAutoDiv">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="generarAutomatico"
                  checked={this.state.boolExtrasAuto}
                  onClick={this.handleChangeBoolExtrasAuto}
                  readOnly
                />
                <label className="form-check-label" htmlFor="defaultCheck1">
                  Generar Extras Automaticamente
                </label>
                <i className="fas fa-info-circle tooltip right">
                  <span className="tiptext">
                    Al establecer la cuota fija, el sistema puede calcular un
                    valor aproximado de las cuotas extra
                  </span>
                </i>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="cobrarIntereses"
                  checked={this.state.boolIntereses}
                  onClick={this.handleChangeBoolIntereses}
                  readOnly
                />
                <label className="form-check-label" htmlFor="defaultCheck1">
                  Cobrar Intereses
                </label>
                <i className="fas fa-info-circle tooltip right">
                  <span className="tiptext">
                    Se establece si se le cobrará intereses en las cuotas de
                    abono extra
                  </span>
                </i>
              </div>
            </div>
            <div className="twentyPadding">
              <div className="form-check twentyPadding">
                <div>
                  <input
                    className="form-check-input fifthPadding"
                    type="checkbox"
                    id="cuotaExtraNormal"
                    checked={this.state.boolExtraFija}
                    onClick={this.handleChangeBoolExtraFija}
                    readOnly
                  />
                  <label
                    className="form-check-label fifthPadding"
                    htmlFor="defaultCheck1"
                  >
                    Cuota extra+Cuota Fija
                  </label>
                  <i className="fas fa-info-circle tooltip right">
                    <span className="tiptext">
                      Se establece que el valor de la cuota(en la que se
                      programe el abono) sea igual al valor del abono + el valor
                      de la cuota normal(fija)
                    </span>
                  </i>
                </div>
                <div>
                  <input
                    className="form-check-input fifthPadding"
                    type="checkbox"
                    id="cuotaExtraSola"
                    checked={this.state.boolExtraSola}
                    onClick={this.handleChangeBoolExtraSola}
                    readOnly
                  />
                  <label
                    className="form-check-label fifthPadding"
                    htmlFor="defaultCheck1"
                  >
                    Cuota Extra sola
                  </label>
                  <i className="fas fa-info-circle tooltip right">
                    <span className="tiptext">
                      Se establece que el valor de la cuota(en la que se
                      programe el abono) sea igual al valor del abono
                    </span>
                  </i>
                </div>
              </div>
            </div>
            <div className="form-group row cotiCommon">
              <div className="col-xs-2 cotiCommon">
                <label htmlFor="exampleFormControlInput1">
                  {' '}
                  Numero abonos extra:{' '}
                </label>
                <i className="fas fa-info-circle tooltip right">
                  <span className="tiptext">
                    Se debe tener en cuenta que en la mayoria de los casos la
                    ultima cuota será de un valor diferente al valor de la cuota
                    normal y de los abonos
                  </span>
                </i>
                <input
                  type="number"
                  id="numbonos"
                  className="form-control hundredWidth"
                  width="35px"
                  value={this.state.numAbonos}
                  onChange={this.handleChangeNumAbonos}
                />
              </div>
            </div>
          </div>
          <div id="dates">
            <div className="form-group cotiCommon">{numAbonosObject}</div>
          </div>
        </div>

        <div className="cotiCommon buttonCreate" id="confirm">
          <button
            id="btnConfirm"
            type="submit"
            className="btn btn-primary mb-2"
            onClick={this.handleAmortizacion}
          >
            Generar Plan
          </button>
        </div>

        <div id="divReload" className="reload invisibleTable">
          <button
            className="btn btn-primary mb-2 reloadButton"
            id="reloadPage"
            onClick={this.handleNewCotizacion}
          >
            Nueva cotizacion
          </button>
        </div>
        {/* <!--Esta tabla es el espacio para la tabla de amortizacion que se crea con los datos de financiacion--> */}
        <div id="table" className="paddingTable" />

        <button
          id="updatingPlan"
          className="btn btn-primary buttonUpload invisible"
          type="button"
          style={{ float: 'right' }}
          disabled
        >
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          {` Actualizado...`}
        </button>
        <button
          className="btn btn-primary mb-2 buttonUpload"
          id="updateBtnPlan"
          style={{ float: 'right' }}
          onClick={this.handleUpload}
        >
          Actualizar Plan
        </button>

        <Modal isOpen={this.state.show} toggle={this.handleClose}>
          <ModalHeader>
            <>{this.state.titleModal}</>
          </ModalHeader>
          <ModalBody>{this.state.messageModal}</ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-default"
              onClick={this.handleClose}
            >
              Cerrar
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
