import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import React, { useRef, useState } from 'react';
import './LoadLeads.scss';
import { Container } from 'reactstrap';
import axiosInstance from 'utils/database/api-leads';
import 'react-toastify/dist/ReactToastify.min.css';
import { toast } from 'react-toastify';
import CloudDownload from '@material-ui/icons/CloudDownload';
import { Link } from 'react-router-dom';

const LoadLeads = () => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const inputRef = useRef(null);

  const handleChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      let file = files[0];
      setFile(file);
      setFileName(file.name);
    } else {
      setFile(null);
      setFileName(null);
    }
  };

  const uploadHandler = (event) => {
    event.preventDefault();

    if (getExtension(file.name) !== 'xlsx') {
      toast.error('Formato de archivo no soportado');
      return;
    }

    // upload file
    const formData = new FormData();
    formData.append('leads', file, file.name);
    axiosInstance
      .post('/leads/load', formData)
      .then((res) => {
        toast.success(res.data.message);
        file.isUploading = false;
        setFile(null);
        setFileName(null);
        inputRef.current.value = null;
      })
      .catch((err) => {
        toast.error(err.response.data.message, { autoClose: false });
        setFile(null);
        setFileName(null);
        inputRef.current.value = null;
      });
  };

  const getExtension = (filename) => {
    return filename.toString().split('.').pop();
  };

  return (
    <Container>
      <form onSubmit={uploadHandler}>
        <div className="file-card form-group">
          <div className="file-inputs">
            <input
              type="file"
              className="form-control"
              onChange={handleChange}
              accept=".xlsx"
              required
              ref={inputRef}
            />
            <button>
              <i>
                <FontAwesomeIcon icon={faPlus} />
              </i>
              Examinar
            </button>
          </div>
          {file ? <h6>Archivo seleccionado: {fileName}</h6> : null}
          <p className="main">Formato aceptado</p>
          <p className="info">.XLSX</p>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled={file === null}
          >
            Guardar
          </Button>
        </div>
      </form>
      <Button variant="text" startIcon={<CloudDownload color="primary" />}>
        <Link to={'/files/guia.xlsx'} target="_blank">
          Archivo guia
        </Link>
      </Button>
    </Container>
  );
};

export default LoadLeads;
