import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import ReactToExcel from 'react-html-table-to-excel';
import { Button, Col, Row } from 'reactstrap';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/es';
import client from 'utils/database';
import { PROYECCIONPAGOSREPORT } from 'utils/database/query/reports';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableReport } from './Tabla';
import { bodyTableExport, setCurrentDate } from './helper';

const useStyles = makeStyles({
  carga: {
    align: 'center',
  },
});

const ProyeccionVsPagos = ({ proyecto, show, filter, nombre }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [fechaFinal, setFechaFinal] = useState(null);
  const [loading, setLoading] = useState(false);

  const getData = () => {
    setLoading(true);
    var dateF = fechaFinal.toISOString();
    client
      .query({
        query: PROYECCIONPAGOSREPORT,
        variables: { proyecto: proyecto, fechaFinalProyeccion: dateF },
      })
      .then((result) => {
        setLoading(false);
        setData(result.data.ReporteProyeccionVsPagos);
      });
  };

  const handleShowDownload = () => {
    return (
      <ReactToExcel
        className="btn btn-success mb-2 spaced"
        table="reporte"
        filename={`${nombre}`}
        sheet="sheet 1"
        buttonText="Exportar"
      />
    );
  };

  return (
    <>
      {show && (
        <>
          {loading ? (
            <CircularProgress className={classes.carga} />
          ) : (
            <>
              <hr />
              <Row>
                <Col xs="2">
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={deLocale}
                  >
                    <KeyboardDatePicker
                      variant="inline"
                      InputProps={{ readOnly: true }}
                      format="dd-MM-yyyy"
                      margin="normal"
                      label="Seleccione la fecha final"
                      value={fechaFinal}
                      onChange={(date) => {
                        setFechaFinal(date);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disablePast
                    />
                  </MuiPickersUtilsProvider>
                </Col>
                <Col>
                  <Button
                    color="primary"
                    style={{ marginTop: '25px' }}
                    onClick={getData}
                    disabled={fechaFinal === null}
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'sync-alt']}
                      color="white"
                      className="mr-1"
                    />
                    Generar tabla
                  </Button>
                </Col>
                <Col></Col>
              </Row>
              {data.length > 0 && handleShowDownload()}
              <br />
              <TableReport data={data} filter={null} />
              <div id="divExportar" className="invisible">
                <table id="reporte" className="table-bordered">
                  <tbody>
                    <tr>
                      <td />
                      <th colSpan={3} className="title">
                        LAR PROYECTOS SAS
                      </th>
                      <td />
                      <td />
                      <td />
                    </tr>
                    <tr>
                      <td />
                      <th colSpan={3} className="title">
                        PAGOS VS PROYECCION
                      </th>
                      <td />
                      <td />
                      <td />
                    </tr>
                    <tr>
                      <td />
                      <th colSpan={3} className="title">
                        {proyecto}
                      </th>
                      <td />
                      <td />
                      <td />
                    </tr>
                    <tr>
                      <td />
                      <th colSpan={3} className="title">
                        {setCurrentDate()}
                      </th>
                      <td />
                      <td />
                      <td />
                    </tr>
                    <tr>
                      <td style={{ width: '0.8cm' }} />
                      <td style={{ width: '0.8cm' }} />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td style={{ width: '0.8cm' }} />
                    </tr>

                    <tr>
                      <td colSpan={3} />
                      <td colSpan={3} />
                      <td colSpan={2} />
                      <td colSpan={2} />
                    </tr>

                    <tr>
                      <td
                        colSpan={10}
                        style={{ borderBottom: '1px solid black' }}
                      />
                    </tr>

                    <tr>
                      <th
                        colSpan={12}
                        style={{
                          border: '1px solid black',
                          textAlign: 'center',
                        }}
                      >
                        Cliente
                      </th>
                      <th
                        colSpan={6}
                        style={{
                          border: '1px solid black',
                          textAlign: 'center',
                        }}
                      >
                        Pagos en el periodo definido
                      </th>
                      <th
                        colSpan={6}
                        style={{
                          border: '1px solid black',
                          textAlign: 'center',
                        }}
                      >
                        Proyeccion
                      </th>
                    </tr>

                    <tr>
                      <th
                        colSpan={6}
                        style={{
                          borderBottom: '1px solid black',
                          borderRight: '1px solid black',
                          textAlign: 'center',
                        }}
                      >
                        NOMBRE
                      </th>
                      <th
                        colSpan={2}
                        style={{
                          borderBottom: '1px solid black',
                          borderRight: '1px solid black',
                          textAlign: 'center',
                        }}
                      >
                        PROYECTO
                      </th>
                      <th
                        colSpan={2}
                        style={{
                          borderBottom: '1px solid black',
                          borderRight: '1px solid black',
                          textAlign: 'center',
                        }}
                      >
                        LOTE
                      </th>
                      <th
                        colSpan={2}
                        style={{
                          borderBottom: '1px solid black',
                          borderRight: '1px solid black',
                          textAlign: 'center',
                        }}
                      >
                        VALOR LOTE
                      </th>
                      <th
                        colSpan={2}
                        style={{
                          borderBottom: '1px solid black',
                          borderRight: '1px solid black',
                          textAlign: 'center',
                        }}
                      >
                        Total
                      </th>
                      <th
                        colSpan={2}
                        style={{
                          borderBottom: '1px solid black',
                          borderRight: '1px solid black',
                          textAlign: 'center',
                        }}
                      >
                        ABONO CAPITAL
                      </th>
                      <th
                        colSpan={2}
                        style={{
                          borderBottom: '1px solid black',
                          borderRight: '1px solid black',
                          textAlign: 'center',
                        }}
                      >
                        ABONO INTERÉS
                      </th>
                      <th
                        colSpan={2}
                        style={{
                          borderBottom: '1px solid black',
                          borderRight: '1px solid black',
                          textAlign: 'center',
                        }}
                      >
                        Total
                      </th>
                      <th
                        colSpan={2}
                        style={{
                          borderBottom: '1px solid black',
                          borderRight: '1px solid black',
                          textAlign: 'center',
                        }}
                      >
                        PROYECCION CAPITAL
                      </th>
                      <th
                        colSpan={2}
                        style={{
                          borderBottom: '1px solid black',
                          borderRight: '1px solid black',
                          textAlign: 'center',
                        }}
                      >
                        PROYECCION INTERÉS
                      </th>
                    </tr>
                    {bodyTableExport(data)}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProyeccionVsPagos;
