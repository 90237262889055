// @ts-nocheck
import { Button, Container, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { toast } from 'react-toastify';
import axiosInstance from 'utils/database/api-leads';
import Loading from 'components/containers/Loading/Loading';
import { DataGrid, esES } from '@mui/x-data-grid';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { GridToolbarFilterButton } from '@mui/x-data-grid';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModalDetail from '../ModalDetail/ModalDetail';
import { Link } from 'react-router-dom';

const ScheduledManagements = () => {
  const [filtros, setFiltros] = useState({
    fechaInicial: '',
    fechaFinal: '',
    idUsuario: '',
  });
  const [gestiones, setGestiones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showObservationTable, setShowObservationTable] = useState(false);
  const [observacionTable, setObservacionTable] = useState('');
  const [titleObservacion, setTitleObservacion] = useState('');

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    getUserId(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserId = (user) => {
    setFiltros({ ...filtros, idUsuario: user._id });
  };

  const handleChange = (event) => {
    setFiltros({ ...filtros, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (new Date(filtros.fechaFinal) < new Date(filtros.fechaInicial)) {
      toast.warning('La Fecha Final debe ser mayor o igual a la Fecha Inicial');
      return;
    }
    setLoading(true);
    axiosInstance
      .post('/leads//management/schedules/get-all', filtros)
      .then((res) => {
        let { data } = res.data;
        setGestiones(data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  const getMinDate = (date) => {
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return date.toISOString().slice(0, 16);
  };

  const handleShowObservation = (tipoGestion, observacion) => {
    setObservacionTable(observacion);
    setTitleObservacion(tipoGestion);
    setShowObservationTable(true);
  };

  const handleHideObservationTable = () => {
    setObservacionTable('');
    setTitleObservacion('');
    setShowObservationTable(false);
  };

  const columns = [
    {
      field: '_id',
      disableExport: true,
      filterable: false,
      headerName: 'ID',
      headerAlign: 'center',
      align: 'center',
      width: 70,
      hide: true,
    },
    {
      field: 'nombreCompleto',
      headerAlign: 'center',
      align: 'center',
      headerName: 'Nombre Lead',
      width: 200,
      renderCell: (cellValues) => {
        return (
          <>
            <Link to={`/leads/manage/${cellValues.row.idLead}`}>
              {cellValues.row.nombreCompleto}
            </Link>
          </>
        );
      },
    },
    {
      field: 'tipoGestion',
      headerName: 'Tipo Gestion',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 180,
      valueGetter: ({ value }) => value && value.nombre,
    },
    {
      field: 'subTipo',
      headerAlign: 'center',
      align: 'center',
      headerName: 'Sub Tipo',
      width: 120,
    },
    {
      field: 'fecha',
      headerName: 'Fecha',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 160,
      valueFormatter: (params) =>
        params.value ? moment(params.value).format('DD/MM/YYYY hh:mm A') : null,
    },
    {
      field: 'fechaProgramada',
      headerName: 'Fecha Programada',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 160,
      valueFormatter: (params) =>
        params.value ? moment(params.value).format('DD/MM/YYYY hh:mm A') : null,
    },
    {
      field: 'usuarioGestion',
      headerName: 'Programada Por',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 250,
      valueGetter: ({ value }) => value && value.nombre,
    },
    {
      field: 'observacion',
      headerName: 'Observacion',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 120,
      renderCell: (cellValues) => {
        return (
          <>
            <Button
              onClick={() =>
                handleShowObservation(
                  cellValues.row.tipoGestion.nombre,
                  cellValues.row.observacion
                )
              }
              variant="text"
              size="small"
            >
              <VisibilityIcon titleAccess="Ver observacion" />
            </Button>
          </>
        );
      },
    },
    {
      field: 'estado',
      headerName: 'Estado',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 130,
      valueGetter: ({ value }) => value && value.nombre,
    },
    {
      field: 'consultorAsignado',
      headerName: 'Consultor',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 230,
      valueGetter: ({ value }) => value && value.nombre,
    },
  ];

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <ModalDetail
            show={showObservationTable}
            handleClose={handleHideObservationTable}
            title={titleObservacion}
            message={observacionTable}
          />
          <Container style={{ textAlign: 'center', paddingBottom: 30 }}>
            <Grid>
              <h3>Consulta Gestiones</h3>
              <p>
                Seleccione un rango de fechas para ver gestiones programadas.
              </p>
              <br />
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    <TextField
                      label="Fecha Inicial"
                      type="date"
                      fullWidth
                      required
                      inputProps={{
                        min: getMinDate(new Date()),
                      }}
                      name="fechaInicial"
                      value={filtros.fechaInicial}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="Fecha Final"
                      type="date"
                      fullWidth
                      required
                      inputProps={{
                        min: getMinDate(new Date()),
                      }}
                      name="fechaFinal"
                      value={filtros.fechaFinal}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid xs={0.5} item>
                    <Button
                      type="submit"
                      variant="outlined"
                      color="primary"
                      fullWidth
                    >
                      <SearchIcon />
                    </Button>
                  </Grid>
                </Grid>
              </form>
              <br />
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div style={{ height: 480 }}>
                    <DataGrid
                      rows={gestiones}
                      // @ts-ignore
                      columns={columns}
                      pageSize={10}
                      rowsPerPageOptions={[10]}
                      getRowId={(row) => row._id}
                      localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                      }
                      components={{ Toolbar: CustomToolbar }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </>
  );
};

export default ScheduledManagements;
