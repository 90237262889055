import gql from 'graphql-tag';

export const USERS = gql`
  query USERS {
    Users {
      _id
      nombre
      rol {
        idRol
        nombre
      }
      correo
      estado
    }
  }
`;

export const SEARCHUSERBYID = gql`
  query SEARCHUSERBYID($id: ID) {
    SearchUserById(id: $id) {
      nombre
      celular
      rol {
        idRol
        nombre
      }
      estado
      direccion
      correo
      cedula
      password
      permisos {
        cotizacion {
          basico
          tasaInteres
          cobroInteresExtra
        }
        modificaciones {
          basico
        }
        reportes {
          basico
        }
        pagos {
          basico
          editar
          eliminar
        }
        consultas {
          basico
          eliminar
          descargar
          validar
        }
        leads {
          cargarLeads
          crearLead
          asignarEspecialista
          informes
          gestionar
          gestionesProgramadas
        }
        gestionesPersuasivas {
          verSeguimientos
          crearSeguimiento
        }
        proyectos {
          editar
          eliminar
        }
      }
    }
  }
`;

export const SEARCHUSERBYPARAM = gql`
  query SearchUser($cedula: String) {
    SearchUserByParam(data: { cedula: $cedula }) {
      nombre
      direccion
      celular
      correo
      planes {
        idPlan
        estado
        descripcion
        proyecto
      }
    }
  }
`;

export const USERREPORT = gql`
  query REPORTTEUSUARIO {
    ReporteUsuario {
      _id
      fechaRealizacionPlan
      vendedor
      userdata {
        _id
        nombre
        planes {
          _id
          idPlan
          estado
          descripcion
          proyecto
        }
        celular
        rol {
          idRol
          nombre
        }
        direccion
        correo
        cedula
      }
    }
  }
`;
