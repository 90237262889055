import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import logo from 'assets/images/lar-azul.svg';

const NotFound = () => {
  return (
    <Row className="main-404--container h-100 pt-5">
      <Col className="mt-5">
        <div className="main-404--logo-container w-100 d-flex justify-content-center animate__animated animate__fadeIn">
          <img src={logo} className="main-404--logo " alt="Logo" />
        </div>
        <Row>
          <Col>
            <div className="main-404--message">
              <div className="main-4040--message-title mt-4">Error 404</div>
              <div className="main-4040--message-subtitle">
                Al parecer no hay nada que ver aqui.
                <br />
                <Link to="/">Volver a Inicio</Link>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default NotFound;
