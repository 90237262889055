import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import client from 'utils/database';
import Navbar from '../../../containers/NavBar/NavBar';
import { CREATECONFIG } from 'utils/database/mutation/projects';
import ToLogin from 'utils/ToLogin/ToLogin';

export default class NewProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasaInteres: '',
      nombre: '',
      maxFinanciacion: 0,
      numInmuebles: 0,
      numTipos: 0,
      numMaxCuotaInicial: 0,
      arrInmuebles: [],
      tipoInmuebles: [],
      show: false,
      loading: false,
      messageModal: '',
      titleModal: '',
      logged: false,
    };
  }

  componentDidMount() {
    let token = sessionStorage.getItem('token');
    if (!token) {
      this.setState({ logged: false });
    } else {
      this.setState({ logged: true });
    }
  }

  handleChangeNombre = (e) => {
    this.setState({
      nombre: e.target.value,
    });
  };

  handleTasaInteres = (e) => {
    this.setState({
      tasaInteres: e.target.value,
    });
  };

  handleChangePorcentajeFinanciar = (e) => {
    this.setState({
      maxFinanciacion: e.target.value,
    });
  };

  handleChangeNumInmuebles = (e) => {
    this.setState({
      numInmuebles: e.target.value,
    });
  };

  handleChangeNumMaxCI = (e) => {
    this.setState({
      numMaxCuotaInicial: e.target.value,
    });
  };

  handleChangeNumTipos = (e) => {
    this.setState({
      numTipos: e.target.value,
    });
    let num = e.target.value;
    let arrInmuebles = [];
    for (let i = 0; i < num; i++) {
      arrInmuebles.push(i);
    }
    this.setState({
      arrInmuebles: arrInmuebles,
    });
  };

  handleChangeArrayInmueble = (e) => {
    let id = e.target.id;
    this.setState({
      [id]: e.target.value,
    });
  };

  createConfiguration = () => {
    if (this.checkFields()) {
      this.uploadConfiguration();
    } else {
      console.log('no pasa');
      this.setState({
        show: true,
        messageModal: 'Todos los campos deben estar llenos correctamente',
        titleModal: <div className="center wrong" />,
      });
    }
  };

  checkFields = () => {
    let { nombre, tasaInteres, numInmuebles, numTipos } = this.state;
    console.log('dt: ', nombre, tasaInteres, numInmuebles, numTipos);
    let arr = [tasaInteres, numInmuebles, numTipos];
    if (nombre === '') return false;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === 0 || arr[i] === '0') return false;
    }
    return true;
  };

  uploadConfiguration = () => {
    this.setState({
      loading: true,
    });
    let listArr = [];
    let key = 'tipo';
    let keyFinan = 'maxFinan';
    let keyIni = 'maxCuotesIni';
    let finalKey = '';
    let finalKeyFinan = '';
    let finalKeyIni = '';
    let finalType;
    for (let i = 0; i < this.state.numTipos; i++) {
      finalKey = key + i;
      finalKeyFinan = keyFinan + i;
      finalKeyIni = keyIni + i;
      finalType = {
        tipo: this.state[finalKey],
        maxFinanciacion: parseFloat(this.state[finalKeyFinan]),
        maximoCuotasInicial: parseInt(this.state[finalKeyIni]),
      };
      listArr.push(finalType);
    }

    let projectData = {
      tasaInteres: parseFloat(this.state.tasaInteres),
      nombre: this.state.nombre,
      tipoInmuebles: listArr,
      // @ts-ignore
      numLotes: parseInt(this.state.numInmuebles),
      lotesAutomaticos: true,
      // 'maxFinanciacion': parseFloat(this.state.maxFinanciacion),
      // 'maximoCuotasInicial': parseInt(this.state.numMaxCuotaInicial)
    };

    client
      .mutate({ mutation: CREATECONFIG, variables: { data: projectData } })
      .then((result) => {
        console.log('result', result);
        this.setState({
          show: true,
          messageModal: 'Proyecto creado correctamente',
          titleModal: <div className="center success" />,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          show: true,
          messageModal:
            'Ocurrio un problema al intentar crear el proyecto en la base de datos',
          titleModal: <div className="center wrong" />,
          loading: false,
        });
      });
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
    this.props.history.push('/projects');
  };

  render() {
    let arrInmuebles = this.state.arrInmuebles.map((inmueble) => {
      let key = 'tipo' + inmueble;
      let keyFinan = 'maxFinan' + inmueble;
      let keyIni = 'maxCuotesIni' + inmueble;
      return (
        <Row className="pl-3 mt-2 mb-2">
          <Col className="col-xs-2 cotiCommon">
            <label>{inmueble}. Tipo : </label>
            <input
              className="form-control"
              id={key}
              value={this.state[key]}
              onChange={this.handleChangeArrayInmueble}
            />
          </Col>
          <Col className="col-xs-2 cotiCommon">
            <label>{inmueble}. % Maximo a Financiar : </label>
            <input
              className="form-control"
              id={keyFinan}
              value={this.state[keyFinan]}
              onChange={this.handleChangeArrayInmueble}
            />
          </Col>
          <Col className="col-xs-2 cotiCommon">
            <label>{inmueble}. # Maximo Cuotas Iniciales </label>
            <input
              className="form-control"
              id={keyIni}
              value={this.state[keyIni]}
              onChange={this.handleChangeArrayInmueble}
            />
          </Col>
        </Row>
      );
    });
    return !this.state.logged ? (
      <ToLogin />
    ) : (
      <>
        <Navbar></Navbar>
        <Row className="main-menu--project-page pt-5 pl-4">
          <Col>
            <Row>
              <Col>
                <div className="main--page-title text-center mt-3">
                  Nuevo Proyecto
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="mb-3">
                <label>Nombre Proyecto:</label>
                <input
                  type="text"
                  id="nameProject"
                  className="form-control"
                  value={this.state.nombre}
                  onChange={this.handleChangeNombre}
                />
              </Col>
              <Col className="mb-3">
                <label>Tasa Interes:</label>
                <input
                  type="number"
                  id="precioFull"
                  className="form-control"
                  value={this.state.tasaInteres}
                  onChange={this.handleTasaInteres}
                />
              </Col>
              <Col className="mb-3">
                <label> Numero De Inmuebles: </label>
                <input
                  type="number"
                  id="financiar"
                  className="form-control"
                  value={this.state.numInmuebles}
                  onChange={this.handleChangeNumInmuebles}
                />
              </Col>
              <Col className="mb-3">
                <label># Tipos de Inmuebles: </label>
                <input
                  type="number"
                  id="cuotainicial"
                  className="form-control"
                  value={this.state.numTipos}
                  onChange={this.handleChangeNumTipos}
                />
              </Col>
            </Row>

            {arrInmuebles}

            <Row className="mt-3">
              <Col xs={3}>
                <Button
                  color="primary"
                  className="w-100"
                  onClick={this.createConfiguration}
                >
                  {!this.state.loading ? (
                    'Crear'
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                      {` Subiendo...`}
                    </>
                  )}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Modal isOpen={this.state.show} onHide={this.handleClose}>
          {this.state.titleModal}
          <ModalBody>{this.state.messageModal}</ModalBody>
          <ModalFooter>
            <Button type="button" color="default" onClick={this.handleClose}>
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
