import { Box, Grid, MenuItem, TextField } from '@mui/material';
import { DataGrid, esES, GridToolbarFilterButton } from '@mui/x-data-grid';
import { GridToolbarExport } from '@mui/x-data-grid';
import { GridToolbarContainer } from '@mui/x-data-grid';
import Loading from 'components/containers/Loading/Loading';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axiosInstance from 'utils/database/api-leads';
import { columnsD } from './data';

const TablaSeguimientos = () => {
  const [projects, setProjects] = useState([]);
  const [seguimientos, setSeguimientos] = useState([]);
  const [idProyecto, setIdProyecto] = useState('');
  const [nameProyecto, setNameProyecto] = useState('');
  const [loading, setLoading] = useState(false);
  const proyectoDefault = 'Default';

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = () => {
    axiosInstance.get(`/projects`).then((resp) => {
      const allProjects = resp.data;
      const proys = allProjects.data.filter(
        (p) => p.nombre !== proyectoDefault
      );
      setProjects(proys);
    });
  };

  const handleChange = (e) => {
    const id = e.target.value;
    setIdProyecto(id);
    let proyecto = projects.filter(p => p._id === id)[0];
    setNameProyecto(proyecto.nombre);
    getSeguimientos(id);
  };

  const getSeguimientos = (idProyecto) => {
    setLoading(true);
    axiosInstance
      .get(`/clients/trackings/${idProyecto}`)
      .then((resp) => {
        const seguims = resp.data.data;
        setSeguimientos(seguims);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} csvOptions={{fileName: `Seguimientos-${nameProyecto}`}} />
    </GridToolbarContainer>
  );

  return (
    <>
      <Grid container spacing={1}>
        <Grid xs={2} item>
          <TextField
            select
            variant="outlined"
            fullWidth
            label="Proyecto"
            name="proyecto"
            required
            value={idProyecto}
            onChange={handleChange}
          >
            {projects.map((proyecto) => (
              <MenuItem key={proyecto._id} value={proyecto._id}>
                {proyecto.nombre}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid xs={12} item marginTop={5}>
          {loading ? (
            <Loading />
          ) : (
            <Box
              sx={{
                height: 500,
                width: '100%',
                '& .super-app-theme--header': {
                  backgroundColor: 'rgba(255, 170, 0, 0.500)',
                },
              }}
            >
              <DataGrid
                rows={seguimientos}
                // @ts-ignore
                columns={columnsD}
                pageSize={5}
                disableSelectionOnClick
                rowsPerPageOptions={[5]}
                getRowId={(row) => row._id}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default TablaSeguimientos;
