// @ts-nocheck
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from 'react-table';
import { handleDownload } from './xlsx-download';
import client from 'utils/database';
import { DETAIL_PAYMENTREPORT, PAYMENTREPORT } from 'utils/database/query/reports';
import { Filter } from './Filter';
import { Checkbox, CircularProgress, FormControlLabel, FormGroup, makeStyles } from '@material-ui/core';
import { eliminarTypeName } from './helper';
import { ReportePagosDetallado } from './PaymentReportDetail';

const useStyles = makeStyles({
  carga: {
    align: 'center',
  }
});

const PaymentReport = ({ show, filter, proyecto }) => {

  const [data, setData] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFin, setFechaFin] = useState(null);
  const [detailReport, setDetailReport] = useState(false);
  const [detailReportTable, setDetailReportTable] = useState(false);
  const [cedula, setCedula] = useState(null);
  const [lote, setLote] = useState(null);
  const [loading, setLoading] = useState(false);

  const consultarReportePagos = () => {
    setLoading(true);
    let dateI = new Date(fechaInicio);
    let dateF = new Date(fechaFin);
    dateI = parseDate(dateI).toISOString().substring(0, 10);
    dateF = parseDate(dateF).toISOString().substring(0, 10);
    if(detailReport) {
      client.query({ query: DETAIL_PAYMENTREPORT, variables: { fechaInicio: dateI, fechaFin: dateF, proyecto, cedula, lote } }).then((result) => {
        setLoading(false);
        setDetailReportTable(true);
        setDataDetail(result.data.ReportePagosDetallado);
      });
    } else {
      client.query({ query: PAYMENTREPORT, variables: { fechaInicio: dateI, fechaFin: dateF, proyecto, cedula, lote } }).then((result) => {
        setLoading(false);
        setDetailReportTable(false);
        setData(eliminarTypeName(result.data.ReportePagos));
      });
    }

  };

  const handleChangeCedula = (e) => {
    setCedula(e.target.value);
  }

  const handleChangeLote = (e) => {
    setLote(e.target.value);
  }

  const handleChangeDetailReport = (e) => {
    setDetailReport(!detailReport);
  }

  const parseDate = (fecha) => {
    fecha.setHours(0, 0, 0, 0);
    return new Date( fecha.getTime() + Math.abs(fecha.getTimezoneOffset()*60000) );
  }

  return <>{show ? <Table data={data} dataDetail={dataDetail} filter={filter} loading={loading} consultarReportePagos={consultarReportePagos}
    fechaInicio={fechaInicio} setFechaInicio={setFechaInicio} fechaFin={fechaFin} setFechaFin={setFechaFin} cedula={cedula} handleChangeCedula={handleChangeCedula} lote={lote} handleChangeLote={handleChangeLote}
    detailReport={detailReport} handleChangeDetailReport={handleChangeDetailReport} detailReportTable={detailReportTable} /> : null}</>;
};

const Table = ({ data, dataDetail, filter, loading, consultarReportePagos, fechaInicio, setFechaInicio, fechaFin, setFechaFin, cedula, handleChangeCedula, lote, handleChangeLote, detailReport, handleChangeDetailReport, detailReportTable }) => {
  const classes = useStyles();
  const columns = useMemo(
    () => [
      {
        Header: 'Cedula',
        accessor: 'cedula',
      },
      {
        Header: 'Nombre',
        accessor: 'nombre',
      },
      {
        Header: 'Celular',
        accessor: 'celular',
      },
      {
        Header: 'Plan',
        accessor: 'descripcion',
      },
      {
        Header: 'Estado',
        accessor: 'estado',
      },
      {
        Header: 'Fecha Pago',
        accessor: 'fechaPago',
      },
      {
        Header: 'Valor Pago',
        accessor: 'valorPago',
      },
      {
        Header: 'Abono Capital',
        accessor: 'abonoCapital',
      },
      {
        Header: 'Abono Interes',
        accessor: 'abonoInteres',
      },
      {
        Header: 'Observaciones',
        accessor: 'observaciones',
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const startDownload = () => {
    handleDownload(data, columns, 'Pagos');
  };

  useEffect(() => {
    setGlobalFilter(filter);
  }, [data, setGlobalFilter, filter]);

  return (
    <>
      <hr />
      {loading ? (
        <CircularProgress className={classes.carga} />
      ) : (
      <>
        <div>
          <Filter fechaInicio={fechaInicio} setFechaInicio={setFechaInicio} fechaFin={fechaFin} setFechaFin={setFechaFin} cedula={cedula} handleChangeCedula={handleChangeCedula} lote={lote} handleChangeLote={handleChangeLote}/>
          <FormGroup>
            <FormControlLabel style={{position: 'relative'}} control={<Checkbox checked={detailReport} onChange={handleChangeDetailReport} color="primary" />} label="Resumen por mes" />
          </FormGroup>
        </div>
        <Row>
          <Col xs={{ size: 2 }}>
            <Button color="success" className="w-100" onClick={consultarReportePagos}
              disabled={fechaInicio === null || fechaFin === null || fechaFin < fechaInicio}>
              <FontAwesomeIcon
                icon={['fas', 'sync-alt']}
                color="white"
                className="mr-1"
              />
        Generar tabla
            </Button>
          </Col>
        </Row>
      {(detailReportTable && dataDetail === null) || (!detailReportTable && data.length === 0)? null :
        <>
          {
            detailReportTable? <ReportePagosDetallado info={dataDetail}/> :
            <>
              {
                data.length === 0? "No se encontraron pagos realizados" :
                <>
                <div>
            <Row className="mb-3">
              <Col xs={{ offset: 10, size: 2 }}>
                <Button color="success" className="w-100" onClick={startDownload} disabled={data.length === 0}>
                  <FontAwesomeIcon
                    icon={['far', 'file-excel']}
                    color="white"
                    className="mr-2"
                  />
            Descargar
                </Button>
              </Col>
            </Row>
            <table {...getTableProps()} className="table table-bordered main--table">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon
                                icon="sort-down"
                                color="black"
                                className="ml-2"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon="sort-up"
                                color="black"
                                className="ml-2"
                              />
                            )
                          ) : (
                            <FontAwesomeIcon
                              icon="sort"
                              color="black"
                              className="ml-2"
                            />
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="pagination table--pag d-flex justify-content-end mr-5">
              <button
                type="button"
                className=" mr-2 main-table--page-index"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                <FontAwesomeIcon icon="chevron-left" color="black" />
              </button>

              <span className="table--pag__page">{pageIndex + 1}</span>
              <button
                className=" ml-2 mr-2 main-table--page-index"
                type="button"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                <FontAwesomeIcon icon="chevron-right" color="black" />
              </button>
              <span className="table--pag__length">de {pageOptions.length}</span>
            </div>
          </div>
                </>
              }
          </>
          }
        </>
      }
      </>)}
    </>
  );
};

export default PaymentReport;
