// @ts-nocheck
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from 'react-table';
import { handleDownload } from './xlsx-download';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { TABLEREPORT } from 'utils/database/query/reports';
import client from 'utils/database';
import { Filter } from './Filter';
import { eliminarTypeName } from './helper';

const useStyles = makeStyles({
  carga: {
    align: 'center',
  }
});

const QuoteReport = ({ show, filter, proyecto }) => {

  const [data, setData] = useState([]);
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFin, setFechaFin] = useState(null);
  const [cedula, setCedula] = useState(null);
  const [lote, setLote] = useState(null);
  const [loading, setLoading] = useState(false);

  const consultarReporteCuotas = () => {
    setLoading(true);
    let dateI = new Date(fechaInicio);
    let dateF = new Date(fechaFin);
    dateI = sumarDias(dateI, -1).toISOString().substring(0, 10);
    dateF = sumarDias(dateF, -1).toISOString().substring(0, 10);
    client.query({ query: TABLEREPORT, variables: { fechaInicio: dateI, fechaFin: dateF, proyecto, cedula, lote } }).then((result) => {
      setLoading(false);
      setData(eliminarTypeName(result.data.ReporteTabla));
    });
  };

  const handleChangeCedula = (e) => {
    setCedula(e.target.value);
  }

  const handleChangeLote = (e) => {
    setLote(e.target.value);
  }

  const sumarDias = (fecha, dias) => {
    let date = fecha.setDate(fecha.getDate() + dias);
    return new Date(date);
  }

  return <>{show ? <Table data={data} filter={filter} loading={loading} consultarReporteCuotas={consultarReporteCuotas}
    fechaInicio={fechaInicio} setFechaInicio={setFechaInicio} fechaFin={fechaFin} setFechaFin={setFechaFin} cedula={cedula} handleChangeCedula={handleChangeCedula} lote={lote} handleChangeLote={handleChangeLote} /> : null}</>;
};

const Table = ({ data, filter, loading, consultarReporteCuotas, fechaInicio, setFechaInicio, fechaFin, setFechaFin, cedula, handleChangeCedula, lote, handleChangeLote }) => {
  const classes = useStyles();
  const columns = useMemo(
    () => [
      {
        Header: 'Cedula',
        accessor: 'cedula',
      },
      {
        Header: 'Nombre',
        accessor: 'nombre',
      },
      {
        Header: 'Celular',
        accessor: 'celular',
      },
      {
        Header: 'Vendedor',
        accessor: 'vendedor',
      },
      {
        Header: 'Plan',
        accessor: 'descripcion',
      },
      {
        Header: 'Fecha Pago',
        accessor: 'fechaPago',
      },
      {
        Header: 'Valor Teorico',
        accessor: 'valorTeorico',
      },
      {
        Header: 'Pago Total',
        accessor: 'pagoTotal',
      },
      {
        Header: 'Estado Cuota',
        accessor: 'estadoCuota',
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const startDownload = () => {
    handleDownload(data, columns, 'Cuotas');
  };

  useEffect(() => {
    setGlobalFilter(filter);
  }, [data, setGlobalFilter, filter]);

  return (
    <>
      <hr />
      {loading ? (
        <CircularProgress className={classes.carga} />
      ) : (<>
        <div>
          <Filter fechaInicio={fechaInicio} setFechaInicio={setFechaInicio} fechaFin={fechaFin} setFechaFin={setFechaFin} cedula={cedula} handleChangeCedula={handleChangeCedula} lote={lote} handleChangeLote={handleChangeLote} />
        </div>
        <Row>
          <Col xs={{ size: 2 }}>
            <Button color="success" className="w-100" onClick={consultarReporteCuotas}
              disabled={fechaInicio === null || fechaFin === null || fechaFin < fechaInicio}>
              <FontAwesomeIcon
                icon={['fas', 'sync-alt']}
                color="white"
                className="mr-1"
              />
        Generar tabla
      </Button>
          </Col>
        </Row>
        {data.length === 0 ? null : <div>
          <Row className="mb-3">
            <Col xs={{ offset: 10, size: 2 }}>
              <Button color="success" className="w-100" onClick={startDownload}>
                <FontAwesomeIcon
                  icon={['far', 'file-excel']}
                  color="white"
                  className="mr-2"
                />
            Descargar
          </Button>
            </Col>
          </Row>
          <table {...getTableProps()} className="table table-bordered main--table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon
                              icon="sort-down"
                              color="black"
                              className="ml-2"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon="sort-up"
                              color="black"
                              className="ml-2"
                            />
                          )
                        ) : (
                          <FontAwesomeIcon
                            icon="sort"
                            color="black"
                            className="ml-2"
                          />
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pagination table--pag d-flex justify-content-end mr-5">
            <button
              type="button"
              className=" mr-2 main-table--page-index"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              <FontAwesomeIcon icon="chevron-left" color="black" />
            </button>

            <span className="table--pag__page">{pageIndex + 1}</span>
            <button
              className=" ml-2 mr-2 main-table--page-index"
              type="button"
              onClick={nextPage}
              disabled={!canNextPage}
            >
              <FontAwesomeIcon icon="chevron-right" color="black" />
            </button>
            <span className="table--pag__length">de {pageOptions.length}</span>
          </div>
        </div>}
      </>)}
    </>
  );
};

export default QuoteReport;
