import { getGridStringOperators } from '@mui/x-data-grid';
import moment from 'moment';

const filterOperators = getGridStringOperators().filter(({ value }) =>
  ['equals', 'contains'].includes(value)
);

let columnsD = [
  {
    field: 'cliente',
    headerName: 'Nombre',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 250,
    valueGetter: ({ value }) => value && value.nombre,
  },
  {
    field: 'lote',
    headerName: 'Lote',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 100,
  },
  {
    field: 'fechaSeguimiento',
    headerName: 'Fecha Seguimiento',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 160,
    valueFormatter: (params) =>
      params.value ? moment(params.value).format('DD/MM/YYYY hh:mm A') : null,
  },
  {
    field: 'tipoSeguimiento',
    headerName: 'Tipo Seguimiento',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    width: 230,
    valueGetter: ({ value }) => value && value.nombre,
  },
  {
    field: 'observacion',
    headerName: 'Observacion',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'resultado',
    headerName: 'Resultado',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    flex: 0.5,
    valueGetter: ({ value }) => value && value.nombre,
  }
];

columnsD.map((col) => (col.filterOperators = filterOperators));

export { columnsD };
