import {
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import ModalAuth from '../ModalAuthorization/ModalAuth';
import DetalleLead from './DetalleLead';

const Perfilamiento = ({ lead, perfil, savePerfilamiento }) => {
  const [perfilamiento, setPerfilamiento] = useState({
    sectorInversion: '',
    areaLote: '',
    clima: '',
    etapa: '',
    presupuesto: '',
    mesesInversion: '',
    motivoInversion: '',
  });
  const [showMessage, setShowMessage] = useState(false);
  const [messageViewed, setMessageViewed] = useState(false);

  useEffect(() => {
    if (perfil) {
      setPerfilamiento({
        sectorInversion: perfil.sectorInversion,
        areaLote: perfil.areaLote,
        clima: perfil.clima,
        etapa: perfil.etapa,
        presupuesto: perfil.presupuesto,
        mesesInversion: perfil.mesesInversion,
        motivoInversion: perfil.motivoInversion,
      });
    }
  }, [perfil]);

  const climas = ['Calido', 'Templado', 'Frio'];
  const etapas = ['Sobre planos', 'En construcción', 'Terminado'];
  const motivosInversion = [
    'Disfrute familiar',
    'Inversión',
    'Renta vacacional',
    'Todas las anteriores',
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    savePerfilamiento(perfilamiento);
  };

  const handleChange = (event) => {
    setPerfilamiento({
      ...perfilamiento,
      [event.target.name]: event.target.value,
    });
  };

  const handleHideMessage = () => {
    setShowMessage(false);
  };

  const handleShowMessage = () => {
    setShowMessage(true);
    setMessageViewed(true);
  };

  return (
    <>
      <ModalAuth show={showMessage} handleClose={handleHideMessage} />
      <Container
        style={{
          alignContent: 'center',
          paddingBlock: '40px',
          paddingTop: '10px',
        }}
      >
        <Grid
          container
          spacing={1}
          alignItems={'center'}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Grid item xs={12}>
            <Card
              style={{ minWidth: 680, padding: '0px 5px', margin: '0 auto' }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h6"
                  style={{
                    textAlign: 'center',
                    color: 'rgba(255, 170, 0, 0.968)',
                  }}
                >
                  Informacion de Perfilamiento <br />
                  <Button
                    onClick={handleShowMessage}
                    style={{ textTransform: 'none' }}
                  >
                    Ver y Aceptar Proteccion de Datos
                  </Button>
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        placeholder="En que sector busca realizar esta inversion?"
                        label="Sector de inversion"
                        variant="outlined"
                        fullWidth
                        required
                        multiline
                        rows={1}
                        name="sectorInversion"
                        value={perfilamiento.sectorInversion}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <TextField
                        placeholder="Area total en m2"
                        label="Area total"
                        type="number"
                        variant="outlined"
                        fullWidth
                        required
                        name="areaLote"
                        value={perfilamiento.areaLote}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <TextField
                        placeholder="Que presupuesto tiene para la inversión?"
                        label="Presupuesto"
                        type="number"
                        variant="outlined"
                        fullWidth
                        required
                        name="presupuesto"
                        value={perfilamiento.presupuesto}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <TextField
                        placeholder="En cuantos meses visualiza disfrutarla?"
                        label="Tiempo estimado inversion"
                        type="number"
                        variant="outlined"
                        fullWidth
                        required
                        name="mesesInversion"
                        value={perfilamiento.mesesInversion}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <TextField
                        select
                        variant="outlined"
                        fullWidth
                        required
                        label="Clima preferifo"
                        name="clima"
                        value={perfilamiento.clima}
                        onChange={handleChange}
                      >
                        {climas.map((clima) => (
                          <MenuItem key={clima} value={clima}>
                            {clima}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <TextField
                        select
                        variant="outlined"
                        fullWidth
                        required
                        label="Etapa del proyecto"
                        name="etapa"
                        value={perfilamiento.etapa}
                        onChange={handleChange}
                      >
                        {etapas.map((etapa) => (
                          <MenuItem key={etapa} value={etapa}>
                            {etapa}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <TextField
                        select
                        variant="outlined"
                        fullWidth
                        required
                        label="Motivo de inversion"
                        name="motivoInversion"
                        value={perfilamiento.motivoInversion}
                        onChange={handleChange}
                      >
                        {motivosInversion.map((motivo) => (
                          <MenuItem key={motivo} value={motivo}>
                            {motivo}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <br />
                    <Grid xs={12} item>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={!messageViewed}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <DetalleLead lead={lead}/>
    </>
  );
};

export default Perfilamiento;
