import gql from 'graphql-tag';

export const DELETETABLE = gql`
  mutation DELETETABLE($idTable: ID) {
    DeleteTable(idTable: $idTable) {
      _id
    }
  }
`;

export const UPDATETABLE = gql`
  mutation UPLOADTABLE($id: ID, $data: TableInput) {
    UpdateTable(id: $id, data: $data) {
      _id
    }
  }
`;

export const UPDATETABLESAMECUOTE = gql`
  mutation UPDATETABLESAMECUOTE(
    $idTable: ID
    $cuota: Int
    $data: UpdateTableCuoteInput
  ) {
    UpdatePaymentCuote(idTable: $idTable, cuota: $cuota, data: $data) {
      _id
    }
  }
`;

export const UPDATETABLEPAY = gql`
  mutation UPDATETABLEPAY($idTable: ID, $data: UpdateTablePaymentInput) {
    UpdatePaymentTable(idTable: $idTable, data: $data) {
      _id
    }
  }
`;

export const UPLOADTABLE = gql`
  mutation UPLOADTABLE($datos: TableInput) {
    CreateTable(data: $datos) {
      _id
    }
  }
`;
