import gql from 'graphql-tag';

export const LOTS_PROJECT_AVAILABLES = gql`
  query LOTS_PROJECT_AVAILABLES($idProyecto: String) {
    SearchAvailableLotsByIdProyeto(idProyecto: $idProyecto) {
      _id
      idProyecto
      numeroLote
      estado
      tipoInmueble
    }
  }
`;

export const VALIDATE_AVAILABLE_LOTE = gql`
  query VALIDATE_AVAILABLE_LOTE($id: ID, $tipoInm: String) {
    ValidateAvailableLote(id: $id, tipoInm: $tipoInm) {
      estado
    }
  }
`;
