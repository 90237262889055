import gql from 'graphql-tag';

export const USERREPORT = gql`
  query REPORTTEUSUARIO {
    ReporteUsuario {
      _id
      fechaRealizacionPlan
      vendedor
      saldoRestante
      numeroCuotas
      fechaInicialPlan
      valorAFinanciar
      valorTotal
      userdata {
        nombre
        cedula
        celular
        direccion
        planes {
          descripcion
          estado
          proyecto
          idPlan
        }
      }
    }
  }
`;

export const TABLEREPORT = gql`
  query REPORTETABLA($fechaInicio: String!, $fechaFin: String!, $proyecto: String!, $cedula: String, $lote: String) {
    ReporteTabla(fechaInicio: $fechaInicio, fechaFin: $fechaFin, proyecto: $proyecto, cedula: $cedula, lote: $lote) {
      cedula
      nombre
      celular
      direccion
      descripcion
      vendedor
      estado
      fechaPago
      valorTeorico
      pagoTotal
      estadoCuota
    }
  }
`;

export const PAYMENTREPORT = gql`
  query REPORTEPAYMENT($fechaInicio: String!, $fechaFin: String!, $proyecto: String!, $cedula: String, $lote: String) {
    ReportePagos(fechaInicio: $fechaInicio, fechaFin: $fechaFin, proyecto: $proyecto, cedula: $cedula, lote: $lote) {
      cedula
      nombre
      celular
      direccion
      descripcion
      estado
      fechaPago
      valorPago
      abonoCapital,
      abonoInteres
      observaciones
    }
  }
`;

export const DETAIL_PAYMENTREPORT = gql`
  query DETAIL_PAYMENTREPORT($fechaInicio: String!, $fechaFin: String!, $proyecto: String!, $cedula: String, $lote: String) {
    ReportePagosDetallado(fechaInicio: $fechaInicio, fechaFin: $fechaFin, proyecto: $proyecto, cedula: $cedula, lote: $lote) {
      header
      data
    }
  }
`;

export const LOTESREPORT = gql`
  query LOTESREPORT($idProyecto: ID) {
    ReporteLotes(idProyecto: $idProyecto) {
      tipoInmueble
      numeroLote
      estado
      idCliente
      idVendedor
    }
  }
`;

export const PROYECCIONREPORT = gql`
  query PROYECCIONREPORT($proyecto: String!, $fechaFinal: String!, $showCapital: Boolean!, $showInteres: Boolean!) {
    ReporteProyeccion(proyecto: $proyecto, fechaFinal: $fechaFinal, showCapital: $showCapital, showInteres: $showInteres) {
     header
     data
    }
  }
`;

export const PROYECCIONPAGOSREPORT = gql`
  query PROYECCIONPAGOSREPORT($proyecto: String!, $fechaFinalProyeccion: String!) {
    ReporteProyeccionVsPagos(proyecto: $proyecto, fechaFinalProyeccion: $fechaFinalProyeccion) {
      nombre
      proyecto
      lote
      valorLote
      pagoTotal
      abonoCapital
      abonoInteres
      totalProyeccion
      capitalProyeccion
      interesProyeccion
    }
  }
`;
