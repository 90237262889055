import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Consultas from 'components/pages/DashboardItems/Consultas';
import Reportes from 'components/pages/DashboardItems/Reportes';
import Modificaciones from 'components/pages/DashboardItems/Modificaciones';
import Pagos from 'components/pages/DashboardItems/Pagos';
import Cotizacion from 'components/pages/DashboardItems/Cotizacion';

export default class BodyDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 1,
    };
  }

  componentWillMount() {
    let user = JSON.parse(sessionStorage.getItem('user'));
    let type = user.rol.idRol;
    let {
      cotizacion,
      consultas,
      pagos,
      reportes,
      modificaciones,
      leads
    } = user.permisos;
    this.setState({
      type: type,
      cotizacion,
      consultas,
      pagos,
      reportes,
      modificaciones,
      leads
    });
  }

  handleConsultas = () => {
    const element = (
      <div>
        <Consultas />
      </div>
    );
    ReactDOM.render(element, document.getElementById('window'));
  };

  handleCotizacion = () => {
    const element = (
      <div>
        <Cotizacion />
      </div>
    );
    ReactDOM.render(element, document.getElementById('window'));
  };

  handlePagos = () => {
    const element = (
      <div>
        <Pagos />
      </div>
    );
    ReactDOM.render(element, document.getElementById('window'));
  };

  handleModificaciones = () => {
    const element = (
      <div>
        <Modificaciones />
      </div>
    );
    ReactDOM.render(element, document.getElementById('window'));
  };

  handleReporte = () => {
    const element = (
      <div>
        <Reportes />
      </div>
    );
    ReactDOM.render(element, document.getElementById('window'));
  };

  render() {
    return (
      <Row className="p-3">
        <Col>
          <Breadcrumb>
            <BreadcrumbItem active>Dashboard</BreadcrumbItem>
          </Breadcrumb>
          <Row className="row">
            {this.state.cotizacion.basico ? (
              <Col xl={3} sm={6} className=" mb-3">
                <Card className="text-white bg-dark o-hidden h-100 main-dashboard--card">
                  <CardBody>
                    <div className="card-body-icon">
                      <FontAwesomeIcon icon="calculator" />
                    </div>
                    <div className="mr-4">Cotizacion</div>
                  </CardBody>
                  <CardFooter>
                    <Button
                      className="main-dashboard--card-button text-white clearfix small w-100"
                      onClick={this.handleCotizacion}
                    >
                      <span className="float-left">Nueva cotizacion</span>
                      <span className="float-right">
                        <FontAwesomeIcon icon="angle-double-right" />
                      </span>
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            ) : null}
            {this.state.consultas.basico ? (
              <Col xl={3} sm={6} className=" mb-3">
                <Card className="text-white bg-dark o-hidden h-100 main-dashboard--card">
                  <CardBody>
                    <div className="card-body-icon">
                      <FontAwesomeIcon icon="table" />
                    </div>
                    <div className="mr-5">Consultas</div>
                  </CardBody>
                  <CardFooter>
                    <Button
                      className="main-dashboard--card-button text-white clearfix small w-100"
                      onClick={this.handleConsultas}
                    >
                      <span className="float-left">Ver</span>
                      <span className="float-right">
                        <FontAwesomeIcon icon="angle-double-right" />
                      </span>
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            ) : null}
            {this.state.pagos.basico ? (
              <Col xl={3} sm={6} className=" mb-3">
                <Card className="text-white bg-dark o-hidden h-100 main-dashboard--card">
                  <CardBody>
                    <div className="card-body-icon">
                      <FontAwesomeIcon icon="money-check-alt" />
                    </div>
                    <div className="mr-5">Pagos</div>
                  </CardBody>
                  <CardFooter>
                    <Button
                      className="main-dashboard--card-button text-white clearfix small w-100"
                      onClick={this.handlePagos}
                    >
                      <span className="float-left">Nuevo pago</span>
                      <span className="float-right">
                        <FontAwesomeIcon icon="angle-double-right" />
                      </span>
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            ) : null}
            {this.state.modificaciones.basico ? (
              <Col xl={3} sm={6} className=" mb-3">
                <Card className="text-white bg-dark o-hidden h-100 main-dashboard--card">
                  <CardBody>
                    <div className="card-body-icon">
                      <FontAwesomeIcon icon="cogs" />
                    </div>
                    <div className="mr-5">Modificaciones</div>
                  </CardBody>
                  <CardFooter>
                    <Button
                      className="main-dashboard--card-button text-white clearfix small w-100"
                      onClick={this.handleModificaciones}
                    >
                      <span className="float-left">Realizar cambios</span>
                      <span className="float-right">
                        <FontAwesomeIcon icon="angle-double-right" />
                      </span>
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            ) : null}
            {this.state.reportes.basico ? (
              <Col xl={3} sm={6} className=" mb-3">
                <Card className="text-white bg-dark o-hidden h-100 main-dashboard--card">
                  <CardBody>
                    <div className="card-body-icon">
                      <FontAwesomeIcon icon="database" />
                    </div>
                    <div className="mr-5">Reportes</div>
                  </CardBody>
                  <CardFooter>
                    <Button
                      className="main-dashboard--card-button text-white clearfix small w-100"
                      onClick={this.handleReporte}
                    >
                      <span className="float-left">Realizar reporte</span>
                      <span className="float-right">
                        <FontAwesomeIcon icon="angle-double-right" />
                      </span>
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>
    );
  }
}
