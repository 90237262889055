import gql from 'graphql-tag';

export const UPDATEPROJECT = gql`
  mutation UPDATEPROJECT($id: ID, $data: UpdateProjectInput) {
    UpdateProject(id: $id, data: $data) {
      _id
    }
  }
`;

export const CREATECONFIG = gql`
  mutation CREATECONFIG($data: CreateProjectInput) {
    CreateProject(data: $data) {
      _id
    }
  }
`;

export const DELETEPROJECT = gql`
  mutation DELETEPROJECT($id: ID) {
    DeleteProject(id: $id) {
      _id
    }
  }
`;
