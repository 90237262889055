import { Button } from '@mui/material';
import {
  DataGrid,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';

import React, { useState } from 'react';
import ModalDetail from '../ModalDetail/ModalDetail';

const FollowUpManagement = ({ follows, medioGestion }) => {

  const [showObservationTable, setShowObservationTable] = useState(false);
  const [observacionTable, setObservacionTable] = useState('');
  const [titleObservacion, setTitleObservacion] = useState('');

  if (follows.length > 0) {
    follows.map((foll) => (foll.medioGestion = medioGestion));
  }

  const columns = [
    {
      field: 'fecha',
      headerAlign: 'center',
      align: 'center',
      headerName: 'Fecha y Hora',
      width: 200,
      valueFormatter: (params) =>
        params.value ? moment(params.value).format('DD/MM/YYYY hh:mm A') : null,
    },
    {
      field: 'medioGestion',
      headerName: 'Medio de Gestion',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 300,
    },
    {
      field: 'observacion',
      headerName: 'Observacion',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      width: 320,
      renderCell: (cellValues) => {
        return (
          <>
            <Button
              onClick={() => handleShowObservation(cellValues.row)}
              variant="text"
              size="small"
            >
              <VisibilityIcon titleAccess="Ver observacion" />
            </Button>
          </>
        );
      },
    },
  ];

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );

  const handleShowObservation = (data) => {
    setObservacionTable(data.observacion);
    setTitleObservacion('Seguimiento de gestión: ' + data.medioGestion);
    setShowObservationTable(true);
  }

  const handleHideObservationTable = () => {
    setObservacionTable('');
    setTitleObservacion('');
    setShowObservationTable(false);
  };

  return (
    <>
      <ModalDetail show={showObservationTable} handleClose={handleHideObservationTable} title={titleObservacion} message={observacionTable}/>
      <h5 style={{ color: 'rgba(255, 170, 0, 0.968)' }}>
        Seguimientos por Gestion
      </h5>
      <div style={{ height: 400 }}>
        <DataGrid
          rows={follows}
          // @ts-ignore
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          components={{ Toolbar: CustomToolbar }}
          getRowId={(row) => row._id}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        />
      </div>
    </>
  );
};

export default FollowUpManagement;
