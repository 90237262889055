import React, { useEffect, useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { SidebarData } from './sidebarData';
import './Navbar.css';
import { IconContext } from 'react-icons';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const [username, setUsername] = useState('');
  const [role, setRole] = useState(2);
  const navigate = useNavigate();

  const showSidebar = () => setSidebar(!sidebar);

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('configuracion');
    // Link to login
    navigate('/login');
  };

  const validateLink = (link) => {
    return (link === "Usuarios" && role !== 7)
    || (link === "Leads" && role === 1);
  }

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    if(user){
      setUsername(user.nombre);
      setRole(user.rol.idRol);
    }
  }, [])

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className="navbar">
          <Link to="#" className="menu-bars">
            <FaIcons.FaBars onClick={showSidebar} />
          </Link>
          <h4 className="menu">Menu</h4>
          <div id='optionMenu' className="ml-auto">
            <h4 className="menu">{username}</h4>
            <Button
              id="logout"
              type="button"
              className="main-navbar--button"
              onClick={handleLogout}
            >
              <FontAwesomeIcon icon="sign-out-alt" color="white" />
            </Button>
          </div>
        </div>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className="nav-menu-items">
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <AiIcons.AiOutlineClose onClick={showSidebar}/>
              </Link>
            </li>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link hidden={validateLink(item.title)} to={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
