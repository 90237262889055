// @ts-nocheck
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import axiosInstance from 'utils/database/api-leads';
import { toast } from 'react-toastify';

const CreateManagement = ({ addManagement }) => {
  const [gestiones, setGestiones] = useState([]);
  const [subtipos, setSubtipos] = useState([]);
  const [consultores, setConsultores] = useState([]);
  const [unableConsultores, setUnableConsultores] = useState(false);
  const [gestion, setGestion] = useState({
    idGestion: '',
    fecha: '',
    observacion: '',
    idConsultor: '',
    subtipo: '',
    programada: false,
    fechaProgramada: '',
    idUser: '',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    addManagement(gestion);
    resetForm();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === 'idGestion') {
      // Se pone el idGestion y se reinicia el subtipo y el idConsultor
      setGestion({ ...gestion, [name]: value, subtipo: '', idConsultor: '' });
      const gestions = gestiones.filter((gest) => gest._id === value);
      if (gestions.length > 0) {
        let gestionSelected = gestions[0];
        // Se toman subtipos para ser mostrados en caso de que la gestion tenga
        setSubtipos(gestionSelected.subtipos);

        // Si la gestion que se está seleccionando requiere consultor
        setUnableConsultores(gestionSelected.requiereConsultor);
      } else {
        setUnableConsultores(false);
      }
    } else if (name === 'programada') {
      let checked = event.target.checked;
      setGestion({
        ...gestion,
        [name]: checked,
        fechaProgramada: checked ? gestion.fechaProgramada : '',
        fecha: checked ? '' : gestion.fecha,
      });
    } else {
      setGestion({ ...gestion, [name]: value });
    }
  };

  const resetForm = () => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    setGestion({
      idGestion: '',
      fecha: '',
      observacion: '',
      idConsultor: '',
      subtipo: '',
      programada: false,
      fechaProgramada: '',
      idUser: user._id,
    });
  };

  const getGestiones = () => {
    let userLocal = JSON.parse(sessionStorage.getItem('user'));
    axiosInstance
      .get(`/leads/management/types/get?`, {
        params: {
          userId: userLocal._id,
        },
      })
      .then((resp) => {
        const gestions = resp.data.data;
        setGestiones(gestions);
      });
  };

  const getConsultores = () => {
    axiosInstance
      .get(`/users/roles/citas`)
      .then((resp) => {
        const users = resp.data;
        setConsultores(users.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const getMinDate = (date) => {
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return date.toISOString().slice(0, 16);
  };

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    setGestion({ ...gestion, idUser: user._id });
    getGestiones();
    getConsultores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container
        maxWidth="xl"
        style={{ paddingBlock: '20px', paddingLeft: '0px' }}
      >
        <Grid container maxWidth={'true'} spacing={1}>
          <Grid item xs={12}>
            <Card
              style={{
                padding: '0px 5px',
                margin: '0 auto',
              }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h6"
                  style={{
                    textAlign: 'center',
                    color: 'rgba(255, 170, 0, 0.968)',
                  }}
                >
                  Crear Gestion
                </Typography>
                <br />
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        select
                        variant="outlined"
                        fullWidth
                        label="Tipo de Gestion"
                        name="idGestion"
                        value={gestion.idGestion}
                        required
                        onChange={handleChange}
                      >
                        {gestiones.map((gest) => (
                          <MenuItem key={gest._id} value={gest._id}>
                            {gest.nombre}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        select
                        variant="outlined"
                        fullWidth
                        label="Tipo Cita"
                        name="subtipo"
                        value={gestion.subtipo}
                        required={subtipos.length > 0}
                        disabled={subtipos.length === 0}
                        onChange={handleChange}
                      >
                        {subtipos.map((sub) => (
                          <MenuItem key={sub} value={sub}>
                            {sub}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="datetime-local"
                        label="Fecha y Hora"
                        type="datetime-local"
                        fullWidth
                        required={!gestion.programada}
                        inputProps={{
                          min: getMinDate(new Date()),
                        }}
                        name="fecha"
                        value={gestion.fecha}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        select
                        variant="outlined"
                        fullWidth
                        label="Consultor"
                        name="idConsultor"
                        value={gestion.idConsultor}
                        onChange={handleChange}
                        required={unableConsultores}
                        disabled={!unableConsultores}
                      >
                        {consultores.map((consultor) => (
                          <MenuItem key={consultor._id} value={consultor._id}>
                            {consultor.nombre}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={gestion.programada}
                            name="programada"
                            onChange={handleChange}
                          />
                        }
                        label="Programar Gestion"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="datetime-local"
                        label="Fecha y Hora Programada"
                        type="datetime-local"
                        fullWidth
                        inputProps={{
                          min: getMinDate(new Date()),
                        }}
                        name="fechaProgramada"
                        value={gestion.fechaProgramada}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                        disabled={!gestion.programada}
                        required={gestion.programada}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        placeholder="Ingrese el detalle de la gestion"
                        label="Observacion"
                        variant="outlined"
                        fullWidth
                        required
                        multiline
                        rows={1}
                        name="observacion"
                        value={gestion.observacion}
                        onChange={handleChange}
                      />
                    </Grid>
                    <br />
                    <br />
                    <Grid xs={12} item>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CreateManagement;
