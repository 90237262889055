// @ts-nocheck
import axiosInstance from 'utils/database/api-leads';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Avatar, Button, Container, Grid } from '@mui/material';
import styled from '@emotion/styled';
import EmailIcon from '@mui/icons-material/Email';
import PortraitIcon from '@mui/icons-material/Portrait';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import HouseIcon from '@mui/icons-material/House';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CreateManagement from './CreateManagement';
import HistoryManagement from './HistoryManagement';
import { toast } from 'react-toastify';
import Perfilamiento from './Perfilamiento';

const LeadManage = () => {
  const [lead, setLead] = useState({});
  const [name, setName] = useState('');
  const [proyecto, setProyecto] = useState('');
  const [managements, setManagements] = useState([]);
  const [showPerfilamiento, setShowPerfilamiento] = useState(false);
  const params = useParams();

  const getLead = () => {
    axiosInstance.get(`/leads/${params.id}`).then((resp) => {
      const lead = resp.data.data;
      setLead(lead);
      setName(lead.nombreCompleto);
      setProyecto(lead.proyecto.nombre)
      setManagements(lead.gestiones);
    });
  };

  const addManagement = (data) => {
    axiosInstance
      .post(`/leads/management/${lead._id}`, data)
      .then((res) => {
        toast.success(res.data.message);
        getLead();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const addFollowUp = (idGestion, observacion) => {
    const data = { idGestion, observacion };
    axiosInstance
      .post(`/leads/management/follow-up/${lead._id}`, data)
      .then((res) => {
        toast.success(res.data.message);
        getLead();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const finalizeManagement = (idGestion, idTypo, idResponse) => {
    const data = { idGestion, idTypo, idResponse };
    axiosInstance
      .post(`/leads/management/finalize/${lead._id}`, data)
      .then((res) => {
        toast.success(res.data.message);
        getLead();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleSavePerfilamiento = (data) => {
    axiosInstance
      .patch(`/leads/perfilamiento/${lead._id}`, data)
      .then((res) => {
        toast.success(res.data.message);
        getLead();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const stringAvatar = (name) => {
    return name !== '' ? `${name.split('')[0]}` : '';
  };

  const OrangeAvatar = styled(Avatar)`
    background-color: orange;
  `;

  useEffect(() => {
    getLead();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container
        maxWidth={'true'}
        style={{
          alignContent: 'center',
          paddingBlock: '40px',
          paddingTop: '10px',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <h3>Gestion y Perfilamiento de Lead</h3>
          <p>
            En esta sesion podra crear gestiones y sus respectivos seguimientos,
            ademas podrá perfilar el lead.
          </p>
        </div>
        <br />
        <Grid container spacing={1}>
          <Grid
            item
            xs={3}
            alignItems={'center'}
            padding={'5px'}
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '15px',
            }}
          >
            <OrangeAvatar>{stringAvatar(name)}</OrangeAvatar>
            <h5 style={{ marginTop: '10px' }}>{lead.nombreCompleto}</h5>
            <p>
              <EmailIcon style={{ marginRight: '5px' }} color={'primary'} />{' '}
              {lead.correo}
            </p>
            <p>
              <LocalPhoneIcon
                style={{ marginRight: '5px' }}
                color={'primary'}
              />
              {lead.celular}
            </p>
            <p>
              <HouseIcon
                style={{ marginRight: '5px' }}
                color={'primary'}
              />
              {proyecto}
            </p>
            <Link to={`/leads/detail/${lead._id}`}>Actualizar</Link>
            <br />
            <Link to={'/leads'}>Volver</Link>
          </Grid>
          <Grid item xs={7}>
            {!showPerfilamiento ? (
              <>
                <CreateManagement addManagement={addManagement} />
                <br />
                <HistoryManagement
                  managements={managements}
                  addFollowUp={addFollowUp}
                  finalizeManagement={finalizeManagement}
                />
              </>
            ) : (
              <Perfilamiento
                lead={lead}
                perfil={lead.perfilamiento}
                savePerfilamiento={handleSavePerfilamiento}
              />
            )}
          </Grid>
          <Grid
            item
            xs={2}
            alignItems={'center'}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            {showPerfilamiento ? (
              <Button
                style={{ textTransform: 'none' }}
                onClick={() => setShowPerfilamiento(false)}
              >
                <ManageAccountsIcon /> Volver a Gestion
              </Button>
            ) : (
              <Button
                style={{ textTransform: 'none' }}
                onClick={() => setShowPerfilamiento(true)}
              >
                <PortraitIcon /> Ir a Perfilamiento
              </Button>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default LeadManage;
