// @ts-nocheck
/* eslint-disable no-nested-ternary */
import React, {useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';

import { handleDownloadDataDetail } from './xlsx-download';

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#59B6F3',
  },
  cell: {
    fontWeight: 'bold',
    textAlign: 'center',
    border: '1px solid',
  },
  cellBody: {
    border: '1px solid',
  },
  carga: {
    align: 'center',
  },
  date: {
    backgroundColor: 'red !important'
  }
});

export const ReportePagosDetallado = ({info}) => {
  let columns = [];

  const setColumns = (col) => {
    return [
      {
        Header: 'PagoTotal',
        accessor: `${col.toLowerCase()}.totalPago`,
      },
      {
        Header: 'Capital',
        accessor: `${col.toLowerCase()}.capital`,
      },
      {
        Header: 'Interes',
        accessor: `${col.toLowerCase()}.interes`,
      },
    ]
  }

  info.header.map((col, i) => {
    if (i > 4) {
      columns.push({
        Header: `${col}`,
        style: { textAlign: 'center'},
        columns: setColumns(col)
      });
    } else {
        columns.push({
        Header: col,
        accessor: col.toLocaleLowerCase(),
        });
    }
    return 0;
  });

  let header = columns;

  return (
    <>
      <TableData
        data={info.data}
        columns={header}
        filter=""
      />
    </>
  );
}


const TableData = ({ data, columns, filter}) => {
  const classes = useStyles();

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
    setGlobalFilter,
  } = useTable(
    {
      data,
      columns,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const startDownload = () => {
    handleDownloadDataDetail(data, columns, 'DetallePagos');
  };

  useEffect(() => {
    setGlobalFilter(filter);
  }, [data, setGlobalFilter, filter]);

  return (
    <>
      <hr />
        <>
          {
            data.length === 0? "No se encontraron pagos realizados" :
            <>
            <Row>
            <Col xs={{ size: 2 }}>
              <Button
                color="success"
                className="w-90"
                onClick={startDownload}
                disabled={columns.length === 0 || data.length === 0}
              >
                <FontAwesomeIcon
                  icon={['far', 'file-excel']}
                  color="white"
                  className="mr-1"
                />
                Descargar
              </Button>
            </Col>
          </Row>
          <br />
          <table
            {...getTableProps()}
            className="table table-bordered main--table"
          >
            <thead className={classes.container}>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} className={classes.cell}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={classes.cellBody}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {columns.length > 0 || data.length > 0 ? (
            <Pagination
              previousPage={previousPage}
              canPreviousPage={canPreviousPage}
              pageIndex={pageIndex}
              nextPage={nextPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
            />
          ) : null}
            </>
          }
        </>
    </>
  );
};

const Pagination = ({
  previousPage,
  canPreviousPage,
  pageIndex,
  nextPage,
  canNextPage,
  pageOptions,
}) => {
  return (
    <div className="pagination table--pag d-flex justify-content-end mr-5">
      <button
        type="button"
        className=" mr-2 main-table--page-index"
        onClick={previousPage}
        disabled={!canPreviousPage}
      >
        <FontAwesomeIcon icon="chevron-left" color="black" />
      </button>

      <span className="table--pag__page">{pageIndex + 1}</span>
      <button
        className=" ml-2 mr-2 main-table--page-index"
        type="button"
        onClick={nextPage}
        disabled={!canNextPage}
      >
        <FontAwesomeIcon icon="chevron-right" color="black" />
      </button>
      <span className="table--pag__length">de {pageOptions.length}</span>
    </div>
  );
};
